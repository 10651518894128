import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import paths from './paths';
import Router from './Router';
import NavBar from './NavBar';
import ErrorBoundary from './ErrorBoundary';
import AutoLogin from '../containers/AutoLogin';
import ToastManager from './ToastManager';

const App = () => (
  <ErrorBoundary>
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>Astroselling: multiplica tus ventas online</title>
        {/* Todo: add meta info */}
        {/* <meta name="description" content="Nested component" /> */}
      </Helmet>
      <NavBar />
      <section className="flex flex-1 bg-blue-lightest section">
        <Router />
      </section>
      <ToastManager />
    </div>
  </ErrorBoundary>
);

export default () => (
  <Switch>
    <Route path={paths.AUTO_LOGIN} component={AutoLogin} />
    <Route path="/" component={App} />
  </Switch>
);
