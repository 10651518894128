import { gql } from 'apollo-boost';

export default gql`
fragment ProductForList on ProductType {
  id
  id_in_source
  title
  currency
  price
  price_var_min
  price_var_max
  sku
  stock
  description
  images
  source_id
  link_count
  variations {
    id 
    title
    currency
    price
    sku
    stock
    images
  }
}`;
