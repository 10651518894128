import { compose, withState, withHandlers } from 'recompose';

export default compose(
  withState('measureName', 'onChangeSizeName', ''),
  withState('measurementValuesMap', 'onChangeMeasureAttribute', {}),
  withState('submittedIntention', 'onChangeSubmittedIntention', false),
  withState('attributesChanged', 'onChangeAttributesChanged', false),
  withState('editionMode', 'onSelectedEditionMode', false),
  withHandlers({
    handleSelectAttribute: ({ onSelectedAttributes,  onSelectedAttributesMap, selectedAttributes, selectedAttributesMap, sizeChart, onChangeSizeChart, onSelectedEditionMode}) => (node) => {
      onSelectedEditionMode(false);
      // If attribute is already selected, we should remove it from both structures
      if (selectedAttributesMap[node.id]) {
        selectedAttributesMap[node.id] = false;
        onSelectedAttributesMap(selectedAttributesMap);
        const filteredAttributes = selectedAttributes.filter(value => value.id !== node.id);
        onSelectedAttributes(filteredAttributes);
        if (sizeChart) {
          sizeChart.columns = sizeChart.columns.filter(col => col.id !== node.id);
          sizeChart.sizes = sizeChart.sizes.map((size)=> {{return {...size, measurements: size.measurements.filter(msr => msr.id !== node.id)}}});
          onChangeSizeChart(sizeChart);
        }
      // attribute added, added it two both structures. 
      } else {
        selectedAttributesMap[node.id] = true;
        selectedAttributes.push(node);
        if(sizeChart) {
          sizeChart.columns = [...sizeChart.columns, node];
          for (let index in sizeChart.sizes) {
            sizeChart.sizes[index].measurements = [...sizeChart.sizes[index].measurements, {id: node.id, value: '0'}];
          }
          onChangeSizeChart(sizeChart);
        }
        onSelectedAttributesMap(selectedAttributesMap);
        onSelectedAttributes(selectedAttributes);
      }
    },
    handleChangeMeasurement: ({ onChangeMeasureAttribute, measurementValuesMap, onChangeSubmittedIntention }) => (key, value) => {
      measurementValuesMap[key] = value;
      onChangeMeasureAttribute(measurementValuesMap);
      onChangeSubmittedIntention(false);
    },
    handleGridRowsUpdated: ({ onChangeSizeChart, sizeChart }) => ({ fromRow, updated }) => {
      const keys = Object.keys(updated);
      if (keys.includes('name')) {
        updated['sizeName']= updated['name'];
        updated['name']=null;
        sizeChart.sizes[fromRow] = {
          ...sizeChart.sizes[fromRow],
          ...updated
        }
      } else {
        sizeChart.sizes[fromRow].measurements = sizeChart.sizes[fromRow].measurements.map((node) => {
          if (keys.includes(node.id)) {
            const id = keys[0];
            return {
              id: id,
              value: updated[id]
            }
          }
        })
      }
      onChangeSizeChart(sizeChart);
    },
    handleCreateMeasurement: ({
      onChangeSubmittedIntention,
      createSizeQuide,
      editSizeQuide,
      title,
      measureName,
      measurementValuesMap, 
      user, 
      selectedAttributes,
      onChangeMeasureAttribute, 
      onChangeSizeName, 
      sizeChart,
      onChangeSizeChart}) => () => 
    {
      onChangeSubmittedIntention(true);
      const stores = (user.me && user.me.stores) || [];
      const storeSession = sessionStorage.getItem('store');
      const storeFromSession = storeSession ? JSON.parse(storeSession) : null ;
      const store = stores.find(store => store.id === storeFromSession.id);
      const measurements = selectedAttributes.map(node => {
        return {id: node.id, value: +measurementValuesMap[node.id]}
      })
      // If not yet created call creation
      if (!sizeChart) {
        createSizeQuide({
          "store_id": store.id,
          "size_chart": {
    	      "name": title,
            "sizes": [
              {
                "name": measureName,
                "measurements": measurements
              }
            ]
          }
        }).then(({data}) => {
          const sizeChart = data.createSizeChart.sizeChart;
          onChangeSubmittedIntention(false);
          onChangeMeasureAttribute({});
          onChangeSizeName('');
          onChangeSizeChart(sizeChart);
        })
      } else {
        editSizeQuide({
          "store_id": store.id,
          "size_chart_id": sizeChart.id,
          "size_chart": {
    	      "name": sizeChart.chartName,
            "sizes": [
              {
                "name": measureName,
                "measurements": measurements
              },
              ...sizeChart.sizes.map((size) => {return {name: size.sizeName, measurements: size.measurements.map(measurement => {return {id: measurement.id, value: +measurement.value}})}})
            ]
          }
        }).then(({data}) => {
          const sizeChart = data.updateSizeChart.sizeChart;
          onChangeSubmittedIntention(false);
          onChangeMeasureAttribute({});
          onChangeSizeName('');
          onChangeSizeChart(sizeChart);
        })
      }
    },
    handleEditSizeGuide: ({
      selectedAttributes,
      onChangeSubmittedIntention,
      createSizeQuide,
      editSizeQuide,
      measureName,
      onChangeMeasureAttribute, 
      onChangeSizeName, 
      sizeChart,
      user,
      measurementValuesMap,
      onChangeSizeChart}) => () => {
        const stores = (user.me && user.me.stores) || [];
        const storeSession = sessionStorage.getItem('store');
        const storeFromSession = storeSession ? JSON.parse(storeSession) : null ;
        const store = stores.find(store => store.id === storeFromSession.id);
        const measurements = selectedAttributes.map(node => {
          return {id: node.id, value: +measurementValuesMap[node.id]}});
        editSizeQuide({
          "store_id": store.id,
          "size_chart_id": sizeChart.id,
          "size_chart": {
            "name": sizeChart.chartName,
            "sizes": sizeChart.sizes.map((size) => {return {name: size.sizeName, measurements: size.measurements.map(measurement => {return {id: measurement.id, value: +measurement.value}})}})
          }
        }).then(({data}) => {
          const sizeChart = data.updateSizeChart.sizeChart;
          onChangeSubmittedIntention(false);
          onChangeMeasureAttribute({});
          onChangeSizeName('');
          onChangeSizeChart(sizeChart);
        })}
      })
);
