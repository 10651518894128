import React, { useState } from 'react';
import { compose, withState } from 'recompose';
import Button from '../../../components/Button';
import ArrowRightIcon from '../../../components/svgs/ArrowRight';
import Loader from '../../../components/svgs/Loader';
import withHandleTags from './withHandleTags';

const AddTags = props => {
  const {
    onChangeStock,
    onChangeTime,
    onChangeLocation,
    onChangeShipping,
    stock,
    time,
    location,
    shipping,
    updateTag,
    nextStep,
    jumpStep,
    loadingData
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const saveTags = () => {
    const tagList = [
      { tagEnum: "STOCK", response: stock },
      { tagEnum: "TIME", response: time },
      { tagEnum: "LOCATION", response: location },
      { tagEnum: "SHIPPING", response: shipping },
    ];

    setIsLoading(true);
    Promise.all(tagList.map(tag => updateTag(tag.tagEnum, tag.response))).then(() => {
      setIsLoading(false);
      nextStep();
    });
  }

  return (
    <div className="flex flex-1 items-start justify-center md:items-center">
      <div style={{ minHeight: "540px" }} className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
        <div className="onboarding flex-1 tags-container">
          {
            !loadingData ? (
              <>
                <div className="flex flex-col">
                  <div className="tag-wrapper">
                    <div className="individual-tag">
                      <p className="mb-4" style={{ fontSize: "18px" }}>
                        Cuando detectemos una pregunta sobre <br />
                        <span className="font-bold text-vivid-blue">Stock</span> tu respuesta rápida será
                  </p>
                      <textarea
                        style={{ border: "solid 1px lightgrey" }}
                        className="rounded w-full mb-2"
                        rows="3"
                        name="stock"
                        placeholder="Si, tenemos en stock."
                        onChange={(ev) => onChangeStock(ev.target.value)}
                        value={stock}
                      >
                      </textarea>
                    </div>
                    <div className="individual-tag">
                      <p className="mb-4" style={{ fontSize: "18px" }}>
                        Cuando detectemos una pregunta sobre <br />
                        <span className="font-bold text-vivid-blue">Horario</span> tu respuesta rápida será
                  </p>
                      <textarea
                        style={{ border: "solid 1px lightgrey" }}
                        className="rounded w-full mb-2"
                        rows="3"
                        name="horario"
                        placeholder="Nuestro horario de atención es de Lunes a Viernes de 9 a 18 horas."
                        onChange={(ev) => onChangeTime(ev.target.value)}
                        value={time}
                      >
                      </textarea>
                    </div>
                  </div>
                  <div className="tag-wrapper">
                    <div className="individual-tag">
                      <p className="mb-4" style={{ fontSize: "18px" }}>
                        Cuando detectemos una pregunta sobre <br />
                        <span className="font-bold text-vivid-blue">Ubicación</span> tu respuesta rápida será
                  </p>
                      <textarea
                        style={{ border: "solid 1px lightgrey" }}
                        className="rounded w-full mb-2"
                        rows="3"
                        name="ubicacion"
                        placeholder="Por políticas de Mercado Libre no podemos pasarte nuestra ubicación exacta, estamos en el centro de la ciudad. Luego de comprar obtendrás todos los datos."
                        onChange={(ev) => onChangeLocation(ev.target.value)}
                        value={location}
                      >
                      </textarea>
                    </div>
                    <div className="individual-tag">
                      <p className="mb-4" style={{ fontSize: "18px" }}>
                        Cuando detectemos una pregunta sobre <br />
                        <span className="font-bold text-vivid-blue">Envíos</span> tu respuesta rápida será
                  </p>
                      <textarea
                        style={{ border: "solid 1px lightgrey" }}
                        className="rounded w-full mb-4"
                        rows="3"
                        name="envios"
                        placeholder="Ej: Hacemos envíos a todo el país."
                        onChange={(ev) => onChangeShipping(ev.target.value)}
                        value={shipping}
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
                <Button className="tag-button" onClick={() => { saveTags(nextStep) }} isLoading={isLoading}>
                  Continuar <ArrowRightIcon />
                </Button>
                {/* <div className="mt-4">
            <a className="" href="#" onClick={jumpStep}>Saltar este paso</a>
          </div> */}
              </>
            ) :
              <div className="onboarding flex-1 flex-row items-center justify-center">
                <Loader className="text-vivid-blue self-center h-6" />
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default compose(withHandleTags)(AddTags);
