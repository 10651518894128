import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { CSSTransition } from 'react-transition-group';

import EventService from '../../../../app/EventService';
import Header from './Header';
import withPredefinedAnswers from '../withPredefinedAnswers';
import withAddTag from './withAddTag';
import withUpdateTag from './withUpdateTag';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loader from '../../../../components/svgs/Loader';

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags,
      visible: false,
      selected: null,
      loading: false,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.onSelected = this.onSelected.bind(this);
    this.notSelected = this.notSelected.bind(this);
    this.escButtonPressed = this.escButtonPressed.bind(this);
  }

  componentDidMount() {
    this.setState({ visible: true });
    document.addEventListener('keydown', this.escButtonPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escButtonPressed, false);
  }

  UNSAFE_componentWillReceiveProps({ tags }) {
    if (tags) {
      this.setState({ tags });
    }
  }

  setSaved(savedTag) {
    const tag = { [savedTag.id]: { ...savedTag, touched: false, isNew: false } };

    this.setState({ tags: { ...this.state.tags, ...tag } });
  }

  saveHandler() {
    Object.keys(this.state.tags).forEach(tag => {
      const value = this.state.tags[tag];
      if (!value.touched) {
        return;
      }
      this.setState({ loading: true });
      const message = isSuccessful => {
        const textMessage = isSuccessful ? 'Cambios guardados' : 'Ocurrió un error, inténtelo nuevamente';
        return (
          <div className="success-spanMessage">
            <i style={{ fontSize: '20px' }} className="fa fa-check-circle mr-3" aria-hidden="true" />
            <span>{textMessage}</span>
          </div>
        );
      };
      if (value.isNew) {
        this.props
          .addTag(tag, value.response)
          .then(({ data }) => {
            if (data.addTag) {
              this.setSaved(data.addTag);
              this.setState({ loading: false });
              EventService.triggerEvent('toast', [message(true), 'success']);
            }
          })
          .catch(() => {
            this.setState({ loading: false });
            EventService.triggerEvent('toast', [message(false), 'error']);
          });
      } else {
        this.props
          .updateTag(tag, value.response)
          .then(({ data }) => {
            if (data.updateTag) {
              this.setSaved(data.updateTag);
              this.setState({ loading: false });
              EventService.triggerEvent('toast', [message, 'success']);
            }
          })
          .catch(() => {
            this.setState({ loading: false });
            EventService.triggerEvent('toast', [message(false), 'error']);
          });
      }
    });
  }

  changeHandler(event) {
    const tag = {
      [event.target.name]: {
        ...this.state.tags[event.target.name],
        response: event.target.value,
        touched: true,
      },
    };
    this.setState({
      tags: {
        ...this.state.tags,
        ...tag,
      },
    });
  }

  onSelected(id) {
    this.setState({ selected: id });
  }

  notSelected() {
    this.setState({ selected: null });
  }

  escButtonPressed(event) {
    if (event.keyCode === 27) {
      event.target.blur();
    }
  }

  render() {
    const { tags } = this.state;
    return (
      <CSSTransition
        in={this.state.visible}
        timeout={300}
        classNames="card"
        unmountOnExit
        // onEnter={() => this.setState({ showButton: false })}
        onExited={() => this.props.setShowing(0)}
      >
        <div className="predefined-answers-section predefined-answers-section--tags">
          <Header title="Tags" backHandler={() => this.setState({ visible: false })} />
          <PerfectScrollbar>
            <div className="predefined-answers__body">
              <p className="predefined-answers__description">
                Los Tags son palabras clave que se repiten mucho en tus preguntas.
                <br />
                Asigna una respuesta a los siguientes tags y usalas para responder más rápido.
              </p>
              {Object.keys(tags).map((id, index) => {
                const tag = tags[id];
                return (
                  <div key={index} className="predefined-answers-item">
                    <label htmlFor={`tag-${id}`} className="predefined-answers-item__label">
                      <strong># {tag.label}</strong>
                    </label>
                    <textarea
                      id={`tag-${tag.id}`}
                      className={
                        this.state.selected === tag.id
                          ? 'predefined-answers-item__input selected'
                          : 'predefined-answers-item__input' + (!tag.response ? ' predefined-answers-item__input--empty' : '')
                      }
                      name={tag.id}
                      value={tag.response || ''}
                      placeholder="Agrega una respuesta para este Tag"
                      onChange={this.changeHandler}
                      onFocus={() => this.onSelected(tag.id)}
                      onBlur={this.notSelected}
                    />
                  </div>
                );
              })}
            </div>
          </PerfectScrollbar>
          <div className="predefined-answers__footer flex">
            <button onClick={this.saveHandler} className="btn">
              {this.state.loading ? <Loader /> : 'Guardar cambios'}
            </button>
            <button className="mr-0 btn-cancel" onClick={() => this.setState({ visible: false })}>
              Cancelar
            </button>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default compose(
  withPredefinedAnswers,
  withAddTag,
  withUpdateTag
)(Tags);
