import { graphql } from 'react-apollo';

import CREATESIZEGUIDE from '../gql/createSizeGuide';

export default graphql(CREATESIZEGUIDE, {
  props: ({ mutate }) => ({
    createSizeQuide: (input) => mutate({
      variables: { input },
    })
  }),
});
