import { graphql } from 'react-apollo';
import { compose, withState, withHandlers } from 'recompose';

import ADDSOURCE from './gql/addSource';

export default compose(
  withState('loading', 'onChangeLoading', false),
  withState('invalidCredentials', 'onChangeInvalidCredentials', false),
  graphql(ADDSOURCE, {
    props: ({ mutate }) => ({
      addSource: (input) => mutate({
        variables: { input },
      })
    }),
  }),
  withHandlers({
    handleAddSource: ({onChangeLoading, onChangeInvalidCredentials, addSource, history}) => (input) => {
      onChangeLoading(true);
      addSource(
      {
        'channelType': 'MERCADOLIBRE',
        ...input
      })
        .then((result) => {
          onChangeLoading(false);
          if (result.data.addSource.status === 'INVALID_CREDENTIALS') {
            onChangeInvalidCredentials(true)
          } else {
            const storeSession = sessionStorage.getItem('store');
            const storeFromSession = storeSession ? JSON.parse(storeSession) : null ;
            storeFromSession.sources =  storeFromSession && storeFromSession.sources && storeFromSession.sources.length ? storeFromSession.sources  : [];
            storeFromSession.sources.push(result.data.addSource.source);
            sessionStorage.setItem('store', JSON.stringify(storeFromSession));
            history.push('/sincronizar');
          }
        });
    }
  }),
);
