import React from 'react';
import { compose } from 'recompose';
import Loader from '../../components/svgs/Loader';
import { Link, withRouter } from 'react-router-dom';
import paths from '../../app/paths';
import Button from '../../components/Button';
import ArrowRightIcon from '../../components/svgs/ArrowRight';

import withUserData from '../../app/withUserData';

const SincronizarSide = props => {
  const { user } = props;
  const storeSession = sessionStorage.getItem('store');
  const storeFromSession = storeSession ? JSON.parse(storeSession) : null ;
  const connectedWithShopify = storeFromSession && storeFromSession.sources.find(source => source.type === 'SHOPIFY');

  const msg = connectedWithShopify ? (
    <>
      <p className="description mb-2 text-lg mt-10">
        Excelente, ya conectaste tu cuenta con MercadoLibre y Shopify.<br/>
        Ahora puedes configurar la sincronización de tus productos.
      </p>
      <Button className="w-1/2 mt-10" onClick={() => { props.history.push(paths.SINCRONIZARPRODUCTOS) }}>
          Continuar <ArrowRightIcon />
      </Button>
    </>
  )
  :  (
    <p className="description mb-2 text-lg mt-10">
    Ya conectaste tu cuenta con MercadoLibre,<br />
    ahora selecciona las plataformas que quieras<br />
    sincronizar o <Link to={paths.PREDEFINED_ANSWERS}>salta este paso</Link>
    <br />
  </p>);
  return (
    <aside style={{ maxWidth: "483px" }} className="erp-selection-left-side">
      {
        user.loading ? (<div className="flex py-6 h-full">
          <Loader className="flex-1 text-vivid-blue self-center h-6" />
        </div>) : (
        <>
          <span className="mb-2 text-2xl font-bold">{`Ya casi estamos, ${user.me.name}!`}</span>
          {msg}
        </>
        )
      }
      {/* // Todo: Leer el nombre */}
    </aside>
  )
};

export default compose(
  withUserData,
  withRouter,
)(SincronizarSide);
