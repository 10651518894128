import { graphql } from 'react-apollo';
import ME from '../../../app/gql/viewer';
import ANSWER_QUESTIONS from '../gql/answerQuestions';

export default graphql(
  ANSWER_QUESTIONS,
  {
    props: ({ mutate }) => ({
      answerQuestions: (input) => mutate({
        variables: {
          input,
        },
        updateQueries: {
          Questions: (previousQueryResult, { mutationResult }) => {
            const newQueryResult = JSON.parse(JSON.stringify(previousQueryResult));
            
            if (mutationResult.data.answerQuestions.replied_ids) {
              mutationResult.data.answerQuestions.replied_ids.forEach(questionId => {
                const index = newQueryResult.questions.data.findIndex(node => node.id === questionId);
                if (index !== -1) {
                  newQueryResult.questions.data[index].status = 'ANSWERED';
                }
              });
            }
            return newQueryResult;
            // return JSON.parse(JSON.stringify(previousQueryResult));
            // return Object.assign({}, previousQueryResult);
            // return previousQueryResult;
          },
          GroupedQuestions: (previousQueryResult, { mutationResult }) => {
            const newQueryResult = JSON.parse(JSON.stringify(previousQueryResult));
            
            if (mutationResult.data.answerQuestions.replied_ids) {
              mutationResult.data.answerQuestions.replied_ids.forEach(questionId => {
                newQueryResult.listings.data.forEach((listing, index) => {
                  const questionIndex = listing.questions.findIndex(node => node.id === questionId);
                  if (questionIndex !== -1) {
                    newQueryResult.listings.data[index].questions[questionIndex].status = 'ANSWERED';
                  }
                });
              });
            }
            return newQueryResult;
            // return JSON.parse(JSON.stringify(previousQueryResult));
            // return Object.assign({}, previousQueryResult);
            // return previousQueryResult;
          },
        },
        update: (cache, { data: { answerQuestions } }) => {
          if (Array.isArray(answerQuestions.replied_ids)) {
            const queryData = cache.readQuery({ query: ME });
            const storeSession = sessionStorage.getItem('store');
            const storeFromSession = storeSession ? JSON.parse(storeSession) : null;
            const index = queryData.me.stores.findIndex(store => store.id === storeFromSession.id);
            queryData.me.stores[index].pendingQuestions = (queryData.me.stores[index].pendingQuestions - answerQuestions.replied_ids.length);
            cache.writeQuery({ query: ME, data: queryData });
          }
        },
      }),
    }),
  },
);
