import React from 'react';
import profile from '../../../assets/images/onboarding/profile.jpg';
import QuestionsAndOrdersTab from './QuestionsAndOrdersTab';
import Moment from 'react-moment';
import Loader from '../../../components/svgs/Loader';
import unknownUser from '../../../assets/images/CRM/unknownUser.png';

const CustomerInformation = props => {
  const { contact, loading, isMobile } = props;

  if (loading) {
    return (
      <div className="flex flex-col justify-center h-full">
        <span className="flex flex-col text-center">
          <Loader className="text-vivid-blue m-auto" />
          <p className="mt-3">Cargando datos del contacto</p>
        </span>
      </div>
    );
  }

  if (!loading && !contact) {
    return (
      <div className="flex flex-col justify-center h-full">
        <span style={{ fontSize: '18px' }} className="flex flex-col text-center items-center">
          <img src={unknownUser} alt="no-user-data" className="opacity-50 w-1/3" />
          <p style={{ fontFamily: 'Arboria-Medium', color: '#656565' }} className="my-4 text-xl opacity-75">
            No tenemos información <br /> de este contacto
          </p>
        </span>
      </div>
    );
  }

  const noName = !contact.first_name && !contact.last_name;

  return (
    <div className="flex flex-col" style={isMobile ? { padding: '20px' } : {}}>
      <div className={`flex ${!isMobile && 'ml-6'}`}>
        {!isMobile && <img className="rounded-full w-40" src={profile} alt="profile" />}
        <div className="flex flex-col text-xl ml-4 mb-4 justify-center">
          <div className="mb-4 mr-12 flex flex-col">
            <span className="text-2xl">{noName ? contact.nickname : `${contact.first_name} ${contact.last_name}`}</span>
            <a href={contact.permalink} target="_blank" className="text-md" style={{ fontSize: '12px', lineHeight: '1.5', color: 'rgb(110, 107, 111)' }}>{`MercadoLibre ${
              noName ? '' : contact.nickname
            }`}</a>
          </div>
          <div className="flex items-center" style={isMobile ? { flexDirection: 'column', alignItems: 'flex-start' } : {}}>
            <div className="flex flex-col text-md mr-10">
              {contact.phone && (
                <a href={`tel:${contact.phone}`} className="flex items-center">
                  <i title="Teléfono" style={{ fontSize: '32px' }} className="mr-4 fa fa-mobile" aria-hidden="true" />
                  {contact.phone}
                </a>
              )}
              <span className="flex items-center">
                <i title="País" style={{ fontSize: '23px' }} className="mr-4 fa fa-map-marker" aria-hidden="true" />
                {contact.country_id}
              </span>
            </div>
            <div className="flex flex-col text-md">
              <span className="flex items-center">
                <i title="Fecha de registro en MercadoLibre" style={{ fontSize: '20px' }} className="mr-4 my-1 fa fa-calendar" aria-hidden="true" />
                <Moment format="DD/MM/YYYY">{contact.registration_date}</Moment>
              </span>
              {contact.points > 0 && (
                <span className="flex items-center">
                  <i title="Puntos en ML" style={{ fontSize: '21px' }} className="mr-4 fa fa-star-half-o" aria-hidden="true" />
                  {contact.points}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <QuestionsAndOrdersTab isMobile={isMobile} orders={contact.orders} questions={contact.questions} />
      </div>
    </div>
  );
};

export default CustomerInformation;
