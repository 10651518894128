import React, { useState } from 'react';

import DialogIcon from '../../../components/svgs/Dialog';
import { formatDateText } from '../../../utils/date';

const AnsweredQuestion = props => {
  const date = formatDateText(props.date_created);
  const [ open, setOpen ] = useState(false);
  const {
    answer_text,
    from_meli_id,
    listing,
    status,
    text,
  } = props;
  const userQuestionClass = ['user-question'];
  const userQuestionClassDialog = ['user-question__question__dialog'];
  if (open) {
    userQuestionClass.push('open');
    userQuestionClassDialog.push('open');
  } else {
    userQuestionClassDialog.push('close');
  }
  const className = [ 'userquestion-wrapper', status.toLowerCase() ];
  return (
    <div className={className.join(' ')}>
      <div className={userQuestionClass.join(' ')}>
        <div className="flex flex-col flex-1" onClick={
          (ev) => { 
            if (answer_text) {
              setOpen(!open)
            }
            ev.stopPropagation();
        }}>
         <div className="user-question__question" >
            <div className={ userQuestionClassDialog.join(' ')} >

              <div className="flex flex-col" style={{width: '3rem', alignItems: 'center'}}>
                <DialogIcon className="mt-1" /> 
                { open && open && answer_text && (
                  <div className="flex flex-1">
                    <div style={{borderRightColor: '#BFC6D0', borderRightWidth: '1px', flex: '50%'}}></div>
                  </div>)
                }
              </div>

              <div className="flex flex-col word-break">
                <p className="question-text">{text}</p>
                {
                  listing &&
                  <a
                    href={listing.permalink}
                    target="_blank"
                    className="text-vivid-blue text-base"
                    onClick={ev => ev.stopPropagation()}
                  >{listing.title}</a>
                }
                <span className="user-question__username">{from_meli_id}</span>
              </div>
              {
                !open && answer_text && (
                <div className="flex flex-col flex-1 justify-center">
                    <span className="question-date self-end pr-4">{date}</span>
                    <a className="question-reply pr-4 self-end">Ver respuesta</a>
                </div>)}
              </div>
          </div>
          <div className="user-question__question">
              {open && answer_text && (<>
              <div className="flex flex-row justify-end" style={{width: '3rem', height: '1.5rem'}}>
                <div className="flex" style={{width: '50%', marginTop: '-2rem', borderLeftColor: '#BFC6D0', borderLeftWidth: '1px', borderBottomColor: '#BFC6D0', borderBottomWidth: '1px'}}></div>
              </div>
              <div className="flex flex-row flex-1 pt-4 pb-4">
              {/* Dialog */}
                <div className="flex flex-col " style={{width: '2rem', alignItems: 'center'}}>
                  <DialogIcon className="mt-1" />
                </div>
                <div className="flex flex-col px-2 word-break">
                  <p className="question-text">{answer_text}</p>
                  <span className="user-question__username">{from_meli_id}</span>
                </div>
                { open && <div className="flex flex-col flex-1 justify-end"><a className="question-close pr-4 self-end">Cerrar</a></div> }
              </div> </>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnsweredQuestion;
