import React from 'react';

import UserQuestion from './UserQuestion';

const Question = props => {
  const {
    answering,
    batchEnabled,
    checked,
    checkHandler,
    getMoreQuestionHandler,
    deleteHandler,
    deleting,
    errorIds,
    fading,
    groupedByListing,
    question,
    greetings,
    predefinedAnswers,
    sendHandler,
    tags,
    moreQuestions,
    loadingMoreQuestions,
    questionExpandedId,
    openQuestionHandler,
  } = props;
  const className = ['question-article', question.status.toLowerCase()];
  if (fading) {
    className.push('fade');
  }
  return (
    <article
      className={className.join(' ')}
    >
      <UserQuestion
        checked={checked}
        checkHandler={checkHandler}
        getMoreQuestionHandler={getMoreQuestionHandler}
        groupedByListing={groupedByListing}
        sendHandler={sendHandler}
        deleteHandler={deleteHandler}
        {...question}
        predefinedAnswers={predefinedAnswers}
        greetings={greetings}
        predefinedTags={tags}
        batchEnabled={batchEnabled}
        errorIds={errorIds}
        answering={answering}
        deleting={deleting}
        moreQuestions={moreQuestions}
        loadingMoreQuestions={loadingMoreQuestions}
        questionExpandedId={questionExpandedId}
        openQuestionHandler={openQuestionHandler}
      />
    </article>
  );
};

export default Question;
