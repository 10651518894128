import { gql } from 'apollo-boost';
import question from './question';

export default gql`
  query Questions($cursor: String, $filter: QuestionsInput, $orderBy: QuestionOrder) {
    questions(paginate: { count: 20, after: $cursor }, filters: $filter, order_by: $orderBy) {
      data {
        ...Question
        listing {
          id
          meli_id
          site_id
          title
          seller_id
          category_id
          official_store_id
          price
          currency_id
          available_quantity
          thumbnail
          permalink
        }
      }
      pageInfo {
        nextCursor
        count
        hasMorePages
      }
    }
  }

  ${question}
`;
