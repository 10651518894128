import React from 'react';
import SignUp from './SignUp.css'

const SignUpGif = () =>{
    return (
      <div className="shadow rounded-xl">
          <img className="rounded-xl" src={require('./SignUp.gif')} alt="signUp" />      
      </div>
    )
}

export default SignUpGif;
