import { gql } from 'apollo-boost';
import question from './question';

export default gql`
  query GroupedQuestions($cursor: String) {
    listings(filters: { questionFilter: { only_pending_questions: true } }, paginate: { count: 20, after: $cursor }) {
      data {
        id
        meli_id
        title
        price
        currency_id
        available_quantity
        thumbnail
        permalink
        questions {
          ...Question
        }
      }
      pageInfo {
        nextCursor
        hasMorePages
        total
      }
    }
  }

  ${question}
`;
