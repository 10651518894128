import React from 'react';

export default (props) => (
  <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
    <g fill="#2B48F0" fillRule="evenodd">
        <path d="M5.5.511C2.607.511.58 3.694.58 3.694S2.058 6.878 5.5 6.878c3.472 0 4.92-3.184 4.92-3.184S8.422.511 5.5.511zm0 5.209a2.013 2.013 0 0 1-2.025-2.026c0-1.128.897-2.025 2.025-2.025s2.025.897 2.025 2.025A2.013 2.013 0 0 1 5.5 5.72z"/>
        <path d="M6.368 3.694a.868.868 0 1 1-1.736 0 .868.868 0 0 1 1.736 0"/>
    </g>
  </svg>
);
