import React, { useState } from 'react';
import Categories from './Categories';
import Tags from './Tags';
import Greetings from './Greetings';
import Responses from './Responses';

const show = { TAGS: 1, GREETINGS: 2, RESPONSES: 3 };

const section = (showing, setShowing) => {
  switch (showing) {
    case show.TAGS:
      return <Tags setShowing={setShowing} />;
    case show.GREETINGS:
      return <Greetings setShowing={setShowing} />;
    case show.RESPONSES:
      return <Responses setShowing={setShowing} />;
    default:
      return null;
  }
};

export default ({ restartWalkThrough }) => {
  const [showing, setShowing] = useState(0);

  return (
    <div className={'predefined-answers'}>
      <h2 className="text-blue-darkest text-2xl mb-2">Respuestas predefinidas</h2>
      <div style={{ display: 'flex' }}>
        <p className="text-sm text-blue-darkest mb-3">Tus tags y respuestas predefinidas</p>
        <i onClick={restartWalkThrough} style={{ marginLeft: '0.25rem', cursor: 'pointer' }} className="fa fa-question-circle" aria-hidden="true" />
      </div>
      <div className="p-6 bg-white flex flex-col flex-1 justify-center" style={{ position: 'relative', overflow: 'hidden' }}>
        <Categories setShowing={setShowing} {...show} />
        {section(showing, setShowing)}
      </div>
    </div>
  );
};
