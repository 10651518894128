import React from 'react';
import floor from '../../../assets/images/EmptyState/floor.png';
import mailBox from '../../../assets/images/EmptyState/mailBox.png';
import arrowRight from '../../../assets/images/EmptyState/white-arrow-right.png';

const EmptyStateVideo = props => {
  return (
    <div className="flex flex-1 flex-col justify-between">
      <div style={{ marginLeft: '6%', marginTop: '3%' }} className="flex flex-col items-start">
        <span className="font-bold es-title">
          ¡Estás al día! No tienes <br /> preguntas sin responder
        </span>
        <span className="my-5 text-md">
          Mientras tanto, mirá el video que preparamos para <br />
          explicarte como responder tus próximas preguntas <br />
          en tiempo récord.
        </span>
        <span
          onClick={props.handleClickOpen}
          style={{
            borderRadius: '50px',
            WebkitBoxShadow: '2px 15px 45px -6px rgba(43,72,240,1)',
            MozBoxShadow: '0px 10px 40px -6px rgba(43,72,240,1)',
            boxShadow: '0px 10px 40px -6px rgba(43,72,240,1)',
            padding: '10px 24px',
          }}
          className="text-white text-md font-semibold cursor-pointer mt-1 bg-vivid-blue flex items-center"
        >
          Ver video <img style={{ width: '7px', height: '8px', marginLeft: '7px' }} src={arrowRight} alt="flecha" />
        </span>
      </div>
      <img className="es-floor" src={floor} alt="floor" />
      <img className="es-mailBox" src={mailBox} alt="mailBox" />
    </div>
  );
};

export default EmptyStateVideo;
