export default {
  ADD_STORE: '/agregar-tienda',
  PREDEFINED_ANSWERS: '/configurar-respuestas-predefinidas',
  FAST_ANSWERS: '/configurar-respuestas-rapidas',
  CONFIGURATION_CONFIRM: '/confirmar-configuracion',
  ADD_ANOTHER_STORE: '/vincular',
  CRM: '/crm',
  REGISTERED: '/registrado',
  AUTO_LOGIN: '/auto-login',
  CHANGEDPASSWORD: '/contraseñaCambiada',
  DEFAULT: '/preguntas',
  LOGIN: '/entrar',
  LOGOUT: '/logout',
  ORDERS: '/ordenes',
  PASSWORD_RECOVERY: '/restablecer',
  PASSWORD_RECOVERY_SENT: '/restablecer/enviado',
  PUBLICATIONS: '/publicaciones',
  QUESTIONS: '/preguntas',
  QUESTIONS_HISTORY: '/preguntas/historial',
  QUESTIONS_RESPONSES: '/preguntas/respuestas',
  QUESTIONS_STATS: '/preguntas/estadisticas',
  RESETPASSWORD: '/resetpassword',
  SINCRONIZAR: '/sincronizar',
  SINCRONIZARCANCELARLIBRA: '/sincronizar/cancelar/zlibra',
  SINCRONIZARCONFIRMARZLIBRA: '/sincronizar/confirmar/zlibra',
  SINCRONIZARCONFIRMARSHOPIFY: '/sincronizar/confirmar/shopify',
  SINCRONIZARCANCELARSHOPIFY: '/sincronizar/cancelar/shopify',
  SINCRONIZARCONFIRMARZUREO: '/sincronizar/confirmar/zureo',
  SINCRONIZARCANCELARZUREO: '/sincronizar/cancelar/zureo',
  SINCRONIZARPRODUCTOS: '/sincronizar/productos',
  SINCRONIZARSHOPIFY: '/sincronizar/shopify',
  SINCRONIZARLIBRA: '/sincronizar/libra',
  SHOPIFY: '/sincronizar/shopify',
  LIBRA: '/sincronizar/libra',
  SIGNUPPREGUNTAS: '/registro-preguntas',
  SIGNUPERP: '/registro-erp',
  SIGNUP: '/registro',
  SIZE_GUIDE: '/guia-de-talles',
  STORE: '/tienda',
  NOTFOUND: '/notFound',
};
