import React from 'react';
import { Select, TextField, InputLabel, MenuItem } from '@material-ui/core';

const MobileListingEditing = props => {
  const { setNewTitle, setNewStock, setNewStatus, setNewPrice, setNewSKU, newTitle, newStatus, newStock, newPrice, newSKU } = props;
  return (
    <div className="flex flex-col p-4 pt-8">
      <TextField style={{ marginBottom: '20px' }} label="Título" variant="outlined" onChange={e => setNewTitle(e.target.value)} value={newTitle} />
      <TextField style={{ marginBottom: '20px' }} label="Precio" variant="outlined" onChange={e => setNewPrice(e.target.value)} value={newPrice} />
      <TextField style={{ marginBottom: '20px' }} label="Stock" variant="outlined" onChange={e => setNewStock(e.target.value)} value={newStock} />
      <TextField style={{ marginBottom: '20px' }} label="SKU" variant="outlined" onChange={e => setNewSKU(e.target.value)} value={newSKU} />
      <InputLabel id="listing-status">Estado</InputLabel>
      <Select style={{ marginBottom: '20px' }} labelId="listing-status" value={newStatus} onChange={e => setNewStatus(e.target.value)}>
        <MenuItem value={'active'}>Activa</MenuItem>
        <MenuItem value={'closed'}>Cerrada</MenuItem>
        <MenuItem value={'paused'}>Pausada</MenuItem>
        <MenuItem value={'inactive'}>Inactiva</MenuItem>
      </Select>
    </div>
  );
};

export default MobileListingEditing;
