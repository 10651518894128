import { graphql } from 'react-apollo';
import UPDATE_TAG from '../../gql/updateTag';

export default graphql(
  UPDATE_TAG,
  {
    props: ({ mutate }) => ({
      updateTag: (tag, response) => mutate({
        variables: {
          input: {
            tag,
            response,
          }
        },
      })
    })
  },
);
