import { gql } from 'apollo-boost';
import productForList from './productForList';

export default gql`
query Products($filter: ProductsInput!, $paginate: PaginateInputNormal!) {
  products (
    filters: $filter,
    paginate: $paginate,
  ) {
    products {
      ...ProductForList
    }
    paging {
      total
      pages
      currentPage
    }
  }
}
${productForList}
`;
