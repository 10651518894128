import React, { Component } from 'react';
import { compose, withApollo } from 'react-apollo';

import paths from '../../../app/paths';

class LogOut extends Component {
  constructor(props) {
    super(props)
    localStorage.clear();
    sessionStorage.clear();
    this.props.client.cache.reset()
    .then(() => this.props.history.push(paths.LOGIN));
  }

  render () {
    return <></>
  }
}

export default compose(
  withApollo
)(LogOut);
