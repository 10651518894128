const months = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Oct',
  'Nov',
  'Dic',
];

const formatDateText = (dateStr, withTime = false) => {
  dateStr = dateStr.replace(/^([-\d]+) ([\d:]+)$/, '$1T$2Z');
  const d = new Date();
  const currentYear = d.getFullYear();
  const date = new Date(dateStr);
  const day = new Date(date.getTime());
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  day.setHours(0);
  day.setMinutes(0);
  day.setSeconds(0);
  day.setSeconds(0);

  const hour = date.getHours();
  const minutes = date.getMinutes();
  const time = ` ${hour}:${minutes < 10 ? `0${minutes}` : minutes}`;
  if (d.getTime() === day.getTime()) {
    return `Hoy${withTime ? time : ""}`;
  }
  d.setDate(d.getDate() - 1);
  if (d.getTime() === day.getTime()) {
    return `Ayer${withTime ? time : ""}`;
  }
  d.setDate(d.getDate() - 1);
  if (d.getTime() === day.getTime()) {
    return `Hace dos días${withTime ? time : ""}`;
  }
  let text = `${date.getDate()} ${months[date.getMonth()]}.`;
  if (date.getFullYear() != currentYear) {
    text += ` ${date.getFullYear()}`;
  }
  if(withTime){
    text += time;
  }
  return text;
};

export { formatDateText };
