import { graphql } from 'react-apollo';
// import { gql } from 'apollo-boost';
import ME from '../../../app/gql/viewer';
import DELETE_QUESTIONS from '../gql/deleteQuestions';

export default graphql(
  DELETE_QUESTIONS,
  {
    props: ({ mutate }) => ({
      deleteQuestions: (questions) => mutate({
        variables: {
          input: {
            questions,
          }
        },
        updateQueries: {
          Questions: (previousQueryResult, { mutationResult }) => {
            mutationResult.data.deleteQuestions.deleted_ids.forEach(questionId => {
              const index = previousQueryResult.questions.data.findIndex(node => node.id === questionId);
              if (index !== -1) {
                previousQueryResult.questions.data[index].status = 'DELETED';
              }
            });
            return previousQueryResult;
          },
          GroupedQuestions: (previousQueryResult, { mutationResult }) => {
            mutationResult.data.deleteQuestions.deleted_ids.forEach(questionId => {
              previousQueryResult.listings.data.forEach((listing, index) => {
                const questionIndex = listing.questions.findIndex(node => node.id === questionId);
                if (questionIndex !== -1) {
                  previousQueryResult.listings.data[index].questions[questionIndex].status = 'DELETED';
                }
              });
            });
            return previousQueryResult;
          },
        },
        update: (cache, { data: { deleteQuestions } }) => {
          const queryData = cache.readQuery({ query: ME });
          const storeSession = sessionStorage.getItem('store');
          const storeFromSession = storeSession ? JSON.parse(storeSession) : null ;
          const index = queryData.me.stores.findIndex(store => store.id === storeFromSession.id);
          queryData.me.stores[index].pendingQuestions = (queryData.me.stores[index].pendingQuestions - deleteQuestions.deleted_ids.length);
          cache.writeQuery({ query: ME, data: queryData });
// // const data = {
// //   id: '6195563667',
// //   __typename: 'QuestionType',
// //   status: "yeah",
// //   text: 'lalala'

// // };
// // console.log(cache.writeData({ id: '6195563667', data }));

// // console.log('getCacheKey', cache.getCacheKey({ __typename: 'QuestionType', id: '6195563667' }));
// // const model = cache.data.get('TagType_TIME');
// // console.log(model)



// // cache.writeFragment({
// //   id: '6195563667',
// //   fragment: gql`
// //   fragment myDeleted on QuestionType {
// //     text
// //   }
// //   `,
// //   data: {
// //     text: 'lalalala',
// //     __typename: 'QuestionType',
// //   },
// // });




// // const question = cache.readFragment({
// //   id: '6195563667',
// //   fragment: gql`
// //     fragment deleted on QuestionType {
// //       id
// //       __typename
// //       text
// //       status
// //     }
// //   `,
// // });
// // console.log(question)
// // const question = {
// //   id: "6195563667",
// //   __typename: 'QuestionType',
// //   text: "lalala"
// // };
// // console.log(          cache.writeData(question))

//           // deleted_ids:
//           //   0: "6173609737"
//           // error_ids: null
//           if (deleteQuestions.deleted_ids && deleteQuestions.deleted_ids.length) {
//             deleteQuestions.deleted_ids.map(id => {
//               const question = cache.readModel('QuestionType', id);
//               // model.todos.push(createTodo);
//               question.status = 'ANSWERED';
//               proxy.writeModel(question);
//             });
//           }

//           if (deleteQuestions.error_ids && deleteQuestions.error_ids.length) {

//           }
//           // const { allTodos } = cache.readQuery({ query });
//           // cache.writeQuery({
//           //   query: GET_TODOS,
//           //   data: { allTodos: allTodos.filter(e => e.id !== id)}
//           // });
        },
        // refetchQueries: [ 'Me' ],
      })
    })
  },
);
