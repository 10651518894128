import React from 'react';

export default ({ checked, className, clickHandler, value }) => {
  const classNames = [ 'checkbox' ];
  className = className || '';
  if (checked) {
    classNames.push('checkbox--checked');
  }
  return (
    <>
      <input type="checkbox" value={value} className={className} checked={checked} onChange={clickHandler}
        style={{ position: 'absolute', opacity: '0' }}
      />
      <span
        className={classNames.join(' ')}
      >
        <span
          className="checkbox__indicator"
          >
        </span>
      </span>
    </>
  );
};
