import React from 'react';

const Bag = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15"
    className={className}
  >
    <path fill="#F0F5FB" fillOpacity=".548" fillRule="evenodd" d="M10.818 4.432h-1.92V3.068C8.899 1.972 8.3.96 7.329.411a3.206 3.206 0 0 0-3.14 0 3.056 3.056 0 0 0-1.57 2.657v1.364H.522A.518.518 0 0 0 0 4.943v7.5c0 .678.276 1.329.767 1.808.49.48 1.156.749 1.85.749h6.107c.694 0 1.36-.27 1.85-.75.491-.478.767-1.129.767-1.807v-7.5a.518.518 0 0 0-.523-.511zm-7.16-1.359c0-1.091.901-1.975 2.013-1.975 1.111 0 2.012.884 2.012 1.975V4.39H3.659V3.073zm6.586 9.315c0 .836-.696 1.514-1.553 1.514H2.65c-.858 0-1.553-.678-1.553-1.514v-6.9H2.65v1.851c0 .279.232.505.517.505a.512.512 0 0 0 .518-.505V5.488h4.142v1.851c0 .279.232.505.518.505a.512.512 0 0 0 .517-.505V5.488h1.38v6.9z"/>
  </svg>
);

Bag.defaultProps = {
  className: '',
};

export default Bag;
