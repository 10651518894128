import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import paths from '../../app/paths';
import Sincronizar from './Sincronizar';
import SincronizarSide from './SincronizarSide';
import ProductsTable from '../synchronize/products/components/ProductsTable';
import SincronizarShopify from './SincronizarShopify';
import SincronizarLibra from './SincronizarLibra';

const routes = () => (
  <div className="flex flex-1 items-start justify-center md:items-center">
    <div className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl sm:px-8 md:px-18">
      <div style={{ minHeight: "571px" }} className="erp-selection-container">
        <SincronizarSide/>
        <section style={{ maxWidth: "510px" }} className="erp-selection-right-side">
          <Switch>
            <Route exact path={paths.SINCRONIZARCONFIRMARZLIBRA} component={Sincronizar} />
            <Route exact path={paths.SINCRONIZARCANCELARLIBRA} component={Sincronizar} />
            <Route exact path={paths.SINCRONIZARCANCELARSHOPIFY} component={Sincronizar} />
            <Route exact path={paths.SINCRONIZARCANCELARZUREO} component={Sincronizar} />
            <Route exact path={paths.SINCRONIZARCONFIRMARZLIBRA} component={Sincronizar} />
            <Route exact path={paths.SINCRONIZARCONFIRMARZLIBRA} component={Sincronizar} />
            <Route exact path={paths.SINCRONIZARCONFIRMARZUREO} component={Sincronizar} />
            <Route exact path={paths.SINCRONIZAR} component={Sincronizar} />
            <Route exact path={paths.SINCRONIZARSHOPIFY} component={SincronizarShopify} />
            <Redirect exact from={paths.SINCRONIZARCONFIRMARSHOPIFY} to={paths.SINCRONIZARSHOPIFY} />
            <Route exact path={paths.SINCRONIZARLIBRA} component={SincronizarLibra} />
          </Switch>
        </section>
      </div>
    </div>
  </div>
);

export default () => (<Switch>
  <Route path={paths.SINCRONIZARPRODUCTOS} component={ProductsTable} />
  <Route path={paths.SINCRONIZAR} component={routes} />
</Switch>);
