import { gql } from 'apollo-boost';

export default gql`
  query CatalogProductQuery($catalog_product_id: String) {
    CatalogProductQuery(catalog_product_id: $catalog_product_id) {
      id
      status
      domain_id
      permalink
      product_name
      buy_box_winner
      short_description
      parent_id
      children_ids
    }
  }
`;
