import { graphql } from 'react-apollo';

import VIEWER from './gql/viewer';

export default graphql(
  VIEWER,
  {
    options: {
      notifyOnNetworkStatusChange: true,
    },
    props({ data }) {
      return {
        user: data,
      };
    },
  }
)
