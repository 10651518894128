import { gql } from 'apollo-boost';

export default gql`
mutation addSource($input: addSourceInput!) {
  addSource(input: $input) {
    status
    source {
      id
      name
      type
    }
  }
}
`;
