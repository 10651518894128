import { graphql } from 'react-apollo';

import PASSWORD_RECOVERY from '../gql/passwordRecovery';

export default graphql(
  PASSWORD_RECOVERY, {
  props({ ownProps, mutate }) {
    const passwordRecovery = (email) => {
      return mutate({
        variables: { input: { email } }
      })
    };
    return {
      ...ownProps,
      passwordRecovery,
    };
  },
});
