import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { NETWORK_STATUS } from '../../../apollo/constants';
import Loader from '../../../components/svgs/Loader';
import QUESTIONS from '../gql/questions';
import Question from './Question';
import { useQuery } from '@apollo/react-hooks';

function SingleQuestions (props) {
  let moreLoaded = false;
  let lastCursor = null;
  
  const { loading, error, data, networkStatus, fetchMore, refetch } = useQuery(QUESTIONS, {
    variables: { filter: { status: 'UNANSWERED' } },
    partialRefetch: true,
  });
  
  useEffect(() => {
    if (data && data.questions && data.questions && data.questions.pageInfo && data.questions.pageInfo.hasOwnProperty('count')) {
      
      let totalQuestions = data.questions.pageInfo.count;
      
      if (totalQuestions) {
        const totalAnswered = data.questions.data.reduce((subtotal, question) => subtotal + (question.status === 'ANSWERED' ? 1 : 0), 0);
        
        totalQuestions = totalQuestions - totalAnswered;
      }
      
      props.setRefreshFunc(refetch);
      props.setTotalQuestions(totalQuestions);
    }
  }, [data]);
  
  if (networkStatus === NETWORK_STATUS.LOADING) {
    return (
      <div className="flex py-6 h-full">
        <Loader className="flex-1 text-vivid-blue self-center h-6"/>
      </div>
    );
  }
  
  if (error && !data.questions) {
    return <p>Error {error.message}</p>;
  }
  
  const hasMore = data.questions && data.questions.pageInfo && data.questions.pageInfo.hasMorePages;
  const cursor = data.questions && data.questions.pageInfo.nextCursor;
  
  const loadMore = () =>
    fetchMore({
      variables: { cursor },
      updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => {
        if (!moreLoaded) {
          moreLoaded = true;
        }
        
        return {
          questions: {
            ...previousResult.questions,
            ...fetchMoreResult.questions,
            data: [...previousResult.questions.data, ...fetchMoreResult.questions.data],
          },
        };
      },
    });
  
  return (
    <PerfectScrollbar
      onYReachEnd={() => {
        if (!loading && hasMore && lastCursor !== cursor) {
          lastCursor = cursor;
          loadMore();
        }
      }}
    >
      {data.questions.data.filter(question => question.status === 'UNANSWERED' || props.fadingIds.includes(question.id)).map(node => (
        <Question
          key={node.id}
          question={node}
          checked={props.checked}
          checkHandler={props.checkHandler}
          getMoreQuestionHandler={props.getMoreQuestionHandler}
          sendHandler={props.sendHandler}
          deleteHandler={props.deleteHandler}
          predefinedAnswers={props.predefinedAnswers}
          greetings={props.greetings}
          tags={props.tags}
          batchEnabled={props.batchEnabled}
          errorIds={props.errorIds}
          fading={props.fadingIds.includes(node.id)}
          answering={props.answering}
          deleting={props.deleting}
          moreQuestions={props.moreQuestions}
          loadingMoreQuestions={props.loadingMoreQuestions}
          questionExpandedId={props.questionExpandedId}
          openQuestionHandler={props.openQuestionHandler}
        />
      ))}
      {networkStatus === NETWORK_STATUS.FETCH_MORE && (
        <div className="flex py-6">
          <Loader className="flex-1 text-vivid-blue self-center h-5"/>
        </div>
      )}
      {!hasMore && moreLoaded
        ? <p className="text-center text-2xl text-bluey-grey opacity-40">No tienes más preguntas sin responder</p>
        : false}
    </PerfectScrollbar>
  );
}

// class SingleQuestions extends Component {
//   constructor (props) {
//     super(props);
//     this.moreLoaded = false;
//     this.refetch = null;
//     this.lastCursor = null;
//     this.query = QUESTIONS;
//     this.variables = {
//       cursor: null,
//       filter: { status: 'UNANSWERED' },
//     };
//   }
//
//   render () {
//     return (
//       <Query query={this.query} variables={this.variables} notifyOnNetworkStatusChange errorPolicy="all">
//         {({ loading, error, data, refetch, networkStatus, fetchMore }) => {
//           if (networkStatus === NETWORK_STATUS.LOADING) {
//             return (
//               <div className="flex py-6 h-full">
//                 <Loader className="flex-1 text-vivid-blue self-center h-6"/>
//               </div>
//             );
//           }
//           if (error) {
//             // this doesn't make sense. Gonna leave it, just in case.
//             if (!(data && data.questions)) {
//               return <p>Error {error.message}</p>;
//             }
//           }
//           if (!this.refetch) {
//             this.refetch = refetch.bind(this);
//           }
//           const hasMore = data.questions && data.questions.pageInfo && data.questions.pageInfo.hasMorePages;
//           const cursor = data.questions && data.questions.pageInfo.nextCursor;
//           const loadMore = () =>
//             fetchMore({
//               variables: {
//                 cursor: data.questions.pageInfo.nextCursor,
//                 // status: "UNANSWERED",
//               },
//               updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => {
//                 if (!this.moreLoaded) {
//                   this.moreLoaded = true;
//                 }
//
//                 return {
//                   questions: {
//                     ...previousResult.questions,
//                     ...fetchMoreResult.questions,
//                     data: [...previousResult.questions.data, ...fetchMoreResult.questions.data],
//                   },
//                 };
//               },
//             });
//           return (
//             <PerfectScrollbar
//               onYReachEnd={() => {
//                 if (!loading && hasMore && this.lastCursor != cursor) {
//                   this.lastCursor = cursor;
//                   loadMore();
//                 }
//               }}
//             >
//               {data.questions.data.filter(question => question.status === 'UNANSWERED' || this.props.fadingIds.includes(question.id)).map(node => (
//                 <Question
//                   key={node.id}
//                   question={node}
//                   checked={this.props.checked}
//                   checkHandler={this.props.checkHandler}
//                   getMoreQuestionHandler={this.props.getMoreQuestionHandler}
//                   sendHandler={this.props.sendHandler}
//                   deleteHandler={this.props.deleteHandler}
//                   predefinedAnswers={this.props.predefinedAnswers}
//                   greetings={this.props.greetings}
//                   tags={this.props.tags}
//                   batchEnabled={this.props.batchEnabled}
//                   errorIds={this.props.errorIds}
//                   fading={this.props.fadingIds.includes(node.id)}
//                   answering={this.props.answering}
//                   deleting={this.props.deleting}
//                   moreQuestions={this.props.moreQuestions}
//                   loadingMoreQuestions={this.props.loadingMoreQuestions}
//                   questionExpandedId={this.props.questionExpandedId}
//                   openQuestionHandler={this.props.openQuestionHandler}
//                 />
//               ))}
//               {networkStatus === NETWORK_STATUS.FETCH_MORE && (
//                 <div className="flex py-6">
//                   <Loader className="flex-1 text-vivid-blue self-center h-5"/>
//                 </div>
//               )}
//               {!hasMore && this.moreLoaded
//                 ? <p className="text-center text-2xl text-bluey-grey opacity-40">No tienes más preguntas sin responder</p>
//                 : false}
//             </PerfectScrollbar>
//           );
//         }}
//       </Query>
//     );
//   }
// }

export default SingleQuestions;
