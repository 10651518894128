import { gql } from 'apollo-boost';

export default gql`
mutation UpdateGreetings ($input: UpdateGreetingsInput!) {
  updateGreetings (input: $input) {
    id: __typename
    greeting
    farewell
    __typename
  }
}
`;
