import React from 'react';
import Button from '../../../components/Button';
import SignUpIllustration from '../../../components/svgs/onboarding/SignUpIllustration';

const AllSet = props => {
  const { me, nextStep } = props;
  return (
    <div className="flex flex-1 items-start justify-center md:items-center">
      <div style={{ minHeight: "540px" }} className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
        <div className="onboarding flex-1">
          <p style={{ fontSize: "25px" }} className="mb-8 font-bold"> Genial, {me && me.name.split(' ').slice(0, 1)}!</p>
          <p style={{ fontSize: "18px" }} className="description mb-14">
            Ya te conectaste con Mercado Libre
            y estamos listos para comenzar a configurar
            tu nueva cuenta de Astroselling.
          </p>
          <Button onClick={nextStep}>
            Comenzar
        </Button>
        </div>
        <div className="flex-1 ml-24 hidden xl:block">
          <SignUpIllustration />
        </div>
      </div>
    </div>
  )
}
export default AllSet;