import { graphql } from 'react-apollo';
import { get } from 'lodash';

import ATTRIBUTES from './../gql/attributes';

export default graphql(
  ATTRIBUTES,
  { 
    options: {
      notifyOnNetworkStatusChange: true,
      skip: true,
      variables: {
        site_id: 'MLA'
      }, 
    },
    props({ data }) {
      const measurementAttributes = get(data, 'measurementAttributes', []);
      const loadingMeasurementAttributes = get(data, 'loading', false);
      return { measureAttributes: measurementAttributes, loadingMeasurementAttributes };
    },
  }
)
