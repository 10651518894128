import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    label: {
        fontFamily: "Arboria",
        fontSize: "13px"
    }
}));

export default function SincronizarConfiguración(props) {
    const classes = useStyles();
    const { handleChangeCheckbox, configuration } = props;
    return (
        <div className="flex flex-col w-full items-center mt-4">
            <div className="mb-6 font-bold text-md w-full self-start">
                Configuración
            </div>
            <div className="w-full flex justify-around mb-6">
                <div className="flex flex-col">
                    <FormControlLabel
                        classes={{ label: classes.label }}
                        control={
                            <Checkbox color="primary" checked={configuration.sync_title} onChange={(event) => {handleChangeCheckbox('sync_title', event.target.checked)}} value="titulo" />
                        }
                        label="Sincronizar Título"
                    />
                    <FormControlLabel
                        classes={{ label: classes.label }}
                        control={
                            <Checkbox color="primary" checked={configuration.sync_price} onChange={(event) => {handleChangeCheckbox('sync_price', event.target.checked)}} value="precio" />
                        }
                        label="Sincronizar Precio"
                    />
                    <FormControlLabel
                        classes={{ label: classes.label }}
                        control={
                            <Checkbox color="primary" checked={configuration.sync_stock} onChange={(event) => {handleChangeCheckbox('sync_stock', event.target.checked)}} value="stock" />
                        }
                        label="Sincronizar Stock"
                    />
                </div>
                <div className="flex flex-col">
                    <FormControlLabel
                        classes={{ label: classes.label }}
                        control={
                            <Checkbox color="primary" checked={configuration.sync_images} onChange={(event) => {handleChangeCheckbox('sync_images', event.target.checked)}} value="imgs" />
                        }
                        label="Sincronizar Imágenes"
                    />
                    <FormControlLabel
                        classes={{ label: classes.label }}
                        control={
                            <Checkbox color="primary" checked={configuration.sync_description} onChange={(event) => {handleChangeCheckbox('sync_description', event.target.checked)}}value="description" />
                        }
                        label="Sincronizar Descripción"
                    />
                </div>
            </div> 
            {
                false && (            
                <div className="font-bold text-md w-full self-start opacity-50 cursor-pointer">
                    Configuración avanzada
                </div>)
            }
        </div>
    );
}
