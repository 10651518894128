import { graphql } from 'react-apollo';

import LOGIN from '../gql/login';

export default graphql(LOGIN, {
  props: ({ mutate }) => ({
    login: (input) => mutate({
      variables: { input },
      // update: cache => {
      //   cache.writeData({ data: { isLoggedIn: true } });
      // }
    })
  }),
});
