import { graphql } from 'react-apollo';
import { compose, withState } from 'recompose';



import ADDSOURCEINTEREST from './gql/addSourceInterest';

export default compose(
  graphql(ADDSOURCEINTEREST, {
    props: ({ mutate }) => ({
      requestSource: () => mutate({})
    }),
  }),
  withState('loading', 'onChangeLoading', false),
);