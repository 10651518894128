import React from 'react';
import Loader from './svgs/Loader';

const Button = (props) => {
  const { disabled, type, children, className, isLink, isLoading } = props;
  let classN = 'text-white font-semibold py-4 px-8 rounded-sm focus:outline-none'
  classN = !isLink ? classN + ' bg-blue hover:bg-blue-dark' : classN +' bg-white hover:bg-gray text-blue';

  const classNames = [classN, className];
  if (disabled || isLoading) {
    classNames.push('cursor-not-allowed opacity-50');
  };
  return (
    <button
      type={type}
      className={classNames.join(' ')}
      disabled={disabled || isLoading}
      onClick={props.onClick}
      id={props.id}
      style={props.style}
    >
      {isLoading ? <Loader /> : children}
    </button>
  );
}

Button.defaultProps = {
  type: 'button',
  disabled: false,
};

export default Button;
