import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CustomerQuestion from './CustomerQuestion';
import CustomerOrder from './CustomerOrder';
import ContactOrder from '../../../components/svgs/onboarding/ContactOrder';
import ContactQuestion from '../../../components/svgs/onboarding/ContactQuestion';
import EmptyEnvelope from '../../../components/svgs/EmptyEnvelope';
import emptyBox from '../../../assets/images/CRM/emptyBox.png';

const QuestionsAndOrdersTab = props => {
  let { orders, questions, isMobile } = props;

  const classes = useStyles({ isMobile });
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        TabIndicatorProps={{ style: { backgroundColor: '#2b48f0' } }}
        value={value}
        onChange={handleChange}
        textColor="inherit"
        centered
        style={{
          borderBottom: 'solid 1px #d2dbe2',
          borderTop: 'solid 1px #d2dbe2',
        }}
      >
        <Tab label="Órdenes" icon={<ContactOrder size={32} />} {...a11yProps(0)} />
        <Tab label="Preguntas" icon={<ContactQuestion size={32} />} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className={classes.listContainer}>
          {orders.length > 0 ? (
            orders.map(order => <CustomerOrder key={order.id} order={order} isMobile={isMobile} />)
          ) : (
            <span className="flex flex-col items-center mt-14 mb-10">
              <img className="opacity-25" style={{ width: '85px' }} src={emptyBox} alt="sin_ordenes" />
              <p style={{ opacity: '0.35' }} className="font-semibold text-xl">
                Sin órdenes
              </p>
            </span>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.listContainer}>
          {questions.length > 0 ? (
            questions.map(question => <CustomerQuestion key={question.id} question={question} isMobile={isMobile} />)
          ) : (
            <span className="flex flex-col items-center mt-14 mb-10">
              <EmptyEnvelope className="opacity-25" size={85} />
              <p style={{ opacity: '0.35' }} className="font-semibold text-xl">
                Sin preguntas
              </p>
            </span>
          )}
        </div>
      </TabPanel>
    </div>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index} id={`scrollable-force-tabpanel-${index}`} aria-labelledby={`scrollable-force-tab-${index}`}>
    {children}
  </div>
);

const a11yProps = index => ({
  id: `scrollable-force-tab-${index}`,
  'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listContainer: ({ isMobile }) => ({
    marginTop: '16px',
    '& > div': {
      borderBottom: '1px solid lightgrey',
      padding: isMobile ? '15px 0' : '20px 15px',
      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
  }),
}));

export default QuestionsAndOrdersTab;
