import { compose, withState, withHandlers, branch } from 'recompose';
import withUpdateGreetings from '../../questions/components/PredefinedAnswers/withUpdateGreetings';
import withPredefinedAnswers from '../../questions/components/withPredefinedAnswers';

export default compose(
  withPredefinedAnswers,
  withState('loading', 'onChangeLoading', false),
  withUpdateGreetings,
  branch(({ loadingData }) => { return !loadingData }, compose(
    withState('greeting', 'onChangeGreeting', ({ greetings }) => greetings.greeting),
    withState('farewell', 'onChangeFarewell', ({ greetings }) => greetings.farewell),
    withHandlers({
      handleSubmit: ({ greeting, farewell, onChangeLoading, updateGreetings }) => (nextStep) => {
        onChangeLoading(true);
        updateGreetings({ greeting, farewell })
          .then(() => {
            onChangeLoading(false);
            nextStep();
          });
      }
    }),
  )
  ),
);