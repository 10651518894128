import { gql } from 'apollo-boost';

export default gql`
mutation Signup($input: SignupInput!) {
  signup(input: $input) {
    token
    redirect_url
    user {
      add_store_url
    }
  }
}
`;
