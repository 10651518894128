import withUpdateTag from "../../questions/components/PredefinedAnswers/withUpdateTag";
import { compose, withState, branch } from "recompose";
import withPredefinedAnswers from "../../questions/components/withPredefinedAnswers";

export default compose(
    withPredefinedAnswers,
    branch(({ loadingData }) => { return !loadingData }, compose(
        withState("stock", "onChangeStock", ({ tags }) => tags.STOCK.response),
        withState("time", "onChangeTime", ({ tags }) => tags.TIME.response),
        withState("location", "onChangeLocation", ({ tags }) => tags.LOCATION.response),
        withState("shipping", "onChangeShipping", ({ tags }) => tags.SHIPPING.response),
    )
    ),
    withUpdateTag
);