import { graphql } from 'react-apollo';
import UPDATE_GREETINGS from '../../gql/updateGreetings';

export default graphql(
  UPDATE_GREETINGS,
  {
    props: ({ mutate }) => ({
      updateGreetings: (input) => mutate({
        variables: {
          input
        },
      })
    })
  },
);
