import { gql } from 'apollo-boost';

export default gql`
mutation Login($input: LoginInput!) {
  login(input: $input) {
    token
    redirect_url
    me: user {
      id
      name
      stores {
        id
        name
        sources {
          id
          name
          type
        }
      },
      add_store_url
    }
  }
}
`;
