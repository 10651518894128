import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tutorial from './Tutorial';
import { makeStyles } from '@material-ui/core/styles';
import EmptyStateVideo from './EmptyStateVideo';
import EmptyStateStats from './EmptyStateStats';
import withUserData from '../../../app/withUserData';
import withUpdateWalkThrough from './PredefinedAnswers/withUpdateWalkThrough';
import { compose } from 'react-apollo';

const EmptyState = (props) => {

  const [open, setOpen] = useState(false);  
  const [showTutorial, setShowTutorial] = useState(null);

  useEffect(() => {
    setShowTutorial(props.user.me.show_walk_throughs.includes('TUTORIAL'));
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const stopShowingTutorial = () => {
    const walkThrough = ["TUTORIAL"]
    props.updateWalkThrough({ mark_as_seen: walkThrough });
  };

  const startShowingTutorial = () => {
    setShowTutorial(true);
  }

  const useStyles = makeStyles({
    dialog: {
      width: '90%',
      height: '80vh'
    }
  });
  const classes = useStyles();

  return (
    <>
      {showTutorial ? <EmptyStateVideo handleClickOpen={handleClickOpen}/> : <EmptyStateStats />}
      {/* ----------------MODAL---------------- */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={false}
          classes={{
            paper: classes.dialog
          }}
        >
          <DialogContent>
            <Tutorial />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default compose(
  withUserData,
  withUpdateWalkThrough
)(EmptyState);