import { graphql } from 'react-apollo';
import { get } from 'lodash';

import QUESTIONS from '../gql/questions';

export default graphql(
  QUESTIONS,
  {
    options: {
      notifyOnNetworkStatusChange: true,
      variables: {
        cursor: null,
        status: 'ANSWERED',
        orderBy: {
          field: 'DATE_CREATED',
          direction: 'DESC'
        }
      }
    },
    props({ data }) {
      const { error, loading } = data;
      const questions = get(data, 'questions.data', []);
      const hasMore = get(data, 'questions.pageInfo.hasMorePages', false);
      const cursor = get(data, 'questions.pageInfo.nextCursor', null);
      const networkStatus = get(data, 'networkStatus', null);

      // Method called when for loading more results
      const loadMore = () => data.fetchMore({
        variables: { cursor },
        updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => {
          const result = {
            questions: {
              ...fetchMoreResult.questions,
              data: [
                ...previousResult.questions.data,
                ...fetchMoreResult.questions.data,
              ]
            },
          };
          return result;
        },
      });

      return { error, loading, questions, hasMore, cursor, loadMore, networkStatus };
    },
  },
);
