import React from 'react';

export default ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="96px" height="35.748px" viewBox="0 0 96 35.748" enableBackground="new 0 0 96 35.748">
<path fill="#EEEDED" d="M83.598,19.431c-0.529,0.213-1.097,0.301-1.695,0.301c-2.393,0-4.047-1.434-3.352-6.961
	c0.235-1.986,0.615-3.426,1.113-4.458c-4.197,2.456-6.188,5.491-7.131,7.766c0.146,6.062,3.469,8.31,8.587,8.31
	c5.757,0,9.92-2.613,11.397-9.554C90.629,19.668,84.313,19.632,83.598,19.431z"/>
<path fill="#EEEDED" d="M84.173,0.97c-0.065,0-0.161-0.013-0.292-0.004c-5.206,0.302-9.117,2.984-10.388,9.607
	c2.364-3.934,5.713-4.998,8.501-5.065c-0.006,0-0.01,0-0.016-0.001l0.008-0.013c3.008,0.176,5.908,0.113,5.027,7.114
	c-0.473,3.76-1.484,5.637-2.869,6.459c-0.037,0.023-0.076,0.041-0.113,0.063c0,0,8.057,0.091,8.857-6.614
	C93.915,3.898,90.396,0.917,84.173,0.97z"/>
<path fill="#EEEDED" d="M30.894,19.155c-1.994,0-3.508-0.953-3.12-4.156l0.537-4.509l-4.528-9.349h-0.12L22.185,13.26
	c-0.817,6.721,1.573,9.66,5.232,10.563c0.82,0.229,1.599,0.215,1.599,0.215s4.706-5.436,4.632-5.412
	C32.792,18.931,31.75,19.155,30.894,19.155z"/>
<path fill="#EEEDED" d="M10.816,19.144c4.247,0,7.928,0.781,10.742,2.471l-2.165,4.593c-4.849-2.468-9.572-2.94-13.73-2.94H3.106
	l-0.172-3.3L15.063,5.198H5.706V1.142h16.513l-0.391,3.323L9.821,19.144H10.816z"/>
<polygon fill="#EEEDED" points="28.417,9.702 29.467,1.142 24.271,1.142 "/>
<path fill="#EEEDED" d="M39.875,21.771l2.51-20.628h-5.773L34.53,18.249c-0.002,0-5.001,5.835-5.001,5.835
	C31.688,24.19,35.358,23.923,39.875,21.771z"/>
<path fill="#EEEDED" d="M55.71,2.639c0.64-0.62,1.349-1.172,2.129-1.657c-0.642-0.12-1.286-0.16-1.851-0.16
	c-2.204,0-4.538,0.843-6.303,2.288V1.182h-4.78l-2.492,20.607h5.463l1.891-15.381c0.756-0.506,2.252-0.811,3.695-0.921
	c0.148-0.254,0.309-0.502,0.473-0.744C54.544,3.916,55.71,2.639,55.71,2.639z"/>
<path fill="#EEEDED" d="M66.031,0.653c-3.829,0-6.693,1.277-8.749,3.266c0,0-1.027,1.128-1.56,1.856
	c-1.436,2.09-2.254,4.618-2.572,7.184c-0.379,3.299,0.179,6.323,2.01,8.37c8.623,2.023,13.423-1.619,14.833-2.954
	c-1.257,0.422-3.081,0.806-4.911,0.806c-3.219,0-6.137-1.06-5.835-4.369c0.856,0.267,1.887,0.4,2.917,0.4
	c4.25,0,9.955-1.989,10.688-7.985C73.367,2.77,70.402,0.653,66.031,0.653z M67.055,7.315c-0.34,2.911-3.345,4.364-5.921,4.364
	c-0.559,0-1.247-0.087-1.845-0.31c0.598-3.748,2.574-6.216,5.839-6.216C66.414,5.153,67.186,6.255,67.055,7.315z"/>
<path fill="#EEEDED" d="M49.17,19.655c14.596,6.791,21.262-1.305,21.262-1.305l0.09,4.456C70.521,22.806,59.125,30.487,49.17,19.655
	z"/>
<g>
	<g>
		<g>
			<g>
				<path fill="#EEEDED" d="M29.01,33.922c-0.406,0.144-0.649,0.196-0.992,0.196c-1.048,0-1.231-0.379-1.116-1.354l0.371-2.97H26.63
					l0.079-0.651h0.641l0.166-1.342h0.806l-0.166,1.342h1.564l-0.204,0.651h-1.448l-0.379,3.067
					c-0.039,0.328-0.067,0.604,0.438,0.604c0.204,0,0.445-0.022,0.836-0.153L29.01,33.922z"/>
				<path fill="#EEEDED" d="M31.556,34.157c-0.864,0-1.614-0.5-1.456-1.75l0.397-3.264h0.786l-0.398,3.213
					c-0.095,0.82,0.392,1.138,0.991,1.138c0.351,0,0.74-0.108,1.097-0.291l0.499-4.06h0.788l-0.479,3.902
					c-0.037,0.322-0.008,0.467,0.203,0.467c0.082,0,0.177-0.02,0.304-0.068v0.506c-0.187,0.125-0.448,0.207-0.69,0.207
					c-0.304,0-0.563-0.135-0.644-0.428C32.557,33.993,32.042,34.157,31.556,34.157z"/>
				<path fill="#EEEDED" d="M36.979,33.078c0.298,0.155,0.794,0.329,1.261,0.329c0.659,0,1.018-0.344,1.06-0.678
					c0.135-1.158-2.238-0.653-2.03-2.275c0.114-1.002,0.971-1.449,1.836-1.449c0.444,0,0.871,0.118,1.232,0.329l-0.214,0.671
					c-0.252-0.15-0.669-0.291-1.061-0.291c-0.482,0-0.939,0.178-1.008,0.672c-0.127,1.098,2.251,0.633,2.06,2.227
					c-0.096,0.785-0.757,1.535-1.991,1.535c-0.557,0-1.138-0.148-1.392-0.34L36.979,33.078z"/>
				<path fill="#EEEDED" d="M41.02,31.591c0.234-1.884,1.155-2.566,2.467-2.566c1.323,0,1.999,0.662,1.767,2.558
					c-0.233,1.888-1.147,2.576-2.448,2.576C41.378,34.157,40.786,33.485,41.02,31.591z M41.844,31.591
					c-0.174,1.43,0.233,1.903,1.058,1.903c0.711,0,1.354-0.474,1.527-1.903c0.195-1.557-0.271-1.904-1.049-1.904
					C42.691,29.687,42.04,30.034,41.844,31.591z"/>
				<path fill="#EEEDED" d="M46.945,29.796h-0.921l0.077-0.65h0.921l0.049-0.393c0.166-1.32,0.865-1.747,1.807-1.747
					c0.351,0,0.718,0.039,1.077,0.202l-0.145,0.632c-0.264-0.096-0.595-0.155-0.902-0.155c-0.516,0-0.935,0.226-1.041,1.041
					l-0.05,0.42h1.399l-0.075,0.65H47.74l-0.727,5.952h-0.795L46.945,29.796z"/>
				<path fill="#EEEDED" d="M51.6,33.922c-0.41,0.144-0.652,0.196-0.994,0.196c-1.045,0-1.23-0.379-1.114-1.354l0.368-2.97h-0.643
					l0.076-0.651h0.643l0.166-1.342h0.806l-0.162,1.342h1.565l-0.209,0.651h-1.444l-0.382,3.067
					c-0.037,0.328-0.067,0.604,0.438,0.604c0.204,0,0.447-0.022,0.837-0.153L51.6,33.922z"/>
				<path fill="#EEEDED" d="M58.533,29.146c0.289,0.582,0.408,1.414,0.309,2.233c-0.172,1.396-0.861,2.778-2.33,2.778
					c-0.617,0-1.076-0.217-1.224-0.574c-0.375,0.387-0.845,0.574-1.33,0.574c-1.06,0-1.524-0.428-1.379-1.596l0.416-3.418h0.81
					l-0.38,3.096c-0.087,0.673-0.087,1.234,0.71,1.255c0.404-0.021,0.922-0.304,1.01-1.028l0.407-3.32h0.817l-0.381,3.094
					c-0.088,0.682-0.125,1.248,0.593,1.248c0.894,0,1.358-0.963,1.487-1.984c0.104-0.861-0.061-1.816-0.399-2.357H58.533
					L58.533,29.146z"/>
				<path fill="#EEEDED" d="M63.13,34.157c-0.301,0-0.563-0.12-0.623-0.438c-0.387,0.277-0.862,0.387-1.31,0.387
					c-1.17,0-1.857-0.748-1.645-2.469c0.243-1.988,1.398-2.572,2.635-2.572c0.521,0,1.058,0.086,1.584,0.281l-0.457,3.699
					c-0.039,0.322-0.012,0.467,0.203,0.467c0.078,0,0.174-0.02,0.301-0.068v0.506C63.634,34.075,63.382,34.157,63.13,34.157z
					 M62.895,29.832c-0.271-0.067-0.515-0.104-0.744-0.104c-1.002,0-1.623,0.658-1.78,1.969c-0.136,1.08,0.157,1.779,1.021,1.779
					c0.313,0,0.672-0.059,1.101-0.271L62.895,29.832z"/>
				<path fill="#EEEDED" d="M67.27,29.86c-0.205-0.049-0.389-0.061-0.508-0.061c-0.396,0-0.814,0.092-1.078,0.293l-0.475,3.93
					h-0.788l0.595-4.879h0.688l0.01,0.463c0.397-0.404,0.884-0.521,1.321-0.521c0.107,0,0.266,0.01,0.418,0.045L67.27,29.86z"/>
				<path fill="#EEEDED" d="M70.861,33.167l0.021,0.68c-1.865,0.631-3.724,0.312-3.412-2.207c0.174-1.484,1.223-2.563,2.738-2.563
					c1.002,0,1.369,0.651,1.286,1.358c-0.195,1.485-2.091,1.942-3.237,1.594C68.188,33.647,69.493,33.642,70.861,33.167z
					 M70.648,30.454c0.047-0.469-0.213-0.719-0.612-0.719c-1,0-1.515,0.933-1.681,1.688C69.066,31.708,70.514,31.502,70.648,30.454z
					"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path fill="#EEEDED" d="M22.799,31.735l0.842,0.854l-0.494,0.439l-1.062-1.119v-0.355l1.062-1.115l0.494,0.418L22.799,31.735z
				 M24.197,31.735l0.839,0.854l-0.496,0.439l-1.061-1.119v-0.355l1.061-1.115l0.496,0.418L24.197,31.735z"/>
		</g>
		<g>
			<path fill="#EEEDED" d="M74.996,31.972l-0.844-0.859l0.494-0.438l1.063,1.122v0.354l-1.063,1.117l-0.494-0.422L74.996,31.972z
				 M73.6,31.972l-0.841-0.859l0.495-0.438l1.061,1.122v0.354l-1.061,1.117l-0.495-0.422L73.6,31.972z"/>
		</g>
	</g>
</g>
</svg>

);