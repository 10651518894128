import React from 'react';

export default ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={className}>
    <g fill="none" fillRule="evenodd" stroke="#8D94AA">
      <circle cx="7.75" cy="7.75" r="7.2" strokeWidth="1.1" />
      <path
        fill="#8D94AA"
        fillRule="nonzero"
        strokeWidth=".3"
        d="M5.5 6.134c.023-.67.24-1.192.65-1.57C6.561 4.189 7.105 4 7.782 4c.67 0 1.206.162 1.61.485.403.323.604.786.604 1.387 0 .296-.047.555-.142.776-.095.22-.215.407-.36.559a8.11 8.11 0 0 1-.559.525c-.22.19-.392.352-.513.485a1.87 1.87 0 0 0-.314.485 1.54 1.54 0 0 0-.132.65h-.833c0-.304.046-.57.137-.799a1.96 1.96 0 0 1 .331-.564c.13-.149.305-.322.525-.52a4.15 4.15 0 0 0 .64-.684c.136-.198.205-.453.205-.765 0-.358-.114-.63-.343-.816-.228-.186-.544-.28-.947-.28-.403 0-.723.105-.958.314-.236.21-.362.512-.377.908L5.5 6.134zm2.043 4.554c.175 0 .321.06.44.182a.645.645 0 0 1 .176.468c0 .19-.059.348-.177.474a.578.578 0 0 1-.44.188.596.596 0 0 1-.444-.188.653.653 0 0 1-.183-.474c0-.19.061-.346.183-.468a.606.606 0 0 1 .445-.182z"
      />
    </g>
  </svg>
);
