import { gql } from 'apollo-boost';

export default gql`
  query PagedListings($filters: ListingsInput, $paginate: PaginateInputNormal) {
    pagedListings(filters: $filters, paginate: $paginate) {
      data {
        id
        meli_id
        site_id
        title
        status
        seller_id
        category_id
        official_store_id
        price
        currency_id
        available_quantity
        sold_quantity
        catalog_product_id
        catalog_listing
        buying_mode
        listing_type_id
        condition
        visits
        thumbnail
        seller_custom_field
        permalink
        item_relations {
          id
          variationId
          stockRelation
        }
        variations {
          id
          meli_id
          is_combo
          combinations_description
          combinations {
            id
            name
            value_id
            value_name
          }
          item_relations {
            id
            variationId
            stockRelation
          }
          price
          available_quantity
          sold_quantity
          seller_sku
          catalog_product_id
        }
      }
      pageInfo {
        total
        lastPage
        currentPage
        perPage
        hasMorePages
      }
    }
  }
`;
