import React from 'react';
import ReactDataGrid from 'react-data-grid';

const SizeEditSizesTable = props => {
  const {
    sizeChart,
    handleGridRowsUpdated,
    attributesChanged
  } = props;

  const measurementColumns = sizeChart.columns.map(column => {return { key: column.id, name: column.name, editable: true}});
  const columns = [{ key: 'name', name: 'Nombre', editable: true }, ...measurementColumns];
  const rows = sizeChart.sizes.map((size)=> {
    let row = {name: size.sizeName };
    for (let index in size.measurements) {
      row[size.measurements[index].id]=size.measurements[index].value;
    }
    return row;
  });
  return (

    <div className="flex-1 flex items-center justify-center h-full w-full guide-size-table">
      {!attributesChanged && <ReactDataGrid
        columns={columns}
        rowGetter={i => {return Object.assign({}, rows[i]);}}
        rowsCount={sizeChart.sizes.length}
        enableCellSelect={true}
        minHeight={0}
        onGridRowsUpdated={handleGridRowsUpdated} />}
    </div>
)};

export default SizeEditSizesTable;