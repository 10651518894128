import { gql } from 'apollo-boost';

export default gql`
  query CatalogProductsQuery($filters: CatalogProductsInput, $paginate: PaginateInputNormal!) {
    CatalogProductsQuery(filters: $filters, paginate: $paginate) {
      catalogProducts {
        id
        status
        permalink
        product_name
        parent_id
        children_ids
      }
      paging {
        total
        pages
        currentPage
      }
    }
  }
`;
