import React, { useState } from 'react';

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'


const PhoneInput = props => {
  const { value, changeHandler} = props;
  const status = '';
  return (
    <div className={`form-field mb-4 ${status}`}>
      <label className="float-left text-sm font-bold mb-2 leading-none" htmlFor={`FormField-phone`}>
        Teléfono
      </label>
      <ReactPhoneInput
          id="phone"
          className="field appearance-none border rounded-sm w-full px-4 mb-3 leading-tight focus:outline-none"
          defaultCountry={'ar'}
          regions={['north-america', 'south-america', 'central-america']}
          excludeCountries={['bm', 'fk', 'gf', 'gy', 'pm', 'sr']}
          value={value}
          onChange={changeHandler}
          inputStyle={{
            width: '100%',
            height: '46px',
            fontFamily: 'Arboria'
          }}
          buttonStyle={{ height: '46px', top: 'auto'}}
          />
    </div>
  )
}

export default PhoneInput;