import React from 'react';

export default function Disclaimer() {
    return (
        <div className="mt-4">
            <div className="font-bold mb-2">
                Confirmación
            </div>
            <div>
                Al vincular tu producto a publicaciones de Mercado Libre, autorizas a Astroselling a modificar los datos de dicha publicación replicando los valores de Precio, Stock y Título entre otros, según la configuración establecida.
                <br />
                Astroselling actualizará dichos valores tomando la información del producto publicado en Shopify. La actualización se hará de manera automática cada 6 horas.
            </div>
        </div>
    )
}
