import { gql } from 'apollo-boost';

export default gql`
mutation editSizeChart($input:  UpdateSizeChartInput!) {
  updateSizeChart(input: $input) {
    sizeChart {
      id
      chartName
      linked_items_quantity
      columns {
        id
        name
      }
      sizes {
        sizeName
        measurements {
          id
          value
        }
      }
    }
  }
}

`;


