import React from 'react';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';

const CustomerOrder = props => {
  const { order, isMobile } = props;

  const classes = useStyles({ isMobile, ordersQty: order.items.length });
  const maxItemsShowed = 2;

  const currencySymbols = {
    USD: 'U$S', // US Dollar
    EUR: '€', // Euro
    UYU: '$', // UY Peso
    ARS: '$', // AR Peso
    BRL: 'R$', // BR Real
    MXN: '$', // Peso Mexicano
    CLP: '$', // CL Peso
    CRC: '₡', // Costa Rica Colón
    GBP: '£', // British Pound Sterling
    PYG: '₲', // Paraguayan Guarani
  };

  return (
    <div className="flex">
      <div className={classes.orderImageContainer} style={order.items.length > 1 ? { left: '30px' } : {}}>
        {order.items.slice(0, maxItemsShowed).map((item, index) => (
          <img
            key={`image-${index}`}
            className={classes.orderImage}
            style={{ left: `-${index * 30}px`, zIndex: `${100 + index}` }}
            src={item.listing.thumbnail}
            alt={item.listing.title}
            title={item.listing.title}
            onClick={() => window.open(item.listing.permalink, '_blank').focus()}
          />
        ))}
        {order.items.length > maxItemsShowed && (
          <div
            className={classes.moreItems}
            style={{ left: `-${maxItemsShowed * 30}px`, zIndex: `${100 + maxItemsShowed}` }}
            title={order.items
              .slice(maxItemsShowed)
              .map(i => i.listing.title)
              .join(' + ')}
          >
            +{order.items.length - maxItemsShowed}
          </div>
        )}
      </div>
      <div className="flex flex-col overflow-hidden">
        <div className="flex justify-between">
          <span className="font-semibold">{order.status}</span>
          <span style={{ fontSize: '15px' }} className="opacity-75">
            <Moment format="DD/MM/YYYY HH:mm">{order.date_created}</Moment>
          </span>
        </div>
        <span className="opacity-75">{`${currencySymbols[order.currency_id]} ${order.total_amount}`}</span>
        <span className="truncate">{`${order.items.length === 1 ? order.items[0].listing.title : `${order.items.length} items comprados`}`}</span>
        <a className="opacity-75 truncate cursor-pointer" target="_blank" href={order.link}>
          {order.link}
        </a>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  orderImageContainer: ({ isMobile, ordersQty }) => ({
    display: 'flex',
    width: isMobile || ordersQty === 1 ? 'auto' : '110px',
    margin: isMobile ? '0 10px 0 0' : '0 0',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    position: 'relative',
  }),
  orderImage: ({ ordersQty }) => ({
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
    width: '50px',
    height: '50px',
    flexWrap: 'nowrap',
    border: '1px solid lightgrey',
    margin: ordersQty === 1 ? '0 15px 0 0' : '1px',
    borderRadius: '50%',
    '&:hover': {
      zIndex: '200 !important',
      border: '1px solid #2b48f0',
    },
  }),

  moreItems: {
    position: 'relative',
    width: '50px',
    height: '50px',
    flexWrap: 'nowrap',
    border: '1px solid lightgrey',
    margin: '1px',
    borderRadius: '50%',
    background: 'white',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#2b48f0',
    cursor: 'default',
  },
}));

export default CustomerOrder;
