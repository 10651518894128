import React, { PureComponent } from 'react';
import Eye from '~/components/svgs/Eye';
import { Field } from 'formik';

class FormField extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render () {
    const { label, type, name, value, status, changeHandler, errorMsg, blurHandler, customClass } = this.props;
    return <div className={ customClass == undefined ? `form-field mb-4 ${status}` : `form-field ${status} ${customClass}`}>
      <label className="float-left text-sm font-bold mb-2 leading-none" htmlFor={`FormField-${name}`}>
        {label}
      </label>
      <p className="float-right text-xs italic">
        <span className="text-red">{errorMsg}</span>
        {type === 'password' && <><Eye className="ml-2" /><a className="ml-1" onClick={() => this.setState({show: !this.state.show})}>{this.state.show ? 'Ocultar' : 'Mostrar'}</a></>}
      </p>
      <input
        id={`FormField-${name}`}
        className="field appearance-none border rounded-sm w-full px-4 mb-3 leading-tight focus:outline-none"
        style={{ height: '46px', boxSizing: 'inherit', fontSize: 'initial', color: 'inherit' }}
        name={name}
        type={this.state.show ? 'text' : type}
        value={value}
        onChange={changeHandler}
        //onChange={(ev) => changeHandler(name, ev.target.value)}
        onBlur={blurHandler}
      />
    </div>
  }
}
  // <li>
  //   <label className="block text-grey-darker text-sm font-bold mb-2">
  //     {label}
  //     <input type={type} name={name} className="rounded appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none" />
  //   </label>
  //   <p class="text-red text-xs italic">Please choose a password.</p>
  // </li>

FormField.defaultProps = {
  errorMsg: null,
  status: '',
  type: 'text',
};

export default FormField;
