import React, { useState, useRef, useEffect } from 'react';
import MaterialTable from 'material-table';
import Refresh from '../../components/svgs/Refresh';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import listings from './products/gql/getListings';
import UPDATE_LISTING from './products/gql/updateListingMutation';
import Loader from '../../components/svgs/Loader';
import { NETWORK_STATUS } from '../../apollo/constants';
import { FormControlLabel, MenuItem, Menu, Checkbox, Tooltip, IconButton } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import MobileListingCard from '../onboarding/components/MobileListingCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import listingImg from '../../assets/images/Publications/listing.png';
import Swal from 'sweetalert2';
import Catalog from '../../components/svgs/onboarding/Catalog';
import LinkPopupListing from './LinkPopupListing';
import catalogProduct from './products/gql/catalogProduct';

let searchContactsTimeout = null;

const ListingTable = () => {
  const listingTitle = listing => {
    if (!listing.parentId) {
      return (
        <div className="flex items-center">
          <img src={listing.thumbnail} alt="" className="mr-2 w-12 rounded-full" style={!listing.parentId ? { border: 'solid 1px lightgray' } : {}} />
          <span className="flex flex-col">
            <span className={listing.catalog_listing ? 'block' : 'hidden'}>
              <span style={{ borderRadius: '3px' }} className="inline-block bg-vivid-blue font-medium text-white px-2 text-sm">
                Catálogo
              </span>
            </span>
            <a className="text-black" href={listing.permalink} target="_blank">
              {listing.title}
            </a>
          </span>
        </div>
      );
    } else {
      return <div>{listing.combinations_description}</div>;
    }
  };

  const listingPrice = listing => (
    <div className="flex">
      <span className="mr-1">{listing.currency_id}</span>
      <span>{listing.price}</span>
    </div>
  );

  const renderListingState = listing => {
    switch (listing.status) {
      case 'active':
        return <div style={{ color: '#19b162' }}>Activa</div>;
      case 'closed':
        return <div style={{ color: '#ff637e' }}>Cerrada</div>;
      case 'paused':
        return <div style={{ color: '#eda124' }}>Pausada</div>;
      case 'inactive':
        return <div style={{ color: '#gray' }}>Inactiva</div>;
      case 'under_review':
        return <div style={{ color: '#157cda' }}>En revisión</div>;
      default:
        return <div style={{ color: '#black' }}>{listing.status}</div>;
    }
  };

  const listingLink = listing => (
    <a href={listing.permalink} target="_blank" style={{ color: '#2b48f0' }}>
      <span className="mr-2">{listing.meli_id ? listing.meli_id : ''}</span>
      <i style={!listing.meli_id ? { display: 'none' } : {}} className="fa fa-external-link" aria-hidden="true"></i>
    </a>
  );

  const listingSKU = listing => {
    if (listing.parentId) {
      return <span>{listing.seller_sku ? listing.seller_sku : '-'}</span>;
    } else {
      return <span>{listing.seller_custom_field ? listing.seller_custom_field : '-'}</span>;
    }
  };
  const listingSold = listing => <span>{listing.sold_quantity}</span>;

  const linkToCatalog = listing => {
    if (listing.catalog_product_id && !listing.catalog_listing) {
      return (
        <span
          className={listing.item_relations.length > 0 ? '' : 'cursor-pointer'}
          style={{ color: '#151e47' }}
          onClick={() => {
            if (listing.item_relations.length > 0) {
              return null;
            }
            if (listing.variations && listing.variations.length > 0) {
              return null;
            }
            setProductCatalogId(listing.catalog_product_id);
            setListingMeliId(listing.meli_id);
            setParentMeliId(listing.parentMeliId ? listing.parentMeliId : null);
            getCatalogProduct();
            handleOpenLinkListing();
          }}
        >
          {listing.item_relations.length > 0 ? (
            <span className="opacity-50">Publicado</span>
          ) : !listing.variations || listing.variations.length === 0 ? (
            <Tooltip title="Publicar">
              <IconButton>
                <Catalog style={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
          )}
        </span>
      );
    }
  };

  const columns = [
    {
      title: 'Publicación',
      field: 'title',
      cellStyle: {
        fontWeight: '600',
      },
      render: listingTitle,
    },
    {
      title: 'Stock',
      field: 'available_quantity',
      type: 'numeric',
      editable: (e, rowData) => (rowData.variations && rowData.variations.length > 0 ? false : true),
    },
    {
      title: 'Precio',
      field: 'price',
      render: listingPrice,
      type: 'numeric',
      editable: (e, rowData) => (rowData.variations && rowData.variations.length >= 0 ? true : false),
    },
    {
      title: 'Estado',
      field: 'status',
      render: renderListingState,
      lookup: { active: 'Activa', closed: 'Cerrada', paused: 'Pausada' },
    },
    {
      title: 'Vendidos',
      field: 'sold_quantity',
      render: listingSold,
      editable: 'never',
    },
    {
      title: 'SKU',
      field: 'seller_custom_field',
      render: listingSKU,
    },
    {
      title: 'Publicación en ML',
      field: 'permalink',
      render: listingLink,
      editable: 'never',
    },
    {
      title: 'Publicar en catálogo',
      field: 'catalog_product_id',
      render: linkToCatalog,
      editable: 'never',
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];

  const pageSize = 10;

  const { loading, data, refetch, networkStatus, called } = useQuery(listings, {
    variables: { paginate: { page: 1, per_page: pageSize }, filters: {} },
    notifyOnNetworkStatusChange: true,
  });

  const [updateListing, { error }] = useMutation(UPDATE_LISTING);

  if (error) {
    Swal.fire({
      type: 'error',
      title: 'Oops!...',
      text: error ? error.graphQLErrors[0].message : 'Algo ha salido mal :(',
    });
  }
  const [productCatalogId, setProductCatalogId] = useState(null);
  const [listingMeliId, setListingMeliId] = useState(null);
  const [parentMeliId, setParentMeliId] = useState(null);
  const [getCatalogProduct, { loading: catalogLoading, data: catalogData, error: catalogError }] = useLazyQuery(catalogProduct, {
    variables: { catalog_product_id: productCatalogId && productCatalogId },
    notifyOnNetworkStatusChange: true,
  });

  const [listingState, setListingState] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [listingList, setListingList] = useState([]);
  const [listingsWithCatalog, setListingsWithCatalog] = useState(false);
  const [menu, setMenu] = useState(null);
  const handleClose = () => setMenu(null);
  const [openLinkListing, setOpenLinkListing] = useState(false);

  const handleOpenLinkListing = () => {
    setOpenLinkListing(true);
  };
  const handleCloseLinkListing = () => {
    setOpenLinkListing(false);
  };

  useEffect(() => {
    if (!called || loading || !isMobile) {
      return;
    }

    const newListings = data.pagedListings.data;

    const listingWithVariations = addListingVariations(page === 1 ? newListings : listingList.concat(newListings));
    setListingList(listingWithVariations);
  }, [data]);

  useEffect(() => {
    if (loading || !isMobile) {
      return;
    }

    if (page === 1) {
      setListingList([]);
    }

    refetch({
      paginate: { page: page, per_page: pageSize },
      filters: { status: listingState, title: search },
    });
  }, [page]);

  const handleChange = name => {
    setListingState(name);
    tableRef.current.onQueryChange({ page: 0, status: name });
  };

  const tableRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const filterByStatusMobile = status => {
    setListingState(status);
    refetch({
      paginate: { page: 1, per_page: pageSize },
      filters: { status, title: search },
    });
    setPage(1);
    handleClose();
  };

  const searchMobile = title => {
    if (!loading) setSearch(title);

    searchContactsTimeout && clearTimeout(searchContactsTimeout);
    searchContactsTimeout = setTimeout(() => {
      refetch({
        paginate: { page: 1, per_page: pageSize },
        filters: { status: listingState, title },
      });
      setPage(1);
    }, 500);
  };

  const addListingVariations = listingList => {
    let listingWithVariations = [];

    listingList.forEach(listing => {
      const variations = [...listing.variations];
      listingWithVariations.push(listing);

      if (variations && variations.length > 0) {
        variations.forEach(variation => {
          listingWithVariations.push({
            ...variation,
            parentId: listing.id,
            status: listing.status,
            catalog_listing: listing.catalog_listing && listing.catalog_listing,
            parentMeliId: listing.meli_id,
          });
        });
      }
    });

    return listingWithVariations;
  };

  const updateSelectedListing = (newData, oldData) => {
    if (oldData.status === 'paused' && newData.status === 'closed') {
      Swal.fire({
        type: 'error',
        title: 'Oops!...',
        text: 'No se puede pasar del estado "Pausada" a "Cerrada"',
      });
      return Promise.resolve();
    } else if (oldData.status === 'closed' && (newData.status === 'active' || newData.status === 'paused')) {
      Swal.fire({
        type: 'error',
        title: 'Oops!...',
        text: 'No se puede cambiar el estado de una publicación cerrada',
      });
      return Promise.resolve();
    } else {
      if (
        oldData.title === newData.title &&
        oldData.seller_custom_field === newData.seller_custom_field &&
        oldData.price === newData.price &&
        oldData.available_quantity === newData.available_quantity &&
        oldData.status === newData.status
      ) {
        return Promise.resolve();
      } else {
        return updateListing({
          variables: {
            input: {
              listingId: oldData.id,
              ...(oldData.title !== newData.title && { title: newData.title }),
              ...(oldData.seller_custom_field !== newData.seller_custom_field && { seller_custom_field: newData.seller_custom_field }),
              ...(oldData.price !== newData.price && { price: newData.price }),
              ...(oldData.available_quantity !== newData.available_quantity && { available_quantity: newData.available_quantity }),
              ...(oldData.status !== newData.status && { status: newData.status }),
            },
          },
        });
      }
    }
  };

  return (
    <div className="flex flex-col w-full listing-table">
      <LinkPopupListing
        openLinkListing={openLinkListing}
        handleCloseLinkListing={handleCloseLinkListing}
        catalogData={catalogData}
        catalogLoading={catalogLoading}
        catalogError={catalogError}
        productCatalogId={productCatalogId}
        listingMeliId={listingMeliId}
        parentMeliId={parentMeliId}
      />
      <div style={{ width: '90%' }} className="flex flex-col w-full justify-start mb-3">
        {loading ? (
          <Loader className="text-vivid-blue" />
        ) : (
          <div className="flex items-center">
            <span className="text-2xl text-blue-darkest font-arboria font-bold mb-2 mr-2">{`${data && data.pagedListings.pageInfo.total} publicaciones`}</span>
            <span onClick={() => refetch()}>
              <Refresh className={networkStatus === NETWORK_STATUS.REFETCH ? 'spin' : 'cursor-pointer'} />
            </span>
          </div>
        )}
        <span>El listado de todas las publicaciones de tu tienda</span>
      </div>
      {!isMobile && (
        <MaterialTable
          editable={{
            isEditable: rowData => rowData.status !== 'closed',
            onRowUpdate: (newData, oldData) => updateSelectedListing(newData, oldData),
          }}
          parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
          tableRef={tableRef}
          style={{ width: '90%' }}
          actions={[
            {
              isFreeAction: true,
              icon: () => (
                <div>
                  <i className={'fa fa-filter ' + (listingState ? 'text-vivid-blue' : '')} aria-hidden="true" onClick={event => setAnchorEl(event.currentTarget)} />
                  <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                    <MenuItem style={{ borderBottom: '1px solid lightgrey' }}>
                      <FormControlLabel control={<Checkbox checked={listingState === null} onChange={() => handleChange(null)} />} label="Todas" />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel control={<Checkbox checked={listingState === 'active'} onChange={() => handleChange('active')} />} label="Activas" />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel control={<Checkbox checked={listingState === 'closed'} onChange={() => handleChange('closed')} />} label="Cerradas" />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel control={<Checkbox checked={listingState === 'paused'} onChange={() => handleChange('paused')} />} label="Pausadas" />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel control={<Checkbox checked={listingState === 'inactive'} onChange={() => handleChange('inactive')} />} label="Inactivas" />
                    </MenuItem>
                  </Menu>
                </div>
              ),
            },
            {
              isFreeAction: true,
              icon: () => <Catalog size={20} style={listingsWithCatalog ? { fill: '#2b48f0' } : { fill: '#616161' }} />,
              tooltip: 'Solo publicaciones en catálogo',
              onClick: () => {
                tableRef.current.onQueryChange({ page: 0, catalog_listing: !listingsWithCatalog });
                setListingsWithCatalog(!listingsWithCatalog);
              },
            },
          ]}
          data={query => {
            return refetch({
              paginate: { per_page: pageSize, page: query.page + 1 },
              filters: {
                ...(query.search && { title: query.search }),
                ...(query.status && { status: query.status }),
                ...(query.catalog_listing && { catalog_listing: true }),
              },
            }).then(({ data }) => {
              return {
                data: addListingVariations(data.pagedListings.data),
                page: query.page,
                totalCount: data.pagedListings.pageInfo.total,
              };
            });
          }}
          columns={columns}
          options={{
            rowStyle: value => {
              if (value.state == 'inactive') return { opacity: '0.5' };
            },
            toolbar: true,
            showSelectAllCheckbox: false,
            showTitle: false,
            searchFieldAlignment: 'left',
            columnsButton: true,
            showFirstLastPageButtons: false,
            pageSizeOptions: [],
            pageSize,
            actionsColumnIndex: 7,
            debounceInterval: 500,
          }}
          localization={{
            toolbar: {
              showColumnsTitle: 'Mostrar columnas',
              showColumnsAriaLabel: 'Mostrar columnas',
              addRemoveColumns: 'Añadir o quitar columnas',
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar',
            },
            header: {
              actions: 'Editar',
            },
            body: {
              editTooltip: 'Editar',
              emptyDataSourceMessage: (
                <>
                  {loading ? (
                    <div className="flex flex-col justify-center">
                      <span className="mb-1">
                        <Loader className="text-vivid-blue" />
                      </span>
                      <span>Cargando publicaciones...</span>
                    </div>
                  ) : (
                    <span>Aún no tienes publicaciones</span>
                  )}
                </>
              ),
            },
          }}
        />
      )}

      {/* -----MOBILE----- */}

      {isMobile && (
        <>
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', ...(listingState ? { color: '#2b48f0' } : {}), ...(loading ? { display: 'none' } : {}) }}>
            <i
              className="fa fa-filter"
              aria-hidden="true"
              style={{ padding: '0 10px', fontSize: '24px', position: 'absolute', top: '-70px', right: '0' }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={event => setMenu(event.currentTarget)}
            />
            <input
              type="text"
              placeholder="Buscar.."
              style={{
                width: '100%',
                height: '40px',
                margin: '10px 0',
                borderRadius: '6px',
                padding: '10px 30px 10px 10px',
                lineHeight: '40px',
                fontSize: '16px',
                boxShadow: '0 3px 16px 0 #0000002e',
              }}
              value={search}
              onChange={event => searchMobile(event.target.value)}
            />
            <i className="fa fa-search text-vivid-blue" aria-hidden="true" style={{ position: 'absolute', top: '22px', right: '15px' }} />
            <Menu id="simple-menu" anchorEl={menu} keepMounted open={Boolean(menu)} onClose={handleClose}>
              <MenuItem onClick={() => filterByStatusMobile(null)}>
                Todas
                {listingState === null ? <i className="fa fa-check text-vivid-blue" aria-hidden="true" style={{ fontSize: '20px', margin: '0 0 0 10px' }} /> : null}
              </MenuItem>
              <MenuItem onClick={() => filterByStatusMobile('active')}>
                Activas
                {listingState === 'active' ? <i className="fa fa-check text-vivid-blue" aria-hidden="true" style={{ fontSize: '20px', margin: '0 0 0 10px' }} /> : null}
              </MenuItem>
              <MenuItem onClick={() => filterByStatusMobile('closed')}>
                Cerradas
                {listingState === 'closed' ? <i className="fa fa-check text-vivid-blue" aria-hidden="true" style={{ fontSize: '20px', margin: '0 0 0 10px' }} /> : null}
              </MenuItem>
              <MenuItem onClick={() => filterByStatusMobile('paused')}>
                Pausadas
                {listingState === 'paused' ? <i className="fa fa-check text-vivid-blue" aria-hidden="true" style={{ fontSize: '20px', margin: '0 0 0 10px' }} /> : null}
              </MenuItem>
              <MenuItem onClick={() => filterByStatusMobile('inactive')}>
                Inactivas
                {listingState === 'inactive' ? <i className="fa fa-check text-vivid-blue" aria-hidden="true" style={{ fontSize: '20px', margin: '0 0 0 10px' }} /> : null}
              </MenuItem>
            </Menu>
          </div>
          <InfiniteScroll
            next={() => setPage(prev => prev + 1)}
            hasMore={loading ? true : (data && data.pagedListings.pageInfo.total) > listingList.length}
            dataLength={loading ? 10 : data && data.pagedListings.pageInfo.total}
            loader={
              <div style={{ textAlign: 'center', ...(listingList.length === 0 ? { marginTop: '50%' } : { margin: '20px 0' }) }}>
                <Loader style={{ color: '#2b48f0', marginBottom: '5px' }} />
                <div>
                  {search ? 'Buscando' : 'Cargando'} {listingList.length > 0 ? ' más ' : ' '}publicaciones
                </div>
              </div>
            }
            endMessage={!loading && listingList.length === 0 ? '' : <div style={{ textAlign: 'center' }}>No hay más publicaciones</div>}
          >
            {!loading && listingList.length === 0 ? (
              <div className="flex flex-col items-center" style={{ marginTop: '25%' }}>
                <img style={{ width: '120px', opacity: '0.5', marginBottom: '15px' }} src={listingImg} alt="publicaciones" />
                <p style={{ fontFamily: 'Arboria-Medium', opacity: '0.65' }} className="text-xl">
                  {search ? 'No hay resultados para la búsqueda' : 'Aún no tienes publicaciones'}
                </p>
              </div>
            ) : (
              <>
                {listingList.map(listing => {
                  if (!listing.parentId) {
                    return <MobileListingCard key={listing.id} listing={listing} />;
                  }
                })}
              </>
            )}
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

export default ListingTable;
