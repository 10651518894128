import React, { Component } from 'react';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Button from '../../../components/Button';
import FetchingQuestions from '../../../components/svgs/onboarding/FetchingQuestions';

class QuestionsFetched extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percent: 100
    }
  }

  render() {
    const { nextStep } = this.props;
    return (
      <div className="flex flex-1 items-start justify-center md:items-center">
        <div style={{ minHeight: "540px" }} className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
          <div className="onboarding flex-1">
            <p style={{ fontSize: "25px" }} className="mb-8 font-bold"> Preguntas importadas</p>
            <p style={{ fontSize: "18px" }} className="description mb-10">
              Las preguntas de tu tienda de Mercado <br />
              Libre se han importado correctamente.
            </p>
            <Progress
              style={{ maxWidth: "350px" }}
              className="mb-10"
              theme={{
                active: {
                  color: '#19b162'
                },
                success: {
                  color: '#19b162',
                  //symbol: <i style={{ fontSize: "22px", color: "#19b162" }} className="fa fa-check-circle"></i>
                  symbol: `${this.state.percent}%`
                }
              }}
              percent={100}
            />
            <Button style={{ padding: "1rem 3rem" }} onClick={nextStep}>
              Continuar
            </Button>
          </div>
          <div className="flex-1 ml-24 hidden xl:block">
            <FetchingQuestions />
          </div>
        </div>
      </div>
    )
  }
}

export default QuestionsFetched;
