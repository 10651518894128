import { gql } from 'apollo-boost';

export default gql`
mutation AddStore($input: AddStoreInput!) {
  addStore(input: $input) {
    status
    token
    store {
      id
      name
      sources {
        id 
        name
        type
      }
      pendingQuestions
    }
  }
}
`;