import { compose, withState, withHandlers } from 'recompose';

export default compose(
  withState('open', 'onChangeOpen', false),
  withHandlers({
    openModal: ({ onChangeOpen }) => () => {
      onChangeOpen(true); 
    },
    closeModal: ({ onChangeOpen }) => () => {
      onChangeOpen(false); 
    }
  }),
);
