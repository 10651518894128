import { graphql } from 'react-apollo';
import { get } from 'lodash';

import PRODUCT from '../gql/product';

export default graphql(
  PRODUCT,
  {
    options({product}) {
      return {
        notifyOnNetworkStatusChange: true,
        variables: { 
          filter: {
            "source_id": product.source_id,
            "id": product.id
          },
        },
    }
  },
    props({data}) {
      const { error, loading, product } = data;
      const initialValueMap = {}
      const initialLisitingItems = product && product.links && product.links.length ? 
        product.links[0].items.map((node) => {
          initialValueMap[node.item_id.toString()] = true;
          return {label: node.title, value: node.item_id.toString()} }) :
        []; 
      return { productError: error, productLoading: loading, product, initialValueMap, initialLisitingItems };
    },
  },
);