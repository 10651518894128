import React, { useState, useEffect, useRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomerPopup from './CustomerPopup';
import ContactQuestion from '../../../components/svgs/onboarding/ContactQuestion';
import ContactOrder from '../../../components/svgs/onboarding/ContactOrder';
import { useMediaQuery } from 'react-responsive';
import Loader from '../../../components/svgs/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import resources from '../gql';
import { useQuery } from '@apollo/react-hooks';
import MobileContactCard from './MobileContactCard';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import contactsImg from '../../../assets/images/CRM/contacts.png';
import Refresh from '../../../components/svgs/Refresh';
import { NETWORK_STATUS } from '../../../apollo/constants';

let searchContactsTimeout = null;

const Crm = () => {
  const pageSize = 10;

  const [contacts, setContacts] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [contactData, setContactData] = useState(null);
  const [search, setSearch] = useState(null);
  const [queryOptions, setQueryOptions] = useState({ search: '', page: 1, orderBy: { field: 'created_at', direction: 'desc' } });
  const [pageInfo, setPageInfo] = useState({ total: null, current_page: 1 });

  const { called, data, loading, refetch, networkStatus } = useQuery(resources.contacts({}), {
    variables: { paginate: { per_page: pageSize, page: 1 } },
    notifyOnNetworkStatusChange: true,
  });

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const showCustomerDetails = (ev, rowData) => {
    setContactData(rowData);
    setShowDetails(true);
  };

  useEffect(() => {
    if (!called || loading) {
      return;
    }

    const newContacts = data.meliContacts.data;

    setContacts(queryOptions.page === 1 ? newContacts : contacts.concat(newContacts));
    setPageInfo(data.meliContacts.pageInfo);
  }, [data]);

  useEffect(() => {
    if (loading || !isMobile) {
      return;
    }

    if (queryOptions.page === 1) {
      setContacts([]);
    }

    refetch({
      paginate: {
        per_page: pageSize,
        page: queryOptions.page,
      },
      filters: {
        ...(queryOptions.withOrders && { has_orders: true }),
        ...(queryOptions.withQuestions && { has_questions: true }),
        ...(queryOptions.search && { q: queryOptions.search }),
        ...(queryOptions.orderBy && {
          order_by: {
            field: queryOptions.orderBy.field.toUpperCase(),
            direction: queryOptions.orderBy.direction.toUpperCase(),
          },
        }),
      },
    });
  }, [queryOptions]);

  useEffect(() => {
    if (search === null) {
      return;
    }

    searchContactsTimeout && clearTimeout(searchContactsTimeout);
    searchContactsTimeout = setTimeout(() => setQueryOptions({ search, page: 1 }), 500);
  }, [search]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => setAnchorEl(null);

  const orderByOptions = [
    { field: 'created_at', direction: 'desc', text: 'Más nuevos' },
    { field: 'created_at', direction: 'asc', text: 'Más viejos' },
    { field: 'nickname', direction: 'asc', text: 'Nickname A-Z' },
    { field: 'nickname', direction: 'desc', text: 'Nickname Z-A' },
    { field: 'first_name', direction: 'asc', text: 'Nombre A-Z' },
    { field: 'first_name', direction: 'desc', text: 'Nombre Z-A' },
    { field: 'last_name', direction: 'asc', text: 'Apellido A-Z' },
    { field: 'last_name', direction: 'desc', text: 'Apellido Z-A' },
  ];

  const tableRef = useRef();

  return (
    <div id="crm" className="flex flex-col">
      <div className="crm-title">
        <div className="flex items-center">
          <span className="text-2xl text-blue-darkest font-bold mb-2 mr-2">
            {loading ? <Loader className="text-vivid-blue mr-2" /> : <span className="mr-1">{data && data.meliContacts.pageInfo.total}</span>}
            <span>Clientes</span>
          </span>
          <span onClick={() => refetch()}>
            <Refresh className={networkStatus === NETWORK_STATUS.REFETCH ? 'spin' : 'cursor-pointer'} />
          </span>
        </div>
        <span>Aquí encontrarás la información de todos los clientes que te hayan preguntado o comprado</span>
      </div>
      <CustomerPopup isMobile={isMobile} handleClose={() => setShowDetails(false)} open={showDetails} userData={contactData} meliId={contactData && contactData.meli_id} />
      {!isMobile && (
        <MaterialTable
          tableRef={tableRef}
          actions={[
            {
              isFreeAction: true,
              icon: () => <ContactOrder size={20} style={queryOptions.withOrders ? { fill: '#2b48f0' } : { fill: 'black' }} />,
              tooltip: 'solo usuarios con órdenes',
              onClick: () => {
                tableRef.current.onQueryChange({ page: 0, withOrders: !queryOptions.withOrders });
                setQueryOptions(prev => ({ ...queryOptions, withOrders: !prev.withOrders }));
              },
            },
            {
              isFreeAction: true,
              icon: () => <ContactQuestion size={20} style={queryOptions.withQuestions ? { fill: '#2b48f0' } : { fill: 'black' }} />,
              tooltip: 'solo usuarios con preguntas',
              onClick: () => {
                tableRef.current.onQueryChange({ page: 0, withQuestions: !queryOptions.withQuestions });
                setQueryOptions(prev => ({ ...queryOptions, withQuestions: !prev.withQuestions }));
              },
            },
          ]}
          components={{
            Toolbar: props => (
              <MTableToolbar
                {...props}
                columnsButton={true}
                exportButton={true}
                localization={{
                  showColumnsTitle: 'Mostrar columnas',
                  showColumnsAriaLabel: 'Mostrar columnas',
                  exportTitle: 'Exportar',
                  exportAriaLabel: 'Exportar',
                  exportName: 'Exportar a CSV',
                  searchTooltip: 'Buscar',
                  searchPlaceholder: 'Buscar',
                  addRemoveColumns: 'Agregar o quitar columnas',
                }}
                toolbarButtonAlignment="right"
                searchFieldAlignment="left"
              />
            ),
          }}
          style={{ width: '95%', minHeight: '70%' }}
          title=""
          onRowClick={showCustomerDetails}
          columns={[
            {
              title: 'Nickname',
              field: 'nickname',
              render: contact => (
                <div className="flex">
                  <a href={contact.permalink} target="_blank" style={{ color: 'black' }}>
                    <span>{contact.nickname}</span>
                    <i class="fa fa-external-link ml-2" aria-hidden="true"></i>
                  </a>
                </div>
              ),
            },
            { title: 'Nombre', field: 'first_name', hidden: true },
            { title: 'Apellido', field: 'last_name', hidden: true },
            { title: 'Teléfono', field: 'phone', searchable: false, sorting: false },
            {
              title: 'Órdenes',
              searchable: false,
              sorting: false,
              render: orders,
              headerStyle: { textAlign: 'center' },
            },
            {
              title: 'Preguntas',
              searchable: false,
              sorting: false,
              render: questions,
              headerStyle: { textAlign: 'center' },
            },
          ]}
          data={query => {
            return refetch({
              paginate: { per_page: pageSize, page: query.page + 1 },
              filters: {
                ...(query.withOrders && { has_orders: true }),
                ...(query.withQuestions && { has_questions: true }),
                q: query.search,
                order_by: {
                  field: (query.orderBy ? query.orderBy.field : 'created_at').toUpperCase(),
                  direction: (query.orderDirection || 'desc').toUpperCase(),
                },
              },
            }).then(({ data }) => {
              setPageInfo({ ...data.meliContacts.pageInfo });
              setSearch(query.search);
              return {
                data: data.meliContacts.data,
                page: query.page,
                totalCount: data.meliContacts.pageInfo.total,
              };
            });
          }}
          options={{ search: true, pageSize, pageSizeOptions: [], showFirstLastPageButtons: false, debounceInterval: 500, exportAllData: true }}
          localization={{
            toolbar: { searchPlaceholder: 'Buscar' },
            body: {
              emptyDataSourceMessage: (
                <div>
                  {search ? (
                    'No hay resultados para su búsqueda'
                  ) : (
                    <div className="flex flex-col items-center">
                      <img style={{ width: '120px', opacity: '0.5' }} src={contactsImg} alt="clientes" />
                      <p style={{ fontFamily: 'Arboria-Medium', opacity: '0.65' }} className="text-xl">
                        Aún no tienes clientes
                      </p>
                    </div>
                  )}
                </div>
              ),
            },
          }}
        />
      )}

      {isMobile && (
        <>
          <div style={{ padding: '10px', width: '100%', height: '100%', position: 'relative' }}>
            <i
              className="fa fa-filter"
              aria-hidden="true"
              style={{ padding: '0 10px', fontSize: '24px', position: 'absolute', top: '-77px', right: '10px' }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={event => setAnchorEl(event.currentTarget)}
            />
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="Buscar.."
                style={{
                  width: '100%',
                  height: '40px',
                  margin: '10px 0',
                  borderRadius: '6px',
                  padding: '10px 30px 10px 10px',
                  lineHeight: '40px',
                  fontSize: '16px',
                  boxShadow: '0 3px 16px 0 #0000002e',
                }}
                value={search}
                onChange={event => !loading && setSearch(event.target.value)}
              />

              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {orderByOptions.map(option => (
                  <MenuItem
                    key={`${option.field}-${option.direction}`}
                    onClick={() => {
                      setQueryOptions({ ...queryOptions, orderBy: { ...option } });
                      handleClose();
                    }}
                  >
                    {option.text}
                    {queryOptions.orderBy && queryOptions.orderBy.field === option.field && queryOptions.orderBy.direction === option.direction ? (
                      <i className="fa fa-check text-vivid-blue" aria-hidden="true" style={{ fontSize: '20px', margin: '0 0 0 10px' }} />
                    ) : null}
                  </MenuItem>
                ))}
                <MenuItem
                  style={{ borderTop: '1px solid lightgrey' }}
                  onClick={() => {
                    setQueryOptions(prev => ({ ...queryOptions, withOrders: !prev.withOrders }));
                    handleClose();
                  }}
                >
                  Con órdenes
                  {queryOptions.withOrders ? <i className="fa fa-check text-vivid-blue" aria-hidden="true" style={{ fontSize: '20px', margin: '0 0 0 10px' }} /> : null}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setQueryOptions(prev => ({ ...queryOptions, withQuestions: !prev.withQuestions }));
                    handleClose();
                  }}
                >
                  Con preguntas
                  {queryOptions.withQuestions ? <i className="fa fa-check text-vivid-blue" aria-hidden="true" style={{ fontSize: '20px', margin: '0 0 0 10px' }} /> : null}
                </MenuItem>
              </Menu>

              <i className="fa fa-search text-vivid-blue" aria-hidden="true" style={{ position: 'absolute', top: '22px', right: '15px' }} />
            </div>

            <InfiniteScroll
              next={() => setQueryOptions({ ...queryOptions, page: queryOptions.page + 1 })}
              hasMore={loading ? true : pageInfo.total > contacts.length}
              dataLength={loading ? 10 : pageInfo.total}
              loader={
                <div style={{ textAlign: 'center', ...(contacts.length === 0 ? { marginTop: '50%' } : { margin: '20px 0' }) }}>
                  <Loader style={{ color: '#2b48f0', marginBottom: '5px' }} />
                  <div>
                    {search ? 'Buscando' : 'Cargando'} {contacts.length > 0 ? ' más ' : ' '}clientes
                  </div>
                </div>
              }
              scrollableTarget="questions-list"
              endMessage={!loading && contacts.length === 0 ? '' : <div style={{ textAlign: 'center' }}>No hay más clientes</div>}
            >
              {!loading && contacts.length === 0 ? (
                <div className="flex flex-col items-center" style={{ marginTop: '50%' }}>
                  <img style={{ width: '120px', opacity: '0.5' }} src={contactsImg} alt="clientes" />
                  <p style={{ fontFamily: 'Arboria-Medium', opacity: '0.65' }} className="text-xl">
                    {search ? 'No hay resultados para la búsqueda' : 'Aún no tienes clientes'}
                  </p>
                </div>
              ) : (
                <>
                  {contacts.map(contact => (
                    <MobileContactCard key={contact.id} onClick={ev => showCustomerDetails(ev, contact)} contact={contact} />
                  ))}
                </>
              )}
            </InfiniteScroll>
          </div>
        </>
      )}
    </div>
  );
};

const orders = contact => (
  <span className="flex items-center justify-center text-lg" style={{ color: '#333' }}>
    <ContactOrder size={20} style={{ fill: '#333', marginRight: '8px' }} />
    {contact.ordersQty}
  </span>
);

const questions = contact => (
  <span className="flex items-center justify-center text-lg" style={{ color: '#333' }}>
    <ContactQuestion size={20} style={{ fill: '#333', marginRight: '8px' }} />
    {contact.questionsQty}
  </span>
);

export default Crm;
