import React from 'react';

import Tick from '../../../components/svgs/Tick';

export default ({ clickHandler, checked, node }) => {
  let classNames = [ 'inline-flex items-center text-md cursor-pointer select-none' ];
  classNames = checked ? classNames + ' text-blue' : classNames + ' text-blue-darkest';
  return (
    <div className="w-1/2 mb-4">
      <div className={classNames} onClick={clickHandler}>
        <div className="w-4 h-4 rounded-sm border border-blue mr-1 flex items-center justify-center">
          {checked && <Tick />}
        </div>  
        {node.name}
      </div>
    </div>
  );
};