import React, { Component } from 'react';

import ResponseForm from './ResponseForm';
import Checkbox from '../../../components/Checkbox';
import Loader from '../../../components/svgs/Loader';
import DialogIcon from '../../../components/svgs/Dialog';
import TagIcon from '../../../components/svgs/TagIcon';
import OtherQuestion from './OtherQuestion';
import { formatDateText } from '../../../utils/date';

class UserQuestion extends Component {
  constructor (props) {
    super(props);
    this.state = {
      respond: false,
      otherQuestionSelected: false,
      tutorialQuestionId: '-1',
    };
    this.date = formatDateText(props.date_created, true);
    this.form = React.createRef();
    this.toggleResponse = this.toggleResponse.bind(this);
    this.toggleMoreResponses = this.toggleMoreResponses.bind(this);
    this.setResponseTag = this.setResponseTag.bind(this);
  }
  
  toggleResponse (ev) {
    ev.stopPropagation();
    if (!this.state.respond && this.props.batchEnabled) {
      return;
    }
    
    this.setState({ respond: !this.state.respond });
    this.props.openQuestionHandler(this.state.respond ? null : this.props.id, this.state.respond ? null : this.props.from_meli_id);
  }
  
  toggleMoreResponses (itemId, fromMeliId, ev) {
    ev.stopPropagation();
    // When closing questions
    if (this.state.otherQuestionSelected) {
      this.props.getMoreQuestionHandler(null, null);
    } else {
      this.props.getMoreQuestionHandler(itemId, fromMeliId);
    }
    this.setState({ otherQuestionSelected: !this.state.otherQuestionSelected });
  }
  
  setResponseTag (ev) {
    if (!this.props.batchEnabled) {
      const response = this.props.predefinedTags[this.props.tags[0]].response;
      if (!this.state.respond) {
        this.props.openQuestionHandler(this.props.id);
        this.setState(
          {
            respond: true,
          },
          () => this.form.current.insertText(response),
        );
      } else {
        this.form.current.insertText(response);
      }
      ev.stopPropagation();
    }
  }
  
  render () {
    const {
      checked,
      checkHandler,
      deleteHandler,
      fading,
      from_meli_id,
      greetings,
      id,
      listing,
      predefinedAnswers,
      predefinedTags,
      sendHandler,
      status,
      tags,
      text,
      meliId,
      from_answered_questions,
      moreQuestions,
      loadingMoreQuestions,
      questionExpandedId,
      loadContactDetails,
    } = this.props;
    
    const { tutorialQuestionId } = this.state;
    const meliIdAux = meliId ? meliId : listing['meli_id'];
    const isChecked = checked.includes(id);
    const userQuestionClass = ['user-question'];
    
    if (isChecked) {
      userQuestionClass.push('checked');
    }
    
    if (questionExpandedId === id && this.state.respond) {
      userQuestionClass.push('open');
    }
    
    const className = ['userquestion-wrapper', status.toLowerCase()];
    if (fading) {
      className.push('fade');
    }
    
    return (
      <div className={className.join(' ')}>
        <div className="question-checkbox-wrapper">
          <div id={id === tutorialQuestionId ? 'user-question-checkbox' : ''}>
            <Checkbox
              className="question-checkbox"
              value={id}
              checked={isChecked}
              clickHandler={() => {
                checkHandler(id, false, this.props.from_meli_id);
                this.props.openQuestionHandler(null, this.props.from_meli_id);
              }}
            />
          </div>
        </div>
        <div
          id={id === tutorialQuestionId ? 'user-question' : ''}
          className={userQuestionClass.join(' ')}
          onClick={ev =>
            window.screen.width < 600 ? checkHandler(id, true, this.props.from_meli_id) : !(questionExpandedId === id && this.state.respond) &&
              this.toggleResponse(ev)
          }
        >
          {questionExpandedId === id &&
          this.state.respond &&
          this.state.otherQuestionSelected &&
          !loadingMoreQuestions &&
          moreQuestions.map(node => <OtherQuestion key={'other-responses' + node.id} {...node} />)}
          <div className="user-question__question px-5 py-4">
            <div className="flex items-start flex-1 px-2">
              <DialogIcon className="mt-1"/>
              <div className="flex-1 px-2 word-break">
                {this.props.errorIds[id] && (
                  <p className="text-danger">
                    No fue posible {this.props.errorIds[id].type === 'd' ? 'eliminar' : 'responder a'} la
                    pregunta: <em>{this.props.errorIds[id].message}</em>
                  </p>
                )}
                <p className="question-text">{text}</p>
                {listing && (
                  <a href={listing.permalink} target="_blank" className="text-vivid-blue text-base" onClick={ev => ev.stopPropagation()}>
                    {listing.title}
                  </a>
                )}
              </div>
            </div>
            {tags && predefinedTags[tags[0]] && !!predefinedTags[tags[0]].response && (
              <button
                id={id === tutorialQuestionId ? 'user-question-tag' : ''}
                className="hidden-in-mobile ml-7 md:ml-0 bg-transparent px-4 py-1 border border-orange rounded-xs text-orange font-bold hover:bg-orange hover:text-white whitespace-no-wrap"
                onClick={this.setResponseTag}
              >
                <span style={{ marginRight: '6px' }}>
                  <TagIcon/>
                </span>
                #{predefinedTags[tags[0]].label}
              </button>
            )}
            <span className="hidden-in-mobile question-date">{this.date}</span>
            <a className="question-reply">Responder</a>
          </div>
          {questionExpandedId === id && this.state.respond && (
            <div className="flex flex-col px-8 pb-8">
              <div className="user-question__userinfo">
                <span onClick={() => loadContactDetails(from_meli_id)} className="user-question__username ml-4 md:ml-0">
                  {from_meli_id}
                </span>
                {!loadingMoreQuestions && from_answered_questions > 0 && (
                  <a
                    className="text-vivid-blue md:ml-1 cursor-pointer ml-4 md:ml-0"
                    onClick={ev => {
                      this.toggleMoreResponses(meliIdAux, from_meli_id, ev);
                    }}
                  >
                    {' '}
                    {this.state.otherQuestionSelected ? 'Cerrar otras preguntas del usuario' : 'Ver otras preguntas del usuario'}
                  </a>
                )}
                {loadingMoreQuestions && <Loader className="text-vivid-blue px-4"/>}
              </div>
              <ResponseForm
                id={id}
                ref={this.form}
                ids={[id]}
                closeHandler={this.toggleResponse}
                sendHandler={(text, tags) => sendHandler([id], text, tags)}
                onSelectHandler={() => this.props.openQuestionHandler(this.props.id)}
                deleteHandler={() => deleteHandler([id])}
                predefinedAnswers={predefinedAnswers}
                greetings={greetings}
                tags={predefinedTags}
                focusOnMount
                tag={tags}
                answering={this.props.answering}
                deleting={this.props.deleting}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UserQuestion;
