import React, { Component } from 'react';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Button from '../../../components/Button';
import Loader from '../../../components/svgs/Loader';
import FetchingQuestions from '../../../components/svgs/onboarding/FetchingQuestions';

class FetchingQuestionsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            percent: 0,
        }
    }

    render() {
        const { changeReady } = this.props;
        if (!this.questionsReadyTimeout) {
            this.questionsReadyTimeout = setTimeout(() => {
                changeReady();
            }, 20 * 1000);
        }
        if (!this.loadingBarInterval) {
            this.loadingBarInterval = setInterval(() => {
                if (this.state.percent < 100) {
                    this.setState({ percent: this.state.percent + 1 })
                }
            }, 0.2 * 1000);
        }
        return (
            <div className="flex flex-1 items-start justify-center md:items-center">
                <div style={{ minHeight: "540px" }} className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
                    <div className="onboarding flex-1">
                        <p style={{ fontSize: "25px" }} className="mb-8 font-bold">Importando preguntas</p>
                        <p style={{ fontSize: "18px" }} className="description mb-10">
                            Estamos importando las preguntas de tu <br />
                            tienda de Mercado Libre. Esto podría tardar <br />
                            unos segundos.
                        </p>
                        <Progress
                            style={{ maxWidth: "350px" }}
                            className="mb-10"
                            theme={{
                                active: {
                                    color: '#19b162'
                                },
                                success: {
                                    color: '#19b162',
                                    //symbol: <i style={{ fontSize: "22px", color: "##19b162" }} className="fa fa-check-circle"></i>
                                    symbol: `${this.state.percent}%`
                                }
                            }}
                            percent={this.state.percent}
                        />
                        <Button style={{ padding: "1rem 3rem" }} disabled={true}>
                            <Loader />
                        </Button>
                    </div>
                    <div className="flex-1 ml-24 hidden xl:block">
                        <FetchingQuestions />
                    </div>
                </div>
            </div>
        )
    }
}

export default FetchingQuestionsComponent;