import React from 'react';
import { compose, withState } from 'recompose';
import { withApollo } from 'react-apollo';
import AsyncSelect from './AsyncSelect';
import LISTINGS from './products/gql/listings';

const SelectListings = props => {
  const { client, searchWord, onChangeSearchWord, onChangeSelected, listingSelected, initialValueMap } = props;
  return <AsyncSelect
            selected={listingSelected}
            loadOptions={ () => {
              return client.query({
                  query: LISTINGS,
                  options: {
                    notifyOnNetworkStatusChange: true,
                  },
                  variables: {
                    filter: {
                      title: searchWord,
                    },
                    paginate: {
                      count: 20
                    }
                  }
                }
                ).then(({ data }) => {
                  let options = listingSelected;
                  for (let node of data.listings.data) { 
                    // Only if the listing isn't already in the default values add it to options.
                    if (!initialValueMap || !initialValueMap[node.id.toString()]) {
                      options = [...options, {label: node.title, value: node.id.toString()}];
                    }
                  }
                  return options;
                })
                
              } 
            }
            handleInputChange={(ev)=> onChangeSearchWord(ev)}
            handleChangeSelected={(ev)=> onChangeSelected(ev)}/>;
};

export default compose(
  withApollo,
  withState('searchWord', 'onChangeSearchWord', ''),
)(SelectListings);