import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomerInformation from './CustomerInformation';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLazyQuery } from '@apollo/react-hooks';
import resources from '../gql';

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const CustomerPopup = withStyles(styles)(props => {
  const { handleClose, open, meliId, classes, isMobile } = props;

  const [loadContactDetails, { called, data, loading }] = useLazyQuery(resources.contacts({ loadRelations: ['orders', 'questions'] }));

  const DialogContent = withStyles(theme => ({
    root: isMobile
      ? {
          maxWidth: '100%',
          maxHeight: '100%',
          width: '100%',
          height: '100%',
          margin: 0,
          padding: '0 !important',
        }
      : {
          padding: theme.spacing(2),
          width: '600px',
        },
  }))(MuiDialogContent);

  useEffect(() => {
    if (!meliId || !open || (called && loading)) {
      return;
    }

    //TODO: meliId ivan (52239466)
    loadContactDetails({ variables: { filters: { meli_id: meliId }, paginate: { per_page: 1, page: 1 } } });
  }, [meliId, open]);

  const contact = data && data.meliContacts.data[0];

  return (
    <Dialog fullScreen={isMobile} fullWidth={isMobile} maxWidth={'100%'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContent>
        <PerfectScrollbar>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} style={isMobile ? { top: 0, right: 0 } : { top: 0 }}>
            <CloseIcon />
          </IconButton>
          <CustomerInformation isMobile={isMobile} contact={contact} loading={loading} />
        </PerfectScrollbar>
      </DialogContent>
    </Dialog>
  );
});

export default CustomerPopup;
