import React, { PureComponent } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { compose, withApollo } from 'react-apollo';
import paths from '../app/paths';

class AutoLogin extends PureComponent {
  constructor (props) {
    super(props);
    this.params = queryString.parse(this.props.location.search);
    props.history.replace(paths.AUTO_LOGIN);
  }

  render () {
    if (!this.params.token) {
      return <Redirect to={paths.LOGIN} />;
    }
    if (localStorage.getItem('token')) {
      client.resetStore();
      localStorage.clear();
    }
    localStorage.setItem('token', this.params.token);
    return <Redirect to={paths.DEFAULT} />;
  }
}

export default compose(
  withApollo,
  withRouter,
)(AutoLogin);
