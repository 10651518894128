import React from 'react';
import Hashtag from '../../../../components/svgs/Hashtag';
import Hand from '../../../../components/svgs/Hand';
import Chat from '../../../../components/svgs/Chat';

export default ({ setShowing, TAGS, GREETINGS, RESPONSES }) => (
  <ul id="predefined-answers" className="predefined-answers-categories px-8" style={{ justifyContent: 'space-evenly' }}>
    <li
      id="tags"
      className="flex flex-col flex-1 cursor-pointer items-center border-b-2 border-grey-light justify-center"
      onClick={() => setShowing(TAGS)}
    >
      <div className="flex flex-col items-start">
        <Hashtag className="mb-2" />
        Tags
      </div>
    </li>
    <li
      id="greetings"
      className="flex flex-col flex-1 cursor-pointer items-center border-b-2 border-grey-light justify-center"
      onClick={() => setShowing(GREETINGS)}
    >
      <Hand className="mb-2" />
      Saludo y <br />
      despedida
    </li>
    <li
      id="predefined-answers"
      className="flex flex-col flex-1 cursor-pointer items-center justify-center"
      onClick={() => setShowing(RESPONSES)}
    >
      <Chat className="mb-2" />
      Más <br />
      respuestas
    </li>
  </ul>
);
