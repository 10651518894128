import React, { useState } from 'react';
import Available from '../../../assets/images/Publications/warehouse.png';
import Price from '../../../assets/images/Publications/coin.png';
import Sold from '../../../assets/images/Publications/sold.png';
import MobileListingPopup from './MobileListingPopup';
import MobileListingVariations from './MobileListingVariations';

const MobileListingCard = ({ listing }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const openEditingPopup = () => {
    setOpenPopup(true);
  };

  const closeEditingPopup = () => {
    setOpenPopup(false);
  };

  return (
    <div
      style={{
        background: 'white',
        borderRadius: '6px',
        width: '100%',
        marginBottom: '10px',
        padding: '20px 0',
        boxShadow: '0 3px 16px 0 #0000002e',
        fontSize: '14px',
        color: '#1c181d',
        position: 'relative',
      }}
    >
      <MobileListingPopup openPopup={openPopup} closeEditingPopup={closeEditingPopup} listing={listing} />
      <div style={{ padding: '0 20px' }}>
        <div className="flex justify-between items-baseline">
          <a href={listing.permalink} target="_blank" className="flex items-baseline text-black" style={{ marginRight: '15px' }}>
            <i style={{ marginRight: '5px' }} className="fa fa-external-link" aria-hidden="true" />
            <h3 style={{ fontSize: '16px', lineHeight: '1.25' }}>{listing.title}</h3>
          </a>
          <i onClick={openEditingPopup} className="fa fa-pencil-square-o" aria-hidden="true" style={{ fontSize: '18px', color: 'rgb(132, 130, 133)' }}></i>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', justifyContent: 'space-around' }}>
          <span className="flex flex-1">
            <div style={{ fontSize: '14px', lineHeight: '1.5', color: '#6e6b6f', marginRight: '4px' }}>Estado: </div>
            <span style={{ color: '#1c181d' }}>{translateStatus(listing.status)}</span>
          </span>
          <span className="flex flex-1">
            <div style={{ fontSize: '14px', lineHeight: '1.5', color: '#6e6b6f', marginRight: '4px' }}>Condición: </div>
            <span style={{ color: '#1c181d' }}>{translateCondition(listing.condition)}</span>
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px', justifyContent: 'space-around' }}>
          <span
            // onClick={() => <MobileListingVariations listing={listing} openVariations={openVariations} /> }
            className="flex flex-1 text-black"
          >
            <div style={{ fontSize: '14px', lineHeight: '1.5', color: '#6e6b6f', marginRight: '4px' }}>Variaciones: </div>
            <span style={{ color: '#1c181d' }}>{listing.variations.length}</span>
          </span>
          <span className="flex flex-1">
            <div style={{ fontSize: '14px', lineHeight: '1.5', color: '#6e6b6f', marginRight: '4px' }}>Visitas: </div>
            <span style={{ color: '#1c181d' }}>{listing.visits ? listing.visits : 0}</span>
          </span>
        </div>
      </div>

      <div style={{ margin: '14px 0', width: '100%', height: '1px', backgroundColor: '#d2dbe2' }} />

      <div
        style={{
          padding: '0 20px',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ width: '30px', marginRight: '5px' }} src={Price} alt="precio" />
          <span
            style={{
              borderRadius: '8px',
              backgroundColor: '#f2f5f7',
              padding: '4px 6px',
              minWidth: '28px',
              textAlign: 'center',
            }}
          >
            {`${listing.currency_id} ${listing.price}`}
          </span>
        </span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ width: '40px' }} src={Sold} alt="vendidos" />
          <span
            style={{
              borderRadius: '8px',
              backgroundColor: '#f2f5f7',
              padding: '4px 6px',
              minWidth: '28px',
              textAlign: 'center',
            }}
          >
            {listing.sold_quantity}
          </span>
        </span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ width: '32px', marginRight: '6px' }} src={Available} alt="disponibles" />
          <span
            style={{
              borderRadius: '8px',
              backgroundColor: '#f2f5f7',
              padding: '4px 6px',
              minWidth: '28px',
              textAlign: 'center',
            }}
          >
            {listing.available_quantity}
          </span>
        </span>
      </div>
    </div>
  );
};

const translateStatus = status => {
  switch (status) {
    case 'active':
      return <div style={{ color: '#19b162' }}>Activa</div>;
    case 'closed':
      return <div style={{ color: '#ff637e' }}>Cerrada</div>;
    case 'paused':
      return <div style={{ color: '#eda124' }}>Pausada</div>;
    case 'inactive':
      return <div style={{ color: '#gray' }}>Inactiva</div>;
    case 'under_review':
      return <div style={{ color: '#157cda' }}>En revisión</div>;
    default:
      return <div style={{ color: '#black' }}>{status}</div>;
  }
};

const translateCondition = condition => {
  switch (condition) {
    case 'new':
      return 'nuevo';
    case 'used':
      return 'usado';
    default:
      return condition;
  }
};

export default MobileListingCard;
