import React from 'react';

import ChevronLeft from '../../../../components/svgs/ChevronLeft';

export default ({ title, backHandler, className }) => (
  <div className={"predefined-answers__header " + className}>
    <h3>
      <a onClick={backHandler} className="inline-block cursor-pointer"><ChevronLeft className="text-vivid-blue mr-4" /></a>
      {title}
    </h3>
  </div>
);
