import React, { Component } from "react";
import { Link } from "react-router-dom";
import Chevron from "./svgs/Chevron";

export default class Breadcrumb extends Component {
  render() {
    const lastElement = this.props.path.pop();

    return (
      <div className="text-blue-darkest text-sm font-bold flex items-baseline mb-8">
        {this.props.path.map((el) => {
          return (
            <div key={el.title}>
              <Link
                className={"no-underline text-blue-darkest mr-2"}
                to={el.path}
              >
                {el.title}
              </Link>
              <Chevron className="rotate-3/4 mr-2" />
            </div>
          );
        })}

        <Link
          className="no-underline text-blue"
          to={lastElement.path}
          key={lastElement.title}
        >
          {lastElement.title}
        </Link>
      </div>
    );
  }
}
