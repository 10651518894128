import React from 'react';
import { NavLink } from 'react-router-dom';
import paths from '../../../app/paths';

const Guides = props => {
  const {
    storeSizeCharts
  } = props;
  return (<>
    <h2 className="text-blue-darkest text-2xl mb-2">
      Mis guías
    </h2>

    <p className="text-sm text-blue-darkest mb-3">
      Las guías de talles que has creado
    </p>

    <div className="-mx-8 flex overflow-x-scroll flex-wrap">
      {
        storeSizeCharts && storeSizeCharts.map(storeSizeChart => (
          <div key={storeSizeChart.id} className="w-1/3 px-8 flex flex-col">
            <div className="mb-8 p-5 bg-white h-40 ">
              <div className="self-end">
                <span className="w-1 h-1 mb-1 bg-grey rounded-full block"></span>
                <span className="w-1 h-1 mb-1 bg-grey rounded-full block"></span>
                <span className="w-1 h-1 mb-1 bg-grey rounded-full block"></span>
                {/* <div className="flex items-center">
                  <DropDown> 
                    <div>    
                      <b style={{ paddingRight: '1em' }}>{'store.name'}</b>
                    </div>
                  </DropDown>
                </div> */}
              </div>
    
              <div className="self-start flex-1 ml-8">
                <NavLink
                  to={paths.SIZE_GUIDE + '/' + storeSizeChart.id}
                  activeClassName="text-blue font-semibold"
                >
                  <h3 className="mb-3 text-xl text-blue-darkest">
                    {storeSizeChart.chartName}
                  </h3>
                </NavLink>
    
                <div className="-mx-3 flex flex-wrap">
                  <p className="px-3 py-1 text-blue-darkest"
                    ><span className="text-blue">{storeSizeChart.sizes ? storeSizeChart.sizes.lenght : 0}</span> talles</p
                  >
                  <p className="px-3 py-1 text-blue-darkest border-l border-solid"
                    ><span className="text-blue">{storeSizeChart.linked_items_quantity}</span> productos asociados</p
                  >
                </div>
              </div>
            </div>
          </div>
          )
        )
      }
    </div>
  </>
)}

export default Guides;