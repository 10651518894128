import React from 'react';
import { compose } from 'recompose';
import withHandleGreetings from './withHandleGreetings';
import Button from '../../../components/Button';
import Loader from '../../../components/svgs/Loader';

const AddGreetings = props => {
  const {
    onChangeGreeting,
    onChangeFarewell,
    handleSubmit,
    loading,
    nextStep,
    greeting,
    farewell,
    loadingData,
    jumpStep
  } = props;
  return (
    <div className="flex flex-1 items-start justify-center md:items-center">
      <div style={{ minHeight: "540px" }} className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
        {
          !loadingData ? (
            <div className="onboarding flex-1">
              <div className="greetings-container">
                <div className="mb-6 mt-2 sm:flex flex-col">
                  <p className="mb-6" style={{ fontSize: "18px" }}>
                    ¿Cómo te gustaría <span className="font-bold text-vivid-blue">saludar</span> a tus clientes <br />
                    cuando respondas a sus preguntas?
                      </p>
                  <textarea
                    style={{ border: "solid 1px lightgrey" }}
                    className="rounded w-full mb-2"
                    rows="3"
                    name="saludo"
                    placeholder="Ej: Hola! Gracias por tu pregunta."
                    onChange={(ev) => { onChangeGreeting(ev.target.value); }}
                    value={greeting}
                  >
                  </textarea>
                </div>
                <div className="flex flex-col">
                  <p className="mb-6" style={{ fontSize: "18px" }}>
                    ¿Cómo te gustaría <span className="font-bold text-vivid-blue">despedirte</span> de tus clientes <br />
                    cuando respondas a sus preguntas?
                      </p>
                  <textarea
                    style={{ border: "solid 1px lightgrey" }}
                    className="rounded w-full mb-4"
                    rows="3"
                    name="despedida"
                    placeholder="Ej. Estamos a las órdenes. Saludos!"
                    onChange={(ev) => { onChangeFarewell(ev.target.value) }}
                    value={farewell}
                  >
                  </textarea>
                </div>
              </div>
              <div className="greetings-buttons">
                <Button disabled={loading} onClick={() => { handleSubmit(nextStep) }}>
                  {loading ? <Loader /> : (<> Continuar </>)}
                </Button>
                <div className="jump-step">
                  <a className="" href="#" onClick={() => jumpStep(3)}>Saltar este paso</a>
                </div>
              </div>
            </div>
          ) :
            <div className="onboarding flex-1 flex-row items-center justify-center">
              <Loader className="text-vivid-blue self-center h-6" />
            </div>
        }
      </div>
    </div>
  )
}

export default compose(
  withHandleGreetings
)(AddGreetings);
