import React from 'react';
import Button from '../../../components/Button';
import ConfigAnswers from '../../../assets/images/onboarding/configPredefinedAnswers.png';

const ConfigPredefinedAnswers = props => {
  const { nextStep, jumpStep } = props;
  return (
    <div className="flex flex-1 items-start justify-center md:items-center">
      <div style={{ minHeight: "540px" }} className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
        <div className="onboarding flex-1">
          <p style={{ fontSize: "25px" }} className="mb-8 font-bold"> Respuestas predefinidas</p>
          <p style={{ fontSize: "18px" }} className="description mb-14">
            Astroselling te permite responder las preguntas
            de tu tienda de Mercado Libre más rápido que nunca.<br /><br />
            Para eso, ayúdanos a configurar tus nuevas
            respuestas predefinidas.
          </p>
          <Button onClick={nextStep}>
            Configurar respuestas predefinidas
          </Button>
          <div className="mt-4">
            <a className="" href="#" onClick={() => jumpStep(3)}>Saltar este paso</a>
          </div>
        </div>
        <div className="flex-1 ml-24 hidden xl:block">
          <img src={ConfigAnswers} />
        </div>
      </div>
    </div>
  )
}

export default ConfigPredefinedAnswers;
