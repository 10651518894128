import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Loader from '../../components/svgs/Loader';
import Swal from 'sweetalert2';
import { useMutation } from '@apollo/react-hooks';
import CREATE_CATALOG_LISTING from './products/gql/createCatalogListing';
import SearchCatalogProduct from './products/components/SearchCatalogProduct';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    marginRight: '4rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: '24px 16px',
    width: '600px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '12px',
  },
}))(MuiDialogActions);

const LinkPopupListing = props => {
  const { openLinkListing, handleCloseLinkListing, catalogData, catalogLoading, catalogError, listingMeliId, productCatalogId, parentMeliId } = props;
  const title = catalogData && catalogData.CatalogProductQuery.product_name;
  const permalink = catalogData && catalogData.CatalogProductQuery.permalink;

  const [searchOther, setSearchOther] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');

  useEffect(() => {
    setSearchOther(false);
    setSelectedProduct('');
  }, [openLinkListing]);

  const [createCatalogListing, { loading: createCatalogLoading, error: createCatalogError }] = useMutation(CREATE_CATALOG_LISTING, {
    onCompleted() {
      Swal.fire({
        type: 'success',
        title: '¡Éxito!',
        text: 'El producto se a publicado correctamente.',
      });
    },
  });

  useEffect(() => {
    showPopupError(createCatalogError);
    showPopupError(catalogError);
  }, [createCatalogError, catalogError]);

  const showPopupError = error => {
    if (error) {
      Swal.fire({
        type: 'error',
        title: 'Oops!...',
        text: error ? error.graphQLErrors[0].message : 'Algo ha salido mal :(',
      });
    }
  };

  return (
    <div>
      <Dialog maxWidth={false} onClose={handleCloseLinkListing} aria-labelledby="customized-dialog-title" open={openLinkListing} disableBackdropClick={true}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseLinkListing}>
          Publicar en catálogo de Mercado Libre
        </DialogTitle>
        <DialogContent dividers style={{ textAlign: 'center' }}>
          {catalogLoading ? (
            <Loader className="text-vivid-blue" />
          ) : (
            <div>
              {!searchOther ? (
                <>
                  <span className="text-md font-semibold flex self-start">¿Es este tu producto?</span>
                  <div style={{ border: 'solid 1px lightgray' }} className="flex flex-col items-center mt-6 p-4 rounded">
                    <a style={{ fontSize: '15px' }} className="flex items-baseline" target="_blank" href={selectedProduct === '' ? permalink : selectedProduct.permalink}>
                      <span className="text-vivid-blue">{selectedProduct === '' ? title : selectedProduct.product_name}</span>
                      <i className="fa fa-external-link ml-2 text-vivid-blue" aria-hidden="true" style={!title && !selectedProduct ? { display: 'none' } : {}}></i>
                    </a>
                  </div>
                </>
              ) : (
                <SearchCatalogProduct setSelectedProduct={setSelectedProduct} setSearchOther={setSearchOther} />
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {!catalogLoading ? (
            <>
              {searchOther ? (
                <Button onClick={() => setSearchOther(false)}>Volver</Button>
              ) : (
                <>
                  <Button onClick={() => setSearchOther(true)}>No, buscar otro</Button>
                  <Button
                    autoFocus
                    onClick={() => {
                      !createCatalogLoading
                        ? createCatalogListing({
                            variables: {
                              input: {
                                listing_id: parentMeliId || listingMeliId,
                                catalog_product_id: selectedProduct.id || productCatalogId,
                                variation_id: parentMeliId ? listingMeliId : null,
                              },
                            },
                          })
                        : null;
                    }}
                    color="primary"
                    variant="contained"
                  >
                    {createCatalogLoading ? <Loader /> : 'Si, Publicar'}
                  </Button>
                </>
              )}
            </>
          ) : (
            <Loader className="text-vivid-blue" />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LinkPopupListing;
