import React from 'react';
import Button from '../../../components/Button';
import ConfigTags from '../../../assets/images/onboarding/configTags.png';

const ConfigArtificialIntelligence = props => {
  const { nextStep, jumpStep } = props;
  return (
    <div className="flex flex-1 items-start justify-center md:items-center">
      <div style={{ minHeight: '540px' }} className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
        <div className="onboarding flex-1">
          <p style={{ fontSize: '25px' }} className="mb-8 font-bold">
            {' '}
            Inteligencia artificial
          </p>
          <p style={{ fontSize: '18px' }} className="description mb-14">
            Astroselling reconoce palabras clave en las <br />
            preguntas de tu tienda y te sugiere responderlas <br />
            con un solo click. Configura las respuestas para <br />
            estos casos y comienza a responder más rápido.
          </p>
          <Button onClick={nextStep}>Configurar inteligencia artificial</Button>
          <div className="mt-4">
            <a className="" href="#" onClick={jumpStep}>
              Saltar este paso
            </a>
          </div>
        </div>
        <div className="flex-1 ml-24 hidden xl:block">
          <img src={ConfigTags} />
        </div>
      </div>
    </div>
  );
};

export default ConfigArtificialIntelligence;
