import { gql } from 'apollo-boost';

export default gql`
  query MeasurementAttributes($site_id: String!) {
    measurementAttributes(site_id: $site_id) {
      id
      name  
      type {
        default_unit
      }
    }
  }`
;
