import React from 'react';

const Refresh = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="13" height="13" viewBox="0 0 13 13">
    <path fill="#2B48F0" fillRule="evenodd" d="M9.455 3.25v1.182h3.25v-3.25h-1.182V2.38A6.524 6.524 0 0 0 6.5 0 6.507 6.507 0 0 0 0 6.5h1.182A5.324 5.324 0 0 1 6.5 1.182c1.654 0 3.21.778 4.21 2.068H9.454zM6.5 13c3.584 0 6.5-2.916 6.5-6.5h-1.182A5.324 5.324 0 0 1 6.5 11.818 5.339 5.339 0 0 1 2.291 9.75h1.254V8.568H.295v3.25h1.182V10.62A6.522 6.522 0 0 0 6.5 13z"/>
  </svg>
);

Refresh.defaultProps = {
  className: '',
};

export default Refresh;
