import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SizeGuide from './SizeGuide';
import EditSizeGuide from './EditSizeGuide';

export default () => (
  <Switch>
    <Route path='/guia-de-talles/:sizeGuideId' component={EditSizeGuide} />
    <Route exact path='/guia-de-talles' component={SizeGuide}/>
  </Switch>
);
