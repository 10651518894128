import React from 'react';

export default ({ className }) => (
  <svg
    className={"fill-current " + className}
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path fillRule="evenodd" d="M8 1.078L6.896 0 4.019 2.94 1.08.064 0 1.17l4.045 3.954z"/>
  </svg>
);
