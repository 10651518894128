import { graphql } from 'react-apollo';

import EDITSIZEGUIDE from '../gql/editSizeGuide';

export default graphql(EDITSIZEGUIDE, {
  props: ({ mutate }) => ({
    editSizeQuide: (input) => mutate({
      variables: { input },
    })
  }),
});
