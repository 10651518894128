import { gql } from 'apollo-boost';

export default gql`
mutation LinkProduct($input: LinkProductInput!) {
  linkProduct(input: $input) {
    link {
      source_id
      product_id
      items {
        item_id
        title
        channel_id
        channel_name
      }
      sync_stock
      sync_price
      sync_title
      sync_images
      sync_description
    }
  }
}
`;