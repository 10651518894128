import React, { Component } from 'react'
import paths from '../../../app/paths';
import AddGreetings from '../components/AddGreetings';
import ConfigPredefinedAnswers from './ConfigPredefinedAnswers';

class PredefinedAnswers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      steps: 0
    }
    this.nextStep = this.nextStep.bind(this);
    this.jumpStep = this.jumpStep.bind(this);
  }

  render() {
    return (
      <>
        {(() => {
            if (this.state.steps === 0) return <ConfigPredefinedAnswers nextStep={this.nextStep} jumpStep={this.jumpStep} />;
            else if (this.state.steps === 1) return <AddGreetings nextStep={() => this.props.history.push(paths.FAST_ANSWERS)} jumpStep={this.jumpStep} />;
        })()}
      </>
    );
  }

  nextStep() {
    this.setState({
      steps: this.state.steps + 1
    })
  }

  jumpStep() {
    this.props.history.push(paths.FAST_ANSWERS)
  }

}

export default PredefinedAnswers;
