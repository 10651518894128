import { gql } from 'apollo-boost';

export default gql`
mutation SelectStore ($input: SelectStoreInput!) {
  selectStore (input: $input) {
    stores {
      id
      name
      pendingQuestions
      sources {
        id
        name
        type
      }
    }
    token
  }
}
`;
