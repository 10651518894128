import { graphql } from 'react-apollo';

import LOGGED_IN from './gql/loggedIn';

export default graphql(
  LOGGED_IN,
  {
    props({ data: { error, isLoggedIn }}) {
      if (error) {
        console.error(error);
      }
      return { isLoggedIn };
    },
  }
);
