import { gql } from 'apollo-boost';

export default gql`
fragment Question on QuestionType {
  id: meli_id
  meli_id
  text
  status
  tags
  date_created
  from_meli_id
  from_answered_questions
  answer_text
}
`;
