import { graphql } from 'react-apollo';
import UPDATE_WALK_THROUGH from '../../gql/updateWalkThrough';

export default graphql(
    UPDATE_WALK_THROUGH,
    {
      props: ({ mutate }) => ({
        updateWalkThrough: (input) => mutate({
          variables: {
            input            
          },
        })
      })
    },
  );