import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import AddStore from '../containers/onboarding/components/AddStore';
import PredefinedAnswers from '../containers/onboarding/components/PredefinedAnswers';
import FastAnswers from '../containers/onboarding/components/FastAnswers';
import SizeGuide from '../containers/sizeGuide/routes';
import ConfigurationConfirm from '../containers/onboarding/components/ConfigurationConfirm';
import Synchronize from '../containers/synchronize/routes';
import Onboarding from '../containers/onboarding/routes';
import Questions from '../containers/questions/routes';

import paths from '../app/paths';
import LogOut from '../containers/onboarding/components/LogOut';
import NotFound from '../containers/onboarding/components/NotFound';
import Crm from '../containers/onboarding/components/Crm';
import ListingTable from '../containers/synchronize/ListingTable';
import MobileListingVariations from '../containers/onboarding/components/MobileListingVariations';

const ASTROPIFYLINK = `${process.env.ASTROSELLING_1}user/meli/astropify/callback`;
const NOVALINK = `${process.env.ASTROSELLING_3}admin/mercadolibre/process-new-store`;

const Router = props => {
  const searchProps = props.location.search;
  const fromAstropify = queryString.parse(searchProps).from_astropify;
  const fromNova = queryString.parse(searchProps).from_nova;
  if (fromAstropify) {
    document.location.href = ASTROPIFYLINK + searchProps;
    return null;
  }
  if (fromNova) {
    document.location.href = NOVALINK + searchProps;
    return null;
  }
  return localStorage.getItem('token') ? (
    <Switch>
      <Redirect exact from="/" to={paths.QUESTIONS} />
      <Redirect exact from={paths.LOGIN} to={paths.QUESTIONS} />
      <Route path={paths.QUESTIONS} component={Questions} />
      <Route path={paths.CRM} component={Crm} />
      {/* <Route path={paths.SIZE_GUIDE} component={SizeGuide} /> */}
      <Route path={paths.SINCRONIZAR} component={Synchronize} />
      <Route path={paths.ADD_STORE} component={AddStore} />
      <Route path={paths.PREDEFINED_ANSWERS} component={PredefinedAnswers} />
      <Route path={paths.FAST_ANSWERS} component={FastAnswers} />
      <Route path={paths.CONFIGURATION_CONFIRM} component={ConfigurationConfirm} />
      <Route path={paths.ADD_ANOTHER_STORE} component={AddStore} />
      <Route path={paths.REGISTERED} component={AddStore} />
      <Route path={paths.PUBLICATIONS} component={ListingTable} />
      <Route path={paths.LOGOUT} component={LogOut} />
      <Route path={paths.NOTFOUND} component={NotFound} />
      <Redirect to={paths.NOTFOUND} />
    </Switch>
  ) : (
    <Route path="/" component={Onboarding} />
  );
};

export default withRouter(Router);
