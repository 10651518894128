import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withApollo } from 'react-apollo';
import EventService from '../../app/EventService';
import { compose } from 'recompose';

import withRequestSource from './withRequestSource';
import STRIPESESSION from './gql/stripeSession';

import Loader from '../../components/svgs/Loader';

const messageElement = (msg) => {
    return (
        <div className="success-spanMessage">
            <i style={{ fontSize: "20px" }} className="fa fa-check-circle mr-3" aria-hidden="true"></i>
            <span>{msg}</span>
        </div>
    )
}

const STRIPEKEY = process.env.STRIPEKEY;

class RequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.requestSource = this.requestSource.bind(this);
    }


    requestSource() {
        this.props.onChangeLoading(true);
        this.props.requestSource().then((result) => {
            EventService.triggerEvent('toast', [messageElement(result.data.addSourceInterest.message), 'success']);
            this.setState({ isAdded: true });
            this.props.onChangeLoading(false);
            this.props.successRequestSource();
            this.props.closeModalHandler();
          }).catch(() => {
            EventService.triggerEvent('toast', [(
                <div className="success-spanMessage">
                    <i style={{ fontSize: "20px" }} className="fa fa-check-circle mr-3" aria-hidden="true"></i>
                    <span>{this.props.loading}</span>
                </div>
            ), 'success']);
            this.setState({ error: true });
            this.props.onChangeLoading(false);
            this.props.closeModalHandler();
          })
    }

    render() {
        const closeButtonClass = ['px-8 py-3 font-arboria'];
        const confirmButtonClass = ['bg-vivid-blue text-white px-8 py-3 font-arboria'];
        if (this.props.loading) {
            closeButtonClass.push('cursor-not-allowed');
            confirmButtonClass.push('cursor-not-allowed');
        }
        const { client, source } = this.props;
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="font-arboria px-6 py-6 text-2xl" id="alert-dialog-title">{"Configuración de ERP"}</div>
                    <DialogContent>
                        <div style={{ fontSize: "1rem" }} className="font-arboria" id="alert-dialog-description">
                            Integrándote a Astroselling podrás mantener actualizadas tus publicaciones y automatizar tu tienda de Mercado Libre. <br /><br />
                            Para sincronizar tus productos debes suscribirte al plan Astro Publicaciones. El costo mensual es de U$S 24.99 por cada 200 productos sincronizados.<br /><br />
                            Recuerda, puedes cancelar tu suscripción en cualquier momento.
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button
                            className={ closeButtonClass.join(' ')}
                            onClick={this.props.closeModalHandler} color="primary">
                            Cerrar
                        </button>
                        <button
                            className={ confirmButtonClass.join(' ')}
                            color="primary" autoFocus
                            onClick={()=> {
                                this.props.onChangeLoading(true);
                                client.query({
                                    query: STRIPESESSION,
                                    options: {
                                      notifyOnNetworkStatusChange: true,
                                    },
                                    variables: {
                                      source: source
                                    }
                                  }).then(({ data }) => {
                                    const { stripeSession } = data;
                                    const stripe = Stripe(STRIPEKEY);
                                    stripe.redirectToCheckout({
                                        // Make the id field from the Checkout Session creation API response
                                        // available to this file, so you can provide it as parameter here
                                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                                        sessionId: stripeSession.session_id
                                      }).then((result) => {
                                        console.log('result ===>', result);
                                      });
                                  })
                                }
                            }>
                            {
                                this.props.loading && (<Loader className="flex-1 self-center" />)
                            }
                            {
                                !this.props.loading && '¡Quiero suscribirme!'
                            }
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(
    withRequestSource,
    withApollo,
  )(RequestModal);
