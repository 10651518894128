import React from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorQuestions from './ErrorQuestions';

import paths from '../../app/paths';
import Index from './components/Index';
import History from './components/History';

const TooltipStatisticsContent = React.forwardRef((props, ref) => (
  <NavLink className="font-normal text-sm text-dark-blue-grey active:text-dark-blue-grey" to={paths.QUESTIONS_STATS} activeClassName="text-blue font-semibold">
    Estadísticas
  </NavLink>
));

const TooltipResponseContent = React.forwardRef((props, ref) => (
  <NavLink className="font-normal text-sm text-dark-blue-grey active:text-dark-blue-grey" to={paths.QUESTIONS_RESPONSES} activeClassName="text-blue font-semibold">
    Gestión de respuestas
  </NavLink>
));

export default () => (
  <div className="flex-1 flex" style={{ maxWidth: '100vw' }}>
    <aside className={'bg-pale-grey-dark pl-6 pr-4 py-7 flex-col hidden lg:flex'} style={{ width: '169px', flexShrink: '0' }}>
      <ul style={{ listStyleType: 'none', padding: '0' }}>
        <li className="py-2">
          <NavLink exact className="font-normal text-sm text-dark-blue-grey active:text-dark-blue-grey" to={paths.QUESTIONS} activeClassName="text-blue font-semibold">
            Preguntas
          </NavLink>
        </li>
        <li className="py-2">
          <NavLink className="font-normal text-sm text-dark-blue-grey active:text-dark-blue-grey" to={paths.QUESTIONS_HISTORY} activeClassName="text-blue font-semibold">
            Historial
          </NavLink>
        </li>
        <li className="py-2 hidden">
          <Tooltip title="Muy pronto" placement="right">
            <TooltipResponseContent />
          </Tooltip>
        </li>
        <li className="py-2 hidden">
          <Tooltip title="Muy pronto" placement="right">
            <TooltipStatisticsContent />
          </Tooltip>
        </li>
      </ul>
    </aside>
    <section className="w-full md:px-4 lg:px-14 md:pt-10 flex overflow-x-hidden pt-4">
      <Switch>
        <Route
          exact
          path={paths.QUESTIONS}
          component={() => (
            <ErrorQuestions>
              <Index />
            </ErrorQuestions>
          )}
        />
        <Route path={paths.QUESTIONS_HISTORY} component={History} />
        <Route path={paths.QUESTIONS_RESPONSES} component={Index} />
        <Route path={paths.QUESTIONS_STATS} component={Index} />
      </Switch>
    </section>
  </div>
);
