import React, { Component } from 'react';
import { MentionsInput, Mention } from 'react-mentions';

import DropDown from '../../../components/DropDown';
import TrashIcon from '../../../components/svgs/Trash';
import Loader from '../../../components/svgs/Loader';

import Swal from 'sweetalert2';

class ResponseForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      selectedTags: [],
      tutorialQuestionId: '-1',
    };

    this.editor;
    this.inputEl = React.createRef();
    this.insertText = this.insertText.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.answerQuestion = this.answerQuestion.bind(this);
  }

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.inputEl.current.focus();
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  insertText(text) {
    if (!text) {
      return;
    }
    text = text.replace(/([^\s])$/, '$1 ');
    let value;
    const editor = this.inputEl.current;
    const { scrollTop, selectionStart, selectionEnd } = editor;
    editor.focus();
    if (selectionStart || selectionStart == '0') {
      value = editor.value.substring(0, selectionStart) + text + editor.value.substring(selectionEnd, editor.value.length);
    } else {
      value += text;
    }
    this.setState({ value }, () => {
      editor.scrollTop = scrollTop;
      editor.selectionStart = selectionStart + text.length;
      editor.selectionEnd = selectionStart + text.length;
    });
  }

  deleteQuestion() {
    Swal.fire({
      title: '¿Eliminar pregunta?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: this.props.deleteHandler,
    }).then(result => {
      if (result.value) {
        Swal.fire('Eliminada!', 'Tu pregunta ha sido eliminada.', 'success');
      }
    });
  }

  answerQuestion() {
    this.props.sendHandler(this.state.value, this.state.selectedTags);
    this.setState({ value: '' });
  }

  render() {
    const { tag, id } = this.props;
    const { tutorialQuestionId } = this.state;

    const predefinedAnswers = this.props.predefinedAnswers.filter(({ answer }) => !!answer).map(answer => ({ ...answer, name: answer.slug }));
    const tags = Object.values(this.props.tags)
      .filter(({ response }) => !!response)
      .map(tag => ({ ...tag, display: tag.label, name: tag.label }));

    return (
      <div className="responseForm flex flex-col">
        <div className="responseForm-wrapper">
          <MentionsInput
            style={{ marginLeft: '0.75rem', marginTop: '0.5rem' }}
            value={this.state.value}
            onChange={this.handleChange}
            markup="((__type__:__id__))"
            displayTransform={(id, display, type) => {
              if (type === 'tags') {
                const response = tags.find(item => item.id == id);
                return response.response || '';
              } else if (type === 'predefinedAnswers') {
                const response = predefinedAnswers.find(item => item.id == id);
                return response.answer || '';
              }
              return display;
            }}
            placeholder="Escribe tu respuesta…"
            className="responseInput"
            inputRef={this.inputEl}
          >
            <Mention type="predefinedAnswers" trigger="/" appendSpaceOnAdd data={predefinedAnswers} />
            <Mention type="tags" trigger="#" appendSpaceOnAdd data={tags} />
          </MentionsInput>

          {/*
          <textarea placeholder="Escribe tu respuesta..."
            value={this.state.value}
            onChange={this.handleChange}
            // ref={(ref) => { this.editor = ref }}
            className="rounded bg-white"
            style={{ border: '1px solid rgba(138, 143, 177, 0.49)', width: '100%', paddingBottom: '5em' }}
          />
          */}
          <div className="responseForm-presets md:ml-3">
            {/* <p>Utilizá tus respuestas predefinidas</p> */}
            <div className="responseForm-buttons">
              <div id={id == tutorialQuestionId ? 'response-form-greetings' : ''}>
                <button className="btn margin_mobile" onClick={() => this.insertText(this.props.greetings.greeting)} disabled={!this.props.greetings.greeting}>
                  Saludo
                </button>
                {tag && this.props.tags[tag[0]] ? (
                  <button className="btn btn--secondary margin_mobile" onClick={() => this.insertText(this.props.tags[tag[0]].response)}>
                    #{this.props.tags[tag[0]].label}
                  </button>
                ) : (
                  <DropDown
                    list={tags}
                    changeHandler={({ response, id }) => {
                      this.insertText(response);
                      this.setState(prevState => ({ selectedTags: [...prevState.selectedTags, id] }));
                    }}
                    inverted={true}
                  >
                    <button className={'btn btn--secondary margin_mobile'}>
                      #<i className="fa fa-caret-down ml-2" aria-hidden="true"></i>
                    </button>
                  </DropDown>
                )}
                {!!(predefinedAnswers && predefinedAnswers.length) && (
                  <DropDown list={predefinedAnswers} changeHandler={({ answer }) => this.insertText(answer)} inverted={true}>
                    <button className="btn margin_mobile">
                      /<i className="fa fa-caret-down ml-2" aria-hidden="true"></i>
                    </button>
                  </DropDown>
                )}
                <button className="btn" onClick={() => this.insertText(this.props.greetings.farewell)} disabled={!this.props.greetings.farewell}>
                  Despedida
                </button>
              </div>
              <div id={id == tutorialQuestionId ? 'response-form-actions' : ''} className="responseForm-actions">
                {this.props.deleting ? (
                  <Loader className="text-danger self-center h-2" />
                ) : (
                  <div className="delete_mobile">
                    <a className="responseForm-actions__delete" onClick={this.deleteQuestion}>
                      <TrashIcon style={{ width: '15px', height: '15px' }} />
                      {/* {window.screen.width > 600 ? (this.props.answerChecked > 1 ? 'Eliminar preguntas' : 'Eliminar pregunta') : ""} */}
                    </a>
                  </div>
                )}
                <a className="responseForm-actions__cancel" onClick={this.props.closeHandler}>
                  Cancelar
                </a>
                {this.props.answering ? (
                  <button className="btn">
                    <Loader className="text-white self-center h-2" />
                  </button>
                ) : (
                  <button className="btn" onClick={this.answerQuestion}>
                    {this.props.answerChecked > 1 && window.screen.width > 600 ? 'Responder a las' : 'Responder'}{' '}
                    {this.props.answerChecked > 1 && window.screen.width > 600 ? this.props.answerChecked : ''}{' '}
                    {this.props.answerChecked > 1 && window.screen.width > 600 ? 'preguntas' : ''}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResponseForm;
