import { compose, withState, withHandlers } from 'recompose';

export default compose(
  withState('fromMeliId', 'onChangeFromMeliId', null),
  withState('itemId', 'onChangeItemId', null),
  withState('moreQuestions', 'onChangeMoreQuestions', null),
  withHandlers({
    onOtherQuestionSelect: ({ onChangeFromMeliId, onChangeItemId }) => (itemId, fromMeliId) => {
      onChangeFromMeliId(fromMeliId);
      onChangeItemId(itemId);
    },
    onClearOtherQuestions: ({ onChangeMoreQuestions }) => () => {
      onChangeMoreQuestions([]);
    },
  })
);
