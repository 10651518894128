import React from 'react';
import Button from '../../../components/Button';
import ArrowRightIcon from '../../../components/svgs/ArrowRight';
import SignUpIllustration from '../../../components/svgs/onboarding/SignUpIllustration';

const LinkingError = props => {
  const { me } = props;
  return (
    <div className="flex flex-1 items-start justify-center md:items-center">
      <div style={{ minHeight: "540px" }} className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
        <div className="onboarding flex-1">
          <p style={{ fontSize: "25px" }} className="mb-8 font-bold">Error de vinculación</p>
          <p style={{ fontSize: "18px" }} className="description mb-14">
            Ha ocurrido un error al vincular tu cuenta <br />
            Por favor inténtalo de nuevo.
          </p>
          <Button onClick={() => { document.location.href = me.add_store_url }}>
            Vincular <ArrowRightIcon />
          </Button>
        </div>
        <div className="flex-1 ml-24 hidden xl:block">
          <SignUpIllustration />
        </div>
      </div>
    </div>
  )
}

export default LinkingError;