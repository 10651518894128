import { graphql } from 'react-apollo';

import LINK_PRODUCT from '../gql/linkProduct';

export default graphql(LINK_PRODUCT, {
  props: ({ mutate }) => ({
    linkProduct: (product, linked_items, options) => mutate({
      variables: { input: 
        { 
          product_id: product.id, 
          item_ids: linked_items,
          source_id: product.source_id,
          options: options
        },  
      },
      refetchQueries: ['Products'], 
    })
  }),
});
