import { graphql } from 'react-apollo';
import { compose, withState } from 'recompose';



import UPGRADEINTERESTED from './gql/upgradeInterested';

export default compose(
  graphql(UPGRADEINTERESTED, {
    props: ({ mutate }) => ({
      upgradeInterested: () => mutate({})
    }),
  }),
  withState('loading', 'onChangeLoading', false),
);