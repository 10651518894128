import React, { Component } from 'react';
import { compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { NETWORK_STATUS } from '../../../apollo/constants';
import RefreshIcon from '../../../components/svgs/Refresh';
import Checkbox from '../../../components/Checkbox';
import SingleQuestions from './SingleQuestions';
import GroupedQuestions from './GroupedQuestions';
import ResponseForm from './ResponseForm';
import withAnswerQuestions from './withAnswerQuestions';
import withOtherQuestions from './withOtherQuestions';
import withOtherQuestionsHandler from './withOtherQuestionHandler';
import withDeleteQuestions from './withDeleteQuestions';
import withPredefinedAnswers from './withPredefinedAnswers';
import withUserData from '../../../app/withUserData';
import Loader from '../../../components/svgs/Loader';
import EmptyState from './EmptyState';
import CustomerPopup from '../../onboarding/components/CustomerPopup';

class QuestionsList extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      checkAll: false,
      selected: 0,
      checked: [],
      groupedByListing: true,
      refetching: false,
      error_ids: [],
      fading: [],
      deleting: false,
      answering: false,
      meliId: null,
      expandForm: false,
      showEffect: false,
      questionText: '',
      questions: null,
      showText: false,
      questionTextTruncated: false,
      questionExpandedId: null,
      fromMeliId: null,
      showContactDetails: false,
      totalQuestions: null,
      refetchList: false,
    };
    
    this.listTitle = React.createRef();
    this.questionTextRef = React.createRef();
    
    this.sendHandler = this.sendHandler.bind(this);
    this.batchDelete = this.batchDelete.bind(this);
    this.cleanCheckAll = this.cleanCheckAll.bind(this);
    this.toggleCheckAll = this.toggleCheckAll.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.getQuestionById = this.getQuestionById.bind(this);
    this.openQuestionHandler = this.openQuestionHandler.bind(this);
    this.toggleGroupedByListing = this.toggleGroupedByListing.bind(this);
    this.toggleSelectOtherQuestion = this.toggleSelectOtherQuestion.bind(this);
  }
  
  toggleGroupedByListing() {
    const groupedByListing = !this.state.groupedByListing;
    this.setState({ groupedByListing: groupedByListing });
  }
  
  toggleCheckAll() {
    this.openQuestionHandler(null);
    
    const checkAll = !this.state.checkAll;
    const ids = checkAll ? Array.from(
        document.getElementsByClassName('question-checkbox')).
        map(el => el.value) : [];
    
    this.setState({ checkAll, checked: ids, expandForm: ids.length !== 0 });
  }
  
  cleanCheckAll() {
    this.setState({ checkAll: false, checked: [] });
  }
  
  toggleSelectOtherQuestion(itemId, fromMeliId) {
    try {
      this.props.onOtherQuestionSelect(itemId, fromMeliId);
    } catch (error) {
      this.setState({ error: error });
    }
  }
  
  openQuestionHandler(questionId, fromMeliId) {
    this.setState({ questionExpandedId: questionId, fromMeliId });
    this.props.loadContactDetails(fromMeliId);
  }
  
  toggleSelected(id, forceExpand, fromMeliId) {
    if (forceExpand) {
      this.setState({ expandForm: true });
    }
    
    const idStr = id.toString();
    const newState = this.state.checked.includes(idStr)
        ? this.state.checked.filter(itemId => itemId !== idStr)
        : [...this.state.checked, idStr];
    
    this.setState({
                    showEffect: true,
                    questionTextTruncated: false,
                    fromMeliId,
                    checked: newState,
                    ...(newState.length === 0 ? { expandForm: false } : {}),
                  });
  }
  
  sendHandler(questions, text, tags) {
    if (questions === false) {
      questions = this.state.checked;
    }
    
    this.setState({ answering: true });
    const allTags = new Set(tags);
    
    text = text.replace(/\(\(([a-zA-Z]+):(.+)\)\)/g, (match, type, id) => {
      if (type === 'predefinedAnswers') {
        const replacement = this.props[type].find(answer => answer.id === id);
        return replacement.answer;
      } else {
        if (!allTags.has(id)) {
          allTags.add(id);
        }
        const replacement = this.props[type][id];
        return replacement.response;
      }
    });
    
    this.props.answerQuestions({ questions, text, tags: [...allTags] }).
        then(({ data }) => {
          this.setState(
              state => {
                (Array.isArray(data.answerQuestions.replied_ids)
                    ? data.answerQuestions.replied_ids
                    : []).forEach(questionId => {
                  if (state.error_ids[questionId]) {
                    delete state.error_ids[questionId];
                  }
                });
                
                const errorIds = {};
                data.answerQuestions.error_ids.forEach(
                    ({ question_id, message }) => (errorIds[question_id] = {
                      message,
                      type: 'r',
                    }));
                
                return {
                  answering: false,
                  fading: [
                    ...state.fading,
                    ...data.answerQuestions.replied_ids],
                  error_ids: {
                    ...state.error_ids,
                    ...errorIds,
                  },
                };
              },
              () => setTimeout(() => this.setState(state => ({
                fading: state.fading.filter(
                    id => !data.answerQuestions.replied_ids.includes(id)),
              })), 400),
          );
          
          this.openQuestionHandler(null);
          this.cleanCheckAll();
        });
  }
  
  batchDelete(ids) {
    this.setState({ deleting: true });
    if (ids === false) {
      ids = this.state.checked;
    }
    
    return this.props.deleteQuestions(ids).then(({ data }) => {
      this.setState(
          state => {
            const errorIds = {};
            data.deleteQuestions.deleted_ids.forEach(questionId => {
              if (state.error_ids[questionId]) {
                delete state.error_ids[questionId];
              }
            });
            
            data.deleteQuestions.error_ids.forEach(({ question_id, message }) => {
              errorIds[question_id] = { message, type: 'd' };
            });
            return {
              deleting: false,
              fading: [...state.fading, ...data.deleteQuestions.deleted_ids],
              error_ids: {
                ...state.error_ids,
                ...errorIds,
              },
            };
          },
          () =>
              setTimeout(
                  () =>
                      this.setState(state => ({
                        fading: state.fading.filter(
                            id => !data.deleteQuestions.deleted_ids.includes(
                                id)),
                      })),
                  400,
              ),
      );
      this.cleanCheckAll();
    });
  }
  
  componentDidUpdate() {
    if (this.listTitle.current) {
      const handleAnimationEnd = () => {
        this.listTitle.current.removeEventListener('animationend',
                                                   handleAnimationEnd,
        );
        if (this.state.showEffect) {
          this.setState({
                          showEffect: false,
                        });
        }
      };
      this.listTitle.current.addEventListener('animationend',
                                              handleAnimationEnd,
      );
    }
    
    if (
        !this.state.questionTextTruncated &&
        this.questionTextRef &&
        this.questionTextRef.current &&
        this.questionTextRef.current.clientWidth <
        this.questionTextRef.current.scrollWidth
    ) {
      this.setState({ questionTextTruncated: true });
    }
  }
  
  getQuestionById(id) {
    const question = this.state.questions.listings.data.map(
        listing => listing.questions.find(question => question.id === id));
    
    return question.filter(q => q)[0];
  }
  
  questionList() {
    if (this.state.error) {
      throw new Error(this.state.error);
    }
    
    const isMobile = window.innerWidth <= 767;
    
    const { greetings, tags, predefinedAnswers, moreQuestions, loadingMoreQuestions } = this.props;
    const responses = predefinedAnswers.map(answer => ({
      ...answer,
      display: answer.slug,
    }));
    
    const listProps = {
      predefinedAnswers: responses,
      greetings: greetings,
      tags: tags,
      checkHandler: this.toggleSelected,
      getMoreQuestionHandler: this.toggleSelectOtherQuestion,
      sendHandler: this.sendHandler,
      deleteHandler: this.batchDelete,
      batchEnabled: !!this.state.checked.length,
      checked: this.state.checked,
      errorIds: this.state.error_ids,
      fadingIds: this.state.fading,
      answering: this.state.answering,
      deleting: this.state.deleting,
      moreQuestions: moreQuestions,
      loadingMoreQuestions: loadingMoreQuestions,
      questionExpandedId: this.state.questionExpandedId,
      openQuestionHandler: this.openQuestionHandler,
      questionsData: listings => this.setState({ questions: listings }),
      setTotalQuestions: totalQuestions => this.setState({ totalQuestions }),
      setRefreshFunc: refetchList => this.setState({ refetchList }),
    };
    
    const translateX = isMobile ? '0' : '-61%';
    const stickyBottom = {
      position: 'fixed',
      width: isMobile ? '100%' : '62%',
      left: isMobile ? 0 : '50%',
      transform: `translate(${translateX}, 0)`,
    };
    
    return (
        <>
          <div style={this.state.expandForm && this.state.checked.length !== 0
              ? { opacity: '0.5' }
              : {}} className="questions-toolbox">
            <div className="flex-1">
              <label style={{
                display: 'inline-block',
                overflow: 'hidden',
                verticalAlign: 'text-bottom',
              }}>
                <Checkbox checked={this.state.checkAll}
                          clickHandler={this.toggleCheckAll}/>
                <span className="pl-3" style={{ verticalAlign: 'inherit' }}>
                Seleccionar todas
              </span>
              </label>
            </div>
            <div>
              <label style={{
                cursor: 'pointer',
                display: 'inline-block',
                overflow: 'hidden',
                verticalAlign: 'text-bottom',
              }}>
                <input id="groupByListing" type="checkbox" className="switch"
                       checked={this.state.groupedByListing}
                       onChange={this.toggleGroupedByListing}/>
                <span className="switch-control"/>
                <span
                    style={{ verticalAlign: 'top' }}>Agrupar por publicación</span>
              </label>
            </div>
          </div>
          
          <div
              id="questions-list"
              className="questions-list-wrapper"
              style={
                this.state.expandForm && this.state.checked.length !== 0
                    ? {
                      opacity: '0.5',
                      flex: '1 1 0',
                      position: 'relative',
                      overflow: 'auto',
                    }
                    : { flex: '1 1 0', position: 'relative', overflow: 'auto' }
              }
          >
            {this.state.groupedByListing ? (
                <GroupedQuestions {...listProps} />
            ) : (
                <SingleQuestions {...listProps} />
            )}
          </div>
          
          <div
              style={this.state.checked.length === 0
                  ? {} : this.state.expandForm
                      ? { ...stickyBottom } : this.state.checked.length > 1
                          ? {
                            ...stickyBottom,
                            transform: `translate(${translateX}, 77%)`,
                          } : {
                            ...stickyBottom,
                            transform: `translate(${translateX}, 81%)`,
                          }}
              className="questions-batch-response flex flex-col"
          >
            <div
                onClick={() => this.setState(
                    { expandForm: !this.state.expandForm })}
                style={{
                  borderTopLeftRadius: '22px',
                  borderTopRightRadius: '22px',
                  border: '1px solid lightgray',
                  borderBottom: '0',
                }}
                className="flex items-center justify-between px-6 cursor-pointer"
            >
              <h3 style={{ color: '#151e47' }} className={this.state.showEffect
                  ? 'py-4 animated pulse fast'
                  : 'py-4'} ref={this.listTitle}>
              <span className="text-vivid-blue">
                {this.state.checked.length} pregunta{this.state.checked.length >
              1 && 's'}
              </span>{' '}
                seleccionada{this.state.checked.length > 1 && 's'}
              </h3>
              <span>
              <i style={{ width: '15px', height: '15px', color: '#2b48f0' }}
                 className={this.state.expandForm
                     ? 'fa fa-chevron-down'
                     : 'fa fa-chevron-up'} aria-hidden="true"/>
            </span>
            </div>
            {this.state.checked.length === 1 && (
                <>
                  <p
                      ref={this.questionTextRef}
                      className={'px-6 pb-1 truncate ' +
                      (this.state.questionTextTruncated ? '' : 'pb-3')}
                      style={{
                        borderRight: '1px solid lightgray',
                        borderLeft: '1px solid lightgray', ...(this.state.showText
                            ? { whiteSpace: 'normal' }
                            : {}),
                      }}
                  >
                    {this.getQuestionById(this.state.checked[0]).text}
                  </p>
                  
                  <div
                      className="px-6 pb-4 flex justify-between items-center"
                      style={{
                        borderRight: '1px solid lightgray',
                        borderLeft: '1px solid lightgray',
                        fontFamily: 'Arboria-Medium',
                      }}
                  >
                    {this.state.questionTextTruncated && (
                        <span className="text-vivid-blue"
                              onClick={() => this.setState(
                                  { showText: !this.state.showText })}>
                    {this.state.showText ? 'Ver menos..' : 'Ver más..'}
                  </span>
                    )}
                    <span className="cursor-pointer flex items-center"
                          onClick={() => this.setState(
                              { showContactDetails: true })}>
                  <p className="text-vivid-blue underline">Datos de contacto</p>
                  <i className="ml-2 fa fa-user-circle-o" aria-hidden="true"/>
                </span>
                  </div>
                </>
            )}
            
            <CustomerPopup
                isMobile={isMobile}
                open={this.state.showContactDetails}
                handleClose={() => this.setState({ showContactDetails: false })}
                meliId={this.state.fromMeliId}
            />
            
            <ResponseForm
                closeHandler={this.cleanCheckAll}
                deleteHandler={() => this.batchDelete(false)}
                sendHandler={this.sendHandler.bind(this, false)}
                predefinedAnswers={responses}
                greetings={greetings}
                tags={tags}
                useChecked
                answering={this.state.answering}
                deleting={this.state.deleting}
                answerChecked={this.state.checked.length}
            />
          </div>
        </>
    );
  }
  
  render() {
    const { user } = this.props;
    if (user.error) {
      return <p>Error {user.error.message}</p>;
    }
    
    const storeSession = sessionStorage.getItem('store');
    const storeFromSession = storeSession ? JSON.parse(storeSession) : null;
    
    return (
        <div className="flex flex-col h-full">
          <h2 id="question-list" className="question-list">
            {this.state.totalQuestions
                ? `${this.state.totalQuestions} preguntas`
                : 'Preguntas'}
            {user.networkStatus !== NETWORK_STATUS.LOADING && (
                <a
                    id="refresh-questions"
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    onClick={() => {
                      debugger;
                      if (this.state.refetchList) {
                        this.setState({ refetching: true });
                        console.log(
                            { groupedByListing: this.state.groupedByListing });
                        this.state.refetchList().
                            then(a => console.log('====>', a)).
                            finally(() => this.setState({ refetching: false }));
                      } else {
                        user.refetch().
                            then(
                                ({ data: { me: { stores, show_walk_throughs } } }) => {
                                  debugger
                                  const store = stores.find(
                                      store => store.id ===
                                          storeFromSession.id);
                                  if ((store && store.pendingQuestions) ||
                                      show_walk_throughs.includes(
                                          'QUESTIONS')) {
                                    this.setState(
                                        { totalQuestions: store.pendingQuestions });
                                  }
                                });
                      }
                    }}
                >
                  <RefreshIcon className={user.networkStatus ===
                  NETWORK_STATUS.REFETCH || this.state.refetching
                      ? 'spin'
                      : ''}/>
                </a>
            )}
          </h2>
          <div className="question-list-text" style={{ display: 'flex' }}>
            <p className="text-sm text-blue-darkest mb-3">Lista de preguntas que
              aun no has respondido</p>
            <i onClick={this.props.restartWalkThrough}
               style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
               className="fa fa-question-circle"
               aria-hidden="true"/>
          </div>
          <div
              className={
                'p-6 bg-white flex-1 relative overflow-hidden flex-col' +
                (this.state.checked.length ? ' batch-response' : '') +
                (this.state.checked.length > 0 ? ' flex' : ' md:flex')
              }
          >
            {user.networkStatus === NETWORK_STATUS.LOADING
                ? <Loader
                    className="flex-1 text-vivid-blue self-center h-24 w-24"/>
                : this.state.totalQuestions === 0
                    ? <EmptyState/> : null}
            
            <div style={user.networkStatus === NETWORK_STATUS.LOADING ||
            this.state.totalQuestions === 0
                ? { display: 'none' }
                : {}}>{this.questionList()}
            </div>
          </div>
        </div>
    );
  }
}

export default compose(
    withApollo,
    withRouter,
    withUserData,
    withPredefinedAnswers,
    withAnswerQuestions,
    withDeleteQuestions,
    withOtherQuestionsHandler,
    withOtherQuestions,
)(QuestionsList);
