import React, { Component } from 'react';
import Button from '../../../components/Button';
import ArrowRightIcon from '../../../components/svgs/ArrowRight';
import SignUpIllustration from '../../../components/svgs/onboarding/SignUpIllustration';
import withLoggedIn from '../../../app/withLoggedIn';
import { compose } from 'react-apollo';
import paths from '../../../app/paths';

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.NotFoundWhenLoggedIn = this.NotFoundWhenLoggedIn.bind(this);
        this.NotFoundWhenNotLoggedIn = this.NotFoundWhenNotLoggedIn.bind(this);
    }

    NotFoundWhenLoggedIn() {
        return (
            <div className="flex flex-1 items-start justify-center md:items-center">
                <div className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
                    <div className="onboarding flex-1">
                        <h1 className="mb-8">ERROR 404</h1>
                        <p className="description mb-12">
                            Página no encontrada.
                        </p>
                        <Button onClick={() => this.props.history.push(paths.QUESTIONS) }>
                            Volver <ArrowRightIcon />
                        </Button>
                    </div>
                    <div className="flex-1 ml-24 hidden xl:block">
                        <SignUpIllustration />
                    </div>
                </div>
            </div>
        )
    }

    NotFoundWhenNotLoggedIn() {
        return (
            <div className="onboarding flex-1">
                <h1 className="mb-8">ERROR 404</h1>
                <p className="description mb-12">
                    Página no encontrada.
                        </p>
                <Button onClick={() => this.props.history.push(paths.LOGIN) }>
                    Volver <ArrowRightIcon />
                </Button>
            </div>
        )
    }

    render() {
        const { isLoggedIn } = this.props;
        return (
            isLoggedIn ? this.NotFoundWhenLoggedIn() : this.NotFoundWhenNotLoggedIn()
        )
    }
};

export default compose(
    withLoggedIn
)(NotFound);