import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#151e47',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MobileListingVariations = props => {
  const { closeEditingPopup, openPopup, listing } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog fullScreen open={openPopup} onClose={closeEditingPopup} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeEditingPopup} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              variaciones
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          {listing.variations.map((key, variation) => (
            <MobileListingCard key={key} listing={listing} variation={variation} />
          ))}
        </div>
      </Dialog>
    </div>
  );
};

export default MobileListingVariations;
