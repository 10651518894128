import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { compose, branch } from 'recompose';
import { withApollo } from 'react-apollo';
import jwtDecode from 'jwt-decode';
import Logo from '../components/svgs/Logo';
import withLoggedIn from './withLoggedIn';
import withSelectStore from './withSelectStore';
import withUserData from './withUserData';
import withUpgradeInterested from './withUpgradeInterested';
import withHandleModal from './withHandleModal';
import UserMenu from './UserMenu';
import ResponsiveMenu from './ResponsiveMenu';
import PlanModal from './PlanModal';
import paths from './paths';

const freeUserRoleId = 7;

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responsiveMenu: false,
      changingStore: false,
    };

    this.onLogOut = this.onLogOut.bind(this);
    this.onSelectStore = this.onSelectStore.bind(this);
    this.onValidateRolesAndAlert = this.onValidateRolesAndAlert.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
    this.isFreeUser = this.isFreeUser.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ responsiveMenu: window.innerWidth < 1030 });
  }

  onSelectStore({ id }) {
    if (id === 'addAnotherStore') {
      this.props.history.push(paths.ADD_ANOTHER_STORE);
    } else {
      this.setState({ changingStore: true });

      this.props.selectStore(id).then(({ data }) => {
        const decoded = jwtDecode(data.selectStore.token);
        sessionStorage.setItem('sessionToken', data.selectStore.token);
        const store = data.selectStore.stores.find(store => store.id === decoded.store.id);
        if (store) {
          sessionStorage.setItem('store', JSON.stringify(store));
        }
        this.props.client.resetStore();
        this.setState({ changingStore: false });
        window.location.reload();
      });
    }
  }

  onValidateRolesAndAlert(link) {
    // Wait until user info is loaded
    if (this.props.user) {
      const roles = this.props.user.me.roles;
      const freeUser = roles.map(role => role.id).includes(freeUserRoleId);
      // TBD (to be determined) validation

      freeUser ? this.props.onChangeOpen(true) : (window.location = link);
    }
  }

  isFreeUser() {
    if (!!this.props.user && !!this.props.user.me) {
      const roles = this.props.user.me.roles;
      return roles.map(role => role.id).includes(freeUserRoleId);
    }

    return false;
  }

  onLogOut() {
    localStorage.clear();
    sessionStorage.clear();
    this.props.client.cache.reset();
    this.props.client.resetStore();
    this.props.history.push(paths.LOGIN);
  }

  render() {
    const { isLoggedIn } = this.props;
    const me = this.props.user ? this.props.user.me : null;
    const listProps = {
      handleLogOut: this.onLogOut,
      selectHandler: this.onSelectStore,
      validateRolesAndAlert: this.onValidateRolesAndAlert,
      changingStore: this.state.changingStore,
      isFreeUser: this.isFreeUser(),
      me,
    };
    const stores = (me && me.stores) || [];
    if (me && !stores.length && this.props.location.pathname !== paths.ADD_STORE) {
      return <Redirect to={paths.ADD_STORE} />;
    }
    return (
      <>
        <PlanModal open={this.props.open} closeModalHandler={this.props.closeModal} container={this} ref="planModal" userEmail={me && me.email} />
        {isLoggedIn &&
          (this.state.responsiveMenu ? (
            <div className={'overflow-x-hidden bg-dark-blue-grey'}>
              <ResponsiveMenu {...listProps} />
            </div>
          ) : (
            <nav style={{ position: 'fixed', width: '100%', zIndex: '99999' }} className={'header'}>
              <Link to={'/'} className="pr-9">
                <Logo className="w-24" />
              </Link>
              <UserMenu {...listProps} />
            </nav>
          ))}
      </>
    );
  }
}

export default compose(
  withLoggedIn,
  withSelectStore,
  withUpgradeInterested,
  withHandleModal,
  withRouter,
  withApollo,
  branch(({ isLoggedIn }) => isLoggedIn, withUserData)
)(NavBar);
