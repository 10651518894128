import { compose, withState, withHandlers } from 'recompose';

export default compose(
  withState('title', 'onChangeTitle', ''),
  withHandlers({
    handleChangeTitle: ({ onChangeTitle, sizeChart, onChangeSizeChart }) => (title) => {
      if (sizeChart) {
        sizeChart.chartName = title;
        onChangeSizeChart(sizeChart)
      } else {
        onChangeTitle(title); 
      }
    },
  }),
);
