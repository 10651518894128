import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import paths from '../../app/paths';
import SignUp from './components/SignUp';
import Login from './components/Login';
import LoginIllustration from '../../components/svgs/onboarding/LoginIllustration';
import SignUpIllustration from '../../components/svgs/onboarding/SignUpIllustration';
import PasswordResetIllustration from '../../components/svgs/onboarding/PasswordResetIllustration';
import EmailSentIllustration from '../../components/svgs/onboarding/EmailSentIllustration';
import PasswordRecovery from './components/PasswordRecovery';
import PasswordEmailSent from './components/PasswordEmailSent';
import NewPassword from './components/NewPassword';
import PasswordChanged from './components/PasswordChanged';
import SignUpGif from '../../components/gifs/SignUpGif';

export default () => (
  <div className="flex flex-1 items-start justify-center md:items-center">
    <div className= "onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
      <Switch>
        <Route path={paths.LOGIN} component={Login} />
        <Route path={paths.SIGNUP}
            render={(props) => <SignUp {...props} isQuestionsSignUp={true} />} />
        <Route path={paths.SIGNUPPREGUNTAS}
            render={(props) => <SignUp {...props} isQuestionsSignUp={true} />} />
        <Route path={paths.SIGNUPERP} component={SignUp} />
        <Route path={paths.PASSWORD_RECOVERY_SENT} component={PasswordEmailSent} />
        <Route path={paths.PASSWORD_RECOVERY} component={PasswordRecovery} />
        <Route path={paths.RESETPASSWORD} component={NewPassword} />
        <Route path={paths.CHANGEDPASSWORD} component={PasswordChanged} />
        {/* In this case we want to redirect all the routes that the user is accesing
          without a token to the login. */}
        <Redirect from="/" to={paths.LOGIN} />
      </Switch>
      <div className="flex-1 ml-24 hidden xl:block">
        <Switch>
          <Route path={paths.PASSWORD_RECOVERY_SENT} component={EmailSentIllustration} />
          <Route path={paths.PASSWORD_RECOVERY} component={PasswordResetIllustration} />
          <Route path={paths.CHANGEDPASSWORD} component={PasswordResetIllustration} />
          <Route path={paths.RESETPASSWORD} component={PasswordResetIllustration} />
          <Route path={paths.LOGIN} component={LoginIllustration} />
          <Route path={paths.SIGNUP} component={SignUpGif} />
          <Route path="/" component={SignUpIllustration} />
        </Switch>
      </div>
    </div>
  </div>
);
