import React from 'react';
import { compose, graphql, withApollo } from 'react-apollo';
import { Form, withFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loader from '../../../components/svgs/Loader';
import paths from '../../../app/paths';
import FormField from '../../../components/FormField';
import Button from '../../../components/Button';
import withLogin from './withLogin';
import jwtDecode from 'jwt-decode';
import ArrowRightIcon from '../../../components/svgs/ArrowRight';
import EMAIL from '../gql/email';

const ASTROSELLING_3 = process.env.ASTROSELLING_3;

const Login = props => {
  const { values, touched, errors, handleChange, handleBlur, isSubmitting } = props;
  return (
    <div className="onboarding flex-1">
      <Helmet>
        <title>Iniciar sesión en Astroselling</title>
        {/* Todo: add meta info */}
        {/* <meta name="description" content="Nested component" /> */}
      </Helmet>
      <h1 className="mb-2">¡Qué gusto tenerte de vuelta!</h1>
      <p className="description mb-2">
        Ingresá con tu email y contraseña.
        <br />
        ¿Aun no tienes una cuenta? <a href={`${ASTROSELLING_3}register`} >Creá una gratis</a>
      </p>
      <Form className="py-9 mb-4">
        <FormField
          label="Email"
          type="email"
          name="email"
          value={values.email}
          changeHandler={handleChange}
          blurHandler={handleBlur}
          status={touched.email && (errors.email ? 'error' : 'success')}
          errorMsg={touched.email && errors.email}
        />
        <FormField
          label="Clave"
          type="password"
          name="password"
          value={values.password}
          changeHandler={handleChange}
          blurHandler={handleBlur}
          status={touched.password && (errors.password ? 'error' : 'success')}
          errorMsg={touched.password && errors.password}
        />
        <div className="flex flex-wrap items-center justify-between mt-8">
          <Button type="submit" disabled={!!Object.keys(errors).length || isSubmitting}>
            {isSubmitting ? (
              <Loader />
            ) : (
              <>
                Ingresar <ArrowRightIcon />
              </>
            )}
          </Button>
          <p className="description mt-2 md:mt-0 md:w-1/2 lg:w-auto">
            ¿Olvidaste tu clave? <a href={`${ASTROSELLING_3}password/reset`} >Hacé click aquí</a>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default compose(
  withApollo,
  withLogin,
  graphql(EMAIL, {
    props({ ownProps, data }) {
      return {
        ...ownProps,
        loginEmail: data.loginEmail || '',
      };
    },
  }),
  withFormik({
    mapPropsToValues: ({ loginEmail }) => ({
      email: loginEmail,
      password: '',
    }),
    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Email requerido';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Email inválido';
      }
      if (!values.password) {
        errors.password = 'Clave requerida';
      } else if (values.password.length < 6) {
        errors.password = 'Clave demasiado corta';
      }
      return errors;
    },
    handleSubmit: (values, { setSubmitting, props, setFieldError }) => {
      props
        .login({
          email: values.email,
          password: values.password,
        })
        .then(({ data }) => {
          localStorage.setItem('token', data.login.token);
          const decoded = jwtDecode(data.login.token);
          sessionStorage.setItem('sessionToken', data.login.token);
          const store = data.login.me.stores.find(store => store.id === decoded.store.id);
          if (store) {
            sessionStorage.setItem('store', JSON.stringify(store));
            localStorage.setItem('store', JSON.stringify(store));
          }
          props.client.writeData({ data: { isLoggedIn: true } });
          // gaSetUserId(email);
          if (data.login.redirect_url === null) {
            if (data.login.me.stores.length) {
              props.history.push(paths.QUESTIONS);
            } else {
              props.history.push(paths.ADD_STORE);
            }
          } else {
            document.location.href = data.login.redirect_url;
          }
        })
        .catch(err => {
          setSubmitting(false);
          const errorCode = err.graphQLErrors[0].message;
          let errorMsg;
          let field;
          switch (errorCode) {
            case 'INVALID_CREDENTIALS':
              field = 'email';
              errorMsg = 'El email o la clave son incorrectos.';
              setFieldError('password', ' ');
              break;
            default:
              field = 'email';
              errorMsg = 'Ha ocurrido un error. Inténtelo nuevamente.';
              break;
          }
          if (field) {
            setFieldError(field, errorMsg);
          }
        })
        .finally(() => {
          //setSubmitting(false);
        });
    },
  })
)(Login);
