import React, { Component } from 'react';
import { compose } from 'react-apollo';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { NETWORK_STATUS } from '../../../apollo/constants';
import AnsweredQuestion from './AnsweredQuestion';
import Loader from '../../../components/svgs/Loader';
import withAnsweredQuestions from './withAnsweredQuestions';

class HistoryContent extends Component {
  
  constructor(props) {
    super(props);
    this.moreLoaded = false;
    this.lastCursor = null;
  }

  render () {
    const { error, loading, questions, hasMore, loadMore, networkStatus, cursor } = this.props;
    if (networkStatus === NETWORK_STATUS.LOADING) {
      return (
        <div className="flex py-6 h-full">
          <Loader className="flex-1 text-vivid-blue self-center h-6" />
        </div>
      );
    }
    if (error) {
      return <p>Error {error.message}</p>
    }
    return (
        /* Not implemented yet. 
        <div className="questions-toolbox">
          Buscar
        </div> */
        <div id="questions-list" className="questions-list-wrapper" style={{ flex: '1 1 0', position: 'relative', overflow: 'auto' }}>
          <PerfectScrollbar
            onYReachEnd={() => {
              if (!loading && this.lastCursor != cursor && hasMore) {
                if (!this.moreLoaded) {
                  this.moreLoaded = true;
                }
                this.lastCursor = cursor;
                loadMore();                
              }
            }}
          >
            {
              questions
              .map(node => (
                <AnsweredQuestion
                  key={node.id}
                  {...node}
                />
              ))
            }
            { networkStatus === NETWORK_STATUS.FETCH_MORE && (
              <div className="flex py-6">
                <Loader className="flex-1 text-vivid-blue self-center h-5" />
              </div>
            )}
            { !hasMore && this.moreLoaded && <p className="text-center text-2xl text-bluey-grey opacity-40">No tienes más preguntas</p> }
          </PerfectScrollbar>
        </div>
    );
  }
};

export default compose(
  withAnsweredQuestions,
)(HistoryContent);
