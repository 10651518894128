import React from 'react';
import { compose } from 'react-apollo';
import { Form, withFormik } from 'formik';

import Loader from '../../../components/svgs/Loader';
import FormField from '../../../components/FormField';
import Button from '../../../components/Button';
import withPasswordRecovery from './withPasswordRecovery';
import ArrowRightIcon from '../../../components/svgs/ArrowRight';
import paths from '../../../app/paths';

const PasswordRecovery = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    history,
  } = props;
  return (
    <div className="onboarding flex-1">
      <a onClick={() => history.go(-1)}>Volver atrás</a>
      <h1 className="mb-2">Recupera tu clave</h1>
      <p className="mb-2 w-3/4">Te enviaremos un email con las instrucciones para recuperar tu clave.</p>
      <Form className="py-9 mb-4">
        <FormField
          label="Email"
          name="email"
          value={values.email}
          changeHandler={handleChange}
          blurHandler={handleBlur}
          status={touched.email && (errors.email ? 'error' : 'success')}
          errorMsg={touched.email && errors.email}
        />
        <div className="flex flex-wrap items-center justify-between mt-8">
          <Button type="submit" disabled={!!Object.keys(errors).length || isSubmitting}>
          { isSubmitting ? <Loader/> : <>Enviar <ArrowRightIcon /></> }
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default compose(
  withPasswordRecovery,
  withFormik({
    mapPropsToValues: () => ({
      email: '',
    }),
    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Email requerido';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = 'Email inválido';
      }
      return errors;
    },
    handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
      props.passwordRecovery(values.email)
      .then(({ data: { resetPwd: { status }} }) => {
        // data.resetPwd.status// EMAIL_SENT
        // EMAIL_NOT_FOUND
        console.log(status)
        if (status === 'EMAIL_SENT') {
          localStorage.setItem("userEmail", values.email);
          props.history.push(paths.PASSWORD_RECOVERY_SENT);
        } else {
          setFieldError('email', 'No fue posible encontrar el email en nuestros registros');
        }
      })
      .catch(err => {
        const { extraInfo, graphQLErrors, message, networkError } = err;
        console.log(err);
        console.log('graphQLErrors');
        console.log(graphQLErrors);
        console.log('networkError');
        console.log(networkError);
        console.log('extraInfo');
        console.log(extraInfo);
        alert(message);
      })
      .finally(() => setSubmitting(false));
    },
  }),
)(PasswordRecovery);
