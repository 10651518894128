import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import client from './apollo/client';
import App from './app/App';
import 'font-awesome/css/font-awesome.min.css';

// Init Sentry.
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: process.env.SENTRY });
}

render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById('app')
);
