import React from 'react';
import { Form, withFormik } from 'formik';
import { Link } from 'react-router-dom';
import FormField from '../../components/FormField';
import ZLibra2 from '../../components/svgs/ZLibra-2';
import ArrowBack from '../../components/svgs/ArrowBack';
import paths from '../../app/paths';


const SincronizarLibra = props => {
    return (
        <div style={{ minHeight: "492px" }} className="flex flex-col relative justify-end">
            <div style={{ top: "-10px", left: "-24px" }} className="absolute">
                <Link to={paths.SINCRONIZAR} style={{ color: "#2b48f0" }}><ArrowBack /> Volver atras</Link>
            </div>
            <div style={{ maxHeight: "460px" }} className="onboarding flex-1 flex flex-col justify-between">
                {/* // Todo: Leer el nombre */}
                <ZLibra2 />
                <p className="description">
                    Para conectar tu cuenta de Astroselling con Zeta, completa los siguientes campos con la información de tu tienda de Zeta.
                </p>
                <Form>
                    <FormField customClass="mb-2"
                        label="API URL"
                        name="apiUrl"
                        type="text"
                    />
                    <FormField customClass="mb-2"
                        label="API Key"
                        name="apiKey"
                        type="text"
                    />
                    <FormField customClass="mb-2"
                        label="ID de empresa"
                        name="idEmpresa"
                        type="text"
                    />
                    <div className="flex mb-2">
                        <FormField customClass="mr-4"
                            label="Listas de precios"
                            name="priceList"
                            type="text"
                        />
                        <FormField customClass="ml-4"
                            label="Depósitos"
                            name="deposits"
                            type="text"
                        />
                    </div>
                    <Link className="float-right" to={'/sincronizar/productos'}>Conectar</Link>
                </Form>
            </div>
        </div>
    );
};

export default SincronizarLibra;