import { gql } from 'apollo-boost';

const defaultContactFields = [
  'id',
  'meli_id',
  'nickname',
  'registration_date',
  'country_id',
  'first_name',
  'last_name',
  'permalink',
  'ordersQty',
  'questionsQty',
  'phone',
  'points',
];

export default ({ fields = defaultContactFields, loadRelations = [] }) => gql`
  query MeliContact($paginate: PaginateInputNormal!, $filters: MeliContactsInput) {
    meliContacts(paginate: $paginate, filters: $filters) {
      data {
        ${fields.map(
          field => `${field}
        `
        )}
        ${
          loadRelations === 'questions' || loadRelations.includes('questions')
            ? `
          questions {
            id
            text
            status
            answer_text
            date_created
            listing {
              title
              thumbnail
              permalink
            }
          }`
            : ''
        }
        ${
          loadRelations === 'orders' || loadRelations.includes('orders')
            ? `
          orders {
            id
            status
            date_created
            total_amount
            currency_id
            link
            items{
              listing{            
                thumbnail
                title
                permalink
              }       
            }
          }`
            : ''
        }
      }
      pageInfo {
        total
        lastPage
        currentPage
        perPage
        hasMorePages
      }
    }
  }
`;
