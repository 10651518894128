import Button from '../../../components/Button';
import ArrowRightIcon from '../../../components/svgs/ArrowRight';
import { compose, graphql } from 'react-apollo';
import withResetPassword from './withResetPassword';
import FormField from '../../../components/FormField';
import { Form, withFormik } from 'formik';
import queryString from 'query-string';
import EventService from '../../../app/EventService';

import React, { Component } from 'react'
import paths from '../../../app/paths';

const NewPassword = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        isSubmitting,
    } = props;
    return (
        <div className="onboarding flex-1">
            <h1 className="mb-4">Nueva clave</h1>
            <p className="description mb-4">
                Elige una nueva clave para ingresar a tu cuenta.
            </p>
            <Form className="py-9 mb-8">
                <FormField
                    label="Nueva clave"
                    name="password"
                    type="password"
                    value={values.password}
                    changeHandler={handleChange}
                    blurHandler={handleBlur}
                    status={touched.password && (errors.password ? 'error' : 'success')}
                    errorMsg={touched.password && errors.password}
                />
                <div className="flex flex-wrap items-center justify-between mt-12">
                    <Button type="submit" disabled={!!Object.keys(errors).length || isSubmitting}>
                        Confirmar <ArrowRightIcon />
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default compose(
    withResetPassword,
    withFormik({
        mapPropsToValues: () => ({
            password: '',
        }),
        validate: values => {
            const errors = {};
            if (!values.password) {
                errors.password = 'Clave requerida';
            } else if (values.password.length < 6) {
                errors.password = 'Clave demasiado corta';
            }
            return errors;
        },
        handleSubmit: (values, { setSubmitting, props }) => {
            const token = queryString.parse(props.location.search).token;
            props.resetPassword({
                password: values.password,
                token: token
            })
                .then(({ data }) => {
                    status = data.resetPwdConfirm.status;
                    if (status === "PASSWORD_CHANGED") {
                        props.history.push(paths.CHANGEDPASSWORD);
                    } else if (status === "INVALID_TOKEN") {
                        const message = () => {
                            return (
                                <div className="spanMessage">
                                    <span className="text-4xl mr-3">
                                        <i className="fa fa-exclamation-circle"></i>
                                    </span>
                                    <span className="text-lg">El token provisto es inválido</span>
                                </div>
                            )
                        }
                        EventService.triggerEvent('toast', [message, 'error']);
                    }
                    else {
                        alert('Ha ocurrido un problema del lado del servidor. Vuelva a intentarlo más tarde.')
                    }
                })
                .catch(err => {
                    const { extraInfo, graphQLErrors, message, networkError } = err;
                    console.log(err);
                    console.log('graphQLErrors');
                    console.log(graphQLErrors);
                    console.log('networkError');
                    console.log(networkError);
                    console.log('extraInfo');
                    console.log(extraInfo);
                    alert(message);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    }),
)(NewPassword);
