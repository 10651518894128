import { gql } from 'apollo-boost';

export default gql`
  mutation CreateCatalogListing($input: CreateCatalogListingInput!) {
    CreateCatalogListing(input: $input) {
      catalogListing {
        id
      }
    }
  }
`;
