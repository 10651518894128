import React from 'react';
import { Link } from 'react-router-dom';
import { compose, withState, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import RequestModal from './RequestModal';
import Shopify from '../../components/svgs/Shopify';
import ZLibra from '../../components/svgs/ZLibra';
import ZLibraGreen from '../../components/svgs/ZLibraGreen';
import Zureo from '../../components/svgs/Zureo';
import ShopifyGreen from '../../components/svgs/ShopifyGreen';
import Tick2 from '../../components/svgs/Tick2';
import withUserData from '../../app/withUserData';

class Sincronizar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, libraRequested, zureoRequested, openModal, open, closeModal, successRequestSource, source } = this.props;
    const storeSession = sessionStorage.getItem('store');
    const storeFromSession = storeSession ? JSON.parse(storeSession) : null ;
    const connectedWithShopify = storeFromSession && storeFromSession.sources.find(source => source.type === 'SHOPIFY');
    const shopifyButton = connectedWithShopify ? (
      <Link disabled={!user} to={"#"} className={"sm:mb-12 mb-4 rounded-lg flex items-center justify-center cursor-not-allowed "} style={{ backgroundColor: "#20cf74", color: "white", width: "322px", height: "82px", fontSize: "15px", fontWeight: "500" }}>
        <span className="mr-2"><Tick2 /></span> Conectado con <span className="ml-2"><ShopifyGreen /></span>
      </Link>): (
        <button disabled={!user} onClick={()=> openModal('SHOPIFY')} className={"bg-vivid-blue sm:mb-12 mb-4 rounded-lg flex items-center justify-center"} style={{ color: "white", width: "322px", height: "82px", fontSize: "15px", fontWeight: "500" }}>
          Conectar con <span className="ml-2"><Shopify /></span>
        </button>
      )
    const libraButton = libraRequested ? (
      <Link disabled={!user} to={"#"} className={"sm:mb-12 mb-4 rounded-lg flex items-center justify-center cursor-not-allowed"} style={{ backgroundColor: "#20cf74", color: "white", width: "322px", height: "82px", fontSize: "15px", fontWeight: "500" }}>
        <span className="mr-2"><Tick2 /></span> Solicitado <span className="ml-2"><ZLibraGreen/></span>
      </Link>): (
        <button disabled={!user} onClick={()=> openModal('ZLIBRA')}  className={"bg-vivid-blue sm:mb-12 mb-4 rounded-lg flex items-center justify-center"} style={{ color: "white", width: "322px", height: "82px", fontSize: "15px", fontWeight: "500" }}>
          Solicitar <span className="ml-2"><ZLibra /></span>
        </button>
      )
      const zureoButton = zureoRequested ? (
        <Link disabled={!user} to={"#"} className={"sm:mb-12 mb-4 rounded-lg flex items-center justify-center cursor-not-allowed"} style={{ backgroundColor: "#20cf74", color: "white", width: "322px", height: "82px", fontSize: "15px", fontWeight: "500" }}>
          <span className="mr-2"><Tick2 /></span> Solicitado <span className="ml-2"><Zureo/></span>
        </Link>): (
          <button disabled={!user} onClick={()=> openModal('ZUREO')} className={"bg-vivid-blue sm:mb-12 mb-4 rounded-lg flex items-center justify-center"} style={{ color: "white", width: "322px", height: "82px", fontSize: "15px", fontWeight: "500" }}>
            Solicitar <span className="ml-2"><Zureo /></span>
          </button>
        )

    return !user.loading && (
      <div className="onboarding flex-1 flex flex-col justify-center items-center">
        <RequestModal
          open={open}
          closeModalHandler={closeModal}
          source={source}
          container={this}
          successRequestSource={successRequestSource}
          ref="planModal"
        />
        {
          shopifyButton
        }
        {
          libraButton
        }
        {
          zureoButton
        }
      </div>
    );
  };
}

export default compose(
  withRouter,
  withUserData,
  withState('source', 'onChangeSource', false),
  withState('libraRequested', 'onChangeLibraRequested', (props) => {
    return !props.location.pathname.includes('cancelar') && props.location.pathname.includes('zlibra');
  }),
  withState('zureoRequested', 'onChangeZureoRequested', (props) => {
    return !props.location.pathname.includes('cancelar') && props.location.pathname.includes('zureo');
  }),
  withState('open', 'onChangeOpen', false),
  withHandlers({
    openModal: ({ onChangeOpen, onChangeSource }) => (source) => {
      onChangeSource(source)
      onChangeOpen(true);
    },
    closeModal: ({ onChangeOpen }) => (source) => {
      onChangeOpen(false);
    },
    successRequestSource: ({onChangeLibraRequested, onChangeZureoRequested, source}) => () => {
      if (source === 'LIBRA') {
        onChangeLibraRequested(true);
      } else {
        onChangeZureoRequested(true);
      }
    }
  }),
)(Sincronizar);