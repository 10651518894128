import React from 'react';
import Button from '../../../components/Button';
import ArrowRightIcon from '../../../components/svgs/ArrowRight';
import paths from '../../../app/paths';


const PasswordChanged = (props) => {
    return (
        <div className="onboarding flex-1">
            <h1 className="mb-8">Clave cambiada</h1>
            <p className="description mb-12">
                Tu clave ha sido cambiada correctamente.
            </p>
            <Button onClick={() => { document.location.href = paths.LOGIN; }}>
                Continuar <ArrowRightIcon />
            </Button>
        </div>
    )
};

export default PasswordChanged;