import { graphql } from 'react-apollo';
import DELETE_ANSWER from '../../gql/deletePredefinedAnswer';

export default graphql(
  DELETE_ANSWER,
  {
    props: ({ mutate }) => ({
      deletePredefinedAnswer: (id) => mutate({
        variables: {
          input: { id }
        },
        refetchQueries: [ 'PredefinedAnswers' ]
      })
    })
  },
);
