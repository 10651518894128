import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="459" height="299" viewBox="0 0 459 299">
    <defs>
        <filter id="a" width="123%" height="141.5%" x="-11.5%" y="-20.7%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="13"/>
        </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
        <path fill="#D8D8D8" d="M68 35h339v188H68z" filter="url(#a)"/>
        <g fillRule="nonzero">
            <path fill="#F1F4F8" d="M421 6.627c-.01-3.104-2.542-5.617-5.668-5.627H63.674c-3.131.006-5.667 2.525-5.674 5.633V22h363V6.627z"/>
            <path fill="#D0D9E2" d="M421 22H57V6.152a6.115 6.115 0 0 1 1.808-4.35A6.213 6.213 0 0 1 63.188 0h351.63c3.413.003 6.179 2.748 6.182 6.134V22zM58.023 20.985h361.954V6.134c-.004-2.826-2.311-5.115-5.16-5.119H63.189c-2.848.004-5.155 2.293-5.159 5.119l-.006 14.85z"/>
            <path fill="#FFF" d="M63.674 224h351.652c3.131-.006 5.667-2.535 5.674-5.656V22H58v196.344c.007 3.121 2.543 5.65 5.674 5.656z"/>
            <path fill="#D0D9E2" d="M414.818 224H63.182c-3.413-.003-6.179-2.76-6.182-6.162V21h364v196.838c-.003 3.402-2.77 6.159-6.182 6.162zM58.018 22.02v195.818c.003 2.838 2.31 5.139 5.158 5.142h351.642c2.847-.003 5.155-2.304 5.159-5.142V22.02H58.017z"/>
            <circle cx="69" cy="11" r="3" fill="#E4E8EA"/>
            <circle cx="80" cy="11" r="3" fill="#E4E8EA"/>
            <circle cx="91" cy="11" r="3" fill="#E4E8EA"/>
            <path fill="#F1F4F8" d="M68 57h343v2H68z"/>
            <path fill="#748795" d="M67 40h57v2H67z"/>
            <path fill="#ABB3D6" d="M354 36h57v10h-57z"/>
            <path fill="#BCC9CF" d="M369 40h26v2h-26z"/>
            <rect width="201" height="30" x="67" y="77" fill="#F5F8F9" rx="4.14"/>
            <path fill="#2B48F0" d="M260.206 104H74.794c-2.646-.003-4.79-2.176-4.794-4.856V83.856c.003-2.68 2.148-4.853 4.794-4.856h185.412c2.646.003 4.79 2.176 4.794 4.856v15.288c-.003 2.68-2.148 4.853-4.794 4.856zM74.794 79.52a4.258 4.258 0 0 0-3.03 1.272 4.371 4.371 0 0 0-1.256 3.07V99.15c0 2.398 1.919 4.342 4.286 4.342h185.412c2.367 0 4.286-1.944 4.286-4.342V83.856c0-2.398-1.919-4.342-4.286-4.342l-185.412.006z"/>
            <path fill="#F5F8F9" d="M406.278 210H281.722a4.722 4.722 0 0 1-4.722-4.722V80.722A4.722 4.722 0 0 1 281.722 76h124.556A4.722 4.722 0 0 1 411 80.722v124.556a4.722 4.722 0 0 1-4.722 4.722zM281.722 76.98a3.725 3.725 0 0 0-3.718 3.719v124.555a3.725 3.725 0 0 0 3.718 3.719h124.556a3.725 3.725 0 0 0 3.718-3.72V80.7a3.725 3.725 0 0 0-3.718-3.72H281.722zM264.26 142H71.74a4.735 4.735 0 0 1-4.74-4.73v-21.54a4.735 4.735 0 0 1 4.74-4.73h192.52a4.735 4.735 0 0 1 4.74 4.73v21.529a4.724 4.724 0 0 1-1.384 3.352A4.746 4.746 0 0 1 264.26 142zM71.74 112.005a3.734 3.734 0 0 0-3.733 3.724v21.53a3.734 3.734 0 0 0 3.733 3.724h192.52a3.734 3.734 0 0 0 3.733-3.724v-21.53a3.734 3.734 0 0 0-3.733-3.724H71.74z"/>
            <path fill="#FFF" d="M78 84h16v16H78z"/>
            <path fill="#F5F8F9" d="M78 118h16v16H78z"/>
            <path fill="#748795" d="M101 89h47v2h-47z"/>
            <path fill="#E7EDEF" d="M100 93h24v2h-24z"/>
            <path fill="#748795" d="M101 123h47v2h-47z"/>
            <path fill="#E7EDEF" d="M100 127h24v2h-24z"/>
            <rect width="15" height="2" x="236" y="91" fill="#748795" rx="1"/>
            <path fill="#2B48F0" d="M260.847 91.136l-1.204-1.379-.64-.757-.662.757-1.204 1.379a.592.592 0 0 0 0 .757c.183.21.479.21.662 0l.732-.838v4.408c0 .297.21.537.469.537.26 0 .47-.24.47-.537v-4.408l.731.838c.183.21.48.21.662 0a.592.592 0 0 0 0-.757h-.016z"/>
            <rect width="15" height="2" x="237" y="125" fill="#748795" rx="1"/>
            <path fill="#748795" d="M258.121 127.866l1.207 1.377.664.757.663-.757 1.208-1.377a.59.59 0 0 0 0-.757.43.43 0 0 0-.664 0l-.723.832v-4.404c0-.297-.21-.537-.47-.537s-.471.24-.471.537v4.404l-.734-.838a.43.43 0 0 0-.664 0 .59.59 0 0 0 0 .757l-.016.006z"/>
            <path fill="#F5F8F9" d="M264.26 176H71.74a4.735 4.735 0 0 1-4.74-4.73v-21.54a4.735 4.735 0 0 1 4.74-4.73h192.52a4.735 4.735 0 0 1 4.74 4.73v21.529a4.724 4.724 0 0 1-1.384 3.352A4.746 4.746 0 0 1 264.26 176zM71.74 146.005a3.734 3.734 0 0 0-3.733 3.724v21.53a3.734 3.734 0 0 0 3.733 3.724h192.52a3.734 3.734 0 0 0 3.733-3.724v-21.53a3.734 3.734 0 0 0-3.733-3.724H71.74z"/>
            <path fill="#F5F8F9" d="M78 152h16v16H78z"/>
            <path fill="#748795" d="M101 157h47v2h-47z"/>
            <path fill="#E7EDEF" d="M100 161h24v2h-24z"/>
            <rect width="15" height="2" x="237" y="159" fill="#748795" rx="1"/>
            <path fill="#748795" d="M258.121 161.864l1.207 1.379.664.757.663-.757 1.208-1.379a.59.59 0 0 0 0-.757.43.43 0 0 0-.664 0l-.723.838v-4.408c0-.297-.21-.537-.47-.537s-.471.24-.471.537v4.408l-.734-.838a.43.43 0 0 0-.664 0 .59.59 0 0 0 0 .757h-.016z"/>
            <path fill="#F5F8F9" d="M264.26 210H71.74a4.734 4.734 0 0 1-4.74-4.728v-21.544A4.734 4.734 0 0 1 71.74 179h192.52a4.734 4.734 0 0 1 4.74 4.728v21.526a4.722 4.722 0 0 1-1.382 3.355A4.746 4.746 0 0 1 264.26 210zM71.74 180.005a3.734 3.734 0 0 0-3.733 3.723v21.526a3.734 3.734 0 0 0 3.733 3.724h192.52a3.734 3.734 0 0 0 3.733-3.724v-21.526a3.734 3.734 0 0 0-3.733-3.723H71.74z"/>
            <path fill="#F5F8F9" d="M78 187h16v16H78z"/>
            <path fill="#748795" d="M101 192h47v2h-47z"/>
            <path fill="#E7EDEF" d="M100 196h24v2h-24z"/>
            <rect width="15" height="2" x="237" y="194" fill="#748795" rx="1"/>
            <path fill="#748795" d="M258.121 195.864l1.207 1.379.664.757.663-.757 1.208-1.379a.59.59 0 0 0 0-.757.43.43 0 0 0-.664 0l-.723.838v-4.408c0-.297-.21-.537-.47-.537s-.471.24-.471.537v4.408l-.734-.838a.43.43 0 0 0-.664 0 .59.59 0 0 0 0 .757h-.016z"/>
            <path fill="#EAF0F2" d="M295 90h48v2h-48zM295 106h9v2h-9zM310 106.772h86v1h-86zM310 117.772h86v1h-86zM310 129.773h86v1h-86zM310 123.772h86v1h-86zM310 112.772h86v1h-86zM295 117h9v2h-9zM295 129h9v2h-9zM315 135h9v2h-9zM339 135h9v2h-9zM363 135h9v2h-9zM387 135h9v2h-9z"/>
            <path fill="#657F8E" d="M295 95h24v2h-24z"/>
            <rect width="3" height="18" x="313" y="111" fill="#63FDAD" rx="1.38"/>
            <rect width="3" height="22" x="321" y="108" fill="#63FDAD" rx="1.38"/>
            <rect width="3" height="14" x="328" y="115" fill="#63FDAD" rx="1.38"/>
            <rect width="3" height="9" x="336" y="121" fill="#63FDAD" rx="1.38"/>
            <rect width="3" height="20" x="343" y="110" fill="#63FDAD" rx="1.38"/>
            <rect width="3" height="13" x="351" y="117" fill="#63FDAD" rx="1.38"/>
            <rect width="3" height="21" x="358" y="109" fill="#63FDAD" rx="1.38"/>
            <rect width="3" height="18" x="366" y="111" fill="#63FDAD" rx="1.38"/>
            <path fill="#EAF0F2" d="M295 154h48v2h-48zM295 170h9v2h-9zM310 170.773h86v1h-86zM310 181.773h86v1h-86zM310 193.773h86v1h-86zM310 187.773h86v1h-86zM310 176.773h86v1h-86zM295 181h9v2h-9zM295 193h9v2h-9zM315 199h9v2h-9zM339 199h9v2h-9zM363 199h9v2h-9zM387 199h9v2h-9z"/>
            <path fill="#657F8E" d="M295 159h24v2h-24z"/>
            <path fill="#63FDAD" d="M313.282 192a1.28 1.28 0 0 1-1.216-.89 1.32 1.32 0 0 1 .447-1.459l14.202-10.821c.36-.277.837-.339 1.254-.163l11.113 4.68 14.468-11.033c.38-.29.886-.34 1.314-.133l9.894 4.831 23.563-7.947a1.26 1.26 0 0 1 1.252.268c.341.31.493.784.4 1.24a1.297 1.297 0 0 1-.854.974l-24.054 8.08a1.26 1.26 0 0 1-.947-.06l-9.657-4.687-14.432 11.003c-.36.276-.838.338-1.255.163l-11.113-4.68-13.61 10.374c-.222.17-.492.26-.769.26z"/>
            <path fill="#748795" d="M90.304 88.785l-2.197-.776.23-.312a.192.192 0 0 0 0-.257l-.494-.336a.172.172 0 0 0-.155-.104H85.37l-.638.44a.19.19 0 0 0-.052.25l.23.312-2.22.783a.182.182 0 0 0-.115.159L82 96.279a.18.18 0 0 0 .156.195h1.437v.343c0 .1.078.183.173.183h5.469c.045 0 .09-.02.122-.054a.19.19 0 0 0 .05-.13v-.336h1.42c.046 0 .09-.019.122-.053a.19.19 0 0 0 .051-.13l-.575-7.335a.181.181 0 0 0-.12-.159v-.018zm-2.65-1.357l.298.202-1.023 1.393-.202-.434c.03-.01.055-.03.075-.055l.851-1.106zm-2.267-.043l.875 1.143-.219.47-1.024-1.393.368-.22zm-1.8 8.704h-1.236l.04-.544h1.197v.544zm2.732.52h-2.392v-5.501a.188.188 0 0 0-.075-.187.164.164 0 0 0-.19 0 .188.188 0 0 0-.074.187v4.046h-1.15l.483-6.112 2.202-.783.857 1.162a.168.168 0 0 0 .151.067.173.173 0 0 0 .136-.098l.075-.159-.023 7.378zm.207-8.3l-.747-.985h1.5l-.747.978-.006.006zm4.117 7.774h-1.236v-.544h1.196l.04.544zm-1.236-.91V91.12a.188.188 0 0 0-.074-.186.164.164 0 0 0-.19 0 .188.188 0 0 0-.075.186v5.501h-2.392v-.947h.195a.168.168 0 0 0 .175-.08.193.193 0 0 0 0-.202.168.168 0 0 0-.175-.079h-.195v-1.357h.195a.168.168 0 0 0 .175-.08.193.193 0 0 0 0-.202.168.168 0 0 0-.175-.079h-.195v-1.357h.195a.168.168 0 0 0 .175-.079.193.193 0 0 0 0-.202.168.168 0 0 0-.175-.08h-.195v-1.38h.195a.18.18 0 0 0 .141-.181.18.18 0 0 0-.14-.18h-.196v-.862l.074.159c.027.054.076.09.133.098.05 0 .1-.025.132-.068l.857-1.161 2.202.782.477 6.113-1.144-.025z"/>
            <ellipse cx="82" cy="96" fill="#748795" rx="1" ry="1"/>
            <ellipse cx="91" cy="96" fill="#748795" rx="1" ry="1"/>
            <path fill="#748795" d="M89.953 125.986c-.94-.925-.996-2.781-.95-3.803 0-.101-.082-.183-.183-.183h-1.047a.182.182 0 0 0-.183.229c.065.228.097.464.097.702v.034a2.102 2.102 0 0 1-2.391 0v-.034a2.577 2.577 0 0 1 .09-.702.183.183 0 0 0-.124-.229h-1.077c-.1 0-.182.082-.182.183.046 1.022 0 2.855-.95 3.797a.183.183 0 0 0-.052.149v4.471c0 .221.179.4.399.4h6.206a.4.4 0 0 0 .393-.4v-4.471a.183.183 0 0 0-.046-.143zm-3.382-2.284c.382 0 .758-.096 1.093-.28-.142.76-.603 1.313-1.138 1.313-.536 0-1.02-.57-1.14-1.33.369.186.773.287 1.185.297zm3.07 6.887a.029.029 0 0 1-.03.028h-6.2a.028.028 0 0 1-.028-.028v-4.403c.933-.97 1.047-2.741 1.013-3.832H85c-.036.188-.053.38-.052.571 0 1.205.706 2.182 1.572 2.182.865 0 1.571-.977 1.571-2.182a2.995 2.995 0 0 0-.05-.57h.603c-.034 1.09.08 2.854 1.013 3.831l-.017 4.403zM90.99 165.11l-1.358-5.063a.188.188 0 0 0-.093-.117l-2.076-1.163c.264-.44.4-.937.397-1.441 0-1.297-.824-2.326-1.86-2.326-1.034 0-1.858 1.052-1.858 2.326a2.767 2.767 0 0 0 .396 1.441l-2.082 1.163a.188.188 0 0 0-.093.117l-1.357 5.07a.183.183 0 0 0 .112.214l1.06.442c.098.041.214 0 .26-.093l.948-2.325v2.459c0 .103.088.186.198.186h4.784c.11 0 .198-.083.198-.186v-2.454l.949 2.326a.202.202 0 0 0 .254.11l1.06-.441a.2.2 0 0 0 .141-.087.176.176 0 0 0 .02-.158zm-1.147.245l-1.24-3.117a.2.2 0 0 0-.22-.118.19.19 0 0 0-.164.182v3.285h-4.375v-3.285a.192.192 0 0 0-.16-.16.203.203 0 0 0-.212.096l-1.239 3.117-.706-.303 1.3-4.849 1.86-1.034v.715a.345.345 0 0 0-.165.398.375.375 0 0 0 .363.258c.17 0 .318-.105.364-.258a.345.345 0 0 0-.165-.398v-1.058a.185.185 0 0 0 0-.035v-.035a2.366 2.366 0 0 1-.428-1.401c0-1.088.62-1.977 1.413-1.977.787 0 1.438.884 1.438 1.977.009.485-.133.962-.41 1.372a.18.18 0 0 0-.055.133v1.035a.345.345 0 0 0-.165.399.375.375 0 0 0 .363.258c.17 0 .318-.105.364-.258a.345.345 0 0 0-.165-.399v-.72l1.859 1.04 1.289 4.849-.744.29z"/>
            <path fill="#748795" d="M86 156c-.55 0-1 .662-1 1.5s.44 1.5 1 1.5 1-.662 1-1.5-.44-1.5-1-1.5zm0 2.669c-.352 0-.648-.534-.648-1.164 0-.63.296-1.163.648-1.163.352 0 .648.533.648 1.163s-.296 1.159-.648 1.159v.005zM87.905 163.906c-.47-.145-.553-.745-.553-.75a.175.175 0 0 0-.177-.156h-1.323a.175.175 0 0 0-.177.155s-.06.601-.553.751a.172.172 0 0 0-.122.172v.75c0 .095.08.172.177.172h2.645a.174.174 0 0 0 .177-.172v-.756a.17.17 0 0 0-.094-.166zm-.227.745h-2.29v-.46c.331-.173.564-.483.63-.842h1.024c.067.359.3.669.63.841l.006.461zM89.994 193.043l-.701-2.078v-.774a.19.19 0 0 0-.19-.191h-6.21a.19.19 0 0 0-.19.19v.775l-.7 2.078a.191.191 0 0 0 0 .06v6.706c0 .106.084.191.19.191h3.267a.19.19 0 0 0 .19-.155l.351-1.864.357 1.864a.19.19 0 0 0 .19.155h3.262a.19.19 0 0 0 .19-.19v-6.719a.197.197 0 0 0-.006-.048zm-5.746-2.662h.167v.417h-.167v-.417zm-1.158 0h.772v.417h-.772v-.417zm-.707 2.758l.653-1.948h.523c-.06.477-.297 1.662-1.188 2.001l.012-.053zm3.428 3.8l-.517 2.656h-2.911v-6.015c1.23-.358 1.503-1.87 1.562-2.383h1.86l.006 5.742zm0-6.14h-1.004v-.412h1.004v.411zm2.33-.412h.772v.417h-.773v-.417zm-.553 0h.166v.417h-.172l.006-.417zm-1.39 0H87.2v.417h-1.004v-.417zm0 4.765v-3.967h.48v3.05a.971.971 0 0 1-.487.91l.006.007zm3.422 4.46h-2.9l-.51-2.685v-1.352c.564-.198.922-.755.867-1.352v-3.038h.992c.06.536.339 2.055 1.563 2.383l-.012 6.045zm0-6.426c-.874-.34-1.111-1.524-1.189-2h.523l.654 1.947.012.053z"/>
            <path fill="#F9D4CC" d="M38.36 248.304A34.282 34.282 0 0 1 43.957 251c3.037-7.053 6.214-14.058 8.355-21.453.925-3.205 2.99-8.017.512-10.92-2.211-2.589-7.564-3.735-10.147-1.235a9.14 9.14 0 0 0-1.879 3.293l-8.61 21.601c-.774 1.945-1.536 3.896-2.188 5.912a18.404 18.404 0 0 1 8.36.106zM73.569 254.18c.269.261.508.54.753.82.596-4.885 1.18-9.773 1.752-14.666.613-5.461 3.024-13.858 1.337-19.277-1.401-4.493-7.042-8.914-11.164-6.252-2.604 1.682-3.87 5.188-4.128 8.551-.256 3.364.322 6.721.468 10.102.367 8.32-1.659 12.563-2.587 18.773a13.863 13.863 0 0 1 1.944-.933c3.936-1.498 8.606-.054 11.625 2.882z"/>
            <path fill="#F9D4CC" d="M27.224 264.111A146.812 146.812 0 0 1 22 295l6.273-.25a3.854 3.854 0 0 0 1.862-.388c.934-.567 1.205-1.79 1.38-2.845a160.653 160.653 0 0 1 9.538-33.352c.94-2.308 1.935-4.598 2.947-6.883a35.921 35.921 0 0 0-5.797-2.731 19.555 19.555 0 0 0-8.665-.126 37.932 37.932 0 0 0-1.205 4.122c-.82 3.793-.904 7.67-1.109 11.564zM59.032 258.959c.26 7.793 2.443 22.647 1.402 30.364-.367 2.716-.77 6.338 1.52 7.42.854.32 1.79.342 2.657.06a16.565 16.565 0 0 0 8.33-4.983l-3.875-1.237A1551.184 1551.184 0 0 0 74 254.29c-.248-.278-.491-.556-.763-.816-3.047-2.924-7.792-4.362-11.833-2.888-.68.259-1.338.57-1.97.929a39.742 39.742 0 0 0-.402 7.445z"/>
            <path fill="#959BB7" d="M69.118 223.632a23.032 23.032 0 0 0-2.613-7.877c-1.85-3.16-4.743-5.586-7.045-8.448-5.418-6.774-7.837-22.818 4.697-23.176 3.624-.095 7.25.105 10.843.596-1.121-1.3-2.824-2.08-4.473-2.689-7.99-2.981-16.632-3.726-25.162-4-2.577-.084-5.231-.108-7.632.834-8.689 3.422-5.483 17.977-3.346 24.5 2.19 6.695 6.117 13.612 4.003 20.331l14.366.52a4.667 4.667 0 0 0 1.092-2.791 18.627 18.627 0 0 0-2.348-6.619c-.899-1.496-1.128-2.104-2.05-3.577-.404-.65-3.181-4.949-3.674-5.038 1.033.197 1.872 1.031 2.653 1.848 2.096 2.2 4.157 4.514 5.613 7.34 1.456 2.826 2.254 6.242 1.638 9.462a1.048 1.048 0 0 1-.165.453 6.09 6.09 0 0 0 2.836 1.193c3.813.705 7.723.673 11.524-.096a10.682 10.682 0 0 1-.757-2.766z"/>
            <path fill="#ABB3D6" d="M64.058 184.024c-12.466.35-10.055 16.287-4.682 23.013 2.288 2.848 5.168 5.251 7.023 8.39a22.807 22.807 0 0 1 2.604 7.82c.128.949.384 1.875.761 2.753A30.043 30.043 0 0 0 80 221.933a564.247 564.247 0 0 0-3.511-32.8 9.783 9.783 0 0 0-1.077-3.807 5.27 5.27 0 0 0-.533-.716 67.118 67.118 0 0 0-10.821-.586z"/>
            <path fill="#0C304A" d="M41.185 297.97l1.32-.134a1.626 1.626 0 0 0 1.194-.675c.279-.39.37-.882.248-1.345a7.159 7.159 0 0 0-3.595-4.09c-2.252-1.167-4.88-1.442-7.431-1.687a1.854 1.854 0 0 0-1.173.14c-.739.462-.686 1.863-1.56 1.916a1.516 1.516 0 0 1-.968-.473c-3.402-2.734-7.325-1.56-8.088 2.687a12.954 12.954 0 0 0 0 3.691l20.053-.03zM79.185 297.97l1.32-.134a1.626 1.626 0 0 0 1.194-.675c.279-.39.37-.882.248-1.345a7.159 7.159 0 0 0-3.595-4.09c-2.252-1.167-4.88-1.442-7.431-1.687a1.854 1.854 0 0 0-1.173.14c-.739.462-.686 1.863-1.56 1.916a1.516 1.516 0 0 1-.968-.473c-3.402-2.734-7.325-1.56-8.088 2.687a12.954 12.954 0 0 0 0 3.691l20.053-.03z"/>
            <path fill="#F9D4CC" d="M33.093 124.874a.829.829 0 0 0-.366-.653 2.085 2.085 0 0 0-.937-.182 54.087 54.087 0 0 0-8.252.317l-8.416 1.641a37.805 37.805 0 0 0-2.08 14.25c.123 2.51.771 5.345 3.895 6.797 2.24 1.047 5.228 1.094 7.94.8 2.71-.294 5.357-.882 8.098-.982a63.082 63.082 0 0 0 .118-21.988zM80.58 137.744l7.28 5.224a5.503 5.503 0 0 0 7.781-1.415c5.33-8.296 15.677-25.048 15.351-30.538L102.754 107a45.815 45.815 0 0 1-6.71 12.819c-4.85 6.533-9.908 15.808-9.908 15.808l-3.962-7.96s-5.917 5.95-1.593 10.077z"/>
            <path fill="#F9D4CC" d="M102.774 98.008c1.062-.537 2.566-.997 3.3-.1l3.507-9.388c.31-.82 1.08-1.834 1.9-1.427.649.33.527 1.226.309 1.893l-2.039 6.256c1.244-.141 2.864.13 3.125 1.286.194.867-.528 1.851-.03 2.6.497.75 2.123.69 2.372 1.61.017.297.017.594 0 .89.115.75 1.032 1.115 1.468 1.77.547.778.28 1.816-.048 2.694a26.874 26.874 0 0 1-5.825 9.2c-4.745 4.9-8.003-1.894-8.658-5.927a23.795 23.795 0 0 0-1.578-5.867c-1.177-2.719-.606-4.087 2.197-5.49z"/>
            <path fill="#63FDAD" d="M82.167 104.008c-3.321-3.529-8.61-4.5-13.522-5.046a159.937 159.937 0 0 0-36.808.206c-1.985.241-4.024.54-5.75 1.517-2.222 1.259-3.646 3.476-4.976 5.611A8397.36 8397.36 0 0 0 10 124.128a65.825 65.825 0 0 0 13.475 6.776c-.627 7.551-1.253 15.168-.207 22.672 1.637 11.716 7.317 23.567 3.794 34.895a399.574 399.574 0 0 1 41.069.106 3.558 3.558 0 0 0 .52-1.7c.115.78.442 1.514.945 2.123h10.638a48.259 48.259 0 0 0-2.003-16.98c-1.519-5.01-3.853-9.78-5.083-14.868a35.865 35.865 0 0 1 1.537-21.543 23.683 23.683 0 0 0 20.094-1.506c2.045-1.182-10.715-28.077-12.612-30.095z"/>
            <path fill="#F9D4CC" d="M68.937 75.931a1.435 1.435 0 0 1-.528-.64 1.156 1.156 0 0 1 .144-.7c.704-1.788.934-3.722.666-5.62a21.333 21.333 0 0 1-.252-2.31 14.794 14.794 0 0 0-6.089-6.809 21.757 21.757 0 0 0-16.641-2.046c-2.807.818-5.537 2.352-6.977 4.762-1.44 2.41-1.392 5.433-1.086 8.231a35.654 35.654 0 0 0 3.036 11.517C46.13 92.61 44.7 99.782 56.843 100c.822-2.352 1.734-5.162 2.55-7.525.054-.204.154-.393.294-.553.239-.191.537-.297.846-.3 1.92-.182 4.199.359 5.567-.917a4.863 4.863 0 0 0 1.157-2.204l2.118-6.638c.648-2.022 1.164-4.491-.438-5.932z"/>
            <ellipse cx="51" cy="100.5" fill="#63FDAD" rx="14" ry="8.5"/>
            <path fill="#63E59A" d="M85.52 132.916a335.276 335.276 0 0 0-10.418-24.85 5.053 5.053 0 0 0-1.456-2.088 4.984 4.984 0 0 0-2.724-.699c-9.965-.431-20.114 4.142-29.67 1.409-4.191-1.184-8.568-3.793-12.61-2.172-2.783 1.118-4.365 3.905-5.738 6.509l-8.325 15.744-4.287.627A24.541 24.541 0 0 0 0 141.726a36.012 36.012 0 0 0 18.914-5.61 12.467 12.467 0 0 0 3.609-3.17c2.973-4.195 1.16-10.75 5.102-14.1-.314 4.643-.8 9.27-1.457 13.88a3.687 3.687 0 0 1-.85 2.22c-.63.591-1.588.727-2.379 1.112-2.622 1.266-2.74 4.733-2.646 7.573.095 2.84-.553 6.408-3.4 7.1 1.599 11.662 3.567 24.08 11.671 32.855.377.467.863.834 1.415 1.07.728.171 1.487.153 2.206-.052 4.721-.864 9.579-.503 14.371-.136L66.706 186l-4.869-9.236c-1.284-2.45-2.604-5.248-1.67-7.845.66-1.846 2.33-3.16 3.388-4.828 2.082-3.29 1.54-7.461.928-11.242l-14.038 5.485c.594-5.976 6.641-9.774 10.56-14.437a23.519 23.519 0 0 0 5.428-16.957l6.267 7.171c.712.904 1.551 1.701 2.492 2.367 2.307 1.467 5.297 1.26 8.044.994a3.73 3.73 0 0 0 2.034-.592c1.124-.946.737-2.65.25-3.964z"/>
            <path fill="#FFF" d="M63 82a7.897 7.897 0 0 0 1.988 3.723c.1.126.236.22.39.27.146.02.296-.002.43-.064a2.616 2.616 0 0 0 1.61-1.818l.582-.836A7.57 7.57 0 0 1 63 82z"/>
            <path fill="#103D60" d="M40.705 88.257a6.427 6.427 0 0 0 2.954-.49c-.419.396-.637.964-.59 1.543.271 1.065 2.032.796 2.652-.12.62-.915.668-2.123 1.312-3.026.644-.904 1.897-1.4 2.718-2.244 1.424-1.483 1.317-3.852.59-5.773-.726-1.92-1.914-3.643-2.451-5.611a3.468 3.468 0 0 1 .053-2.453 1.676 1.676 0 0 1 2.115-.861c1.554.795.378 3.81 1.985 4.504.38.138.79.159 1.182.06 2.676-.502 4.614-3.41 4.006-6.018a27.355 27.355 0 0 0 10.186-1.55 8.835 8.835 0 0 0 3.403-1.95.695.695 0 0 0 .121-.746.679.679 0 0 0-.63-.408h-1.293c.226-.333.387-.706.472-1.1a.694.694 0 0 0-.142-.586.676.676 0 0 0-.543-.246 19.235 19.235 0 0 0-3.167.424c-1.471-4.187-5.317-7.298-9.63-8.656-4.313-1.358-8.975-1.125-13.383-.15-2.753.599-5.553 1.574-7.485 3.59a11.758 11.758 0 0 0-2.157 3.332 24.619 24.619 0 0 0-1.973 10.17c-.067 1.91.213 3.816.827 5.623 1.3 3.446 4.342 5.929 7.255 8.244.514.406 3.338 1.794 2.777 1.884-1.654.215-1.264 2.608-1.164 2.614zM64.601 76.985a1.73 1.73 0 0 1-1.082-.473A15.448 15.448 0 0 1 62 75.009l.867-.52c.437.497.914.968 1.427 1.41.221.186.314.197.314.197s.043-.042.141-.151A7.828 7.828 0 0 0 65.98 74l1.021.312a8.685 8.685 0 0 1-1.34 2.164c-.173.244-.444.429-.763.52a1.234 1.234 0 0 1-.296-.01z"/>
            <path fill="#ABB3D6" d="M278.528 250h178.436a2.023 2.023 0 0 1 1.438.582c.383.378.598.892.598 1.428V299H276v-46.489a2.522 2.522 0 0 1 2.528-2.51z"/>
            <path fill="#959BB7" d="M278.39 250H348v49h-72v-46.625a2.35 2.35 0 0 1 .695-1.684 2.38 2.38 0 0 1 1.696-.69z"/>
            <path fill="#FFF" d="M436 286h17v8h-17z"/>
            <path fill="#657F8E" d="M451 288v2h-12v-2zM447 290v2h-8v-2z"/>
            <path fill="#FFF" d="M317.472 271.983l-11.004 2.64v-24.164h11.004z" opacity=".87"/>
            <path fill="#8A8FB1" d="M311 250h2v20h-2z"/>
            <path fill="#ABB3D6" d="M355 195h92.543c1.357 0 2.457 1.1 2.457 2.459V250h-95v-55z"/>
            <path fill="#959BB7" d="M355 195h52v55h-52z"/>
            <path fill="#FFF" d="M434 235h12v9h-12z"/>
            <path fill="#657F8E" d="M444.5 237.5v2h-9v-2zM441.5 240.5v2h-5v-2z"/>
            <path fill="#FFF" d="M385 218.944L377 222v-28h8z"/>
            <path fill="#8A8FB1" d="M381 195h1v22h-1z"/>
            <path fill="#ABB3D6" d="M287 217h88v33h-88z"/>
            <path fill="#959BB7" d="M289.43 217H335v33h-48v-30.567a2.434 2.434 0 0 1 2.43-2.433z"/>
            <path fill="#FFF" d="M360 241h11v5h-11z"/>
            <path fill="#657F8E" d="M369.5 242.5v1h-8v-1zM367 244v1h-5v-1z"/>
            <path fill="#FFF" d="M315 231.255L308 233v-16h7z"/>
            <path fill="#8A8FB1" d="M311 217h1v13h-1z"/>
        </g>
    </g>
</svg>
);
