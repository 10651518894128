import React from 'react';
import UserQuestion from './UserQuestion';

const ListingQuestions = props => {
  const { listing } = props;
  
  const questions = listing.questions.filter(question => (question.status === 'UNANSWERED' || props.fadingIds.includes(question.id)));
  
  if (questions.length === 0) {
    return null;
  }
  
  return (
    <article id={props.id === '-1' ? 'fake-listing' : ''} className="mb-12">
      <header className="mb-4 ml-3 flex items-center">
        {listing.thumbnail && <img src={listing.thumbnail} alt="Foto" className="rounded-full mr-3" style={{ width: '40px' }}/>}
        <h3 className="text-vivid-blue text-base inline mr-2"><a href={listing.permalink} target="_blank">{listing.title}</a></h3>
        <div className="stock-and-price">
          <span className="listing-questions__price mr-2">{listing.currency_id} {listing.price}</span>
          <span className="listing-questions__stock">{listing.available_quantity} disponible{listing.available_quantity === 1 ? '' : 's'}</span>
        </div>
      </header>
      
      {questions.map(question => (
        <UserQuestion
          key={question.id}
          {...question}
          meliId={props.meliId}
          predefinedAnswers={props.predefinedAnswers}
          greetings={props.greetings}
          predefinedTags={props.tags}
          checkHandler={props.checkHandler}
          getMoreQuestionHandler={props.getMoreQuestionHandler}
          errorIds={props.errorIds}
          sendHandler={props.sendHandler}
          deleteHandler={props.deleteHandler}
          answering={props.answering}
          deleting={props.deleting}
          checked={props.checked}
          batchEnabled={props.batchEnabled}
          moreQuestions={props.moreQuestions}
          loadingMoreQuestions={props.loadingMoreQuestions}
          fading={props.fadingIds.includes(question.id)}
          questionExpandedId={props.questionExpandedId}
          openQuestionHandler={props.openQuestionHandler}
        />
      ))}
    </article>
  );
};

export default ListingQuestions;
