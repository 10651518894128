import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { CSSTransition } from 'react-transition-group';

import Header from './Header';
import withPredefinedAnswers from '../withPredefinedAnswers';
import withCreatePredefinedAnswer from './withCreatePredefinedAnswer';
import withUpdatePredefinedAnswer from './withUpdatePredefinedAnswer';
import withDeletePredefinedAnswer from './withDeletePredefinedAnswer';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loader from '../../../../components/svgs/Loader';
import Trash from '../../../../components/svgs/Trash';

class Responses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      predefinedAnswers: props.predefinedAnswers,
      visible: false,
      answerSelected: null,
      loading: false,
      enableNewResponse: true,
      deletingPredefinedAnswer: {
        deleting: false,
        index: null,
      },
    };
    this.inputRef = React.createRef();
    this.addNewResponse = this.addNewResponse.bind(this);
    this.changeSlug = this.changeSlug.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.setEditable = this.setEditable.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.deleteAnswer = this.deleteAnswer.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.escButtonPressed = this.escButtonPressed.bind(this);
    this.removeEditable = this.removeEditable.bind(this);
    this.deleteUnsavedAnswers = this.deleteUnsavedAnswers.bind(this);
    this.checkForUnsavedAnswers = this.checkForUnsavedAnswers.bind(this);
  }

  componentDidMount() {
    this.setState({ visible: true });
    document.addEventListener('keydown', this.escButtonPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escButtonPressed, false);
  }

  UNSAFE_componentWillReceiveProps({ predefinedAnswers }) {
    if (predefinedAnswers && !this.state.predefinedAnswers) {
      this.setState({ predefinedAnswers });
    }
  }

  setSaved(savedAnswer, index) {
    this.setState(({ predefinedAnswers }) => {
      predefinedAnswers[index] = {
        ...savedAnswer,
        touched: false,
        isNew: false,
        editable: false,
      };
      return {
        predefinedAnswers,
      };
    });
  }

  saveHandler() {
    this.state.predefinedAnswers.forEach(({ id, slug, answer, touched, isNew }, index) => {
      if (!touched) {
        return;
      }
      if (isNew) {
        this.setState({ loading: true });
        this.props.createPredefinedAnswer({ slug, answer }).then(({ data }) => {
          if (data.createPredefinedAnswer && data.createPredefinedAnswer.predefinedAnswer) {
            this.setSaved(data.createPredefinedAnswer.predefinedAnswer, index);
          }
          this.setState({ loading: false });
          this.checkForUnsavedAnswers();
        });
      } else {
        this.setState({ loading: true });
        this.props.updatePredefinedAnswer({ id, slug, answer }).then(({ data }) => {
          if (data.updatePredefinedAnswer && data.updatePredefinedAnswer.predefinedAnswer) {
            this.setSaved(data.updatePredefinedAnswer.predefinedAnswer, index);
          }
          this.setState({ loading: false });
          this.checkForUnsavedAnswers();
        });
      }
    });
  }

  addNewResponse() {
    this.setState(
      {
        predefinedAnswers: [
          ...this.state.predefinedAnswers,
          {
            id: null,
            slug: '',
            answer: '',
            isNew: true,
            editable: true,
          },
        ],
      },
      () => {
        this.inputRef.current.focus();
        this.checkForUnsavedAnswers();
      }
    );
  }

  checkForUnsavedAnswers() {
    let hasNewResponse = this.state.predefinedAnswers.find(item => item.id === null);
    if (hasNewResponse) this.setState({ enableNewResponse: false });
    else this.setState({ enableNewResponse: true });
  }

  deleteUnsavedAnswers() {
    this.setState(
      ({ predefinedAnswers }) => {
        predefinedAnswers = predefinedAnswers.filter(item => item.id !== null);
        return {
          predefinedAnswers,
        };
      },
      () => this.checkForUnsavedAnswers()
    );
  }

  deleteAnswer(index) {
    this.setState(({ predefinedAnswers }) => {
      predefinedAnswers.splice(index, 1);
      return {
        predefinedAnswers,
      };
    });
  }

  deleteHandler(index) {
    this.setState({
      deletingPredefinedAnswer: {
        deleting: true,
        index: index,
      },
    });
    const { id } = this.state.predefinedAnswers[index];
    if (id === null) {
      this.deleteUnsavedAnswers();
    } else {
      if (id) {
        this.props.deletePredefinedAnswer(id).then(data => {
          this.deleteAnswer(index);
          this.setState({
            deletingPredefinedAnswer: {
              deleting: false,
              index: null,
            },
          });
        });
      } else {
        this.setState({ loading: true });
        this.deleteAnswer(index);
      }
    }
  }

  setEditable(index) {
    this.setState({ answerSelected: index });
    this.setState(({ predefinedAnswers }) => {
      predefinedAnswers[index] = {
        ...predefinedAnswers[index],
        editable: true,
      };
      return {
        predefinedAnswers,
      };
    });
  }

  changeSlug(event, index) {
    const slug = event.target.value;
    this.setState(({ predefinedAnswers }) => {
      predefinedAnswers[index] = {
        ...predefinedAnswers[index],
        touched: true,
        slug,
      };
      return {
        predefinedAnswers,
      };
    });
  }

  changeHandler(event, index) {
    const answer = event.target.value;
    this.setState(({ predefinedAnswers }) => {
      predefinedAnswers[index] = {
        ...predefinedAnswers[index],
        touched: true,
        answer,
      };
      return {
        predefinedAnswers,
      };
    });
  }

  removeEditable() {
    this.state.predefinedAnswers.map((predefinedAnswer, index) =>
      this.setState(({ predefinedAnswers }) => {
        predefinedAnswers[index] = {
          ...predefinedAnswers[index],
          editable: false,
        };
        return {
          predefinedAnswers,
        };
      })
    );
  }

  cancelHandler() {
    if (this.state.answerSelected !== null) {
      this.removeEditable();
    }
    this.deleteUnsavedAnswers();
    this.checkForUnsavedAnswers();
  }

  escButtonPressed(event) {
    if (event.keyCode === 27) {
      this.removeEditable();
      this.deleteUnsavedAnswers();
      this.checkForUnsavedAnswers();
    }
  }

  render() {
    const { predefinedAnswers, visible, deletingPredefinedAnswer } = this.state;
    const { setShowing } = this.props;
    return (
      <CSSTransition
        in={visible}
        timeout={300}
        classNames="card"
        unmountOnExit
        // onEnter={() => this.setState({ showButton: false })}
        onExited={() => setShowing(0)}
      >
        <div className="predefined-answers-section predefined-answers-section--answers__responses">
          <Header title="Más respuestas" backHandler={() => this.setState({ visible: false })} />
          <PerfectScrollbar>
            <div className="predefined-answers__body">
              <p className="predefined-answers__description">
                Definí tantas respuestas personalizadas como quieras. Asignales un título, un contenido y utilizalas para responder más rápido.
              </p>
              {predefinedAnswers.map(({ id, slug, answer, editable }, index) => (
                <div key={index} className={'predefined-answers-item' + (editable ? ' predefined-answers-item--editable' : '')}>
                  <div className="predefined-answers-item__label-edit mb-2">
                    <span style={{ position: 'absolute', left: '12px' }}>/ </span>
                    <input
                      type="text"
                      name="slug"
                      className="predefined-answers-item__label-input"
                      value={slug}
                      onChange={ev => this.changeSlug(ev, index)}
                      ref={this.inputRef}
                      placeholder="Título"
                      onFocus={() => this.setEditable(index)}
                      onBlur={this.removeEditable}
                    />
                    {deletingPredefinedAnswer.deleting && deletingPredefinedAnswer.index == index ? (
                      <Loader className="text-danger self-center h-2" />
                    ) : (
                      <a onClick={() => this.deleteHandler(index)} className="delete">
                        <Trash />
                      </a>
                    )}
                  </div>
                  <textarea
                    name={`answer[${id}]`}
                    className="predefined-answers-item__input"
                    name={slug}
                    value={answer}
                    onChange={ev => this.changeHandler(ev, index)}
                    placeholder="Respuesta"
                    onFocus={() => this.setEditable(index)}
                    onBlur={this.removeEditable}
                  />
                </div>
              ))}
              <div className="predefined-answers-new-item">
                <button style={!this.state.enableNewResponse ? { display: 'none' } : { display: '' }} className="btn" onClick={this.addNewResponse}>
                  Nueva respuesta
                </button>
              </div>
            </div>
          </PerfectScrollbar>
          <div className="predefined-answers__footer flex">
            <button onClick={this.saveHandler} className="btn">
              {this.state.loading ? <Loader /> : 'Guardar cambios'}
            </button>
            <button
              onClick={() => {
                this.cancelHandler();
                this.setState({ visible: false });
              }}
              className="mr-0 btn-cancel"
            >
              Cancelar
            </button>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default compose(
  withPredefinedAnswers,
  withCreatePredefinedAnswer,
  withUpdatePredefinedAnswer,
  withDeletePredefinedAnswer
)(Responses);
