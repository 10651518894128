import React from 'react';

import DialogIcon from '../../../components/svgs/Dialog';

export default (props) => {
  return (
    <>
      <div className="user-question__question" >
        <div className="user-question__question__dialog open" >

          <div className="flex flex-col" style={{width: '3rem', alignItems: 'center'}}>
            <DialogIcon className="mt-1" /> 
            {
              props.answer_text && 
              (<div className="flex flex-1">
                <div style={{borderRightColor: '#BFC6D0', borderRightWidth: '1px', flex: '50%'}}></div>
              </div>)
            }
          </div>

          <div className="flex flex-col flex-1 word-break">
            <p className="question-text">{props.text}</p>
            <span className="user-question__username">{props.from_meli_id}</span>
          </div>
        </div>
      </div>
      {
        props.answer_text && 
        (<div className="user-question__question">
          <div className="flex flex-row justify-end" style={{width: '3rem', height: '1.5rem'}}>
            <div className="flex" style={{width: '50%', marginTop: '-2rem', borderLeftColor: '#BFC6D0', borderLeftWidth: '1px', borderBottomColor: '#BFC6D0', borderBottomWidth: '1px'}}></div>
          </div>
          <div className="flex flex-row flex-1 pt-4 pb-4">
          {/* Dialog */}
            <div className="flex flex-col " style={{width: '2rem', alignItems: 'center'}}>
              <DialogIcon className="mt-1" />
            </div>
            <div className="flex flex-col px-2 word-break">
              <p className="question-text">{props.answer_text}</p>
              <span className="user-question__username">{props.from_meli_id}</span>
            </div>
          </div>
        </div>)
      }
    </>
  )};
