import { gql } from 'apollo-boost';

export default gql`
mutation AnswerQuestions ($input: AnswerQuestionsInput!) {
  answerQuestions (input: $input) {
    replied_ids
    error_ids {
      question_id
      message
    }
  }
}
`;
