import React from 'react';
import MaterialTable from 'material-table';
import { compose, withState, withHandlers } from 'recompose';
import { withApollo } from 'react-apollo';
import { get } from 'lodash';
import PRODUCTS from '../gql/productspagination';
import LinkModal from '../../LinkModal';


const vincular = (quantity, product, reloadProducts) => {
  return (
    <LinkModal
      quantity={quantity}
      product={product}
      handleRefreshTable={reloadProducts}/>
  )
}

const columns = [
  {
    title: 'Producto', field: 'title',
    cellStyle: {
      fontWeight: "600"
    }
  },
  {
    title: 'SKU', field: 'sku'
  },
  {
    title: 'Cantidad', field: 'stock',
  },
  {
    title: 'Moneda', field: 'currency'
  },
  {
    title: 'Precio', field: 'price'
  },
  {
    title: 'Vincular', field: 'link'
  }
];

const createProductInfo = (product, source, reloadProducts) => {
  let result = [];
  for (let i = 0; i < product.variations.length; i++) { 
    result.push(createVariationInfo(product.variations[i], product.id));
  }
  let price = product.variations && product.variations.length ? 
    (product.price_var_min === product.price_var_max ?  product.price_var_min : product.price_var_min + ' - ' + product.price_var_max) :
    product.price;
  result.push({
      id: product.id,
      title: product.title,
      sku: product.sku,
      stock: product.stock,
      currency: product.currency ? product.currency : '-',
      price: price,
      link: vincular(product.link_count, product, reloadProducts)
    })
  return result;
}

const createVariationInfo = (variation, parentId) => {
  return {
    id: variation.id,
    title: variation.title,
    sku: variation.sku,
    stock: variation.stock,
    currency: variation.currency,
    price: variation.price,
    parentId: parentId
  }
}

const ProductsTable = props => {
  const { client, isReload, onReloadProducts, tableRef } = props;    
  const storeSession = sessionStorage.getItem('store');
  const store = storeSession ? JSON.parse(storeSession) : null ;
  const source =  store && store.sources && store.sources.length ? store.sources[0] : null; 
  return (
    source && 
    <div className="flex flex-col justify-center items-center w-full">
      <div style={{ width: "90%" }} className="flex flex-col w-full justify-start mt-8 mb-3">
        <span className="text-2xl text-blue-darkest font-arboria font-bold">Productos</span>
      </div>
      <div className="flex flex-col justify-center items-center w-full relative">
        {/* <div style={{ right: "6%", top: "25px" }} className="flex justify-end absolute z-10">
          <Filters />
          <span className="mx-6">|</span>
          <ColumnDropdown />
        </div> */}
        <MaterialTable style={{ width: "90%", padding: "0 10px" }}
          title=""
          tableRef={tableRef}
          data={(query) => {
              const watchQuery = client.watchQuery({
                query: PRODUCTS,
                options: {
                  notifyOnNetworkStatusChange: true,
                },
                variables: {
                  filter: {
                    title: query.search,
                    source_ids: source ? [source.id] : []
                  },
                  paginate: {
                    per_page: query.pageSize,
                    page: query.page + 1
                  }
                }
              }
              );
              let currentQuery;
              if (isReload) {
                currentQuery = watchQuery.refetch();
              } else {
                currentQuery = watchQuery.result();
              }
              
              return currentQuery.then(({ data }) => {
                const products = get(data, 'products.products', []);
                const paging = get(data, 'products.paging');
                const totalCount = paging.total;
                let productsTable = [];
                for (let i = 0; i < products.length; i++) { 
                  productsTable = [...productsTable, ...createProductInfo(products[i], source, () => {onReloadProducts()})];
                }
                return {
                  data: productsTable,
                  page: paging.currentPage - 1,
                  totalCount: totalCount
                }
              })
            }
          }
          columns={columns}
          parentChildData={(row, rows) => rows.find(a => row.parentId ? a.id === row.parentId : row[0])}
          options={{
            rowStyle: (value) => {
              if (value.state == 'Inactiva') return { opacity: "0.5" }
            },
            pageSize: 20,
            searchFieldAlignment: "left",
            headerStyle: {
              zIndex: "0"
            }
          }}
          localization={{
            toolbar: {
              searchPlaceholder: "Buscar",
              nRowsSelected: "{0} fila(s) seleccionadas"
            }
          }}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => {onReloadProducts()},
            }
          ]}
        />
      </div>
    </div>
  );
};

export default compose(
  withState('isReload', 'onChangeReload', false),
  withState('tableRef', 'onSetTableRef', React.createRef()),
  withHandlers({
    onReloadProducts: ({ onChangeReload, tableRef }) => () => {
      onChangeReload(true);
      tableRef.current && tableRef.current.onQueryChange()
    },
  }),
  withApollo,
)(ProductsTable);