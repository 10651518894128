import React from 'react';
import { Form } from 'formik';


const inputClassName = (valid, submittedIntention) => {
  const className = "mr-7 w-13 py-3 px-1 border-2 border-dashed rounded-lg text-center ";
  return !valid && submittedIntention ? className + 'border-red text-red' : className + 'border-blue text-blue';
}

const SizeGuideCreateMeasure = props => {
  const {
    selectedAttributes,
    onChangeValueAttribute,
    measurementValuesMap,
    submittedIntention,
    handleCreateMeasurement,
    onChangeSizeName,
    measureName,
    sizeChart
  } = props;
  return (

    <div className="flex-1 flex items-center justify-center h-full w-full">
        <Form className="flex w-full overflow-x-scroll items-start">
          <div className="flex flex-col mr-7 w-1/4">
            <div className="h-12">
              <label className="mb-3 ml-1 w-1/2">Nombre del talle</label>
            </div>
            <input
              id="name"
              type="text"
              value={measureName}
              onChange={(evt) => onChangeSizeName(evt.target.value)}
              name="name"
              className="p-3 border-2 border-dashed rounded-lg border-blue text-blue'"
              placeholder="Ej: S, M, 32, 34"
            />
          </div>
          {
              selectedAttributes.map(attribute => (
                <div key={attribute.id} className="flex flex-col w1/4">
                    <div className="h-12">
                      <label className="mb-3 ml-1 w-1/4">
                        { attribute.name }
                      </label>
                      <span className="mb-3 ml-1 block text-grey-dark">
                          { attribute.type.default_unit ? attribute.type.default_unit : ' '  }
                      </span>
                    </div>
                    <div className="flex items-center">
                      <input type="text" value={measurementValuesMap[attribute.id] || ''} onChange={(evt) => onChangeValueAttribute(attribute.id, evt.target.value)} className={inputClassName(!!measurementValuesMap[attribute.id] , submittedIntention)}/>
                    </div>
                </div>
                )
              )
            }
            <div className="flex items-center">

              <div className="w-4 h-4 mt-12 rounded-full text-white text-xs flex justify-center items-center cursor-pointer border hover:text-blue bg-blue border-blue hover:bg-white" onClick={handleCreateMeasurement}>              
                <i className="fa fa-plus"></i>
              </div>
            </div> 
          </Form>
    </div>
)};


export default SizeGuideCreateMeasure;