import { graphql } from 'react-apollo';

import SIGNUP from '../gql/signup';

export default graphql(SIGNUP, {
  // options({ input }) {
    // return {
    //   variables: {
    //     input,
    //   },
    // };
  // },
  props({ ownProps, mutate }) {
    const signup = (input) => {
      return mutate({
        variables: { input }
      })
    };
    return {
      ...ownProps,
      signup,
    };
  },
});
