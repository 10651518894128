import React from 'react';
import CustomerInformation from './CustomerInformation';

const SideContactInfo = ({ loading, contact }) => {
  return (
    <div className={'predefined-answers'} style={{ maxWidth: '100%' }}>
      <h2 className="text-blue-darkest text-2xl mb-2">Información del contacto</h2>
      <div style={{ display: 'flex' }}>
        <p className="text-sm text-blue-darkest mb-3">Aquí podrás ver toda la información del contacto que realizó la pregunta</p>
      </div>
      <div className="bg-white flex flex-col flex-1" style={{ position: 'relative', overflow: 'hidden', justifyContent: 'flex-start' }}>
        <CustomerInformation isMobile={true} contact={contact} loading={loading} />
      </div>
    </div>
  );
};

export default SideContactInfo;
