import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import EventService from './EventService';
import { compose } from 'recompose';

import withUpgradeInterested from './withUpgradeInterested';

import Loader from '../components/svgs/Loader';

const messageElement = msg => {
  return (
    <div className="success-spanMessage">
      <i style={{ fontSize: '20px' }} className="fa fa-check-circle mr-3" aria-hidden="true"></i>
      <span>{msg}</span>
    </div>
  );
};

const API_ENDPOINT = process.env.API_ENDPOINT;

class PlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.upgrade = this.upgrade.bind(this);
  }

  upgrade() {
    hj('vpv', '/agendar-reunion');
    // this.props.onChangeLoading(true);
    // this.props
    //   .upgradeInterested()
    //   .then(result => {
    //     EventService.triggerEvent("toast", [
    //       messageElement(result.data.upgradeInterest.message),
    //       "success"
    //     ]);
    //     this.setState({ isAdded: true });
    //     this.props.onChangeLoading(false);
    //     this.props.closeModalHandler();
    //   })
    //   .catch(() => {
    //     EventService.triggerEvent("toast", [
    //       <div className="success-spanMessage">
    //         <i
    //           style={{ fontSize: "20px" }}
    //           className="fa fa-check-circle mr-3"
    //           aria-hidden="true"
    //         ></i>
    //         <span>{this.props.loading}</span>
    //       </div>,
    //       "success"
    //     ]);
    //     this.setState({ error: true });
    //     this.props.onChangeLoading(false);
    //     this.props.closeModalHandler();
    //   });
  }

  render() {
    const closeButtonClass = ['px-8 py-3 font-arboria'];
    const confirmButtonClass = ['bg-vivid-blue text-white px-8 py-3 font-arboria'];
    if (this.props.loading) {
      closeButtonClass.push('cursor-not-allowed');
      confirmButtonClass.push('cursor-not-allowed');
    }
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <div className="font-arboria px-6 py-6 text-2xl" id="alert-dialog-title">
            {'Funcionalidad Premium'}
          </div>
          <DialogContent>
            <div style={{ fontSize: '1rem' }} className="font-arboria mb-5" id="alert-dialog-description">
              Esta función requiere de un upgrade en tu Plan. Si te interesa puedes agendar una breve llamada para asesorarte.
            </div>
          </DialogContent>
          <DialogActions>
            <button className={closeButtonClass.join(' ')} onClick={this.props.closeModalHandler} color="primary">
              Cerrar
            </button>
            <a
              href="https://calendly.com/rodrigo-camps"
              target="_blank"
              className={confirmButtonClass.join(' ')}
              onClick={this.upgrade}
              color="primary"
              autoFocus
              style={{ color: 'white' }}
            >
              {this.props.loading && <Loader className="flex-1 self-center" />}
              {!this.props.loading && 'Agendar llamada'}
            </a>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(withUpgradeInterested)(PlanModal);
