import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="299" height="351" viewBox="0 0 299 351">
    <g fill="none" fillRule="evenodd" transform="translate(-1)">
        <path fill="#63FDAD" fillRule="nonzero" d="M197.561 64.456l4.515-3.724a3.395 3.395 0 0 1 3.797-.293l1.61.937c5.817 3.373 7.383 10.207 3.512 15.263-4.858 4.668-19.705 23.356-19.441 41.941.256 17.92 11.092 20.796 12.329 21.066l-.285-.05-4.01 18.453c-1.186-.198-29.159-5.173-29.649-39.256-.439-30.513 26.495-53.378 27.622-54.337z"/>
        <path fill="#F9D4CC" fillRule="nonzero" d="M214.588 142.793a7.544 7.544 0 0 1 2.788 1.829c.73.788.783 1.99.124 2.839-.783.827-2.261.87-3.439.541-1.178-.329-2.239-.95-3.432-1.243.607.661.806 1.6.52 2.45-.38.798-1.778 1.193-2.415.528 0 1.814-.3 4.053-2.246 4.712-1.28.431-2.7-.059-3.951-.534l-12.747-4.874a2.349 2.349 0 0 1-1.178-.731 1.668 1.668 0 0 1-.175-1.061 14.276 14.276 0 0 1 2.487-6.988 6.812 6.812 0 0 1 2.196-2.195c2.882-1.625 6.665-.059 9.607.614a62.459 62.459 0 0 1 11.86 4.113z"/>
        <path fill="#748795" fillRule="nonzero" d="M244.83 50.685c.475 3.871-6.06 7.64-14.598 8.415-8.54.776-15.842-1.734-16.317-5.605-.476-3.87 6.058-7.639 14.597-8.415 8.54-.775 15.878 1.75 16.317 5.605z"/>
        <path stroke="#959BB7" strokeLinecap="round" strokeLinejoin="round" d="M238.573 340.244h-.205"/>
        <path fill="#748795" fillRule="nonzero" d="M195.739 145.046s9.446-40.975 5.963-63.168c-3.482-22.193 5.993-25.61 5.993-25.61s14.89-8.963 43.23-4.697c28.338 4.266-19.852 33.205 4.44 93.475-59.626 2.137-59.626 0-59.626 0z"/>
        <path fill="#ABB3D6" fillRule="nonzero" d="M271.888 273.549c-8.203-14.166-38.78-116.766-23.686-127.551a23.122 23.122 0 0 1 5.188-1.12h-57.724s-8.949 85.507 21.878 116.342c0 23.86 10.127 65.312 9.57 79.024h.447a474.556 474.556 0 0 1 2.437-57.388c.41-3.93.856-7.954-.052-11.802-.578-2.459-1.697-4.75-2.546-7.134-1.77-4.969-2.356-10.244-2.773-15.535-.959-12.117-2.598-33.907-3.073-46.053-.19-4.852-.447-9.783-2.064-14.356a18.805 18.805 0 0 0-7.185-9.578c11.312 4.741 14.795 18.234 14.795 18.234 2.078 2.648 8.927 27.344 12.293 37.214a113.173 113.173 0 0 0 7.485 17.159l1.302 2.415 2.02 3.753c3.87 7.207 8.049 15.066 9.739 18.44 3.483 6.958 30.827 58.66 29.334 67.609 1.69 0 3.08 0 4.222-.095-.132-1.961-4.054-54.586-21.607-69.578z"/>
        <path fill="#959BB7" fillRule="nonzero" d="M212.334 178.427a18.805 18.805 0 0 1 7.178 9.578c1.61 4.58 1.873 9.512 2.064 14.356.475 12.146 2.107 33.937 3.073 46.054.417 5.26 1.002 10.565 2.773 15.534.849 2.378 1.968 4.675 2.546 7.134.915 3.849.461 7.866.052 11.802a474.556 474.556 0 0 0-2.459 57.359h10.324s7.456-56.18 2.986-70.098c-2.883-8.978-11.964-61.287-13.778-73.507.036.03-3.447-13.47-14.759-18.212zM297.073 341.085s4.478-43.068-22.375-81.841c-10.925-47.73-19.376-114.366-19.376-114.366h-1.932a23.122 23.122 0 0 0-5.188 1.105c-15.11 10.785 15.483 113.415 23.686 127.551 17.56 15 21.475 67.617 21.622 69.615 7.866-.403 3.563-2.064 3.563-2.064z"/>
        <path fill="#63FDAD" fillRule="nonzero" d="M238.237 339.9s2.824 2.927 1.953 10.559h-31.573s-2.663-8.693 3.768-8.898c4.188-.176 8.351-.73 12.44-1.654l13.412-.007zM297.71 339.9s2.824 2.927 1.953 10.559H268.09s-2.663-8.693 3.769-8.898c4.187-.176 8.35-.73 12.439-1.654l13.412-.007z"/>
        <path fill="#103D60" fillRule="nonzero" d="M227.64 82.844c-4.537 3.87-11.473 6.073-17.517 4.266-.827 3.292 2.67 6.131 5.956 7.96 3.285 1.83 7.127 3.93 7.53 7.274.343 2.817-1.947 5.297-3.14 7.932a3.951 3.951 0 0 0-.27 3.35c1.038 2.115 4.39 2.254 6.782 3.264 3.125 1.317 4.786 4.39 5.122 7.369.337 2.978-.292 5.963-.57 8.941a34.646 34.646 0 0 0 .995 12c.13.749.485 1.44 1.017 1.983.452.35.966.61 1.514.768 2.466.849 5.061 1.26 7.669 1.215a6.366 6.366 0 0 0 3.058-.637c1.464-.856 1.88-2.539 2.115-4.053 3.22-20.73-1.888-41.854.666-62.65.292-2.363.651-4.88-.556-7.016-1.537-2.715-8.13-6.088-11.583-4.178-1.88 1.039-2.48 4.031-3.44 5.656a25.661 25.661 0 0 1-5.348 6.556z" opacity=".17"/>
        <path fill="#F9D4CC" fillRule="nonzero" d="M236.941 144.951a8.151 8.151 0 0 0-3.014 1.976 2.334 2.334 0 0 0-.14 3.066c.85.892 2.437.944 3.718.585 1.28-.358 2.415-1.024 3.702-1.339a2.663 2.663 0 0 0-.563 2.649c.41.863 1.917 1.288 2.605.57 0 1.962.322 4.39 2.429 5.122 1.376.461 2.927-.058 4.273-.578l13.771-5.26c.495-.132.939-.41 1.273-.798a1.8 1.8 0 0 0 .19-1.149 15.417 15.417 0 0 0-2.685-7.544 7.354 7.354 0 0 0-2.385-2.363c-3.11-1.756-7.2-.059-10.376.658a67.456 67.456 0 0 0-12.798 4.405z"/>
        <path fill="#63FDAD" fillRule="nonzero" d="M262.763 57.922l-4.858-4.061a3.659 3.659 0 0 0-4.098-.315l-1.741 1.01c-6.278 3.659-7.976 11.02-3.79 16.485 5.246 5.057 21.285 25.215 20.992 45.3-.278 19.354-11.978 22.464-13.31 22.75.198-.045.308-.06.308-.06l4.332 19.954c1.28-.212 31.463-5.59 32.02-42.395.475-32.941-28.61-57.636-29.855-58.668z"/>
        <path fill="#F9D4CC" fillRule="nonzero" d="M208.456 26.224a1.72 1.72 0 0 0 .63-.731 1.405 1.405 0 0 0-.162-.842 13.595 13.595 0 0 1-.731-6.76c.154-.919.259-1.845.314-2.774a17.663 17.663 0 0 1 7.208-8.173 25.163 25.163 0 0 1 19.587-2.327c3.3.995 6.498 2.84 8.18 5.766 1.684 2.927 1.603 6.534 1.223 9.893a43.53 43.53 0 0 1-3.659 13.814c-5.853 12.337-4.222 20.964-18.52 21.147-.95-2.861-1.148-6.747-2.1-9.593a1.661 1.661 0 0 0-.343-.666 1.595 1.595 0 0 0-.995-.366c-2.261-.234-4.925.41-6.549-1.134a5.854 5.854 0 0 1-1.346-2.656c-.813-2.663-2.488-4.785-3.3-7.449-.74-2.444-1.332-5.436.563-7.149z"/>
        <ellipse cx="221.883" cy="23.188" fill="#103D60" fillRule="nonzero" rx="2.151" ry="5.115"/>
        <ellipse cx="214.5" cy="24.315" fill="#103D60" fillRule="nonzero" rx="1.317" ry="3.98"/>
        <path fill="#020741" fillRule="nonzero" d="M221.707 13.741c4.91-1.463 9.776-3.38 14.898-3.526a20.546 20.546 0 0 0-4.032 10.763c-.08 1.134 0 2.43.849 3.176.485.372 1.061.61 1.668.687 2.096.395 4.26.187 6.242-.6a3.659 3.659 0 0 0 3.38 5.854c.951-5.495 3.33-11.115 1.712-16.456-1.207-3.988-4.565-7.083-8.37-8.78-3.805-1.698-8.05-2.196-12.22-2.261-3.936-.096-8.012.153-11.546 1.895-2.715 1.331-7.99 5.992-5.715 9.556 2.159 3.3 10.244.585 13.134-.308z"/>
        <path fill="#020741" fillRule="nonzero" d="M240.578 4.88c-.651 1.983.498 4.12 1.815 5.657a4.888 4.888 0 0 0 2.729 1.902c.73.067 1.464-.03 2.151-.285a38.722 38.722 0 0 0 6.315-2.503 1.954 1.954 0 0 0-2.166-1.602c.59.127 1.196-.12 1.53-.622a2.861 2.861 0 0 0 .46-1.77c-.124-3.279-3.549-5.064-6.139-5.577-2.473-.512-5.868 2.269-6.695 4.8z"/>
        <text fill="#D1DEFE" fontFamily="Arboria-Black" fontSize="49.35" fontWeight="700">
            <tspan x="0" y="65.671">* * * * * </tspan>
        </text>
    </g>
</svg>

);
