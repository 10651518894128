import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="285" height="350" viewBox="0 0 285 350">
    <g fill="none" fillRule="evenodd">
        <path fill="#748795" fillRule="nonzero" d="M230.698 54.949c.468 3.81-5.963 7.518-14.368 8.281-8.404.764-15.599-1.706-16.067-5.523-.468-3.817 5.963-7.519 14.368-8.282 8.404-.763 15.598 1.707 16.067 5.524z"/>
        <path fill="#63E59A" fillRule="nonzero" d="M200.623 67.415l-7.676 1.894c.432 1.742.792 3.42 1.13 5.04l-1.13.282a97.006 97.006 0 0 1 2.938 18.875c-.404 8.203-2.694 14.584-6.885 19.049-9.55 10.168-26.43 7.244-26.603 7.201l-1.44 7.778c.093 0 .432.08.965.151l-.965 5.207a42.77 42.77 0 0 0 7.252.562c7.266 0 18.486-1.592 26.51-10.083 6.545-6.935 9.57-16.974 9.066-29.951.302-7.548-.751-16.216-3.162-26.005z"/>
        <path fill="#A3A9AF" fillRule="nonzero" d="M158.955 139.892l16.996 1.087.23-.46a4.321 4.321 0 0 0 .086-3.702l-15.18-34.02a3.169 3.169 0 0 0-3.104-1.866l-13.388.886a4.501 4.501 0 0 0-3.724 2.463l18.084 35.612z"/>
        <path fill="#C6CCCE" fillRule="nonzero" d="M157.795 140.072l18.148.907-15.843-35.576a3.666 3.666 0 0 0-3.601-2.16L140.9 104.28l16.895 35.792z"/>
        <path fill="#748795" fillRule="nonzero" d="M159.83 111.84l-.953.421a.71.71 0 0 1-.937-.362l-2.284-5.162a.71.71 0 0 1 .362-.936l.954-.422a.71.71 0 0 1 .936.361l2.285 5.162a.71.71 0 0 1-.362.937z"/>
        <circle cx="157.107" cy="106.968" r="1" fill="#FFF" fillRule="nonzero" transform="rotate(-6.72 157.107 106.968)"/>
        <path stroke="#959BB7" strokeLinecap="round" strokeLinejoin="round" d="M224.54 339.968h-.202"/>
        <path fill="#748795" fillRule="nonzero" d="M182.382 147.821s9.297-40.33 5.869-62.172c-3.428-21.842 5.898-25.206 5.898-25.206s14.656-8.822 42.548-4.623c27.891 4.199-19.539 32.674 4.407 92.001-58.722 2.103-58.722 0-58.722 0z"/>
        <path fill="#ABB3D6" fillRule="nonzero" d="M257.33 274.29c-8.074-13.943-38.17-114.925-23.313-125.54a22.757 22.757 0 0 1 5.078-1.116H182.31s-8.808 84.194 21.497 114.506c0 23.484 9.967 64.282 9.42 77.778h.482a467.07 467.07 0 0 1 2.398-56.483c.403-3.867.843-7.828-.05-11.616-.57-2.42-1.671-4.674-2.507-7.022-1.742-4.89-2.319-10.082-2.729-15.289-.943-11.926-2.557-33.372-3.025-45.327-.187-4.775-.439-9.629-2.03-14.13a18.508 18.508 0 0 0-7.073-9.427c11.134 4.667 14.562 17.947 14.562 17.947 2.045 2.607 8.786 26.913 12.099 36.628a111.388 111.388 0 0 0 7.367 16.887l1.282 2.377 1.988 3.694c3.81 7.094 7.922 14.829 9.585 18.149 3.428 6.848 30.34 57.735 28.872 66.543 1.663 0 3.031 0 4.155-.094-.137-1.915-3.997-53.703-21.274-68.466z"/>
        <path fill="#959BB7" fillRule="nonzero" d="M198.715 180.675a18.508 18.508 0 0 1 7.072 9.448c1.584 4.509 1.844 9.363 2.03 14.13.469 11.955 2.075 33.401 3.026 45.327.41 5.178.986 10.4 2.729 15.29.835 2.34 1.937 4.601 2.506 7.02.9 3.789.454 7.743.05 11.617a467.07 467.07 0 0 0-2.398 56.483h10.17s7.338-55.295 2.937-68.992c-2.866-8.858-11.781-60.343-13.56-72.377 0 0-3.428-13.28-14.562-17.946zM282.124 340.76s4.408-42.389-22.022-80.55c-10.76-46.976-19.077-112.576-19.077-112.576h-1.93c-1.738.165-3.452.53-5.106 1.087-14.872 10.615 15.238 111.626 23.311 125.54 17.284 14.763 21.137 66.55 21.281 68.516 7.77-.382 3.543-2.017 3.543-2.017z"/>
        <path fill="#63FDAD" fillRule="nonzero" d="M224.209 339.6s2.78 2.881 1.923 10.393h-31.075s-2.622-8.556 3.708-8.757a68.653 68.653 0 0 0 12.243-1.628l13.2-.007zM282.744 339.6s2.78 2.881 1.923 10.393h-31.075s-2.622-8.556 3.708-8.757a68.653 68.653 0 0 0 12.243-1.628l13.2-.007z"/>
        <path fill="#F9D4CC" fillRule="nonzero" d="M164.802 120.923a3.392 3.392 0 0 0-2.24-2.586 5.761 5.761 0 0 0-3.082 0 39.357 39.357 0 0 0-10.298 3.5c-2.29 1.196-5.761 2.075-6.287 5.38a8.793 8.793 0 0 0 .324 3.414 19.69 19.69 0 0 0 1.203 3.45c1 1.814 4.263 5.436 13.993 3.262a10.082 10.082 0 0 0 2.232-1.117c.98-.648 2.355-.72 2.802-1.951.684-1.822-.836-4.112-2.226-5.805.893.23 1.44-1.008 1.059-1.995a3.73 3.73 0 0 0-2.16-1.973c.943-.468 2.06-.54 3.024-.965a2.593 2.593 0 0 0 1.656-2.614z"/>
        <path fill="#103D60" fillRule="nonzero" d="M213.438 86.593c-4.465 3.81-11.292 5.977-17.24 4.198-.814 3.24 2.628 6.035 5.862 7.836 3.233 1.8 7.014 3.867 7.41 7.158.339 2.773-1.915 5.214-3.09 7.807a3.889 3.889 0 0 0-.266 3.298c1.023 2.081 4.321 2.218 6.676 3.212 3.075 1.296 4.71 4.32 5.041 7.252.332 2.931-.288 5.87-.561 8.8a34.1 34.1 0 0 0 .979 11.811 3.694 3.694 0 0 0 1.001 1.952c.445.343.95.6 1.49.756a22.015 22.015 0 0 0 7.548 1.195 6.265 6.265 0 0 0 3.01-.626c1.44-.843 1.851-2.5 2.082-3.99 3.168-20.402-1.858-41.193.655-61.66.288-2.327.64-4.804-.547-6.907-1.513-2.672-8.001-5.992-11.4-4.112-1.851 1.023-2.442 3.968-3.385 5.567a25.256 25.256 0 0 1-5.265 6.453z" opacity=".17"/>
        <path fill="#F9D4CC" fillRule="nonzero" d="M222.934 147.727a8.023 8.023 0 0 0-2.967 1.945 2.297 2.297 0 0 0-.137 3.017c.836.879 2.398.93 3.659.576 1.26-.352 2.376-1.008 3.644-1.317a2.621 2.621 0 0 0-.555 2.607c.403.85 1.887 1.267 2.564.561 0 1.93.317 4.321 2.39 5.041 1.355.454 2.882-.057 4.207-.569l13.553-5.178c.487-.129.924-.402 1.253-.784.186-.346.252-.744.188-1.131a15.174 15.174 0 0 0-2.643-7.425 7.238 7.238 0 0 0-2.348-2.326c-3.06-1.729-7.087-.058-10.212.648a66.392 66.392 0 0 0-12.596 4.335z"/>
        <path fill="#63FDAD" fillRule="nonzero" d="M248.349 62.064l-4.782-3.997a3.6 3.6 0 0 0-4.033-.31l-1.714.994c-6.18 3.6-7.85 10.846-3.73 16.225 5.163 4.962 20.95 24.832 20.661 44.586-.274 19.048-11.79 22.109-13.1 22.39.195-.044.303-.058.303-.058l4.263 19.639c1.26-.209 30.967-5.502 31.514-41.726.469-32.415-28.158-56.72-29.382-57.743z"/>
        <path fill="#F9D4CC" fillRule="nonzero" d="M194.898 30.866c.271-.176.486-.426.62-.72a1.383 1.383 0 0 0-.159-.828 13.38 13.38 0 0 1-.72-6.654 25.71 25.71 0 0 0 .31-2.73 17.385 17.385 0 0 1 7.093-8.044 24.766 24.766 0 0 1 19.265-2.312c3.248.98 6.395 2.794 8.051 5.675 1.656 2.88 1.577 6.431 1.203 9.737a42.843 42.843 0 0 1-3.601 13.596c-5.761 12.142-4.155 20.633-18.227 20.813-.937-2.816-1.974-6.114-2.881-8.915a1.635 1.635 0 0 0-.339-.656 1.57 1.57 0 0 0-.979-.36c-2.225-.23-4.847.403-6.446-1.116a5.761 5.761 0 0 1-1.325-2.614l-2.405-7.865c-.742-2.369-1.354-5.314.54-7.007z"/>
        <path fill="#FFF" fillRule="nonzero" d="M213.644 35.41l-9.362 3.234s9.405 2.405 9.362-3.234z"/>
        <ellipse cx="208.113" cy="27.049" fill="#103D60" fillRule="nonzero" rx="2.117" ry="5.034"/>
        <ellipse cx="200.847" cy="28.994" fill="#103D60" fillRule="nonzero" rx="1.296" ry="3.918"/>
        <path fill="#020741" fillRule="nonzero" d="M207.948 18.587c4.832-1.44 9.62-3.327 14.662-3.47a20.222 20.222 0 0 0-3.968 10.593c-.08 1.116 0 2.39.835 3.125a3.406 3.406 0 0 0 1.614.692c2.062.388 4.192.183 6.143-.59a3.6 3.6 0 0 0 3.327 5.76c.936-5.408 3.276-10.939 1.685-16.196-1.188-3.925-4.494-6.971-8.239-8.642-3.745-1.67-7.922-2.16-12.027-2.225-3.874-.094-7.885.151-11.364 1.865-2.672 1.31-7.864 5.898-5.624 9.405 2.124 3.234 10.104.54 12.956-.317z"/>
        <path fill="#020741" fillRule="nonzero" d="M226.513 9.866c-.64 1.952.49 4.055 1.786 5.567a4.81 4.81 0 0 0 2.665 1.837 4.818 4.818 0 0 0 2.117-.281 38.111 38.111 0 0 0 6.215-2.463 1.923 1.923 0 0 0-2.11-1.563 1.44 1.44 0 0 0 1.505-.612 2.816 2.816 0 0 0 .454-1.743c-.122-3.226-3.493-4.984-6.042-5.488-2.434-.49-5.776 2.254-6.59 4.746z"/>
        <path fill="#63FDAD" fillRule="nonzero" d="M115.572 81.717a6.698 6.698 0 0 0 .994-4.667l-3.81-24.42a6.74 6.74 0 0 0-3.687-4.998L95.098 66.766l20.474 14.951zM84.929 80.716l-12.581-9.182-14.886 20.388a6.69 6.69 0 0 0 3.91.569l49.577-7.735a6.69 6.69 0 0 0 3.55-1.735L94.111 68.135 84.93 80.716z"/>
        <path fill="#63FDAD" fillRule="nonzero" d="M84.562 78.361l22.843-31.291a6.669 6.669 0 0 0-2.34-.058l-49.612 7.72c-.76.135-1.492.4-2.16.785l18.723 13.684 12.546 9.16z"/>
        <path fill="#D1DEFE" fillRule="nonzero" d="M54.87 42.41c-2.744-4.378-10.875-7.338-15.26-9.837A514.515 514.515 0 0 0 21.042 22.52c-5.61-2.881-11.357-5.66-17.521-6.964a3.846 3.846 0 0 0-2.16 0C-.8 16.384 0 19.588 1.26 21.476a40.848 40.848 0 0 0 17.104 14.237c2.52 1.123 5.401 1.973 8.044 1.231a13.726 13.726 0 0 0-6.171 4.458 4.63 4.63 0 0 0-1.181 2.823c0 1.729 1.656 3.018 3.284 3.601a10.198 10.198 0 0 0 11.86-4.508l-.1.23h-.043l-.116.36c-.497 1.664-2.16 8.434 4.465 6.424 2.413-1.08 3.976-2.808 6.223-4.587l.597-.461c-.24.18-.464.38-.67.598-.311.31-.556.68-.72 1.087-1.123 3.154 4.717 6.323 8.016 4.84a6.481 6.481 0 0 0 3.017-9.399zM97.222 35.209c2.002-4.768 9.55-8.995 13.46-12.17A458.258 458.258 0 0 1 127.39 10.11c5.041-3.745 10.291-7.418 16.168-9.7a3.846 3.846 0 0 1 2.16-.36c2.233.482 1.988 3.766 1.023 5.833A40.848 40.848 0 0 1 132.165 22.7c-2.32 1.512-5.042 2.823-7.742 2.513a13.726 13.726 0 0 1 6.813 3.4 4.63 4.63 0 0 1 1.62 2.592c.245 1.714-1.152 3.248-2.665 4.083a10.198 10.198 0 0 1-12.43-2.535l.137.209h.05s.065.122.173.338c.757 1.556 3.486 7.973-3.37 7.065-2.557-.677-4.379-2.16-6.878-3.521l-.67-.36c.254.14.495.301.72.482.35.258.645.583.865.958 1.635 2.917-3.6 6.957-7.086 6.056a6.481 6.481 0 0 1-4.48-8.771z"/>
        <path fill="#63E59A" fillRule="nonzero" d="M72.348 71.534l-3.032 4.148 16.283 8.145 8.512-15.692-9.182 12.581z"/>
        <path fill="#63FDAD" fillRule="nonzero" d="M53.674 86.866a6.705 6.705 0 0 0 2.37 4.141l11.925-16.34-17.557-8.887 3.262 21.086z"/>
        <path fill="#63E59A" fillRule="nonzero" d="M70.987 70.54l-3.018 4.127zM51.852 56.569a6.74 6.74 0 0 0-1.988 5.884l.548 3.327 17.586 8.887 3.017-4.134-19.163-13.964z"/>
    </g>
</svg>

);
