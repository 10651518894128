import { gql } from 'apollo-boost';

export default gql`
query storeSizeCharts($storeId: Int!) {
  storeSizeCharts(store_id: $storeId) {
    id 
    chartName
    linked_items_quantity
    sizes{
      sizeName
    }
  }
}
`;


