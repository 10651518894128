import { gql } from 'apollo-boost';
// Todo: add type for store. 

export default gql`
query Me {
  me {
    id
    name
    email
    created_at
    updated_at
    add_store_url
    show_walk_throughs
    stores {
      id
      name
      pendingQuestions
      sources {
        id
        name
        type
      }
    }
    roles {
      id
      name
    }
  }
}
`;
