import { graphql } from 'react-apollo';

import ADDSTORE from '../gql/addStore';

export default graphql(ADDSTORE, {
  props: ({ mutate }) => ({
    addStore: (input) => mutate({
      variables: { input },
      refetchQueries: [ 'Me' ],
    })
  }),
});
