import { gql } from 'apollo-boost';

export default gql`
query PredefinedAnswers {
  predefinedAnswers {
    id
    slug
    answer
  }
  greetings {
    id: __typename
    __typename
    greeting
    farewell
  }
  tags {
    id: tag
    label
    response
  }
}
`;
