import React from 'react';

const PasswordEmailSent = props => {
    const {
        history,
    } = props;
    return (
        <div className="onboarding flex-1">
            <h1 className="mb-6 text-blue-darkest">Correo enviado</h1>
            <p className="mb-8 w-3/4 text-lg text-blue-darkest">Te enviamos un email a <b>{localStorage.getItem("userEmail")}</b> con las instrucciones para resetear tu clave.</p>
            <p className="w-3/4">Si no lo recibes en los próximos minutos, <a onClick={() => history.go(-1)}>haz click aquí</a></p>
        </div>
    );
};

export default PasswordEmailSent;