import React from 'react';
import SignUpIllustration from '../../components/svgs/onboarding/SignUpIllustration';
import Button from '../../components/Button';
import ErrorBoundary from '../../app/ErrorBoundary';

export default class ErrorQuestions extends ErrorBoundary {

  constructor(props) {
    super(props);
    this.handleReload = this.handleReload.bind(this);
  }

  handleReload() {
    this.setState({
      hasError: false,
    })
  }

  render() {
    if (this.state.hasError) {
      // Generic error UI. When more details are needed, create one Custom Error Component.
      return (
      <div className="flex flex-1 items-start justify-center md:items-center">
        <div className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
            <div className="onboarding flex-1">
                {/* Todo: Define what should be shown here. */}
                <h1 className="mb-8">Ocurrió un error.</h1>
                <p className="description mb-12">
                    Estamos trabajando para solucionar el problema.
                </p>
                {/* Todo chequear si no estaba en esta pagina */}
                <Button onClick={this.handleReload}>
                    Volver a cargar la página
                </Button>
            </div>
            <div className="flex-1 ml-24 hidden xl:block">
                <SignUpIllustration />
            </div>
        </div>
      </div>);
    }
    return this.props.children; 
  }
}