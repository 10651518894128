import React, { useState, useEffect, useRef } from 'react';
import Moment from 'react-moment';

const CustomerQuestion = props => {
  const { question, isMobile } = props;
  const [showText, setShowText] = useState(false);
  const [textTruncated, setTextTruncated] = useState(false);
  const textRef = useRef(null);
  const answerTextRef = useRef(null);

  useEffect(() => {
    if (textRef && textRef.current && textRef.current.clientWidth < textRef.current.scrollWidth) {
      setTextTruncated(true);
    }
    if (answerTextRef && answerTextRef.current && answerTextRef.current.clientWidth < answerTextRef.current.scrollWidth) {
      setTextTruncated(true);
    }
  });

  return (
    <div className="flex">
      <img
        className="rounded-full mr-3 cursor-pointer"
        style={{ width: '45px', height: '45px', border: '1px solid lightgray' }}
        src={question.listing.thumbnail}
        alt="thumbnail"
        onClick={() => window.open(question.listing.permalink, '_blank').focus()}
        title={question.listing.title}
      />
      <div className="flex flex-col overflow-hidden flex-1">
        <div className="flex justify-between items-center" style={!isMobile ? { marginBottom: '6px' } : {}}>
          <a href={question.listing.permalink} title={question.listing.title} target="_blank" style={{ color: '#2b48f0' }} className="truncate font-semibold cursor-pointer">
            {question.listing.title}
          </a>
          {!isMobile && (
            <span style={{ fontSize: '15px' }} className="opacity-75 whitespace-no-wrap ml-2">
              <Moment format="DD/MM/YYYY HH:mm">{question.date_created}</Moment>
            </span>
          )}
        </div>
        <span ref={textRef} style={showText ? { whiteSpace: 'normal' } : {}} className="font-semibold truncate mr-4">
          {question.text}
        </span>
        <span ref={answerTextRef} style={showText ? { whiteSpace: 'normal' } : {}} className="opacity-75 truncate">
          {question.answer_text}
        </span>
        <div className={`flex ${isMobile ? 'justify-between' : 'justify-end'}`}>
          {isMobile && (
            <span style={{ fontSize: '15px' }} className="opacity-75">
              <Moment format="DD/MM/YYYY HH:mm">{question.date_created}</Moment>
            </span>
          )}
          {textTruncated && (
            <span style={{ minWidth: '95px' }} className="text-vivid-blue font-semibold cursor-pointer flex justify-end" onClick={() => setShowText(!showText)}>
              {showText ? 'ver menos' : 'ver más'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerQuestion;
