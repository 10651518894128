import React from 'react';
import { Form } from 'formik';
import { Link } from 'react-router-dom';
import { compose, withState } from 'recompose';
import Loader from '../../components/svgs/Loader';
import Button from '../../components/Button';

import FormField from '../../components/FormField';
import Shopify2 from '../../components/svgs/Shopify-2';
import ArrowBack from '../../components/svgs/ArrowBack';

import withAddSource from './withAddSource';


const SincronizarShopify = props => {
  const { onChangeToken, token, loading, handleAddSource, invalidCredentials, onChangeInvalidCredentials} = props;
  return (
    <div style={{ minHeight: "492px" }} className="flex flex-col relative justify-center">
      <div style={{ top: "-10px", left: "-24px" }} className="absolute">
        <Link to={'/sincronizar'} style={{ color: "#2b48f0" }}><ArrowBack /> Volver atras</Link>
      </div>
      <div style={{ maxHeight: "300px" }} className="onboarding flex-1 flex flex-col justify-around">
        {/* // Todo: Leer el nombre */}
        <Shopify2 />
        <p className="description" >
          Para conectar tu cuenta de Astroselling con Shopify, completa el siguiente campo.<br /><br />
          <b>Puedes otener el "Código de Sincronización" siguiendo <a href="http://astropify.mybluemix.net/shopify/home" target="_blank">este enlace</a>.</b><br /><br />
          Si necesitas ayuda para encontrar este dato, <a href="http://soporte.astroselling.com/support/" target="_blank">contáctanos</a>.<br /><br />
        </p>
        <Form>
          <FormField
            label="Código de sincronización"
            name="token"
            type="token"
            changeHandler={(ev) => {
              onChangeInvalidCredentials(false);
              onChangeToken(ev.target.value)}
            }
            errorMsg={invalidCredentials && 'Código incorrecto.'}
          />
          <Button
            disabled={!token || invalidCredentials}
            isLink={true}
            className="float-right"
            onClick={()=> {
              handleAddSource({
                  'sourceType': 'SHOPIFY',
                  'shopifyCreds': {
                    'token': token,
                  }
                })
              }
            }
          >
            { loading ? <Loader className="text-vivid-blue self-center"/> : 'Conectar' }
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default compose(
  withState('token', 'onChangeToken', null),
  withAddSource
  )(SincronizarShopify);