import React, { useState } from 'react';
import { TextField, Button, List, ListItem, Tooltip, IconButton } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { useLazyQuery } from '@apollo/react-hooks';
import CATALOG_PRODUCTS_QUERY from '../gql/CatalogProductsQuery';
import Loader from '../../../../components/svgs/Loader';
import Swal from 'sweetalert2';
import { useEffect } from 'react';

const SearchCatalogProduct = props => {
  const { setSelectedProduct, setSearchOther } = props;
  const [search, setSearch] = useState('');
  const [hasAlreadySearched, setHasAlreadySearched] = useState(false);
  const [products, setProducts] = useState(null);
  const [pagingInfo, setPagingInfo] = useState({
    currentPage: null,
    totalResults: null,
    totalPages: null,
  });
  const perPage = 10;

  const [getCatalogProducts, { loading, data, error }] = useLazyQuery(CATALOG_PRODUCTS_QUERY);

  useEffect(() => {
    setHasAlreadySearched(false);
  }, []);

  useEffect(() => {
    setProducts(data && data.CatalogProductsQuery.catalogProducts);
    setPagingInfo({
      currentPage: data && data.CatalogProductsQuery.paging.currentPage,
      totalResults: data && data.CatalogProductsQuery.paging.total,
      totalPages: data && data.CatalogProductsQuery.paging.pages,
    });
  }, [data]);

  if (error) {
    Swal.fire({
      type: 'error',
      title: 'Oops!...',
      text: error ? error.graphQLErrors[0].message : 'Algo ha salido mal :(',
    });
  }

  const enterPressed = event => {
    let code = event.keyCode || event.which;
    if (code === 13) {
      getCatalogProducts({
        variables: {
          filters: {
            q: search,
            status: 'active',
          },
          paginate: {
            per_page: perPage,
            page: 1,
          },
        },
      });
      setHasAlreadySearched(true);
    }
  };

  const prevPage = () => {
    getCatalogProducts({
      variables: {
        filters: {
          q: search,
          status: 'active',
        },
        paginate: {
          per_page: perPage,
          page: pagingInfo.currentPage - 1,
        },
      },
    });
  };

  const nextPage = () => {
    getCatalogProducts({
      variables: {
        filters: {
          q: search,
          status: 'active',
        },
        paginate: {
          per_page: perPage,
          page: pagingInfo.currentPage + 1,
        },
      },
    });
  };

  return (
    <div className="w-full">
      <div className="flex w-full">
        <TextField
          style={{ width: '100%' }}
          id="outlined-basic"
          label="Buscar..."
          variant="outlined"
          value={search}
          onChange={e => setSearch(e.target.value)}
          onKeyPress={e => enterPressed(e)}
        />
        <Button
          onClick={() => {
            getCatalogProducts({
              variables: {
                filters: {
                  q: search,
                  status: 'active',
                },
                paginate: {
                  per_page: perPage,
                  page: 1,
                },
              },
            });
            setHasAlreadySearched(true);
          }}
          style={{ marginLeft: '8px' }}
          variant="contained"
          color="primary"
        >
          <Search />
        </Button>
      </div>
      {loading ? (
        <div className="mt-7">
          <Loader className="text-vivid-blue" />
        </div>
      ) : products && products.length > 0 ? (
        <div className="w-full">
          <List component="nav">
            {products.map((product, idx) => (
              <ListItem
                key={idx}
                button
                onClick={() => {
                  setSelectedProduct(product);
                  setSearchOther(false);
                }}
                disabled={product.children_ids != null ? true : false}
              >
                <span className={'py-1 ' + (product.children_ids != null ? 'opacity-50' : '')}>{product.product_name}</span>
              </ListItem>
            ))}
          </List>
          <div className="flex justify-center mt-2 items-center">
            <Tooltip title="Página anterior">
              <IconButton style={{ fontSize: '1rem' }}>
                <i
                  onClick={() => (pagingInfo.currentPage > 1 ? prevPage() : null)}
                  style={{ color: '#3f51b5', ...(pagingInfo.currentPage <= 1 ? { opacity: '0.5' } : {}) }}
                  className="fa fa-chevron-left cursor-pointer"
                  aria-hidden="true"
                ></i>
              </IconButton>
            </Tooltip>
            <span className="mx-2">{`${pagingInfo.currentPage}-${pagingInfo.totalPages} de ${pagingInfo.totalResults}`}</span>
            <Tooltip title="Página siguiente">
              <IconButton style={{ fontSize: '1rem' }}>
                <i
                  onClick={() => (pagingInfo.currentPage < pagingInfo.totalPages ? nextPage() : null)}
                  style={{ color: '#3f51b5', ...(pagingInfo.currentPage >= pagingInfo.totalPages ? { opacity: '0.5' } : {}) }}
                  className="fa fa-chevron-right cursor-pointer"
                  aria-hidden="true"
                ></i>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className={'mt-6 ' + (!hasAlreadySearched ? 'hidden' : '')}>No se encontraron resultados</div>
      )}
    </div>
  );
};

export default SearchCatalogProduct;
