import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import EventService from './EventService';

export default class ToastManager extends Component {

    constructor(props) {
        let toastId = null;
        super(props);

        EventService.listenEvent('toast', 'launchToast', (toastMessage, toastType = 'success', toastOptions) => {
            if (!toast.isActive(toastId)) {
                toastId = toast(toastMessage, {
                    position: toast.POSITION.TOP_CENTER,
                    bodyClassName: "toastBody",
                    className: toastType + '-toast',
                    ...toastOptions
                });
            }
        })
    }

    render() {
        return < ToastContainer autoClose={false} hideProgressBar={true} autoClose={3000} />
    }
}