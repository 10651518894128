// import React, { Component, useState } from 'react';
import React, { Component } from 'react';

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      dropDownStyle: {},
    };
    this.inverted = props.inverted || false;
    this.icon = props.icon || null;
    this.toggleList = this.toggleList.bind(this);
    this.openList = this.openList.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.dropDown = React.createRef();
  }

  openList() {
    if (!this.state.listOpen) {
      this.toggleList();
    }
  }

  handleClick() {
    document.removeEventListener('click', this.handleClick);
    this.toggleList();
  }

  toggleList() {
    const listOpen = !this.state.listOpen;
    let dropDownStyle = {};

    if (listOpen) {
      document.addEventListener('click', this.handleClick);
    }
    this.setState(
      {
        listOpen,
      },
      () => {
        let dropDownRect = this.dropDown && this.dropDown.current ? this.dropDown.current.getBoundingClientRect() : null;

        if (dropDownRect && dropDownRect.x + dropDownRect.width > window.innerWidth) {
          delete dropDownStyle.left;
          dropDownStyle.right = 0;
        } else {
          delete dropDownStyle.right;
          dropDownStyle.left = 0;
        }
        this.setState({
          dropDownStyle,
        });
      }
    );
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  render() {
    //     const { options, changeHandler, value } = this.props;
    //     const{ listOpen, title } = this.state
    //     return (
    //       <div className="dropdown-wrapper">
    //         <div className="dropdown-header" onClick={this.toggleList}>
    //           {/*<span className="dropdown-header__title">{title}</span>*/}
    //           { this.props.children }
    //         </div>
    //         { listOpen && (
    //           <ul className="dropdown-list">
    //             {options.map(({ value, text }, index) => (
    //               <li
    //                 key={index}
    //                 className="dropdown-list__item"
    //                 onClick={() => changeHandler(index)}
    //               >
    //                 {text}
    //               </li>
    //             ))
    //             .filter((option, index) => index !== value)}
    //           </ul>
    //         )}
    //       </div>
    //     );
    const { list, changeHandler, label, key } = this.props;
    const { listOpen } = this.state;

    return (
      <div className="dropdown">
        <div className="dropdown-header" onClick={this.openList}>
          {this.props.children}
        </div>
        {listOpen && (
          <ul className={'dropdown-list text-black overflow-hidden ' + (this.props.inverted ? 'dropdown-list-inverted' : '')} ref={this.dropDown} style={this.state.dropDownStyle}>
            {list.map(item => (
              <li
                key={item[key ? key : 'id']}
                className={'dropdown-list__item ' + (item['className'] ? item['className'] + ' dropdown-list__item--custom' : 'hover:text-vivid-blue')}
                onClick={item.action || (() => changeHandler(item))}
              >
                {item[label ? label : 'name']}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default DropDown;
