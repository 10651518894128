import { graphql } from 'react-apollo';
import { get } from 'lodash';

import QUESTIONS from '../gql/questions';

export default graphql(QUESTIONS, {
  options ({ fromMeliId, itemId }) {
    return {
      notifyOnNetworkStatusChange: true,
      variables: {
        cursor: null,
        orderBy: {
          field: 'DATE_CREATED',
          direction: 'DESC',
        },
        filter: {
          from_meli_id: fromMeliId,
          item_id: itemId,
        },
      },
    };
  },
  props: ({ data, ownProps }) => {
    if (!ownProps.fromMeliId) {
      return { moreQuestions: [] };
    }
    const moreQuestions = get(data, 'questions.data', []);
    const loadingMoreQuestions = get(data, 'loading', false);
    return { moreQuestions, loadingMoreQuestions };
  },
  skip: props => !props.fromMeliId,
});
