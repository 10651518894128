import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="488" height="325" viewBox="0 0 488 325">
    <defs>
        <filter id="a" width="126.7%" height="143.6%" x="-13.4%" y="-21.8%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="13"/>
        </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
        <path fill="#D8D8D8" d="M162 64h292v179H162z" filter="url(#a)"/>
        <g>
            <path fill="#F1F4F8" fillRule="nonzero" d="M487 4.56c-.007-2.505-1.996-4.539-4.461-4.56H204.512a4.462 4.462 0 0 0-3.185 1.33A4.61 4.61 0 0 0 200 4.56V17h287V4.56z"/>
            <path fill="#D0D9E2" fillRule="nonzero" d="M486.975 18H199V5.02c.003-2.77 2.192-5.016 4.893-5.02h278.214c2.701.004 4.89 2.25 4.893 5.02L486.975 18zm-287.169-.833h286.363V5.02c-.004-2.303-1.817-4.172-4.062-4.187H203.893c-2.252.004-4.077 1.876-4.08 4.187l-.007 12.147z"/>
            <path fill="#FFF" fillRule="nonzero" d="M204.511 178h278.003c2.475-.007 4.48-2.022 4.486-4.509V17H200v156.491c.003 1.2.48 2.348 1.327 3.194A4.487 4.487 0 0 0 204.51 178z"/>
            <path fill="#D0D9E2" fillRule="nonzero" d="M482.132 178H203.893a4.897 4.897 0 0 1-4.893-4.889V17h288v156.111a4.897 4.897 0 0 1-4.868 4.889zM199.806 17.812v155.3a4.085 4.085 0 0 0 4.08 4.076h278.246a4.085 4.085 0 0 0 4.08-4.077v-155.3H199.807z"/>
            <circle cx="208.5" cy="8.5" r="2.5" fill="#E4E8EA" fillRule="nonzero"/>
            <circle cx="217.5" cy="8.5" r="2.5" fill="#E4E8EA" fillRule="nonzero"/>
            <circle cx="225.5" cy="8.5" r="2.5" fill="#E4E8EA" fillRule="nonzero"/>
            <path fill="#F1F4F8" fillRule="nonzero" d="M208 46h271v1H208z"/>
            <path fill="#748795" fillRule="nonzero" d="M207 32h45v2h-45z"/>
            <path stroke="#ABB3D6" d="M434 29h45v8h-45z"/>
            <path fill="#BCC9CF" fillRule="nonzero" d="M446 32h21v2h-21z"/>
            <rect width="159" height="24" x="207" y="61" fill="#F5F8F9" fillRule="nonzero" rx="4.14"/>
            <path fill="#2B48F0" fillRule="nonzero" d="M360.235 83h-146.47c-2.08 0-3.765-1.733-3.765-3.871V66.871c0-2.138 1.685-3.871 3.765-3.871h146.47c2.08 0 3.765 1.733 3.765 3.871v12.258c0 2.138-1.685 3.871-3.765 3.871zm-146.47-19.6c-1.867.004-3.379 1.558-3.382 3.477v12.258c.003 1.92 1.515 3.474 3.382 3.478h146.47c1.867-.004 3.379-1.558 3.382-3.478V66.871c-.003-1.92-1.515-3.474-3.382-3.477l-146.47.006z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M475.233 167h-98.466c-2.08 0-3.767-1.703-3.767-3.804V63.804c0-2.101 1.686-3.804 3.767-3.804h98.466c2.08 0 3.767 1.703 3.767 3.804v99.418c-.014 2.09-1.696 3.778-3.767 3.778zM376.767 60.824c-1.622.004-2.935 1.33-2.938 2.967v99.43c.003 1.638 1.316 2.964 2.938 2.968h98.466c1.622-.004 2.935-1.33 2.938-2.967V63.804c-.003-1.638-1.316-2.964-2.938-2.967l-98.466-.013zM362.237 113H210.763c-2.078 0-3.763-1.72-3.763-3.842V91.842c0-2.122 1.685-3.842 3.763-3.842h151.474c2.078 0 3.763 1.72 3.763 3.842v17.335c-.01 2.114-1.692 3.823-3.763 3.823zM210.763 88.845c-1.62.004-2.932 1.344-2.935 2.997v17.335c.003 1.654 1.315 2.993 2.935 2.997h151.474c1.62-.004 2.932-1.343 2.935-2.997V91.842c-.003-1.653-1.315-2.993-2.935-2.997H210.763z"/>
            <path fill="#FFF" fillRule="nonzero" d="M216 67h12v12h-12z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M216 94h12v12h-12z"/>
            <path fill="#748795" fillRule="nonzero" d="M234 70h37v2h-37z"/>
            <path fill="#E7EDEF" fillRule="nonzero" d="M233 73h19v2h-19z"/>
            <path fill="#748795" fillRule="nonzero" d="M234 97h37v2h-37z"/>
            <path fill="#E7EDEF" fillRule="nonzero" d="M233 101h19v2h-19z"/>
            <rect width="12" height="2" x="341" y="72" fill="#748795" fillRule="nonzero" rx="1"/>
            <path fill="#2B48F0" fillRule="nonzero" d="M359.897 72.519l-.897-.977-.497-.542-.498.542-.902.983a.407.407 0 0 0-.091.37.369.369 0 0 0 .249.27.332.332 0 0 0 .34-.098l.546-.596v3.145c0 .212.158.384.353.384.195 0 .353-.172.353-.384v-3.15l.546.595a.332.332 0 0 0 .34.099.369.369 0 0 0 .249-.271.407.407 0 0 0-.091-.37z"/>
            <rect width="12" height="2" x="341" y="99" fill="#748795" fillRule="nonzero" rx="1"/>
            <path fill="#748795" fillRule="nonzero" d="M358.098 101.48l.902.98.497.54.498-.54.902-.98a.405.405 0 0 0 0-.54.333.333 0 0 0-.498 0l-.546.594v-3.151c0-.212-.158-.383-.353-.383-.195 0-.353.171-.353.383v3.133l-.546-.593a.333.333 0 0 0-.498 0 .405.405 0 0 0 0 .54l-.005.018z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M362.237 140H210.763c-2.078 0-3.763-1.72-3.763-3.842v-17.316c0-2.122 1.685-3.842 3.763-3.842h151.474c2.078 0 3.763 1.72 3.763 3.842v17.335c-.01 2.114-1.692 3.823-3.763 3.823zm-151.474-24.155c-1.62.004-2.932 1.344-2.935 2.997v17.335c.003 1.654 1.315 2.993 2.935 2.997h151.474c1.62-.004 2.932-1.343 2.935-2.997v-17.335c-.003-1.653-1.315-2.993-2.935-2.997H210.763z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M216 121h12v12h-12z"/>
            <path fill="#748795" fillRule="nonzero" d="M234 125h37v2h-37z"/>
            <path fill="#E7EDEF" fillRule="nonzero" d="M233 128h19v2h-19z"/>
            <rect width="12" height="2" x="341" y="126" fill="#748795" fillRule="nonzero" rx="1"/>
            <path fill="#748795" fillRule="nonzero" d="M358.098 128.475l.902.983.497.542.498-.542.902-.983a.408.408 0 0 0 0-.542.332.332 0 0 0-.498 0l-.546.596v-3.145c0-.212-.158-.384-.353-.384-.195 0-.353.172-.353.384v3.145l-.546-.596a.332.332 0 0 0-.498 0 .408.408 0 0 0 0 .542h-.005z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M362.237 167H210.763c-2.078 0-3.763-1.72-3.763-3.843v-17.314c0-2.122 1.685-3.843 3.763-3.843h151.474c2.078 0 3.763 1.72 3.763 3.843v17.34c-.014 2.112-1.695 3.817-3.763 3.817zm-151.474-24.154c-1.62.003-2.932 1.343-2.935 2.997v17.34c.003 1.654 1.315 2.994 2.935 2.997h151.474c1.62-.003 2.932-1.343 2.935-2.998v-17.339c-.003-1.654-1.315-2.994-2.935-2.997H210.763z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M216 148h12v12h-12z"/>
            <path fill="#748795" fillRule="nonzero" d="M234 152h37v2h-37z"/>
            <path fill="#E7EDEF" fillRule="nonzero" d="M233 155h19v2h-19z"/>
            <rect width="12" height="2" x="341" y="153" fill="#748795" fillRule="nonzero" rx="1"/>
            <path fill="#748795" fillRule="nonzero" d="M358.12 155.493l.88.965.497.542.498-.542.902-.983a.408.408 0 0 0 0-.542.332.332 0 0 0-.498 0l-.546.596v-3.145c0-.212-.158-.384-.353-.384-.195 0-.353.172-.353.384v3.145l-.546-.596a.332.332 0 0 0-.498 0 .408.408 0 0 0 0 .542l.016.018z"/>
            <path fill="#EAF0F2" fillRule="nonzero" d="M387 71h38v2h-38zM387 84h7v2h-7zM399 84.82h68v1h-68zM399 93.82h68v1h-68zM399 102.82h68v1h-68zM399 97.82h68v1h-68zM399 89.82h68v1h-68zM387 93h7v2h-7zM387 102h7v2h-7zM403 107h7v2h-7zM422 107h7v2h-7zM441 107h7v2h-7zM460 107h7v2h-7z"/>
            <path fill="#657F8E" fillRule="nonzero" d="M387 75h19v2h-19z"/>
            <rect width="2" height="15" x="402" y="88" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="17" x="408" y="86" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="11" x="414" y="92" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="7" x="420" y="96" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="15" x="426" y="88" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="10" x="432" y="93" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="16" x="437" y="87" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="15" x="443" y="88" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <path fill="#EAF0F2" fillRule="nonzero" d="M387 122h38v2h-38zM387 135h7v2h-7zM399 135.82h68v1h-68zM399 144.82h68v1h-68zM399 153.82h68v1h-68zM399 148.82h68v1h-68zM399 139.82h68v1h-68zM387 144h7v2h-7zM387 153h7v2h-7zM403 158h7v2h-7zM422 158h7v2h-7zM441 158h7v2h-7zM460 158h7v2h-7z"/>
            <path fill="#657F8E" fillRule="nonzero" d="M387 126h19v2h-19z"/>
            <path fill="#63FDAD" fillRule="nonzero" d="M401.04 152a1.024 1.024 0 0 1-.632-1.84l11.263-8.453c.287-.216.666-.265.998-.127l8.843 3.655 11.482-8.617a1.013 1.013 0 0 1 1.043-.102l7.85 3.75 18.703-6.187c.353-.148.76-.085 1.051.164a1.024 1.024 0 0 1-.42 1.772l-19.062 6.32c-.247.08-.516.062-.751-.05l-7.662-3.662-11.451 8.592a1.007 1.007 0 0 1-.998.127l-8.843-3.655-10.8 8.103a1.008 1.008 0 0 1-.613.21z"/>
            <path fill="#748795" fillRule="nonzero" d="M225.452 70.438l-1.71-.627.174-.25a.155.155 0 0 0 0-.205l-.386-.27a.133.133 0 0 0-.12-.086h-1.801l-.5.356a.145.145 0 0 0-.061.093.157.157 0 0 0 .019.112l.18.25-1.71.627a.143.143 0 0 0-.09.125L219 76.426a.155.155 0 0 0 .03.107.13.13 0 0 0 .09.052h1.12v.27c0 .038.014.075.04.103a.127.127 0 0 0 .093.042h4.259c.073 0 .132-.065.132-.145v-.27h1.102c.074 0 .133-.065.133-.146l-.44-5.87a.142.142 0 0 0-.096-.125l-.012-.006zm-2.066-1.088l.235.158-.801 1.114-.157-.35a.13.13 0 0 0 .06-.039l.663-.883zm-1.765-.033l.68.916-.168.376-.795-1.101.283-.191zm-1.398 6.977h-.963l.036-.435h.933l-.006.435zm2.127.416h-1.856v-4.4c0-.08-.059-.144-.132-.144-.073 0-.133.065-.133.145v3.245h-.91l.38-4.887 1.717-.627.663.93a.13.13 0 0 0 .111.067.13.13 0 0 0 .112-.067l.06-.132-.012 5.87zm.162-6.655l-.602-.791h1.169l-.603.785.036.006zm3.193 6.24h-.958v-.436h.934l.024.435zm-.958-.726v-3.252c0-.08-.06-.145-.132-.145-.074 0-.133.065-.133.145v4.393h-1.861v-.759h.15c.074 0 .133-.065.133-.145 0-.08-.06-.145-.133-.145h-.15v-1.088h.15c.074 0 .133-.065.133-.145 0-.08-.06-.145-.133-.145h-.15v-1.088h.15c.074 0 .133-.065.133-.146 0-.08-.06-.145-.133-.145h-.15v-1.088h.15c.074 0 .133-.065.133-.145 0-.08-.06-.145-.133-.145h-.15v-.66l.06.132c.02.044.058.074.102.08.04 0 .078-.02.103-.053l.662-.93 1.711.66.374 4.88-.886-.066z"/>
            <ellipse cx="219" cy="76" fill="#748795" fillRule="nonzero" rx="1" ry="1"/>
            <ellipse cx="226" cy="76" fill="#748795" fillRule="nonzero" rx="1" ry="1"/>
            <path fill="#748795" fillRule="nonzero" d="M224.928 100.132c-.798-.717-.85-2.164-.811-2.988 0-.08-.07-.144-.157-.144h-.87a.163.163 0 0 0-.128.054.134.134 0 0 0-.03.125c.054.178.08.36.08.544a1.94 1.94 0 0 1-2.042 0 1.835 1.835 0 0 1 .078-.544.133.133 0 0 0-.014-.11.156.156 0 0 0-.097-.069h-.922c-.087 0-.157.064-.157.144.039.795 0 2.241-.812 2.953a.137.137 0 0 0-.045.113v3.48c0 .17.152.31.34.31h5.325c.186-.003.334-.141.334-.31v-3.48a.148.148 0 0 0-.072-.078zm-2.879-1.751a2.11 2.11 0 0 0 .936-.221c-.124.598-.517 1.022-.988 1.022s-.87-.442-.988-1.034c.323.15.679.229 1.04.233zm2.617 5.38h-5.3v-3.467c.8-.76.897-2.128.865-2.99h.516c-.03.147-.046.295-.045.443 0 .933.601 1.698 1.34 1.698.74 0 1.31-.76 1.31-1.698 0-.148-.016-.296-.047-.442h.517c0 .849.072 2.224.864 2.989l-.02 3.467zM225.996 131.294l-1.091-4.156a.155.155 0 0 0-.074-.097l-1.667-.936c.21-.361.318-.768.315-1.18 0-1.065-.67-1.925-1.472-1.925-.804 0-1.48.866-1.48 1.924-.002.413.106.82.315 1.18l-1.673.93a.155.155 0 0 0-.074.097l-1.09 4.157c-.018.07.018.142.086.173l.85.366a.164.164 0 0 0 .208-.077l.763-1.925v2.021c0 .085.072.154.16.154h3.842a.164.164 0 0 0 .114-.045.15.15 0 0 0 .047-.109v-2.02l.763 1.924c.015.038.045.07.084.086a.167.167 0 0 0 .123.004l.85-.366a.153.153 0 0 0 .1-.18zm-.924.206l-1.01-2.566a.162.162 0 0 0-.176-.091.156.156 0 0 0-.133.142v2.694h-3.513v-2.688a.157.157 0 0 0-.131-.13.163.163 0 0 0-.17.08l-1.018 2.565-.569-.244 1.044-3.977 1.513-.847v.584a.29.29 0 0 0-.14.332.306.306 0 0 0 .297.217c.139 0 .26-.089.297-.217a.29.29 0 0 0-.14-.332v-.866a1.972 1.972 0 0 1-.34-1.148c0-.892.494-1.617 1.13-1.617.636 0 1.158.725 1.158 1.623.008.4-.106.792-.328 1.13a.15.15 0 0 0-.047.108v.853a.29.29 0 0 0-.14.332.306.306 0 0 0 .298.217c.138 0 .26-.089.297-.217a.29.29 0 0 0-.14-.332v-.68l1.513.854 1.03 3.977-.582.244z"/>
            <path fill="#748795" fillRule="nonzero" d="M222 123c-.563 0-1 .66-1 1.5s.437 1.5 1 1.5 1-.66 1-1.5-.437-1.5-1-1.5zm0 2.663c-.348 0-.652-.531-.652-1.163s.304-1.163.652-1.163c.348 0 .652.531.652 1.163s-.304 1.163-.652 1.163zM223.895 129.904c-.469-.148-.536-.741-.536-.741a.178.178 0 0 0-.178-.163h-1.34c-.093 0-.17.07-.178.163 0 0-.06.622-.536.74a.178.178 0 0 0-.127.178v.741c0 .098.08.178.179.178h2.642c.098 0 .178-.08.178-.178v-.74a.178.178 0 0 0-.104-.178zm-.23.74h-2.293v-.474a1.23 1.23 0 0 0 .633-.866h1.027c.067.37.3.689.632.866v.474zM225 152.452l-.52-1.66v-.639c0-.084-.065-.153-.145-.153h-4.669c-.079 0-.143.069-.143.153v.639l-.521 1.66a.163.163 0 0 0 0 .05v5.345c0 .084.064.153.144.153h2.442a.147.147 0 0 0 .144-.128l.263-1.487.27 1.487a.147.147 0 0 0 .143.128h2.442c.08 0 .144-.069.144-.153v-5.344a.163.163 0 0 0 .006-.051zm-4.304-2.107h.126v.332h-.126v-.332zm-.868 0h.599v.332h-.599v-.332zm-.533 2.196l.491-1.551h.39c-.043.383-.222 1.321-.88 1.596v-.045zm2.568 3.026l-.383 2.14h-2.179v-4.808c.922-.281 1.126-1.494 1.197-1.916h1.395l-.03 4.584zm0-4.89h-.754v-.332h.754v.332zm1.748-.326h.599v.332h-.599v-.332zm-.413 0h.126v.332h-.126v-.332zm-1.041 0h.754v.332h-.754v-.332zm0 3.831v-3.192h.359v2.439a.785.785 0 0 1-.365.708l.006.045zm2.562 3.556h-2.18l-.382-2.139v-1.079c.424-.156.693-.602.652-1.079v-2.458h.742c.042.428.252 1.635 1.197 1.916l-.03 4.84zm0-5.108c-.653-.274-.833-1.213-.88-1.596h.389l.49 1.552v.044z"/>
            <path fill="#F1F4F8" fillRule="nonzero" d="M463 54.829c-.007-2.664-2.194-4.822-4.894-4.829H154.894c-2.7.007-4.887 2.165-4.894 4.829V68h313V54.829z"/>
            <path fill="#D0D9E2" fillRule="nonzero" d="M463 69H149V55.29c.003-2.92 2.39-5.287 5.335-5.29h303.33c2.945.003 5.332 2.37 5.335 5.29V69zm-313.114-.872H462.12V55.322c-.003-2.435-1.993-4.409-4.45-4.412H154.336c-2.455.003-4.445 1.977-4.449 4.412v12.806z"/>
            <path fill="#FFF" fillRule="nonzero" d="M154.887 244h303.22c2.7-.007 4.886-2.21 4.893-4.929V68H150v171.084c.014 2.712 2.195 4.906 4.887 4.916z"/>
            <path fill="#D0D9E2" fillRule="nonzero" d="M457.665 244h-303.33a5.346 5.346 0 0 1-5.335-5.332V68h314v170.668a5.346 5.346 0 0 1-5.335 5.332zM149.886 68.881v169.787a4.46 4.46 0 0 0 4.45 4.457h303.329a4.46 4.46 0 0 0 4.449-4.457V68.88H149.886z"/>
            <circle cx="159" cy="59" r="3" fill="#E4E8EA" fillRule="nonzero"/>
            <circle cx="169" cy="59" r="3" fill="#E4E8EA" fillRule="nonzero"/>
            <circle cx="178" cy="59" r="3" fill="#E4E8EA" fillRule="nonzero"/>
            <path fill="#F1F4F8" fillRule="nonzero" d="M158 100h296v1H158z"/>
            <path fill="#748795" fillRule="nonzero" d="M158 85h49v2h-49z"/>
            <path fill="#ABB3D6" fillRule="nonzero" d="M405 81h49v9h-49z"/>
            <path fill="#BCC9CF" fillRule="nonzero" d="M418 85h23v2h-23z"/>
            <rect width="173" height="26" x="158" y="116" stroke="#F5F8F9" strokeWidth="1.5" rx="4.14"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M450.898 232H343.102a4.108 4.108 0 0 1-4.102-4.102V120.102a4.108 4.108 0 0 1 4.102-4.102h107.796a4.108 4.108 0 0 1 4.102 4.102v107.796a4.108 4.108 0 0 1-4.102 4.102zM343.089 116.88a3.221 3.221 0 0 0-3.215 3.215v107.803a3.221 3.221 0 0 0 3.215 3.215h107.809a3.221 3.221 0 0 0 3.215-3.215V120.102a3.221 3.221 0 0 0-3.215-3.215l-107.809-.006zM326.903 173H161.097c-2.261-.003-4.094-1.851-4.097-4.131V150.13c.003-2.28 1.836-4.128 4.097-4.131h165.806c2.261.003 4.094 1.851 4.097 4.131v18.738c-.003 2.28-1.836 4.128-4.097 4.131zm-165.806-26.107c-1.772.004-3.208 1.452-3.211 3.238v18.738c.003 1.786 1.439 3.234 3.21 3.238h165.807c1.772-.004 3.208-1.452 3.211-3.238V150.13c-.003-1.786-1.439-3.234-3.21-3.238H161.096z"/>
            <path fill="#FFF" fillRule="nonzero" d="M167 122h14v14h-14z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M167 152h14v14h-14z"/>
            <path fill="#748795" fillRule="nonzero" d="M186 127h41v2h-41z"/>
            <path fill="#E7EDEF" fillRule="nonzero" d="M186 130h20v2h-20z"/>
            <path fill="#748795" fillRule="nonzero" d="M186 156h41v2h-41z"/>
            <path fill="#E7EDEF" fillRule="nonzero" d="M186 160h20v2h-20z"/>
            <rect width="13" height="2" x="304" y="129" fill="#748795" fillRule="nonzero" rx="1"/>
            <path fill="#748795" fillRule="nonzero" d="M324.862 128.832l-1.197-1.178L323 127l-.665.654-1.197 1.178a.457.457 0 0 0 0 .654c.183.181.481.181.665 0l.731-.72v3.776c0 .253.209.458.466.458a.462.462 0 0 0 .466-.458v-3.775l.731.72c.184.18.482.18.665 0a.457.457 0 0 0 0-.655z"/>
            <rect width="13" height="2" x="304" y="158" fill="#748795" fillRule="nonzero" rx="1"/>
            <path fill="#748795" fillRule="nonzero" d="M322.138 160.168l1.197 1.178.665.654.665-.654 1.197-1.178a.458.458 0 0 0 .122-.447.476.476 0 0 0-.787-.207l-.731.72v-3.776A.462.462 0 0 0 324 156a.462.462 0 0 0-.466.458v3.762l-.731-.72a.476.476 0 0 0-.665 0 .457.457 0 0 0 0 .655v.013z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M326.903 202H161.097c-2.261-.003-4.094-1.85-4.097-4.13v-18.74c.003-2.28 1.836-4.127 4.097-4.13h165.806c2.261.003 4.094 1.85 4.097 4.13v18.746c-.007 2.277-1.838 4.12-4.097 4.124zm-165.806-26.107c-1.772.004-3.208 1.451-3.211 3.237v18.746c.003 1.786 1.439 3.234 3.21 3.237h165.807c1.772-.003 3.208-1.45 3.211-3.237V179.13c-.003-1.786-1.439-3.233-3.21-3.237H161.096z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M167 182h14v14h-14z"/>
            <path fill="#748795" fillRule="nonzero" d="M186 186h41v2h-41z"/>
            <path fill="#E7EDEF" fillRule="nonzero" d="M186 190h20v2h-20z"/>
            <rect width="13" height="2" x="304" y="188" fill="#748795" fillRule="nonzero" rx="1"/>
            <path fill="#748795" fillRule="nonzero" d="M322.138 190.168l1.197 1.178.665.654.665-.654 1.197-1.178a.457.457 0 0 0 0-.654.476.476 0 0 0-.665 0l-.731.72v-3.776A.462.462 0 0 0 324 186a.462.462 0 0 0-.466.458v3.775l-.731-.72a.476.476 0 0 0-.665 0 .457.457 0 0 0 0 .655z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M326.903 232H161.097c-2.261-.003-4.094-1.851-4.097-4.131V209.13c.003-2.28 1.836-4.128 4.097-4.131h165.806c2.261.003 4.093 1.851 4.097 4.131v18.706a4.156 4.156 0 0 1-1.19 2.94 4.086 4.086 0 0 1-2.907 1.223zm-165.806-26.113c-1.772.004-3.208 1.451-3.211 3.238v18.712c.003 1.787 1.439 3.234 3.21 3.238h165.807c1.772-.004 3.207-1.451 3.21-3.238V209.13c-.003-1.786-1.438-3.234-3.21-3.238l-165.806-.006z"/>
            <path fill="#F5F8F9" fillRule="nonzero" d="M167 212h14v14h-14z"/>
            <path fill="#748795" fillRule="nonzero" d="M186 216h41v2h-41z"/>
            <path fill="#E7EDEF" fillRule="nonzero" d="M186 219h20v2h-20z"/>
            <rect width="13" height="2" x="304" y="218" fill="#748795" fillRule="nonzero" rx="1"/>
            <path fill="#748795" fillRule="nonzero" d="M322.138 219.168l1.197 1.178.665.654.665-.654 1.197-1.178a.458.458 0 0 0 .122-.447.476.476 0 0 0-.787-.207l-.731.72v-3.776A.462.462 0 0 0 324 215a.462.462 0 0 0-.466.458v3.775l-.731-.72a.476.476 0 0 0-.665 0 .457.457 0 0 0 0 .655z"/>
            <path fill="#EAF0F2" fillRule="nonzero" d="M354 127h42v2h-42zM355 142h7v2h-7zM367 142.804h74v1h-74zM367 152.804h74v1h-74zM367 161.804h74v1h-74zM367 156.804h74v1h-74zM367 147.804h74v1h-74zM355 152h7v2h-7zM355 161h7v2h-7zM372 167h7v2h-7zM392 167h7v2h-7zM413 167h7v2h-7zM434 167h7v2h-7z"/>
            <path fill="#657F8E" fillRule="nonzero" d="M354 132h21v2h-21z"/>
            <rect width="2" height="16" x="370" y="146" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="19" x="377" y="143" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="12" x="383" y="150" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="8" x="390" y="154" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="17" x="396" y="145" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="11" x="403" y="151" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="18" x="409" y="144" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <rect width="2" height="16" x="416" y="146" fill="#63FDAD" fillRule="nonzero" rx="1"/>
            <path fill="#EAF0F2" fillRule="nonzero" d="M354 183h42v2h-42zM355 197h7v2h-7zM367 197.804h74v1h-74zM367 207.804h74v1h-74zM367 217.804h74v1h-74zM367 212.804h74v1h-74zM367 202.804h74v1h-74zM355 207h7v2h-7zM355 217h7v2h-7zM372 222h7v2h-7zM392 222h7v2h-7zM413 222h7v2h-7zM434 222h7v2h-7z"/>
            <path fill="#657F8E" fillRule="nonzero" d="M354 188h21v2h-21z"/>
            <path fill="#63FDAD" fillRule="nonzero" d="M370.092 215.998a1.107 1.107 0 0 1-1.036-.779 1.16 1.16 0 0 1 .378-1.265l12.203-9.412c.31-.24.719-.293 1.077-.142l9.553 4.069 12.44-9.6c.327-.25.76-.295 1.128-.116l8.507 4.172 20.258-6.882a1.097 1.097 0 0 1 1.344.74c.185.578-.104 1.204-.655 1.42l-20.672 7.032a1.07 1.07 0 0 1-.814-.052l-8.306-4.075-12.41 9.573c-.309.24-.719.295-1.077.142l-9.553-4.068-11.7 9.056a1.07 1.07 0 0 1-.665.187z"/>
            <path fill="#748795" fillRule="nonzero" d="M177.38 126.417l-1.958-.605.202-.249a.142.142 0 0 0 .045-.103.142.142 0 0 0-.045-.103l-.436-.272a.152.152 0 0 0-.139-.085H172.983l-.569.357a.142.142 0 0 0-.044.2l.202.248-1.959.606a.146.146 0 0 0-.1.127l-.512 5.887a.141.141 0 0 0 .035.106c.026.03.063.048.104.051h1.263v.273c0 .038.016.075.045.102a.155.155 0 0 0 .107.043h4.878a.149.149 0 0 0 .152-.145v-.273h1.263a.149.149 0 0 0 .152-.145l-.505-5.887a.147.147 0 0 0-.108-.127l-.006-.006zm-2.369-1.09l.266.164-.91 1.12-.177-.345a.153.153 0 0 0 .07-.043l.751-.896zm-2.022-.036l.777.92-.195.376-.917-1.12.335-.176zm-1.605 7h-1.105l.038-.436h1.067v.436zm2.44.418h-2.136v-4.402a.149.149 0 0 0-.152-.146.149.149 0 0 0-.152.146v3.258h-1.042l.43-4.9 1.965-.605.764.932c.028.044.077.07.13.07a.153.153 0 0 0 .13-.07l.069-.127-.007 5.844zm.183-6.661l-.67-.794h1.34l-.664.788-.006.006zm3.658 6.256h-1.093v-.436h1.068l.025.436zm-1.1-.733v-3.264a.149.149 0 0 0-.151-.146.149.149 0 0 0-.152.146v4.408h-2.135v-.763h.177a.153.153 0 0 0 .13-.072.14.14 0 0 0 0-.146.153.153 0 0 0-.13-.072h-.177v-1.09h.177a.153.153 0 0 0 .13-.073.14.14 0 0 0 0-.145.153.153 0 0 0-.13-.073h-.177v-1.09h.177a.153.153 0 0 0 .13-.073.14.14 0 0 0 0-.145.153.153 0 0 0-.13-.073h-.177v-1.078h.177a.153.153 0 0 0 .13-.073.14.14 0 0 0 0-.145.153.153 0 0 0-.13-.073h-.177v-.696l.07.127a.152.152 0 0 0 .12.079c.046 0 .09-.02.12-.055l.764-.932 1.965.605.43 4.9-1.03.012z"/>
            <ellipse cx="170" cy="133" fill="#748795" fillRule="nonzero" rx="1" ry="1"/>
            <ellipse cx="178" cy="133" fill="#748795" fillRule="nonzero" rx="1" ry="1"/>
            <path fill="#748795" fillRule="nonzero" d="M176.95 158.538c-.805-.817-.853-2.496-.81-3.376a.16.16 0 0 0-.157-.162h-.877a.154.154 0 0 0-.126.06.167.167 0 0 0-.03.14c.055.203.083.413.084.624v.031a1.749 1.749 0 0 1-2.05 0 2.36 2.36 0 0 1 .078-.624.163.163 0 0 0-.108-.2h-.932a.16.16 0 0 0-.156.163c.042.91 0 2.558-.817 3.37a.166.166 0 0 0-.048.13v3.95a.35.35 0 0 0 .342.356h5.32a.35.35 0 0 0 .336-.356v-3.968a.166.166 0 0 0-.048-.138zm-2.902-2.028c.328 0 .65-.086.937-.25-.12.675-.516 1.161-.991 1.161s-.872-.499-.992-1.18c.326.174.686.268 1.052.275l-.006-.006zm2.632 6.11h-5.313v-3.907c.8-.861.896-2.427.866-3.4h.517a2.75 2.75 0 0 0-.049.505c0 1.067.602 1.934 1.347 1.934s1.346-.867 1.346-1.934c0-.17-.016-.34-.048-.506h.517c0 .967.072 2.54.865 3.401l-.048 3.906zM177.995 193.217l-1.107-4.622a.169.169 0 0 0-.074-.104l-1.664-1.045a2.81 2.81 0 0 0 .318-1.305c0-1.181-.66-2.141-1.468-2.141-.808 0-1.48.96-1.48 2.141 0 .457.109.907.318 1.305l-1.67 1.038a.169.169 0 0 0-.074.105l-1.089 4.628c-.019.078.017.16.086.196l.85.404c.08.037.172 0 .208-.085l.759-2.147v2.245c0 .094.07.17.159.17h3.836c.087 0 .159-.076.159-.17v-2.239l.758 2.141c.032.086.121.13.202.098l.85-.404a.16.16 0 0 0 .104-.077.18.18 0 0 0 .019-.132zm-.924.222l-1.01-2.84a.157.157 0 0 0-.174-.102.167.167 0 0 0-.131.161v2.996h-3.512v-2.996a.166.166 0 0 0-.13-.149.156.156 0 0 0-.17.09l-1.009 2.833-.569-.274 1.04-4.42 1.511-.946v.653a.336.336 0 0 0-.135.367.31.31 0 0 0 .294.238.31.31 0 0 0 .295-.238.336.336 0 0 0-.136-.367v-.985a2.372 2.372 0 0 1-.342-1.306c0-.992.495-1.802 1.131-1.802.612 0 1.157.81 1.157 1.802.007.443-.108.879-.33 1.253a.175.175 0 0 0-.05.118v.946a.337.337 0 0 0-.135.367.31.31 0 0 0 .295.238.31.31 0 0 0 .294-.238.336.336 0 0 0-.135-.367v-.652l1.51.946 1.035 4.42-.594.254z"/>
            <path fill="#748795" fillRule="nonzero" d="M174 185c-.56 0-1 .658-1 1.5s.44 1.5 1 1.5 1-.658 1-1.5-.44-1.5-1-1.5zm0 2.664c-.351 0-.676-.526-.676-1.164 0-.638.298-1.164.676-1.164.378 0 .676.532.676 1.164 0 .632-.325 1.164-.676 1.164zM175.877 191.91c-.47-.144-.538-.745-.538-.752a.175.175 0 0 0-.178-.158h-1.322a.175.175 0 0 0-.177.158s-.062.601-.54.753a.172.172 0 0 0-.122.172v.745c0 .095.08.172.177.172h2.646a.175.175 0 0 0 .177-.172v-.759a.172.172 0 0 0-.123-.158zm-.232.747h-2.29v-.462c.334-.173.567-.484.634-.845h.995c.066.36.3.672.634.845l.027.462zM177 216.738l-.636-1.874v-.692a.169.169 0 0 0-.166-.172h-5.394a.169.169 0 0 0-.166.172v.699l-.636 1.874a.178.178 0 0 0 0 .053v6.03c0 .095.074.172.165.172h2.838c.08.002.15-.057.165-.139l.306-1.676.311 1.676c.016.082.085.14.166.139h2.837a.169.169 0 0 0 .165-.172v-6.03a.17.17 0 0 0 .045-.06zm-4.994-2.369h.146v.377h-.146v-.377zm-1.005 0h.674v.377h-.674v-.377zm-.636 2.481l.566-1.755h.502c-.05.43-.26 1.498-1.024 1.802l-.044-.047zm2.977 3.392l-.445 2.415h-2.545v-5.417c1.069-.324 1.31-1.683 1.361-2.164h1.629v5.166zm0-5.523h-.872v-.35h.872v.35zm2.03-.37h.674v.377h-.675v-.376zm-.484 0h.146v.377h-.146v-.376zm-1.209 0h.872v.377h-.872v-.376zm0 4.296v-3.57h.42v2.752a.88.88 0 0 1-.4.818h-.02zm2.977 4.012h-2.544l-.446-2.415v-1.22c.494-.178.807-.682.757-1.221v-2.732h.866c.05.482.292 1.847 1.361 2.164l.006 5.424zm0-5.78c-.757-.31-.967-1.373-1.024-1.801h.452l.566 1.755.006.046z"/>
            <path fill="#63FDAD" fillRule="nonzero" d="M74 77.008s14.11-.96 17.912 18.664c8.71 11.776 8.088 23.025 8.088 23.025l15 19.885-8.71 9.418-16.798-3.343S75.362 140.476 74 77.007z"/>
            <path fill="#A3A9AF" fillRule="nonzero" d="M106.838 154.08l-14.253.92-.195-.39a3.7 3.7 0 0 1-.076-3.144l12.737-28.875a2.649 2.649 0 0 1 2.602-1.585l11.227.754a3.788 3.788 0 0 1 3.12 2.09l-15.162 30.23z"/>
            <path fill="#C6CCCE" fillRule="nonzero" d="M108.01 154.228L93 155l13.13-30.161c.519-1.188 1.704-1.919 2.97-1.832l12.9.88-13.99 30.34z"/>
            <path fill="#748795" fillRule="nonzero" d="M109.377 125.468c.52.231.754.839.525 1.36l-1.56 3.536a1.026 1.026 0 0 1-1.355.523 1.031 1.031 0 0 1-.525-1.359l1.56-3.536a1.026 1.026 0 0 1 1.355-.524z"/>
            <ellipse cx="108.867" cy="126.646" fill="#FFF" fillRule="nonzero" rx="1" ry="1" transform="rotate(-83.28 108.867 126.646)"/>
            <path fill="#F9D4CC" fillRule="nonzero" d="M115.338 141.656c-3.58-1.936-6.748-4.422-10.364-6.34-2.01-1.058-4.108-1.936-6.225-2.847a3.78 3.78 0 0 0-2.11-.458c-1.07.258-.666 1.653.256 2.511.92.86 2.197 1.485 2.975 2.402-1.438-.762-3.287-.465-3.673.581-.386 1.046.797 2.583 2.34 3.073-1.032.155-1.96.646-2.147 1.453-.186.807.623 1.988 1.787 2.279-.797.2-.728 1.169-.112 1.84a7.33 7.33 0 0 0 2.49 1.498c3.94 1.736 8.204 3.531 12.063 3.338 2.969-.149 5.347-1.582 6.143-3.706.853-2.273-.647-4.1-3.423-5.624z"/>
            <path fill="#748795" fillRule="nonzero" d="M82.404 108.768a36.273 36.273 0 0 0-1.748-20.971C77.223 78.737 69.42 73 60.86 73H42.231c-1.442.114-3.358 1.031-5.412 2.322L26 135.857 30.795 156 86 153.115s-8.09-24.292-3.596-44.347z"/>
            <path fill="#959BB7" fillRule="nonzero" d="M30.103 155.478S20.996 185.391 33.986 316h11.159s-.352-57.276 4.24-69.145c4.591-11.87 7.809-42.002 8.154-49.261.345-7.259 4.667-18.7 4.667-18.7s5.965 61.805 14.822 70.111C77.028 266.664 88.01 316 88.01 316H99s-5.645-35.834-2.126-48.296c3.518-12.462 2.126-34.276-1.418-51.935C91.912 198.111 86.6 153 86.6 153l-56.496 2.478z"/>
            <path fill="#ABB3D6" fillRule="nonzero" d="M99 315.987s-5.69-35.83-2.144-48.292c3.548-12.46 2.144-34.273-1.429-51.93C91.855 198.108 86.5 153 86.5 153l-39.254 1.709-8.378 1.936s-9.548 56.206-1.897 79.135c6.26 18.818 1.897 64.442.126 80.207h7.613s-.354-57.278 4.275-69.146c4.628-11.868 7.872-41.999 8.22-49.257.348-7.258 4.704-18.697 4.704-18.697s14.626 28.554 22.486 60.639C92.254 271.611 87.922 316 87.922 316L99 315.987z"/>
            <path fill="#63FDAD" fillRule="nonzero" d="M33.772 316s-2.359 2.469-1.633 9h26.404s2.227-7.412-3.153-7.586A57.283 57.283 0 0 1 44.991 316h-11.22zM87.772 316s-2.359 2.469-1.633 9h26.404s2.227-7.412-3.153-7.586A57.283 57.283 0 0 1 98.991 316h-11.22z"/>
            <ellipse cx="56" cy="72.5" fill="#748795" fillRule="nonzero" rx="13" ry="6.5"/>
            <path fill="#F9D4CC" fillRule="nonzero" d="M78.612 54.373a1.522 1.522 0 0 1-.361-.791c.036-.253.147-.49.318-.677a12.08 12.08 0 0 0 2.094-5.6c.07-.818.182-1.631.337-2.436a15.65 15.65 0 0 0-4.289-8.555c-4.253-4.222-10.02-6.498-15.965-6.302-2.986.126-6.078.993-8.104 3.12-2.026 2.125-2.761 5.226-3.167 8.174a38.92 38.92 0 0 0 .075 12.586C51.807 65.705 48.565 72.703 60.54 76c1.422-2.234 3.036-4.86 4.445-7.08.105-.196.252-.365.43-.494.286-.137.61-.17.917-.095 1.95.297 4.052 1.43 5.747.468a5.159 5.159 0 0 0 1.708-1.968l3.784-6.276c1.122-1.88 2.263-4.303 1.041-6.182z"/>
            <path fill="#103D60" fillRule="nonzero" d="M75.387 40.316c.578 3.461 2.873 6.374 6.068 7.704 1.671-3.536-.82-7.578-3.186-10.653-2.081-2.715-4.268-5.532-7.317-7.022-2.734-1.339-5.883-1.478-8.908-1.263-1.756.082-3.494.4-5.168.947-5.093 1.762-8.671 6.833-9.597 12.226-.925 5.393.534 11.006 3.143 15.787-1.543.039-2.868 1.127-3.23 2.652.781.502 1.717.69 2.628.53l.1 1.706c1.18.429 2.024-1.194 2.167-2.457.25-2.24.111-4.508-.41-6.7-.727-3.044-2.08-6.593-.137-9.018.826 1.143 3.454 7.837 4.535 7.85 1.08.012 2.64-3.158 3.727-3.726 2.559-1.263 5.9-.632 8.696-1.964 1.571-.79 7.236-4.288 6.889-6.599z"/>
            <path fill="#FFF" fillRule="nonzero" d="M62 57c2.567 5.274 5.843 7.76 9 6.797L62 57z"/>
            <ellipse cx="67.5" cy="52" fill="#103D60" fillRule="nonzero" rx="1.5" ry="3"/>
            <ellipse cx="73" cy="53.5" fill="#103D60" fillRule="nonzero" rx="1" ry="2.5"/>
            <path fill="#103D60" fillRule="nonzero" d="M44.245 112.473c4.12 2.969 9.723 2.737 14.73 1.837 3.32-.625 7.182-1.293 9.698.944.767.688 1.346 1.613 2.276 2.069 1.573.775 3.944.125 4.824 1.638.592 1.018-.056 2.35-.993 3.068-.937.72-2.114 1.038-3.145 1.625-1.031.588-1.943 1.65-1.761 2.813.302 1.956 3.252 2.425 3.906 4.294.383 1.1-.164 2.275-.403 3.413-.497 2.387.403 4.812.71 7.231.309 2.419-.32 5.363-2.59 6.288a7.017 7.017 0 0 1-3.56.143l-13.365-1.78c-6.29-.838-12.667-1.713-18.24-4.645a3.921 3.921 0 0 1-1.496-1.156 4.848 4.848 0 0 1-.579-1.731 15.205 15.205 0 0 0-1.647-4.282c-1.032-1.806-2.434-3.4-3.309-5.294a21.343 21.343 0 0 1-1.434-5.562c-.629-3.707-1.207-7.5-.629-11.188a18.143 18.143 0 0 1 3.592-8.294c1.258-1.656 4.113-5.032 6.503-4.9 2.673.15 1.792 4.462 2.32 6.437a12.581 12.581 0 0 0 4.592 7.032z" opacity=".17"/>
            <path fill="#63E59A" fillRule="nonzero" d="M69.921 153.404L65 154l2.53-15 4.47.818z"/>
            <path fill="#F9D4CC" fillRule="nonzero" d="M72.677 139.808s2.103-4.046 8.703-6.523a77.589 77.589 0 0 0 9.664-4.192s2.047-.56 1.953 1.067c-.094 1.626-6.782 4.954-6.782 4.954s2.323 3.011-.465 6.454c0 2.686.873 8.257-2.907 8.492-3.78.235-11.843.94-11.843.94l1.677-11.192z"/>
            <path fill="#63FDAD" fillRule="nonzero" d="M44.583 136.499c-7.507-9.102-6.881-8.902-12.312-9.102 1.596-8.475-.9-14.332-.9-14.332S48.55 96.298 38.44 74c-6.012 3.377-13.826 10.91-13.826 10.91-14.327 15.135-33.59 40.666-19.964 57.452 20.414 25.16 60.347 12.405 60.347 12.405L68 136.844s-6.006-9.648-23.417-.345z"/>
        </g>
    </g>
</svg>

);
