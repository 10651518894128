import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import queryString from 'query-string';
import { withRouter, Redirect } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import VIEWER from '../../../app/gql/viewer';
import withAddStore from './withAddStore';
import paths from '../../../app/paths';
import AddGreetings from '../components/AddGreetings';
import AddTags from './AddTags';
import ConfigPredefinedAnswers from './ConfigPredefinedAnswers';
import ConfigArtificialIntelligence from './ConfigArtificialIntelligence';
import AllSet from './AllSet';
import FetchingQuestionsComponent from './FetchingQuestionsComponent';
import QuestionsFetched from './QuestionsFetched';
import LinkingAccount from './LinkingAccount';
import LinkingError from './LinkingError';
import NotLinkedAccount from './NotLinkedAccount';
import AddAnotherStore from './AddAnotherStore';

const ASTROPIFYLINK = `${process.env.ASTROSELLING_1}user/meli/astropify/callback`;

class AddStore extends Component {
  constructor(props) {
    super(props);

    let params = queryString.parse(props.location.search);

    this.state = {
      isAdded: false,
      error: false,
      state: params.state,
      code: params.code,
      ready: false,
      steps: -1,
    };
    this.nextStep = this.nextStep.bind(this);
    this.jumpStep = this.jumpStep.bind(this);
    this.changeReady = this.changeReady.bind(this);
  }

  componentDidMount() {
    if (this.state.state && this.state.code) {
      this.props
        .addStore({
          state: this.state.state,
          code: this.state.code,
        })
        .then(res => {
          localStorage.setItem('token', res.data.addStore.token);
          sessionStorage.setItem('sessionToken', res.data.addStore.token);
          const store = res.data.addStore.store;
          if (store) {
            sessionStorage.setItem('store', JSON.stringify(store));
            localStorage.setItem('store', JSON.stringify(store));
          }
          this.props.client.writeData({ data: { isLoggedIn: true } });
          this.setState({ isAdded: true });
        })
        .catch(() => {
          this.setState({ error: true });
        });
    }
  }

  render() {
    const fromAstropify = queryString.parse(this.props.location.search).from_astropify;
    if (fromAstropify) {
      document.location.href = ASTROPIFYLINK + this.props.location.search;
    }
    const isQuestionsSignUp = localStorage.getItem('isQuestionsSignUp');
    return (
      <>
        {(() => {
          if (this.state.error) {
            return <LinkingError me={this.props.me} />;
          } else if (this.state.state && this.state.code && this.state.isAdded) {
            // Testing add ERP in earlier steps.
            if (this.state.steps === 0)
              return this.state.ready ? (
                <QuestionsFetched nextStep={() => (isQuestionsSignUp ? this.props.history.push(paths.PREDEFINED_ANSWERS) : this.props.history.push(paths.SINCRONIZAR))} />
              ) : (
                <FetchingQuestionsComponent changeReady={this.changeReady} />
              );
            else if (this.state.steps === 1) return <ConfigPredefinedAnswers nextStep={this.nextStep} jumpStep={this.jumpStep} />;
            else if (this.state.steps === 2) return <AddGreetings nextStep={this.nextStep} jumpStep={this.jumpStep} />;
            else if (this.state.steps === 3) return <ConfigArtificialIntelligence nextStep={this.nextStep} jumpStep={this.jumpStep} />;
            else if (this.state.steps === 4) return <AddTags nextStep={() => this.props.history.push(paths.QUESTIONS)} jumpStep={() => this.props.history.push(paths.QUESTIONS)} />;
            else return <AllSet me={this.props.me} nextStep={this.nextStep} />;
          } else if (this.state.state && this.state.code && !this.state.isAdded) {
            return <LinkingAccount />;
          } else if (window.location.pathname === paths.ADD_ANOTHER_STORE) {
            return <AddAnotherStore me={this.props.me} />;
          } else {
            return <NotLinkedAccount me={this.props.me} />;
          }
        })()}
      </>
    );
  }

  nextStep() {
    this.setState({
      steps: this.state.steps + 1,
    });
  }

  jumpStep(step) {
    this.setState({
      steps: step,
    });
  }

  changeReady() {
    this.setState({
      ready: true,
    });
  }
}

export default compose(
  withRouter,
  withAddStore,
  withApollo,
  graphql(VIEWER, {
    props({ ownProps, data }) {
      return {
        ...ownProps,
        ...data,
      };
    },
  })
)(AddStore);
