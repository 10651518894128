import addSource from './addStore';
import email from './email';
import login from './login';
import contacts from './meliContact';
import passwordRecovery from './passwordRecovery';
import resetPassword from './resetPassword';
import signup from './signup';

export default {
  addSource,
  email,
  login,
  contacts,
  passwordRecovery,
  resetPassword,
  signup,
};
