import React from 'react';

const Loader = ({ className, style = {} }) => (
  <svg style={style} className={'fill-current ' + className} width="60" height="15" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15">
      <animate attributeName="r" from="0" to="15" begin="0s" dur="1.5s" values="0;13;15;0;0" calcMode="linear" repeatCount="indefinite"/>
    </circle>
    <circle cx="60" cy="15" r="15">
      <animate attributeName="r" from="0" to="15" begin="0.3s" dur="1.5s" values="0;13;15;0;0" calcMode="linear" repeatCount="indefinite"/>
    </circle>
    <circle cx="105" cy="15" r="15">
      <animate attributeName="r" from="0" to="15" begin="0.6s" dur="1.5s" values="0;13;15;0;0" calcMode="linear" repeatCount="indefinite"/>
    </circle>
  </svg>
);

Loader.defaultProps = {
  className: 'text-white',
};

export default Loader;
