import React from 'react';
import { compose, branch, withState } from 'recompose';
import { withApollo } from 'react-apollo';
import Breadcrumb from '../../components/Breadcrumb';

import withGetGuideSizeInfo from './components/EditGuide/withGetGuideSizeInfo';
import MeasurementForm from './components/CreateGuide/MeasurementForm';
import withUserData from './../../app/withUserData';
import withAttributeSelection from './components/CreateGuide/withAttributeSelection';
import withCreateSizeGuide from './components/withCreateSizeGuide';
import withEditSizeGuide from './components/withEditSizeGuide';
import Guides from './components/Guides';
import withAttributesData from './components/withAttributesData';
import withTitleHandler from './components/CreateGuide/withTitleHandler';
import withGuidesData from './withGuidesData';

const SizeGuide = props => {
  const {
    measureAttributes,
    selectedAttributesMap,
    selectedAttributes,
    handleSelectAttribute,
    handleChangeMeasurement,
    submittedIntention,
    measurementValuesMap,
    handleCreateMeasurement,
    onChangeSizeName,
    measureName,
    sizeChart,
    handleGridRowsUpdated,
    storeSizeCharts,
    handleChangeTitle,
    attributesChanged,
    onChangeAttributesChanged,
    onSelectedEditionMode,
    editionMode,
    handleEditSizeGuide
  } = props;

  console.log('attributesChanged', attributesChanged);
    return (
    <section className="w-full px-4 lg:px-14 py-10 flex overflow-x-hidden">
      <div className="md:w-3/4 flex-1">
        <Breadcrumb path={[
          {title: 'Mi tienda', path: '/'},
          {title: 'Guía de Talles', path: '/guia-de-talles'},
          {title: sizeChart ? sizeChart.chartName : '', path: '/'}
        ]}/>

        <h2 className="text-blue-darkest text-2xl mb-2">
          Editar
        </h2>
        { sizeChart && measureAttributes && (
          <div className="bg-white h-96 flex items-center mb-8 overflow-hidden"> 
            <MeasurementForm 
              measureAttributes={measureAttributes}
              selectedAttributesMap={selectedAttributesMap}
              selectedAttributes={selectedAttributes}
              onSelectAttribute={handleSelectAttribute}
              onChangeValueAttribute={handleChangeMeasurement}
              title={sizeChart.chartName}
              submittedIntention={submittedIntention}
              handleCreateMeasurement={handleCreateMeasurement}
              measurementValuesMap={measurementValuesMap}
              onChangeSizeName={onChangeSizeName}
              measureName={measureName}
              sizeChart={sizeChart}
              handleGridRowsUpdated={handleGridRowsUpdated}
              onTitleChange={handleChangeTitle}
              attributesChanged={attributesChanged}
              onChangeAttributesChanged={onChangeAttributesChanged}
              onSelectedEditionMode={onSelectedEditionMode}
              editionMode={editionMode}
              handleEditSizeGuide={handleEditSizeGuide}
            />
          </div>)

        }
        
        <Guides storeSizeCharts={storeSizeCharts}/>
      </div>
    </section>
    );
}
export default compose(
  withApollo,
  withUserData,
  withCreateSizeGuide,
  withAttributesData,
  withEditSizeGuide,
  withUserData,
  branch(({user }) => { 
    const stores = (user.me && user.me.stores) || [];
    const storeSession = sessionStorage.getItem('store');
    const storeFromSession = storeSession ? JSON.parse(storeSession) : null ;
    const store = stores.find(store => store.id === storeFromSession.id);
    return store ? true : false }, withGuidesData),
  withState('selectedAttributes', 'onSelectedAttributes', []),
  withState('selectedAttributesMap', 'onSelectedAttributesMap', {}),
  withState('sizeChart', 'onChangeSizeChart', null),
  branch(({measureAttributes, storeSizeCharts}) => { return measureAttributes && storeSizeCharts && storeSizeCharts.length}, withGetGuideSizeInfo),
  withAttributeSelection,
  withTitleHandler, 
)(SizeGuide);