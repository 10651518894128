import { gql } from 'apollo-boost';

export default gql`
  mutation UpdateListing($input: UpdateListingInput!) {
    updateListing(input: $input) {
      listing {
        id
        meli_id
        site_id
        title
        status
        seller_id
        category_id
        official_store_id
        price
        currency_id
        available_quantity
        sold_quantity
        buying_mode
        listing_type_id
        condition
        visits
        thumbnail
        seller_custom_field
        permalink
      }
    }
  }
`;
