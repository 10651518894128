import React from 'react';
import Button from '../../../components/Button';
import Loader from '../../../components/svgs/Loader';
import SignUpIllustration from '../../../components/svgs/onboarding/SignUpIllustration';

const LinkingAccount = () => {
    return (
        <div className="flex flex-1 items-start justify-center md:items-center">
            <div style={{ minHeight: "540px" }} className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
                <div className="onboarding flex-1">
                    <p style={{ fontSize: "25px" }} className="mb-8 font-bold">Estamos vinculando tu cuenta</p>
                    <p style={{ fontSize: "18px" }} className="description mb-14">
                        Por favor espera mientras vinculamos tu cuenta. <br />
                        Esta operación puede tomar un tiempo.
                    </p>
                    <Button disabled={true}>
                        <Loader />
                    </Button>
                </div>
                <div className="flex-1 ml-24 hidden xl:block">
                    <SignUpIllustration />
                </div>
            </div>
        </div>
    )
}

export default LinkingAccount;
