import { graphql } from 'react-apollo';
import PREDEFINED_ANSWERS from '../gql/predefinedAnswers';

export default graphql(
  PREDEFINED_ANSWERS,
  {
    props({ data }) {
      let tags = {};
      if (data.tags) {
        data.tags.forEach(tag => tags[tag.id] = { ...tag, isNew: false });
      }
      return {
        greetings: (data.greetings || {}),
        predefinedAnswers: (data.predefinedAnswers || []),
        tags,
        loadingData: data.loading
      };
    },
  },
);
