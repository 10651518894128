import React from 'react';
import { compose } from 'recompose';
import SizeGuideCreateMeasure from './SizeGuideCreateMeasure';
import SizeEditSizesTable from './SizeEditSizesTable';
import withSelectEditionMode from './withSelectEditionMode';
import ArrowRightIcon from '../../../../components/svgs/ArrowRight';



const inputClassName = (valid, submittedIntention) => {
  const className = "mr-7 w-13 py-3 px-1 border-2 border-dashed rounded-lg text-center ";
  return !valid && submittedIntention ? className + 'border-red text-red' : className + 'border-blue text-blue';
}

const SizeGuideCreateTable = props => {
  const {
    selectedAttributes,
    onChangeValueAttribute,
    measurementValuesMap,
    submittedIntention,
    handleCreateMeasurement,
    onChangeSizeName,
    measureName,
    sizeChart,
    onSelectedEditionMode,
    editionMode,
    handleGridRowsUpdated,
    handleEditSizeGuide,
    attributesChanged
  } = props;
  const chartSizesLength = sizeChart && sizeChart.sizes ? sizeChart.sizes.length : 0; 
  const classNameCrearMeasure = !editionMode ? "py-1 pr-3 border-r border-solid inline text-sm font-bold text-blue" :
    "py-1 pr-3 border-r border-solid inline text-sm";
  const classNameEditSize = editionMode ? "cursor-pointer select-none inline-block font-bold text-blue" :
  "cursor-pointer select-none inline-block";
  return (
    <div className="text-blue-darkest flex flex-col h-full">
    <div className="flex items-baseline justify-between">
      <div>
        <h3 className="text-2xl font-normal mb-1">
          Tabla de talles
        </h3>
        <h5 className="text-sm font-normal">
          Creá tus talles
        </h5>
      </div>

      <div>
        <div className={classNameCrearMeasure} onClick={()=> onSelectedEditionMode(false)} >
          <span className="cursor-pointer select-none">
            Creación de talle
          </span>
        </div>
        <div className="pl-3 inline text-sm" onClick={()=> chartSizesLength > 0 && onSelectedEditionMode(true)} >
          <span className={classNameEditSize}>
            <b>{sizeChart && sizeChart.sizes ? sizeChart.sizes.length + ' ' : '0 ' }</b> 
             talle{ chartSizesLength !== 1 ? 's' : '' } guardado{
              chartSizesLength != 1 ? 's' : ''
            }
          </span>
        </div>
      </div>
    </div>
    {
      ! editionMode ?
      <SizeGuideCreateMeasure
        selectedAttributes={selectedAttributes} 
        onChangeValueAttribute={onChangeValueAttribute}
        submittedIntention={submittedIntention}
        measurementValuesMap={measurementValuesMap}
        handleCreateMeasurement={handleCreateMeasurement}
        onChangeSizeName={onChangeSizeName}
        sizeChart={sizeChart}
        measureName={measureName}/> :
      <SizeEditSizesTable
        attributesChanged={attributesChanged}
        sizeChart={sizeChart}
        handleGridRowsUpdated={handleGridRowsUpdated}
        handleEditSizeGuide={handleEditSizeGuide}
      />
    }
    {
      sizeChart && (
      <button className="text-blue self-end font-medium" onClick={handleEditSizeGuide}>
        <ArrowRightIcon className="mr-2"></ArrowRightIcon>
        guardar guía
      </button>
      )
    }        
  </div>
)};

export default SizeGuideCreateTable;