import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { CSSTransition } from 'react-transition-group';

import Header from './Header';
import withPredefinedAnswers from '../withPredefinedAnswers';
import withUpdateGreetings from './withUpdateGreetings';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loader from '../../../../components/svgs/Loader';
import EventService from '../../../../app/EventService';

class Greetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      greetings: props.greetings,
      visible: false,
      selected: null,
      loading: false,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.onSelected = this.onSelected.bind(this);
    this.notSelected = this.notSelected.bind(this);
    this.escButtonPressed = this.escButtonPressed.bind(this);
    this.labels = {
      greeting: 'Saludo',
      farewell: 'Despedida',
    };
  }

  componentDidMount() {
    this.setState({ visible: true });
    document.addEventListener('keydown', this.escButtonPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escButtonPressed, false);
  }

  UNSAFE_componentWillReceiveProps({ greetings }) {
    if (greetings) {
      this.setState({ greetings });
    }
  }

  saveHandler() {
    const message = isSuccessful => {
      const textMessage = isSuccessful ? 'Cambios guardados' : 'Ocurrió un error, inténtelo nuevamente';
      return (
        <div className="success-spanMessage">
          <i style={{ fontSize: '20px' }} className="fa fa-check-circle mr-3" aria-hidden="true"></i>
          <span>{textMessage}</span>
        </div>
      );
    };
    const { greeting, farewell } = this.state.greetings;
    this.setState({ loading: true });
    this.props
      .updateGreetings({ greeting, farewell })
      .then(() => {
        this.setState({ loading: false });
        EventService.triggerEvent('toast', [message(true), 'success']);
      })
      .catch(() => {
        this.setState({ loading: false });
        EventService.triggerEvent('toast', [message(false), 'error']);
      });
  }

  changeHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      greetings: {
        ...this.state.greetings,
        [name]: value,
      },
    });
  }

  onSelected(id) {
    this.setState({ selected: id });
  }

  notSelected() {
    this.setState({ selected: null });
  }

  escButtonPressed(event) {
    if (event.keyCode === 27) {
      event.target.blur();
    }
  }

  render() {
    const { greetings } = this.state;
    const { setShowing } = this.props;
    return (
      <CSSTransition
        in={this.state.visible}
        timeout={300}
        classNames="card"
        unmountOnExit
        // onEnter={() => this.setState({ showButton: false })}
        onExited={() => setShowing(0)}
      >
        <div className="predefined-answers-section predefined-answers-section--greetings">
          <Header title="Saludo y despedida" backHandler={() => this.setState({ visible: false })} />
          <PerfectScrollbar>
            <div className="predefined-answers__body">
              <p className="predefined-answers__description">Definí tu saludo y despedida por defecto para insertarlos más rápido en tus respuestas.</p>
              {Object.keys(this.labels).map(greet => (
                <div key={greet} className="predefined-answers-item">
                  <label htmlFor={`greet-${greet}`} className="predefined-answers-item__label">
                    <strong>/ {this.labels[greet]}</strong>
                  </label>
                  <textarea
                    id={`greet-${greet}`}
                    className={this.state.selected === greet ? 'predefined-answers-item__input selected' : 'predefined-answers-item__input'}
                    name={greet}
                    value={greetings[greet] || ''}
                    onChange={this.changeHandler}
                    onFocus={() => this.onSelected(greet)}
                    onBlur={this.notSelected}
                  />
                </div>
              ))}
            </div>
          </PerfectScrollbar>
          <div className="predefined-answers__footer flex">
            <button onClick={this.saveHandler} className="btn">
              {this.state.loading ? <Loader /> : 'Guardar cambios'}
            </button>
            <button className="mr-0 btn-cancel" onClick={() => this.setState({ visible: false })}>
              Cancelar
            </button>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default compose(
  withPredefinedAnswers,
  withUpdateGreetings
)(Greetings);
