import React, { Component } from 'react'
import paths from '../../../app/paths';
import AddTags from './AddTags';
import ConfigArtificialIntelligence from './ConfigArtificialIntelligence';

class FastAnswers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      steps: 0
    }
    this.nextStep = this.nextStep.bind(this);
    this.jumpStep = this.jumpStep.bind(this);
  }

  render() {
    return (
      <>
        {(() => {
            if (this.state.steps === 0) return <ConfigArtificialIntelligence nextStep={this.nextStep} jumpStep={() => this.props.history.push(paths.QUESTIONS)} />;
            else if (this.state.steps === 1) return <AddTags nextStep={() => this.props.history.push(paths.QUESTIONS)} jumpStep={() => this.props.history.push(paths.QUESTIONS)} />;
        })()}
      </>
    );
  }

  nextStep() {
    this.setState({
      steps: this.state.steps + 1
    })
  }

  jumpStep(step) {
    this.setState({
      steps: step
    })
  }

}

export default FastAnswers;
