import { gql } from 'apollo-boost';

export default gql`
mutation createSizeChart($input:  CreateSizeChartInput!) {
  createSizeChart(input: $input) {
    sizeChart{
      id
      chartName
      linked_items_quantity
      columns {
        id
        name
        type {
          default_unit
        }
      }
      sizes {
        sizeName
        measurements {
          id
          value
        }
      }
    }
  }
}

`;


