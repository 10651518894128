import { graphql } from 'react-apollo';

import SELECT_STORE from './gql/selectStore';

export default graphql(SELECT_STORE, {
  // options: {
  //   notifyOnNetworkStatusChange: true
  // },
  props: ({ mutate }) => ({
    selectStore: (store_id) => mutate({
      variables: { input: { store_id } },
    })
  }),
});
