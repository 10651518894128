import React from 'react';
import YouTube from 'react-youtube';

const Tutorial = () => {
    const opts = {
        width: '100%',
        height: '100%',
        playerVars: {
            autoplay: 1
        }
    };

    return (
        <YouTube
            containerClassName="h-full"
            videoId="4myZTIskJzo"
            opts={opts}
        />
    )
}

export default Tutorial;
