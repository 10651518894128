import React from 'react';

export default ({ className }) => (
  <svg
    className={"fill-current " + className}
    xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11"
  >
    <path fill="#2B48F0" fillRule="evenodd" d="M5.527 11L7 9.482 2.983 5.527l3.93-4.043L5.402 0 0 5.562z"/>
  </svg>
);
