import { graphql } from 'react-apollo';

import GUIDESIZE from './../../gql/guideSize';

export default graphql(
  GUIDESIZE,
  { 
    options: ({match}) => { 
    return { 
      notifyOnNetworkStatusChange: true,
      variables: {
        size_chart_id: +match.params.sizeGuideId
      }, 
      }
    },
    props({ ownProps, data }) {
      const selectedAttributes = [];
      const selectedAttributesMap = {};
      if (data.sizeChart) {
        const columns = data.sizeChart.columns;
        for (let index in columns) {
          selectedAttributesMap[columns[index].id]=true;
        }
        for (let index in ownProps.measureAttributes) {
          if (selectedAttributesMap[ownProps.measureAttributes[index].id]) {
            selectedAttributes.push(ownProps.measureAttributes[index]);
          }
        }
      }
      const title = data.sizeChart?  data.sizeChart.chartName : '';
      return {sizeChart: data.sizeChart, selectedAttributesMap: selectedAttributesMap, selectedAttributes: selectedAttributes, title: title };
    },
  }
)
