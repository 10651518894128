import React from 'react';
import statistics from '../../../assets/images/EmptyState/statistics.png';
import floorStats from '../../../assets/images/EmptyState/floorStats.png';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-apollo';
import paths from '../../../app/paths';

const EmptyStateStats = (props) => {
    return (
        <div className="flex flex-1 flex-col justify-between">
            <div style={{ marginLeft: '6%', marginTop: '3%' }} className="flex flex-col items-start">
                <span className="font-bold" style={{ fontSize: '33px' }}>¡Estás al día! No tienes <br /> preguntas sin responder</span>
                {/* <span className="my-5 text-md">
                    Mientras tanto, mirá tus estadísticas y tiempos promedio <br />
                    de respuesta para saber como vender más.
                </span>
                <a
                    onClick={() => props.history.push(paths.QUESTIONS_STATS)}
                    style={{
                        borderRadius: '50px',
                        WebkitBoxShadow: '2px 15px 45px -6px rgba(43,72,240,1)',
                        MozBoxShadow: '0px 10px 40px -6px rgba(43,72,240,1)',
                        boxShadow: '0px 10px 40px -6px rgba(43,72,240,1)',
                        padding: '10px 24px'
                    }}
                    className="text-white text-md font-semibold cursor-pointer mt-1 bg-vivid-blue flex items-center">
                    Ver estadísticas
                </a> */}
            </div>
            <img className="absolute" style={{ height: 'auto', bottom: '0', right: '0' }} src={floorStats} alt="floor" />
            <img className="absolute" style={{ maxWidth: '57%', maxHeight: '57%', bottom: '6%', right: '5%' }} src={statistics} alt="statistics" />
        </div>
    )
}

export default compose(
    withRouter
)(EmptyStateStats);
