import { compose, withState, withHandlers } from 'recompose';
import STEPS from './stepsHelper';
import ATTRIBUTES from '../../gql/attributes';

export default compose(
  withState('step', 'onChangeStep', STEPS.firstScreen),
  // Todo change this from here
  withState('loadingAttributes', 'onLoadingAttributes', false),
  withState('measureAttributes', 'onChangeMeasureAttributes', []),
  withHandlers({
    handleStartCreation: ({ onChangeStep }) => () => {
      onChangeStep(STEPS.secondScreen); 
    },
    handleTitleSubmitted: ( {onLoadingAttributes, onChangeStep, onChangeMeasureAttributes, client}) => () => {
      onLoadingAttributes(true);
      client.query({
        query: ATTRIBUTES,
        options: {
          notifyOnNetworkStatusChange: true,
        },
        variables: {
          site_id: 'MLA'
        }, 
      }
      ).then(({data})=> {
        onChangeStep(STEPS.thirdScreen);
        onChangeMeasureAttributes(data.measurementAttributes);
      })
    },
  }),
);
