import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from './Stepper';

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: '500px',
  },
  title: {
    fontWeight: 'bold',
    fontFamily: 'Arboria',
    fontSize: '21px',
    color: '#192354',
  },
  confirmButton: {
    color: 'white',
    fontFamily: 'Arboria',
    backgroundColor: '#2b48f0',
    padding: '0.5rem 2rem 0.5rem 2rem',
    '&:hover': {
      backgroundColor: '#192354',
    },
    textTransform: 'none',
  },
  cancelButton: {
    color: '#2b48f0',
    fontFamily: 'Arboria',
    padding: '0.5rem 2rem 0.5rem 2rem',
    textTransform: 'none',
  },
  linkButton: {
    color: '#2b48f0',
    textTransform: 'none',
  },
  dialog: {
    margin: '1rem 1rem 0.5rem 1rem',
  },
}));

export default function DialogSelect(props) {
  const { quantity, product, handleRefreshTable } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: false,
  });

  function handleClickOpen() {
    setState({ ...state, open: true });
  }

  function handleClose() {
    setState({ ...state, open: false });
  }
  // const linkExistText = quantity === 1 ? ' vínculo' : ' vínculos';
  return (
    <div>
      <Button className={classes.linkButton} onClick={handleClickOpen}>
        {quantity > 0 ? 'Editar configuración' : 'Vincular'}
      </Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={state.open} onClose={handleClose}>
        <DialogTitle disableTypography={true} className={classes.title}>
          Publicaciones
        </DialogTitle>
        <DialogContent className={classes.container}>
          <Stepper closeModal={handleClose} refreshTable={handleRefreshTable} product={product} hasLinks={product && product.link_count > 0} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
