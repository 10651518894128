import { ApolloClient, InMemoryCache } from 'apollo-boost';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import paths from '../app/paths';

const cache = new InMemoryCache();
const httpLink = createHttpLink({ uri: process.env.API_ENDPOINT });

const authLink = setContext((_, { headers }) => {
  const localStorageToken = localStorage.getItem('token');
  const sessionStorageToken = sessionStorage.getItem('sessionToken');

  const token = sessionStorageToken ? sessionStorageToken : localStorageToken;

  if (!sessionStorageToken) {
    sessionStorage.setItem('store', localStorage.getItem('store'));
    sessionStorage.setItem('sessionToken', localStorageToken);
  }
  return {
    headers: { ...headers, Authorization: token ? `Bearer ${token}` : '' },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors.find(({ message }) => message === 'Unauthorized')) {
      cache.writeData({ data: { isLoggedIn: false, loginEmail: '' } });

      if (sessionStorage.getItem('sessionToken')) {
        document.location.href = paths.LOGOUT;
      }
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
  resolvers: {
    Query: {
      loginEmail: () => '',
      isLoggedIn: () => !!localStorage.getItem('token'),
    },
  },
});

export default client;
