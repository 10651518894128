import React, { useState } from 'react';

import { withApollo } from 'react-apollo';
import { compose } from 'recompose';
import { Form, withFormik } from 'formik';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import PhoneInput from './PhoneInput';
import Loader from '../../../components/svgs/Loader';
import paths from '../../../app/paths';
import FormField from '../../../components/FormField';
import { Helmet } from 'react-helmet';
import Button from '../../../components/Button';
import withSignup from './withSignup';
import ArrowRightIcon from '../../../components/svgs/ArrowRight';

const SignUp = props => {
  const { values, touched, errors, handleChange, handleBlur, isSubmitting, setFieldValue } = props;
  return (
    <div className="onboarding flex-1">
      {/* SEO Info */}
      <Helmet>
        <title>Registrate en Astroselling</title>
        {/* Todo: add meta info */}
        {/* <meta name="description" content="Nested component" /> */}
      </Helmet>
      {/* SEO Info End */}
      <h1 className="mb-2">Crea tu nueva cuenta, ¡gratis!</h1>
      <p className="description mb-2">
        Gestiona tus tiendas de Mercado Libre en la plataforma más moderna, intuitiva y genial. ¿Ya tienes una cuenta? <Link to={paths.LOGIN}>Ingresar</Link>
      </p>
      <Form className="py-9 mb-4">
        <FormField
          label="Nombre completo"
          name="name"
          value={values.name}
          changeHandler={handleChange}
          blurHandler={handleBlur}
          status={touched.name && (errors.name ? 'error' : 'success')}
          errorMsg={touched.name && errors.name}
        />
        <FormField
          label="Email"
          type="email"
          name="email"
          value={values.email}
          changeHandler={handleChange}
          blurHandler={handleBlur}
          status={touched.email && (errors.email ? 'error' : 'success')}
          errorMsg={touched.email && errors.email}
        />
        <FormField
          label="Clave"
          type="password"
          name="password"
          value={values.password}
          changeHandler={handleChange}
          blurHandler={handleBlur}
          status={touched.password && (errors.password ? 'error' : 'success')}
          errorMsg={touched.password && errors.password}
        />
        <PhoneInput
          value={values.phone}
          changeHandler={val => {
            setFieldValue('phone', val);
          }}
        />
        <div className="flex flex-wrap items-center justify-between mt-8">
          <Button id="registro" type="submit" disabled={!!Object.keys(errors).length || isSubmitting}>
            {isSubmitting ? (
              <Loader />
            ) : (
              <>
                Siguiente <ArrowRightIcon />
              </>
            )}
          </Button>
          {/* <p className="description mt-2 md:mt-0 md:w-1/2">
            Al crear tu cuenta, aceptas los <Link to="/" target="_blank">Términos y Condiciones</Link> de Astroselling.
          </p> */}
        </div>
      </Form>
    </div>
  );
};

export default compose(
  withApollo,
  withSignup,
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      email: '',
      password: '',
    }),
    validate: values => {
      const errors = {};
      if (!values.name) {
        errors.name = 'Nombre requerido';
      }
      if (!values.email) {
        errors.email = 'Email requerido';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Email inválido';
      }
      if (!values.password) {
        errors.password = 'Clave requerida';
      } else if (values.password.length < 6) {
        errors.password = 'Clave demasiado corta';
      }
      return errors;
    },
    handleSubmit: ({ email, name, password, phone }, { props, setSubmitting, setFieldError }) => {
      const trackingInfo = Cookies.get('tracking_info');
      const { isQuestionsSignUp } = props;
      props
        .signup({
          name,
          email,
          password,
          phone,
          trackingInfo,
        })
        .then(({ data }) => {
          localStorage.setItem('token', data.signup.token);
          if (isQuestionsSignUp) {
            localStorage.setItem('isQuestionsSignUp', true);
          }
          sessionStorage.setItem('sessionToken', data.signup.token);
          props.client.writeData({ data: { isLoggedIn: true } });

          gaSetUserId(email); //GetResponse

          data.signup.redirect_url
            ? document.location.href = data.signup.redirect_url
            : props.history.push(paths.ADD_STORE);
        })
        .catch(err => {
          // dataLayer.push({'event': 'registro-no-validacion'}); //Log de evento para google tag manager
          const errorCode = err.graphQLErrors && err.graphQLErrors[0].message;
          let errorMsg;
          let field;

          switch (errorCode) {
            case 'validation':
              field = 'email';
              props.client.writeData({ data: { loginEmail: email } });
              errorMsg = err.graphQLErrors[0].validation['input.email'][0];
              break;
            default:
              errorMsg = 'Ha ocurrido un error. Inténtelo nuevamente.';
              break;
          }

          if (field) {
            setFieldError(field, errorMsg);
          }
        })
        .finally(() => setSubmitting(false));
    },
  })
)(SignUp);
