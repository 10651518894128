import { graphql } from 'react-apollo';
import CREATE_ANSWER from '../../gql/createPredefinedAnswer';

export default graphql(
  CREATE_ANSWER,
  {
    props: ({ mutate }) => ({
      createPredefinedAnswer: ({ slug, answer }) => mutate({
        variables: {
          input: {
            slug,
            answer,
          }
        },
        refetchQueries: [ 'PredefinedAnswers' ]
      })
    })
  },
);
