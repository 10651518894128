import React, { Component } from 'react';
import Button from '../../../components/Button';
import ArrowRightIcon from '../../../components/svgs/ArrowRight';
import SignUpIllustration from '../../../components/svgs/onboarding/SignUpIllustration';
import paths from '../../../app/paths';

export default class ConfigurationConfirm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className="flex flex-1 items-start justify-center md:items-center">
            <div className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
                <div className="onboarding flex-1">
                  <SignUpIllustration />
                </div>
                <div className="flex-1 ml-24 hidden xl:block">
                    <h1 className="mb-8"> Todo Listo!</h1>
                    <p className="description mb-12">
                        Tu cuenta está configurada y ya estamos listos para comenzar. 
                        Bienvenid@ a Astroselling!
                    </p>
                    <Button className="self-end" onClick={() => this.props.history.push(paths.QUESTIONS) }>
                        Finalizar
                    </Button>
                </div>
            </div>
         </div>
        )
    }
};