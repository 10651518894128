import React, {Component} from 'react';
import { compose, withState } from 'recompose';
import { withApollo } from 'react-apollo';

import STEPS from './stepsHelper';
import StartScreen from './StartScreen';
import TitleForm from './TitleForm';
import MeasurementForm from './MeasurementForm';
import withGuideStateHandler from './withGuideStateHandler';
import withTitleHandler from './withTitleHandler';
import withUserData from './../../../../app/withUserData';
import withAttributeSelection from './withAttributeSelection';
import withCreateSizeGuide from '../withCreateSizeGuide';
import withEditSizeGuide from '../withEditSizeGuide';

const getScreen = (props) => {
  const {
    handleStartCreation,
    handleChangeTitle,
    handleTitleSubmitted,
    title,
    step, 
    loadingAttributes, 
    measureAttributes,
    selectedAttributesMap,
    selectedAttributes,
    handleSelectAttribute,
    handleChangeMeasurement,
    submittedIntention,
    measurementValuesMap,
    handleCreateMeasurement,
    onChangeSizeName,
    measureName,
    sizeChart,
    handleGridRowsUpdated,
    handleEditSizeGuide,
    attributesChanged,
    onChangeAttributesChanged,
    onSelectedEditionMode,
    editionMode,
  } = props;
  switch (step) {
    case STEPS.firstScreen:
      return <StartScreen onStartCreation={handleStartCreation}/>
    case STEPS.secondScreen:
      return <TitleForm
               title={title}
               onTitleChange={handleChangeTitle}
               onTitleSubmitted={handleTitleSubmitted}
               loadingAttributes={loadingAttributes}
               />
    case STEPS.thirdScreen:
      return <MeasurementForm 
                measureAttributes={measureAttributes}
                selectedAttributesMap={selectedAttributesMap}
                selectedAttributes={selectedAttributes}
                onSelectAttribute={handleSelectAttribute}
                onChangeValueAttribute={handleChangeMeasurement}
                title={title}
                onTitleChange={handleChangeTitle}
                submittedIntention={submittedIntention}
                handleCreateMeasurement={handleCreateMeasurement}
                measurementValuesMap={measurementValuesMap}
                onChangeSizeName={onChangeSizeName}
                measureName={measureName}
                sizeChart={sizeChart}
                handleGridRowsUpdated={handleGridRowsUpdated}
                handleEditSizeGuide={handleEditSizeGuide}
                attributesChanged={attributesChanged}
                onChangeAttributesChanged={onChangeAttributesChanged}
                onSelectedEditionMode={onSelectedEditionMode}
                editionMode={editionMode}
              />;
    default:
      return null;
  }
}

class SizeGuide extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="bg-white h-96 flex items-center mb-8 overflow-hidden"> 
        {
          getScreen(this.props)
        }
      </div>
    );
  }
}

export default compose(
  withApollo,
  withGuideStateHandler,
  withTitleHandler, 
  withUserData,
  withCreateSizeGuide,
  withEditSizeGuide,
  withState('sizeChart', 'onChangeSizeChart', null),
  withState('selectedAttributes', 'onSelectedAttributes', []),
  withState('selectedAttributesMap', 'onSelectedAttributesMap', {}),
  withAttributeSelection
)(SizeGuide);
