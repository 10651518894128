import React from 'react';

import AttributeCheckbok from '../AttributeCheckbox';
import SizeGuideCreateTable from './SizeGuideCreateTable';


const MeasurementForm = props => {
  const {
    measureAttributes,
    onSelectAttribute,
    selectedAttributes,
    selectedAttributesMap,
    title,
    onChangeValueAttribute,
    measurementValuesMap,
    submittedIntention,
    handleCreateMeasurement,
    onChangeSizeName,
    measureName,
    sizeChart,
    handleGridRowsUpdated,
    onTitleChange,
    handleEditSizeGuide,
    attributesChanged,
    onSelectedEditionMode,
    editionMode,
  } = props;
  return (
    <div className="w-full h-full flex">
      <div className="h-full border-r border-solid px-5 py-4 transition-property-width w-1/5 transition">
        <div className="px-9 h-full flex flex-col justify-center">
          <h5 className="text-sm text-blue-darkest font-medium">
            Título
          </h5>
          <h3 className="text-blue-darkest text-2xl mb-5 font-normal">
            <input
                type="text"
                value={title}
                onChange={(event) => { onTitleChange(event.target.value) }}
                placeholder="Escribí un título para la guía. Ej: Pantalones, Remeras…"
                className="pb-2 text-blue border-b border-solid w-full"
              />
          </h3>
        </div>
      </div>
        {/* Todo: Poner Transitions */}
      <div className="w-2/5 border-r border-solid px-9 py-7 mb-1">
        <h3 className="text-blue-darkest text-2xl font-normal mb-1">
          Atributos
        </h3>
        <h5 className="text-blue-darkest text-sm font-normal mb-10">
          Seleccioná los atributos que apliquen
        </h5>
        <div className="flex flex-wrap">
          {
            measureAttributes && measureAttributes.map(node => (
              <AttributeCheckbok 
                key={node.id}  
                checked={selectedAttributesMap[node.id]} 
                clickHandler={()=> {onSelectAttribute(node)}}
                node={node}
              />))
          }
        </div>
      </div>
      <div className={selectedAttributes.length ? "h-full w-2/5 px-9 py-7" : "flex h-full w-2/5 px-9 py-7 flex justify-center items-center"} >
          {/* Todo: Pick the correct grey */}
          {
            selectedAttributes && selectedAttributes.length ? ( 
              <SizeGuideCreateTable 
                attributesChanged={attributesChanged}
                selectedAttributes={selectedAttributes} 
                onChangeValueAttribute={onChangeValueAttribute}
                submittedIntention={submittedIntention}
                measurementValuesMap={measurementValuesMap}
                handleCreateMeasurement={handleCreateMeasurement}
                onChangeSizeName={onChangeSizeName}
                measureName={measureName}
                sizeChart={sizeChart}
                handleGridRowsUpdated={handleGridRowsUpdated}
                handleEditSizeGuide={handleEditSizeGuide}
                onSelectedEditionMode={onSelectedEditionMode}
                editionMode={editionMode}
              />
            ) :
            (
              <span className="w-2/5 text-center text-dark-grey">
                Seleccioná atributos para crear la tabla de talles
              </span>
            )
          } 
          </div>
    </div>
)};


export default MeasurementForm;