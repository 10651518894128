import React from 'react';

const getInitials = (name) => {
  return name.split(' ')
  .slice(0, 2)
  .map(word => word ? word.trim().charAt(0) : '')
  .join('')
  .toUpperCase();
};


export default ({ name, size = 28 }) => {
  const style = {
    span: {
      borderRadius: '50%',
      display: 'inline-block',
      fontSize: (size / 2 - 2) + 'px',
      height: size + 'px',
      lineHeight: size + 'px',
      marginRight: '10px',
      textAlign: 'center',
      width: size + 'px',
      fontWeight: 'bold',
    }
  };
  return <span style={style.span} className="bg-vivid-blue rounded-full text-white">{getInitials(name)}</span>;
};
