import { gql } from 'apollo-boost';

export default gql`
mutation AddTag ($tag: tagEnum!, $response: String!) {
  addTag (input: {tag: $tag, response: $response}) {
    tag {
      tag
      label
      response
    }
  }
}
`;
