import { gql } from 'apollo-boost';

export default gql`
mutation UpdateTag ($input: TagInput!) {
  updateTag (input: $input) {
    tag {
      id: tag
      tag
      label
      response
    }
  }
}
`;
