import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';

import DropDown from '../components/DropDown';
import ChevronDown from '../components/svgs/ChevronDown';

import Store from '../assets/images/menu/mi-tienda.png';
import Messages from '../assets/images/menu/preguntas.png';
import Listings from '../assets/images/menu/publicaciones.png';
import Orders from '../assets/images/menu/rdenes.png';
import Avatar from '../components/Avatar';
import Loader from '../components/svgs/Loader';
import Bag from '../components/svgs/Bag';
import Help from '../components/svgs/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import paths from './paths';
import Redirect from 'react-router-dom/Redirect';
import crm from '../assets/images/menu/crm.png';
import premium from '../assets/images/menu/premium.png';
import free from '../assets/images/menu/Free.png';

const ASTROSELLING_1 = process.env.ASTROSELLING_1;
const ASTROSELLING_3 = process.env.ASTROSELLING_3;

class UserMenu extends PureComponent {
  constructor (props) {
    super(props);
  }

  render () {
    const { me, isFreeUser } = this.props;

    let stores = (me && me.stores) || [];
    if (me && !stores.length && this.props.location && this.props.location.pathname !== paths.ADD_STORE) {
      return <Redirect to={paths.ADD_STORE}/>;
    }
    const storeSession = sessionStorage.getItem('store');
    const storeFromSession = storeSession ? JSON.parse(storeSession) : null;
    const store = me && !!stores.length && storeFromSession ? stores.find(store => store.id === storeFromSession.id) : null;
    // let addStore = {
    //   id: 'addAnotherStore',
    //   name: 'Vincular otra tienda',
    //   className: ' border-t text-vivid-blue hover:bg-vivid-blue hover:text-white',
    // };
    // stores = [...stores, addStore];

    return (
      <>
        {me && !!stores.length && (
          <div className="flex">
            <div className="py-6 py-6 px-9 border-l border-r border-solid border-blue-grey">
              <div className="-mx-6 flex">
                <NavLink to={paths.QUESTIONS} className="section-link relative">
                  <img src={Messages} className="mr-2" alt=""/>
                  Preguntas {store && !!store.pendingQuestions &&
                <sup className="dot" style={{ top: '-6px' }}><span style={{ width: '3px', height: '3px', borderRadius: '50%', background: 'white' }}/></sup>}
                </NavLink>

                <Tooltip TransitionComponent={Zoom} title='Ir al Panel de Administración' >
                  <a href={`${ASTROSELLING_3}admin/`} className="section-link cursor-pointer relative" target="_blank" >
                    <img src={Store} className="mr-2" alt=""/>
                    Panel Astroselling
                  </a>
                </Tooltip>

                {/* {isFreeUser ? (
                  <a href={`${ASTROSELLING_3}admin/resources/listings`} className="section-link cursor-pointer relative">
                    <img src={Listings} className="mr-2" alt="Publicaciones"/>
                    Publicaciones
                    <img
                      className={isFreeUser ? '' : 'hidden'}
                      style={{
                        width: '16px',
                        position: 'absolute',
                        right: '4px',
                        top: '-7px',
                      }}
                      src={free}
                      alt="free"
                    />
                  </a>
                ) : (
                  <a href={`${ASTROSELLING_1}admin/listing`} className="section-link cursor-pointer relative">
                    <img src={Listings} className="mr-2" alt="Publicaciones"/>
                    Publicaciones
                    <img
                      className={isFreeUser ? '' : 'hidden'}
                      style={{
                        width: '16px',
                        position: 'absolute',
                        right: '4px',
                        top: '-7px',
                      }}
                      src={free}
                      alt="free"
                    />
                  </a>
                )} */}

                {/* {isFreeUser ? (
                  <a href={`${ASTROSELLING_3}admin/resources/meli-contacts`} className="section-link cursor-pointer relative">
                    <img style={{ width: '17px' }} src={crm} className="mr-2" alt=""/>
                    Seguir clientes
                    <img
                      className={isFreeUser ? '' : 'hidden'}
                      style={{
                        width: '16px',
                        position: 'absolute',
                        right: '4px',
                        top: '-7px',
                      }}
                      src={free}
                      alt="free"
                    />
                  </a>
                ) : (
                  <NavLink to={paths.CRM} className="section-link relative">
                  <img style={{ width: '17px' }} src={crm} className="mr-2" alt=""/>
                  Seguir clientes
                  <img
                    className={isFreeUser ? '' : 'hidden'}
                    style={{
                      width: '16px',
                      position: 'absolute',
                      right: '4px',
                      top: '-7px',
                    }}
                    src={free}
                    alt="free"
                  />
                  </NavLink>
                )} */}
                {/* <Tooltip TransitionComponent={Zoom} title={isFreeUser ? 'Funcionalidad Premium' : ''}>
                  <a
                    onClick={() => this.props.validateRolesAndAlert(`${ASTROSELLING_1}admin/order`)}
                    className="section-link cursor-pointer relative"
                    // activeClassName="section-link--active"
                  >
                    <img src={Orders} className="mr-2" alt=""/>
                    Órdenes
                    <img
                      className={isFreeUser ? '' : 'hidden'}
                      style={{
                        width: '17px',
                        position: 'absolute',
                        right: '4px',
                        top: '-7px',
                      }}
                      src={premium}
                      alt="premium"
                    />
                  </a>
                </Tooltip> */}
                {/* <Tooltip TransitionComponent={Zoom} title={isFreeUser ? 'Funcionalidad Premium' : ''}>
                  <a
                    onClick={() => this.props.validateRolesAndAlert(`${ASTROSELLING_1}admin/dashboard`)}
                    className="section-link cursor-pointer relative"

                    // activeClassName="section-link--active"
                  >
                    <img src={Store} className="mr-2" alt=""/>
                    Mi tienda
                    <img
                      className={isFreeUser ? '' : 'hidden'}
                      style={{
                        width: '17px',
                        position: 'absolute',
                        right: '4px',
                        top: '-7px',
                      }}
                      src={premium}
                      alt="premium"
                    />
                  </a>
                </Tooltip> */}
              </div>
            </div>
          </div>
        )}
        <div className="flex-1"/>
        {me ? (
          <div className="flex">
            {!!stores.length && (
              <div className="py-6 px-9 border-l border-r border-solid border-blue-grey text-white flex items-end justify-between">
                <div className="flex items-center">
                  <DropDown list={stores} changeHandler={this.props.selectHandler}>
                    {this.props.changingStore ? (
                      <Loader/>
                    ) : (
                      <div>
                        {store ? (
                          <>
                            <Bag className="mr-2"/>
                            <b style={{ paddingRight: '1em' }}>{store.name}</b>
                          </>
                        ) : (
                          <em style={{ paddingRight: '1em' }}>Seleccione Tienda</em>
                        )}
                        <ChevronDown className="text-green"/>
                      </div>
                    )}
                  </DropDown>
                </div>
              </div>
            )}
            <div className="py-4 px-9 text-white flex items-center">
              <div className="-mx-3 flex items-center">
                {/* <div className="px-3 h-4">
                      <Cog className="text-white opacity-50 hover:opacity-100" />
                    </div> */}
                <div className="px-3 h-4">
                  {/* <Bell className="text-white opacity-50 hover:opacity-100" /> */}
                  <a target="_blank" href="http://help.astroselling.com/">
                    {' '}
                    <Help className="text-white opacity-50 hover:opacity-100"/>
                  </a>
                </div>
                <DropDown
                  list={[
                    {
                      id: 'logout',
                      name: (
                        <i className="fa fa-sign-out">
                          <span> Cerrar sesión</span>
                        </i>
                      ),
                    },
                  ]}
                  changeHandler={this.props.handleLogOut}
                >
                  <div className="px-3 h-8">
                    <Avatar {...me} />
                    <ChevronDown className="text-green"/>
                  </div>
                </DropDown>
              </div>
            </div>
          </div>
        ) : (
          <span className="mr-6">
            <Loader/>
          </span>
        )}
      </>
    );
  }
}

export default UserMenu;
