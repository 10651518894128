import { graphql } from 'react-apollo';
import UPDATE_ANSWER from '../../gql/updatePredefinedAnswer';

export default graphql(
  UPDATE_ANSWER,
  {
    props: ({ mutate }) => ({
      updatePredefinedAnswer: ({ id, slug, answer }) => mutate({
        variables: {
          input: {
            id,
            slug,
            answer,
          }
        },
      })
    })
  },
);
