import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import { compose, withState, withHandlers, branch } from 'recompose';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SelectListings from './SelectListings';
import SincronizarConfiguracion from './SincronizarConfiguracion';
import Disclaimer from './products/components/Disclaimer';
import Loader from '../../components/svgs/Loader';
import withLinkProduct from './products/components/withLinkProduct';
import withProduct from './products/components/withProduct';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: "480px",
    maxHeight: "480px"
  },
  backButton: {
    marginRight: theme.spacing(1),
    textTransform: "none"
  },
  confirmButton: {
    textTransform: "none"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelButton: {
    color: "#2b48f0",
    fontFamily: "Arboria",
    padding: "0.5rem 2rem 0.5rem 2rem",
    textTransform: "none"
  }
}));

function getSteps() {
  return ['Selecciona las publicaciones', 'Selecciona configuración', 'Confirmación'];
}

function getStepContent(stepIndex, props) {
  const { item_ids, onChangeSelected, onChangeCheckbox, configuration, product, initialValueMap } = props;
  switch (stepIndex) {
    case 0:
      return <SelectListings 
              listingSelected={item_ids}
              initialValueMap={initialValueMap}
              product={product}
              onChangeSelected={onChangeSelected}/>;
    case 1:
      return <SincronizarConfiguracion 
                handleChangeCheckbox={onChangeCheckbox}
                product={product}
                configuration={configuration}
              />;
    case 2:
      return <Disclaimer />;
    default:
      return <Typography>'Uknown stepIndex'</Typography>;
  }
}

function HorizontalLabelPositionBelowStepper(props) {
  const { product, linkProduct, productLoading, onChangeLoading, item_ids, configuration, hasLinks, tableRef } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  return (
    <div className={classes.root}> 
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> 
      <div> 
        <div>
          <div className="overflow-auto mb-4 px-4" style={{minHeight: "275px", maxHeight: "275px", maxWidth: "500px"}}>
            {hasLinks && productLoading ? <Loader className="flex-1 text-vivid-blue self-center h-6"/> : getStepContent(activeStep, props)}
          </div>
          {
            (!hasLinks || !productLoading) && (
              <div style={{borderTop: "solid 1px lightgrey"}} className="flex justify-between pt-4 pb-2">
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Atrás
                  </Button> 
                  { 
                    activeStep === steps.length - 1 && ( 
                    <Button className={classes.confirmButton} variant="contained" color="primary" onClick={() => {
                      onChangeLoading(true);
                      let items = item_ids.map(node => node.value);
                      linkProduct(product, items, configuration).then(() => {
                        onChangeLoading(false);
                        props.refreshTable();
                        props.closeModal();
                      })}
                    }>
                      Finalizar
                    </Button>)
                  }
                  { 
                    activeStep !== steps.length - 1 && ( 
                    <Button disabled={item_ids && !item_ids.length} className={classes.confirmButton} variant="contained" color="primary" onClick={handleNext}>
                      Siguiente
                    </Button>)
                  }
                </div>
                <Button className={classes.cancelButton} onClick={props.closeModal}>
                  Cancelar
                </Button>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default compose(
  withState('loading', 'onChangeLoading', false),
  withHandlers({
    onChangeCheckbox: ({ onChangeConfiguration, configuration }) => (id, value) => {
      configuration[id] = value;
      onChangeConfiguration(configuration);
    },
  }),
  branch(({hasLinks}) => { return hasLinks }, withProduct),
  branch(({product}) => { return product ? true : false },
    compose(
      withState('item_ids', 'onChangeSelected', ({hasLinks, initialLisitingItems}) => hasLinks ? initialLisitingItems : []),
      withState('configuration', 'onChangeConfiguration', ({hasLinks, product}) => {
        return {
          sync_title: hasLinks ? product.links[0].sync_title : true,
          sync_price: hasLinks ? product.links[0].sync_price : true,
          sync_stock: hasLinks ? product.links[0].sync_stock : true,
          sync_images: hasLinks ? product.links[0].sync_images : true,
          sync_description: hasLinks ? product.links[0].sync_description : true
        }
      }),
      withHandlers({
        onChangeCheckbox: ({ onChangeConfiguration, configuration }) => (id, value) => {
          configuration[id] = value;
          onChangeConfiguration(configuration);
        },
      }),
    )
  ),
  withLinkProduct
)(HorizontalLabelPositionBelowStepper);
