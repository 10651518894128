import { gql } from 'apollo-boost';

export default gql`
query Product($filter: ProductInput!) {
  product(filters: $filter) {
    id
    id_in_source
    title
    currency
    price
    price_var_min
    price_var_max
    sku
    stock
    description
    images
    source_id
    variations {
      id 
      title
      currency
      price
      sku
      stock
      images
    }
    links {
      source_id
      product_id
      items {
        item_id
        title
        channel_id
        channel_name
      }
      sync_stock
      sync_price
      sync_title
      sync_images
      sync_description
    }
  }
}
`;
