import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import HistoryContent from './HistoryContent';

export default History = () => {
  return (
    <div className="w-3/4 flex-1">
      <div className="flex flex-col h-full">
        <h2 className="text-blue-darkest text-2xl mb-2">Historial</h2>
        <p className="text-sm text-blue-darkest mb-3">Historial de preguntas respondidas</p>
        <div className="card flex-1 relative overflow-hidden flex flex-col">
          <HistoryContent />
        </div>
      </div>
    </div>
  );
};
