import { graphql } from 'react-apollo';

import RESETPASSWORD from '../gql/resetPassword';

export default graphql(RESETPASSWORD, {
  options({ input }) {
    return {
      variables: {
        input,
      },
    };
  },
  props({ ownProps, mutate }) {
    const resetPassword = (input) => {
      return mutate({
        variables: { input }
      })
    };
    
    return {
      ...ownProps,
      resetPassword
    };
  },
});
