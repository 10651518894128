import React from 'react';

export default ({ className, style, size = '496pt' }) => (
  <svg className={className} style={style} height={size} viewBox="0 -32 496.024 496" width={size} xmlns="http://www.w3.org/2000/svg">
    <path d="m8.023438 151.96875v168l140-84zm0 0" fill="#acabb1" />
    <path d="m248.023438 175.96875-240 144v88c.027343 8.828125 7.175781 15.976562 16 16h448c8.828124-.023438 15.972656-7.171875 16-16v-88zm-160 216" fill="#fff" />
    <path d="m248.023438 7.96875-240 144 140 84 100-60 100 60 140-84zm0 0" fill="#e0e0e2" />
    <path d="m488.023438 319.96875v-168l-140 84zm0 0" fill="#acabb1" />
    <g fill="#231f20">
      <path d="m495.808594 150.457031c-.070313-.34375-.171875-.679687-.296875-1.007812-.121094-.371094-.273438-.734375-.449219-1.085938-.050781-.175781-.113281-.34375-.183594-.511719-.054687-.105468-.167968-.152343-.230468-.257812-.441407-.582031-.945313-1.121094-1.496094-1.597656-.234375-.222656-.488282-.425782-.753906-.609375-.101563-.0625-.152344-.175781-.253907-.230469l-240-144c-2.535156-1.523438-5.703125-1.523438-8.242187 0l-240 144c-.09375.054688-.140625.160156-.238282.222656-.28125.1875-.550781.394532-.800781.625-.3125.269532-.609375.5625-.878906.878906-.21875.238282-.421875.488282-.609375.75-.0625.105469-.175781.152344-.230469.257813-.070312.167969-.132812.339844-.183593.511719-.175782.351562-.328126.714844-.449219 1.089844-.125.324218-.226563.664062-.296875 1.007812-.054688.320312-.09375.648438-.109375.972656 0 .1875-.105469.347656-.105469.539063v255.957031c0 13.257812 10.746094 24 24 24h448.023438c13.253906 0 24-10.742188 24-24v-256c0-.191406-.09375-.351562-.101563-.535156-.019531-.328125-.054687-.652344-.113281-.976563zm-15.785156 155.386719-116.449219-69.875 116.449219-69.871094zm-232-288.546875 224.449218 134.671875-124.449218 74.671875-95.878907-57.527344c-2.535156-1.523437-5.703125-1.523437-8.242187 0l-95.878906 57.527344-124.449219-74.671875zm-232 148.800781 116.449218 69.871094-116.449218 69.875zm456 249.871094h-448c-4.417969 0-8-3.578125-8-8v-83.46875l232-139.203125 232 139.203125v83.46875c0 4.421875-3.582032 8-8 8zm0 0" />
      <path d="m56.023438 383.96875h-8v-24c0-4.417969-3.582032-8-8-8-4.417969 0-8 3.582031-8 8v32c0 4.421875 3.582031 8 8 8h16c4.417968 0 8-3.578125 8-8 0-4.417969-3.582032-8-8-8zm0 0" />
      <path d="m104.023438 383.96875h-16c-4.417969 0-8 3.582031-8 8 0 4.421875 3.582031 8 8 8h16c4.417968 0 8-3.578125 8-8 0-4.417969-3.582032-8-8-8zm0 0" />
    </g>
  </svg>
);
