import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MobileListingEditing from './MobileListingEditing';
import UPDATE_LISTING from '../../synchronize/products/gql/updateListingMutation';
import { useMutation } from '@apollo/react-hooks';
import Swal from 'sweetalert2';
import Loader from '../../../components/svgs/Loader';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#151e47',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MobileListingPopup = props => {
  const { closeEditingPopup, openPopup, listing } = props;
  const classes = useStyles();

  const [newTitle, setNewTitle] = useState(listing.title);
  const [newPrice, setNewPrice] = useState(listing.price);
  const [newStock, setNewStock] = useState(listing.available_quantity);
  const [newSKU, setNewSKU] = useState(listing.seller_custom_field);
  const [newStatus, setNewStatus] = useState(listing.status);

  const [updateListing, { loading, error, onCompleted }] = useMutation(UPDATE_LISTING, {
    onCompleted() {
      closeEditingPopup();
    },
  });

  const saveListing = () => {
    updateListing({
      variables: { input: { listingId: listing.id, title: newTitle, seller_custom_field: newSKU, price: newPrice, available_quantity: newStock, status: newStatus } },
    });
  };

  if (error) {
    Swal.fire({
      type: 'error',
      title: 'Oops!...',
      text: error ? error.graphQLErrors[0].message : 'Algo ha salido mal :(',
    });
  }

  if (onCompleted) {
    closeEditingPopup();
  }

  const resetListingFields = () => {
    setNewTitle(listing.title);
    setNewPrice(listing.price);
    setNewStock(listing.available_quantity);
    setNewSKU(listing.seller_custom_field);
    setNewStatus(listing.status);
  };

  return (
    <div>
      <Dialog fullScreen open={openPopup} onClose={closeEditingPopup} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                closeEditingPopup();
                resetListingFields();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              editar publicación
            </Typography>
            <Button autoFocus color="inherit" onClick={saveListing}>
              Guardar
            </Button>
          </Toolbar>
        </AppBar>
        {loading ? (
          <div style={{ height: '85%' }} className="flex flex-col items-center justify-center">
            <Loader className="text-vivid-blue" />
            <p>Actualizando publicación...</p>
          </div>
        ) : (
          <MobileListingEditing
            setNewTitle={setNewTitle}
            setNewPrice={setNewPrice}
            setNewSKU={setNewSKU}
            setNewStatus={setNewStatus}
            setNewStock={setNewStock}
            newTitle={newTitle}
            newSKU={newSKU}
            newStock={newStock}
            newPrice={newPrice}
            newStatus={newStatus}
          />
        )}
      </Dialog>
    </div>
  );
};

export default MobileListingPopup;
