import { graphql } from 'react-apollo';

import STORESIZECHARTS from './gql/storeSizeCharts';

export default graphql(
  STORESIZECHARTS,
  {
    options({user}) {
      const stores = (user.me && user.me.stores) || [];
      const storeSession = sessionStorage.getItem('store');
      const storeFromSession = storeSession ? JSON.parse(storeSession) : null ;
      const store = stores.find(store => store.id === storeFromSession.id);
      return {
        notifyOnNetworkStatusChange: true,
        variables: {
          storeId: store.id
        }
      }
    },
    props({ data, ownProps }) {
      return {
        storeSizeCharts: data.storeSizeCharts ? data.storeSizeCharts.filter((node)=> node.id !== +ownProps.guideSizeId) : []
      };
    },
  }
)
