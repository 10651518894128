import React from 'react';
import * as Sentry from '@sentry/browser';
import SignUpIllustration from '../components/svgs/onboarding/SignUpIllustration';
import ArrowRightIcon from '../components/svgs/ArrowRight';
import Button from '../components/Button';
import paths from '../app/paths';

export default class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.handleGoToHome = this.handleGoToHome.bind(this);
  }

  // This lifecycle is invoked after an error has been thrown by a descendant component.
  // It receives the error that was thrown as a parameter.
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  logErrorToSentry(error, info) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        const eventId = Sentry.captureException(error);
        this.setState({eventId});
      });
    }
  }

  handleGoToHome() {
    document.location.href = paths.QUESTIONS;
  }

  
  // This lifecycle is invoked after an error has been thrown by a descendant component. It receives two parameters:
  // error - The error that was thrown.
  // info - An object with a componentStack
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.logErrorToSentry(error, info);
  }

  render() {
    if (this.state.hasError) {
      // Generic error UI. When more details are needed, create one Custom Error Component.
      return (
      <div className="flex flex-1 items-start justify-center md:items-center">
        <div className="onboarding__main-card bg-white flex items-center justify-between max-w-3xl py-7 px-8 md:py-13 md:px-18">
            <div className="onboarding flex-1">
                {/* Todo: Define what should be shown here. */}
                <h1 className="mb-8">Ocurrió un error.</h1>
                <p className="description mb-12">
                    Estamos trabajando para solucionar el problema.
                </p>
                {/* Todo chequear si no estaba en esta pagina */}
                <Button onClick={this.handleGoToHome}>
                    Ir al Inicio <ArrowRightIcon />
                </Button>
            </div>
            <div className="flex-1 ml-24 hidden xl:block">
                {/* Todo: Change Illustration */}
                <SignUpIllustration />
            </div>
        </div>
      </div>);
    }
    return this.props.children; 
  }
}