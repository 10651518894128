import React from 'react';
import Hand from '../../components/svgs/Hand';
import Hashtag from '../../components/svgs/Hashtag';

export default [
  {
    disableBeacon: true,
    target: '#fake-listing',
    content: (
      <div>
        <h3>¡Bienvenido!</h3>
        <p>Aquí podrás ver todas tus preguntas centralizadas y responderlas de manera fácil y rápida.</p>
      </div>
    ),
    placement: 'auto',
  },
  {
    target: '#refresh-questions',
    content: (
      <div>
        <p>Con esta opción vas a poder recargar y actualizar las preguntas de MercadoLibre</p>
      </div>
    ),
    placement: 'auto',
  },
  {
    disableBeacon: true,
    target: '#predefined-answers',
    content: (
      <div>
        <p>Aquí puedes modificar la configuración de las respuestas automáticas si lo deseas. Mejorarás tu productividad hasta en un 300%</p>
      </div>
    ),
    placement: 'auto',
  },
  {
    target: '#user-question',
    content: (
      <div>
        <p>Ahora te vamos a enseñar a responder.</p>
      </div>
    ),
    placement: 'auto',
  },
  {
    target: '#user-question-tag',
    content: (
      <div>
        <p>
          Nuestra inteligencia artificial detectará sobre qué trata la pregunta. Si lo deseas, podrás contestarla haciendo click sobre el{' '}
          <strong>
            <Hashtag style={{ width: '16px', height: '16px', marginLeft: '4px' }} />
            tag
          </strong>{' '}
          detectado
        </p>
      </div>
    ),
    placement: 'auto',
  },
  {
    target: '#response-form-greetings',
    content: (
      <div>
        <p>
          En esta sección tendrás acceso a tus
          <strong>
            <Hashtag style={{ width: '16px', height: '16px', marginLeft: '4px' }} />
            tags
          </strong>{' '}
          y
          <strong>
            <Hand style={{ width: '17px', height: '17px', marginLeft: '4px' }} />
            saludos
          </strong>
          . Con un solo click vas a poder usarlos para responder fácil y rápido. Recuerda que siempre puedes cambiarlos en la sección de "Respuestas predefinidas".
        </p>
      </div>
    ),
    placement: 'auto',
  },
  {
    target: '#response-form-actions',
    content: (
      <div>
        <p>Finalmente, en esta parte vas a encontrar los botones de acción. Con ellos puedes responder la pregunta, eliminarla o cancelarla para contestarla en otro momento.</p>
      </div>
    ),
    placement: 'auto',
  },
  {
    target: '#user-question-checkbox',
    content: (
      <div>
        <p>Haciendo click aquí, podrás seleccionar varias preguntas y responderlas a la vez.</p>
      </div>
    ),
    placement: 'auto',
  },
];
