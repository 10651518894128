import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Logo from '../components/svgs/Logo';
import Store from '../assets/images/menu/mi-tienda.png';
import Messages from '../assets/images/menu/preguntas.png';
import Listings from '../assets/images/menu/publicaciones.png';
import Orders from '../assets/images/menu/rdenes.png';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import paths from './paths';
import { NavLink } from 'react-router-dom';
import { compose } from 'react-apollo';
import DropDown from '../components/DropDown';
import ChevronDown from '../components/svgs/ChevronDown';
import Loader from '../components/svgs/Loader';
import Bag from '../components/svgs/Bag';
import Help from '../components/svgs/Help';
import crm from '../assets/images/menu/crm.png';
import premium from '../assets/images/menu/premium.png';
import free from '../assets/images/menu/Free.png';

const ASTROSELLING_1 = process.env.ASTROSELLING_1;
const ASTROSELLING_3 = process.env.ASTROSELLING_3;

const drawerWidth = 220;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#151e47',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3), //theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  textItem: {
    color: 'inherit',
    fontFamily: 'Arboria',
  },
});

class PersistentDrawerLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  render() {
    const { classes, theme, isFreeUser } = this.props;
    const { open } = this.state;
    const { me } = this.props;
    let stores = (me && me.stores) || [];
    const storeSession = sessionStorage.getItem('store');
    const storeFromSession = storeSession ? JSON.parse(storeSession) : null;
    const store = me && !!stores.length && storeFromSession ? stores.find(store => store.id === storeFromSession.id) : null;
    let addStore = {
      id: 'addAnotherStore',
      name: 'Vincular otra tienda',
      className: ' border-t text-vivid-blue hover:bg-vivid-blue hover:text-white',
    };
    stores = [...stores, addStore];

    return (
      <ClickAwayListener onClickAway={this.handleDrawerClose}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            style={{ backgroundColor: '#151e47' }}
            position="relative"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar disableGutters={!open}>
              <IconButton color="inherit" aria-label="Open drawer" onClick={this.handleDrawerOpen} className={classNames(classes.menuButton, open && classes.hide)}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" noWrap>
                <Logo className="w-24" />
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon style={{ color: 'white' }} /> : <ChevronRightIcon style={{ color: 'white' }} />}
              </IconButton>
            </div>
            <Divider style={{ backgroundColor: '#8a8fb1' }} />
            {me && (
              <List>
                <ListItem component={NavLink} to={paths.QUESTIONS} className="text-white" activeClassName="text-light-greenish-blue" onClick={this.handleDrawerClose}>
                  <ListItemIcon>
                    <img src={Messages} className="mr-2" alt="" />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.textItem }}
                    style={{ padding: '0px' }}
                    primary={
                      <div>
                        Preguntas
                        {store && !!store.pendingQuestions && <sup style={{ marginRight: '58px' }} className="dot mt-2"></sup>}
                      </div>
                    }
                  />
                </ListItem>
                <ListItem
                  component='a'
                  // component={isFreeUser ? NavLink : 'a'}
                  // to={isFreeUser ? paths.PUBLICATIONS : `${ASTROSELLING_1}admin/listing`}
                  href={isFreeUser ? `${ASTROSELLING_3}admin/resources/listings` : `${ASTROSELLING_1}admin/listing`}
                  // onClick={isFreeUser ? this.handleDrawerClose : () => {}}
                  className="text-white"
                  activeClassName="text-light-greenish-blue"
                >
                  <ListItemIcon>
                    <img src={Listings} className="mr-2" alt="" />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.textItem }} style={{ padding: '0px' }} primary="Publicaciones" />
                  <img
                    className={isFreeUser ? '' : 'hidden'}
                    style={{
                      position: 'absolute',
                      width: '16px',
                      right: '27px',
                      top: '10px',
                    }}
                    src={free}
                    alt="free"
                  />
                </ListItem>
                <ListItem
                  component={isFreeUser ? 'a' : NavLink}
                  to={isFreeUser ? `${ASTROSELLING_3}admin/resources/meli-contacts` : paths.CRM  }
                  href={isFreeUser ? `${ASTROSELLING_3}admin/resources/meli-contacts` : paths.CRM }
                  className="text-white"
                  activeClassName="text-light-greenish-blue"
                  onClick={ !isFreeUser ?? this.handleDrawerClose }
                >
                  <ListItemIcon>
                    <img src={crm} className="mr-2" alt="" style={{ height: '16px' }} />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.textItem }} style={{ padding: '0px' }} primary={<div>Seguir clientes</div>} />
                  <img
                    className={isFreeUser ? '' : 'hidden'}
                    style={{
                      position: 'absolute',
                      width: '16px',
                      right: '22px',
                      top: '10px',
                    }}
                    src={free}
                    alt="free"
                  />
                </ListItem>
                <ListItem component="a" onClick={() => this.props.validateRolesAndAlert(`${ASTROSELLING_1}admin/order`)} className="text-white">
                  <ListItemIcon>
                    <img src={Orders} className="mr-2" alt="" />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.textItem }} style={{ padding: '0px' }} primary="Órdenes" />
                  <img
                    className={isFreeUser ? '' : 'hidden'}
                    style={{
                      position: 'absolute',
                      width: '17px',
                      right: '66px',
                      top: '10px',
                    }}
                    src={premium}
                    alt="premium"
                  />
                </ListItem>
                <ListItem component="a" onClick={() => this.props.validateRolesAndAlert(`${ASTROSELLING_1}admin/dashboard`)} className="text-white">
                  <ListItemIcon>
                    <img src={Store} className="mr-2" alt="" />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.textItem }} style={{ padding: '0px' }} primary="Mi Tienda" />
                  <img
                    className={isFreeUser ? '' : 'hidden'}
                    style={{
                      position: 'absolute',
                      width: '17px',
                      right: '60px',
                      top: '10px',
                    }}
                    src={premium}
                    alt="premium"
                  />
                </ListItem>
              </List>
            )}
            <Divider style={{ backgroundColor: '#8a8fb1' }} />
            {me ? (
              <>
                <div className="flex flex-col bg-dark-blue-grey py-4 px-4 text-white">
                  {!!stores.length && !this.props.changingStore ? (
                    <DropDown list={stores.map(s => ({ ...s, ...(store && s.id === store.id ? { className: 'text-vivid-blue' } : {}) }))} changeHandler={this.props.selectHandler}>
                      <div>
                        {store ? (
                          <>
                            <Bag className="mr-2" />
                            <b style={{ paddingRight: '1em' }}>{store.name}</b>
                          </>
                        ) : (
                          <em style={{ paddingRight: '1em' }}>Seleccione Tienda</em>
                        )}
                        <ChevronDown className="text-green" />
                      </div>
                    </DropDown>
                  ) : (
                    <Loader className="self-center" />
                  )}
                </div>
                <div className="flex flex-col bg-dark-blue-grey py-4 px-4 text-white">
                  <a target="_blank" href="http://soporte.astroselling.com/support/" style={{ display: 'flex', alignItems: 'center' }}>
                    <Help className="text-white mr-2" />
                    <ListItemText classes={{ primary: classes.textItem }} style={{ color: 'white' }} primary="Contactanos" />
                  </a>
                </div>
                <Divider style={{ backgroundColor: '#8a8fb1' }} />
                <ListItem onClick={this.props.handleLogOut} className="text-white cursor-pointer">
                  <ListItemIcon>
                    <i style={{ color: '#8a8fb1', fontSize: '24px' }} className="fa fa-sign-out" />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.textItem }} style={{ padding: '0px' }} primary="Cerrar sesión" />
                </ListItem>
              </>
            ) : (
              <span className="py-4 mx-auto">
                <Loader />
              </span>
            )}
          </Drawer>
        </div>
      </ClickAwayListener>
    );
  }
}

PersistentDrawerLeft.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(withStyles(styles, { withTheme: true }))(PersistentDrawerLeft);
