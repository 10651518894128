import React from 'react';
import Arrow from './../../../../components/svgs/Arrow';
import Loader from './../../../../components/svgs/Loader';

const TitleForm = props => {
  const {
    title,
    onTitleChange,
    onTitleSubmitted,
    loadingAttributes
  } = props;
  const buttonClassName = loadingAttributes ? "text-gray self-end" : "text-blue self-end";
  return (
  <div className="w-full h-full flex">
    <div className="h-full border-r border-solid px-5 py-4 transition-property-width transition w-2/5 animated fadeInRight">
      <div className="h-full flex flex-col">
        {/* Todo fix the movement of the title when typing one title */}
        <div className="flex-1 flex flex-col justify-center px-9">
          <h3 className="text-blue-darkest text-2xl font-normal mb-5">
            Título
          </h3>
            <input
              type="text"
              value={title}
              onChange={(event) => { onTitleChange(event.target.value) }}
              placeholder="Escribí un título para la guía. Ej: Pantalones, Remeras…"
              className="pb-2 text-blue border-b border-solid w-full"
            />
        </div>
        {
          title && title.length && (
            <button className={buttonClassName} onClick={onTitleSubmitted}
            disabled={loadingAttributes}>
              {
                loadingAttributes ? <Loader className="text-vivid-blue" /> : (<><Arrow className="mr-2"/> continuar</>) 
              }
            </button>
          )
        }
      </div>
    </div>
  </div>
)};


export default TitleForm;