import { gql } from 'apollo-boost';
import listing from './listing';

export default gql`
  query listings($filter: ListingsInput!, $paginate: PaginateInput!) {
    listings(filters: $filter, paginate: $paginate) {
      data {
        ...Listing
      }
      pageInfo {
        prevCursor
        nextCursor
        per_page
        hasMorePages
      }
    }
  }
  ${listing}
`;
