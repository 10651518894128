import React from 'react';
import ContactOrder from '../../../components/svgs/onboarding/ContactOrder';
import ContactQuestion from '../../../components/svgs/onboarding/ContactQuestion';

const MobileContactCard = ({ contact, onClick }) => (
  <div
    onClick={onClick}
    style={{
      background: 'white',
      borderRadius: '6px',
      width: '100%',
      marginBottom: '10px',
      padding: '20px 0',
      boxShadow: '0 3px 16px 0 #0000002e',
      fontSize: '14px',
      color: '#1c181d',
      position: 'relative',
    }}
  >
    <div style={{ padding: '0 20px' }}>
      <h3 style={{ fontSize: '16px', lineHeight: '1.25' }}>
        {`${!contact.first_name && !contact.last_name ? contact.nickname : `${contact.first_name || ''} ${contact.last_name || ''}`}`}
      </h3>
      {(contact.first_name || contact.last_name) && <div style={{ fontSize: '12px', lineHeight: '1.5', color: '#6e6b6f' }}>Mercadolibre: {contact.nickname}</div>}
      {(contact.first_name || contact.last_name || contact.phone) && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <i style={{ fontSize: '24px', color: 'rgb(132, 130, 133)' }} className="mr-4 fa fa-mobile" aria-hidden="true" />
          <a href={`tel:${contact.phone}`} style={{ color: '#1c181d' }}>
            {contact.phone ? contact.phone : <i>sin teléfono</i>}
          </a>
        </div>
      )}
    </div>

    <div style={{ margin: '14px 0', width: '100%', height: '1px', backgroundColor: '#d2dbe2' }} />

    <div
      style={{
        padding: '0 20px',
        display: 'flex',
        justifyContent: 'space-around',
      }}
    >
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <ContactOrder size={24} style={{ fill: '#848285', marginRight: '9px' }} />
        <span
          style={{
            borderRadius: '8px',
            backgroundColor: '#f2f5f7',
            padding: '4px 6px',
            minWidth: '28px',
            textAlign: 'center',
          }}
        >
          {contact.ordersQty}
        </span>
      </span>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <ContactQuestion size={24} style={{ fill: '#848285', marginRight: '9px' }} />
        <span
          style={{
            borderRadius: '8px',
            backgroundColor: '#f2f5f7',
            padding: '4px 6px',
            minWidth: '28px',
            textAlign: 'center',
          }}
        >
          {contact.questionsQty}
        </span>
      </span>
    </div>
    <a href={contact.permalink} target="_blank">
      <i
        style={{
          cursor: 'pointer',
          fontSize: '18px',
          position: 'absolute',
          top: '20px',
          right: '20px',
          color: 'rgb(132, 130, 133)',
        }}
        className="fa fa-external-link"
        aria-hidden="true"
      />
    </a>
  </div>
);

export default MobileContactCard;
