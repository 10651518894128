import { gql } from 'apollo-boost';

export default gql`
  query sizeChart($size_chart_id: Int!) {
    sizeChart(size_chart_id: $size_chart_id) {
      id
      chartName
      linked_items_quantity
      columns {
        id
        name
        type {
          default_unit
        }
      }
      sizes {
        sizeName
        measurements {
          id
          value
        }
      }
    }
  }`
;
