import React, { useState } from 'react';
import Joyride, { EVENTS } from 'react-joyride';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import QuestionsList from './QuestionsList';
import resources from '../../onboarding/gql';
import VIEWER from '../../../app/gql/viewer';
import PredefinedAnswers from './PredefinedAnswers';
import UPDATE_WALK_THROUGH from '../gql/updateWalkThrough';
import SideContactInfo from '../../onboarding/components/SideContactInfo';
import steps from '../WalkThroughSteps.js';

const Index = () => {
  const [startingStep, setStartingStep] = useState(0);
  const [joyRide, setJoyRide] = useState(null);
  const [showWalkThrough, setShowWalkThrough] = useState(null);
  const [questionsReady, setQuestionsReady] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);

  const [loadContactDetailsFromServer, { called: loadContactDetailsCalled, data: loadContactDetailsData, loading: loadContactDetailsLoading }] = useLazyQuery(
    resources.contacts({ loadRelations: ['orders', 'questions'] })
  );

  const { data: user } = useQuery(VIEWER, { notifyOnNetworkStatusChange: true });

  const [updateWalkThrough] = useMutation(UPDATE_WALK_THROUGH);

  if (showWalkThrough == null && user && user.me && user.me.show_walk_throughs) {
    setShowWalkThrough(user.me.show_walk_throughs.includes('QUESTIONS'));
  }

  const restartWalkThrough = starting_step => {
    if (joyRide) {
      joyRide.reset(true);
    }
    setStartingStep(starting_step);
  };

  const handleJoyrideCallback = data => {
    const { type } = data;
    if (data.status === 'finished' && user.me.show_walk_throughs.includes('QUESTIONS')) {
      return markWalkThroughAsSeen();
    }

    if (data.step.target === '#user-question-tag' && !EVENTS.TARGET_NOT_FOUND.includes(type)) {
      document.getElementById('user-question').click();
    }
  };

  const markWalkThroughAsSeen = () => {
    return updateWalkThrough({ variables: { input: { mark_as_seen: ['QUESTIONS'] } } });
  };

  const loadContactDetails = meliId => {
    if (meliId) {
      loadContactDetailsFromServer({ variables: { filters: { meli_id: meliId }, paginate: { per_page: 1, page: 1 } } });
    }
    setShowContactInfo(!!meliId);
  };

  const contact = loadContactDetailsCalled && !loadContactDetailsLoading ? loadContactDetailsData.meliContacts.data[0] : false;

  return (
    <>
      <Joyride
        continuous={true}
        run={!!(questionsReady && (showWalkThrough === null ? false : showWalkThrough))}
        locale={{ back: 'Atrás', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
        steps={steps.slice(startingStep)}
        styles={{ options: { primaryColor: '#2B48F0' } }}
        getHelpers={setJoyRide}
        callback={handleJoyrideCallback}
      />

      <div className="md:w-3/4 flex-1">
        <QuestionsList dataReady={() => setQuestionsReady(true)} restartWalkThrough={() => restartWalkThrough(showWalkThrough ? 0 : 1)} loadContactDetails={loadContactDetails} />
      </div>

      <div className="w-1/4 ml-8 hidden pb-10 lg:flex" style={{ flex: '0 0 auto' }}>
        {showContactInfo && loadContactDetailsCalled ? (
          <SideContactInfo loading={loadContactDetailsLoading} contact={contact} />
        ) : (
          <PredefinedAnswers restartWalkThrough={() => restartWalkThrough(2)} />
        )}
      </div>
    </>
  );
};

export default Index;
