import { graphql } from 'react-apollo';
import ADD_TAG from '../../gql/addTag';

export default graphql(
  ADD_TAG,
  {
    props: ({ mutate }) => ({
      addTag: (tag, response) => mutate({
        variables: {
          tag,
          response,
        },
      })
    })
  },
);
