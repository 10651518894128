import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import { compose, branch } from 'recompose';

import CreateGuide from './components/CreateGuide';
import Guides from './components/Guides';
import withUserData from './../../app/withUserData';
import withGuidesData from './../sizeGuide/withGuidesData';

const SizeGuide = props => {
  const {
    storeSizeCharts
  } = props;
    return (
    <section className="w-full px-4 lg:px-14 py-10 flex overflow-x-hidden">
      <div className="md:w-3/4 flex-1">
        <Breadcrumb path={[
          {title: 'Mi tienda', path: '/'},
          {title: 'Guía de Talles', path: '/guia-de-talles'}
        ]}/>

        <h2 className="text-blue-darkest text-2xl mb-2">
          Crear guía de talles
        </h2>

        <p className="text-sm text-blue-darkest mb-3">
          Crea una nueva guía de talles
        </p>

        <CreateGuide />
        <Guides storeSizeCharts={storeSizeCharts}/>
      </div>
    </section>
    );
}
export default compose(
  withUserData,
  branch(({user}) => { 
    const stores = (user.me && user.me.stores) || [];
    const storeSession = sessionStorage.getItem('store');
    const storeFromSession = storeSession ? JSON.parse(storeSession) : null ;
    const store = stores.find(store => store.id === storeFromSession.id);
    return store ? true : false
  },
  withGuidesData)
)(SizeGuide);

