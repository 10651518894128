import { gql } from 'apollo-boost';

export default gql`
mutation UpdateWalkThrough ($input: UpdateWalkThroughInput!) {
    updateWalkThrough (input: $input) {
      user {
        id
        email
        show_walk_throughs
      }
    }
  }
`;