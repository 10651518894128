import { gql } from 'apollo-boost';

export default gql`
mutation UpdatePredefinedAnswer ($input: UpdatePredefinedAnswerInput!) {
  updatePredefinedAnswer (input: $input) {
    predefinedAnswer {
      id
      slug
      answer
    }
  }
}
`;
